'use strict';

var meta = require('./meta.js');
var OTHelpers = require('../common-js-helpers/OTHelpers.js');
var proxyExtras = require('./proxy_extras.js');

var Proto = function PluginProxies() {};
var pluginProxies = new Proto();
var proxies = {};

// Private API

// This is called whenever a Proxy's destroy event fires.
var cleanupProxyOnDestroy = function cleanupProxyOnDestroy(object) {
  if (pluginProxies.mediaCapturer && pluginProxies.mediaCapturer.id === object.id) {
    pluginProxies.mediaCapturer = null;
  } else if (proxies.hasOwnProperty(object.id)) {
    delete proxies[object.id];
  }

  if (object.OTHelpers) {
    object.OTHelpers.remove();
  }
};

// Public API

// Public accessor for the MediaCapturer
pluginProxies.mediaCapturer = null;

pluginProxies.removeAll = function removeAll() {
  for (var id in proxies) {
    if (proxies.hasOwnProperty(id)) {
      proxies[id].destroy();
    }
  }

  if (pluginProxies.mediaCapturer) {
    pluginProxies.mediaCapturer.destroy();
  }
};

pluginProxies.create = function create(options, completion) {
  var proxy = proxyExtras.createPluginProxy(options, completion);

  proxies[proxy.uuid] = proxy;

  // Clean up after this Proxy when it's destroyed.
  proxy.on('destroy', function() {
    cleanupProxyOnDestroy(proxy);
  });

  return proxy;
};

// Two ways to call this function
// - pluginProxies.createMediaPeer(options, completion)
// - pluginProxies.createMediaPeer(completion)
//
// If options is omitted, it defaults to {}.
//
pluginProxies.createMediaPeer = function createMediaPeer(arg1, arg2) {
  var options, completion;

  if (OTHelpers.isFunction(arg1)) {
    options = {};
    completion = arg1;
  } else {
    options = arg1;
    completion = arg2;
  }

  var mediaPeer =  pluginProxies.create(OTHelpers.extend(options || {}, {
    mimeType: meta.mimeType,
    isVisible: true,
    windowless: true
  }), function(err) {
    if (err) {
      completion.call(void 0, err);
      return;
    }

    proxies[mediaPeer.id] = mediaPeer;
    completion.call(void 0, void 0, mediaPeer);
  });

  proxyExtras.makeMediaPeerProxy(mediaPeer);
};

pluginProxies.createMediaCapturer = function createMediaCapturer(completion) {
  // TODO: This violates the always sync or always async idiom.

  if (pluginProxies.mediaCapturer) {
    completion.call(void 0, void 0, pluginProxies.mediaCapturer);
    return;
  }

  pluginProxies.mediaCapturer = pluginProxies.create({
    mimeType: meta.mimeType,
    isVisible: false,
    windowless: false
  }, function(err) {
    completion.call(void 0, err, pluginProxies.mediaCapturer);
  });

  proxyExtras.makeMediaCapturerProxy(pluginProxies.mediaCapturer);
};

module.exports = pluginProxies;
