'use strict';

var Bluebird = require('bluebird');

module.exports = function httpTest(config) {

  var _httpConfig = config.httpConfig;

  function measureDownloadBandwidth(url) {

    var xhr = new XMLHttpRequest();
    var resultPromise = new Bluebird.Promise(function(resolve, reject) {
      var startTs = Date.now();
      var progressLoaded = 0;

      function calculate(loaded) {
        return 1000 * 8 * loaded / (Date.now() - startTs);
      }

      xhr.addEventListener('load', function(evt) {
        resolve(calculate(evt.loaded));
      });
      xhr.addEventListener('abort', function() {
        resolve(calculate(progressLoaded));
      });
      xhr.addEventListener('error', function(evt) {
        reject(evt);
      });

      xhr.addEventListener('progress', function(evt) {
        progressLoaded = evt.loaded;
      });

      xhr.open('GET', url + '?_' + Math.random());
      xhr.send();
    });

    return {
      promise: resultPromise,
      abort: function() {
        xhr.abort();
      }
    };
  }

  /**
   * Measures the bandwidth in bps.
   *
   * @param {string} url
   * @param {ArrayBuffer} payload
   * @returns {{promise: Promise, abort: function}}
   */
  function measureUploadBandwidth(url, payload) {
    var xhr = new XMLHttpRequest();
    var resultPromise = new Bluebird.Promise(function(resolve, reject) {
      var startTs, lastTs, lastLoaded;

      xhr.upload.addEventListener('progress', function(evt) {
        if (!startTs) {
          startTs = Date.now();
        }
        lastLoaded = evt.loaded;
      });
      xhr.addEventListener('load', function() {
        lastTs = Date.now();
        resolve(1000 * 8 * lastLoaded / (lastTs - startTs));
      });
      xhr.addEventListener('error', function(e) {
        reject(e);
      });
      xhr.addEventListener('abort', function() {
        reject();
      });
      xhr.open('POST', url);
      xhr.send(payload);
    });

    return {
      promise: resultPromise,
      abort: function() {
        xhr.abort();
      }
    };
  }

  function doDownload(url, maxDuration) {
    var measureResult = measureDownloadBandwidth(url);

    setTimeout(function() {
      measureResult.abort();
    }, maxDuration);

    return measureResult.promise;
  }

  function loopUpload(url, initialSize, maxDuration) {
    return new Bluebird.Promise(function(resolve) {
      var lastMeasureResult;
      var lastBandwidth = 0;

      setTimeout(function() {
        lastMeasureResult.abort();
        resolve(lastBandwidth);

      }, maxDuration);

      function loop(loopSize) {
        lastMeasureResult = measureUploadBandwidth(url, new ArrayBuffer(loopSize / 8));
        lastMeasureResult.promise
          .then(function(bandwidth) {
            lastBandwidth = bandwidth;
            loop(loopSize * 2);
          });
      }

      loop(initialSize);
    });
  }

  return Bluebird
    .all([
      doDownload(_httpConfig.downloadUrl, _httpConfig.duration * 1000),
      loopUpload(_httpConfig.uploadUrl, _httpConfig.uploadSize, _httpConfig.duration * 1000)
    ])
    .then(function(results) {
      return {
        downloadBandwidth: results[0],
        uploadBandwidth: results[1]
      };
    });
};
