'use strict';

// We need to do this first because of circular dependency issues with otplugin.js. @TODO: fix this.
var OT = {};
global.OT = OT;
global.TB = OT;
module.exports = OT;

var OTHelpers = require('./common-js-helpers/OTHelpers.js');

// This is not obvious, so to prevent end-user frustration we'll let them know
// explicitly rather than failing with a bunch of permission errors. We don't
// handle this using an OT Exception as it's really only a development thing.
var shouldAlertAboutFileProtocol = (
  OTHelpers.env.name !== 'Node' &&
  global.location.protocol === 'file:' &&
  typeof window.cordova === 'undefined'
);

if (shouldAlertAboutFileProtocol) {
  global.alert('You cannot test a page using WebRTC through the file system due to browser ' +
    'permissions. You must run it over a web server.');
}

// TODO: This is added to OTHelpers for consistency with the capabilities stuff. Capabilities' days
// are numbered though.
OTHelpers.getUserMedia = require('./helpers/get_user_media.js');

var Analytics                       = require('./helpers/analytics.js');
var APIKEY                          = require('./ot/api_key.js');
var AudioLevelTransformer           = require('./ot/audio_level_transformer');
var WebaudioAudioLevelSampler       = require('./helpers/audio_level_samplers/webaudio_audio_level_sampler');
var GetstatsAudioOutputLevelSampler = require('./helpers/audio_level_samplers/getstats_audio_output_level_sampler');
var cssLoader                       = require('./helpers/css_loader.js');
var Dialogs                         = require('./helpers/dialogs.js');
var EnvironmentLoader               = require('./ot/environment_loader.js');
var Events                          = require('./ot/events.js');
var guidStorage                     = require('./helpers/guid_storage.js');
var initSession                     = require('./ot/session/init.js');
var logging                         = require('./ot/logging.js');
var OTError                         = require('./ot/ot_error.js');
var properties                      = require('./helpers/properties.js');
var screenSharing                   = require('./ot/screensharing/screen_sharing.js');
var sessionObjects                  = require('./ot/session/objects.js');
var systemRequirements              = require('./ot/system_requirements.js');

// Allow events to be bound on OT
OTHelpers.eventing(OT);

OT.$ = OTHelpers;

// Define the APIKEY this is a global parameter which should not change
OT.APIKEY = APIKEY.value;

OT.AnalyserAudioLevelSampler = WebaudioAudioLevelSampler;

// TODO: Fix dodgy use of this by OTPlugin. It may also need to be exposed here for public use,
// hopefully not.
OT.Analytics = Analytics;

OT.Anvil = require('./ot/anvil.js');
OT.Archive = require('./ot/archive.js');
OT.ArchiveEvent = Events.ArchiveEvent;
OT.ArchiveUpdatedEvent = Events.ArchiveUpdatedEvent;
OT.AudioLevelTransformer = AudioLevelTransformer;
OT.AudioLevelUpdatedEvent = Events.AudioLevelUpdatedEvent;
OT.Capabilities = require('./ot/capabilities.js');
OT.Chrome = require('./ot/chrome/chrome.js');
OT.Connection = require('./ot/connection.js');
OT.ConnectionCapabilities = OT.Connection.Capabilities;
OT.ConnectionEvent = Events.ConnectionEvent;
OT.ConnectivityAttemptPinger = require('./helpers/connectivity_attempt_pinger.js');
OT.DEBUG = logging.DEBUG;
OT.DestroyedEvent = Events.DestroyedEvent;

// TODO: This is here because of OTPlugin. Another circular dependency issue we need to sort out.
OT.Dialogs = Dialogs;

OT.ERROR = logging.ERROR;

OT.EnvLoadedEvent = Events.EnvLoadedEvent;

// TODO: re-expose old screenSharing api

OT.Error = OTError;

OT.Error.on(Events.Event.names.EXCEPTION, function(exceptionEvent) {
  if (exceptionEvent.target === OT.Error) {
    // Rebind target to OT if it's set to OT.Error to preserve old behaviour.
    var exceptionEventClone = OTHelpers.clone(exceptionEvent);
    exceptionEventClone.target = OT;
    OT.dispatchEvent(exceptionEventClone);
  } else {
    OT.dispatchEvent(exceptionEvent);
  }
});

OT.Event = Events.Event;
OT.ExceptionCodes = require('./ot/exception_codes.js');
OT.ExceptionEvent = Events.ExceptionEvent;

OT.getDevices = require('./ot/get_devices.js');
OT.GetStatsAudioLevelSampler = GetstatsAudioOutputLevelSampler;
OT.HAS_REQUIREMENTS = 1;

OT.INFO = logging.INFO;
OT.IntervalRunner = require('./ot/interval_runner.js');
OT.IssueReportedEvent = Events.IssueReportedEvent;
OT.LOG = logging.LOG;
OT.MediaStoppedEvent = Events.MediaStoppedEvent;
OT.Microphone = require('./ot/publisher/microphone.js');
OT.Modal = OTHelpers.Modal;
OT.NONE = logging.NONE;
OT.NOT_HAS_REQUIREMENTS = 0;
OT.PeerConnection = require('./ot/peer_connection/peer_connection.js');

// TODO: this is here for repel, but it doesn't belong here
OT.PeerConnection.QOS = require('./ot/peer_connection/qos.js');

OT.Publisher = require('./ot/publisher');
OT.PublisherPeerConnection = require('./ot/peer_connection/publisher_peer_connection.js');
OT.PublishingState = require('./ot/publisher/state.js');
OT.Raptor = require('./ot/messaging/raptor/legacy_structure.js');
OT.Rumor = require('./ot/messaging/rumor/legacy_structure.js');
OT.Session = require('./ot/session/handle.js').Session;
OT.SessionConnectEvent = Events.SessionConnectEvent;
OT.SessionDisconnectEvent = Events.SessionDisconnectEvent;
OT.SessionDispatcher = require('./ot/messaging/raptor/session_dispatcher.js');
OT.SessionInfo = require('./ot/session/info.js');
OT.Signal = require('./ot/messaging/raptor/signal.js');
OT.SignalEvent = Events.SignalEvent;
OT.Stream = require('./ot/stream.js');
OT.StreamChannel = require('./ot/stream_channel.js');
OT.StreamEvent = Events.StreamEvent;
OT.StreamPropertyChangedEvent = Events.StreamPropertyChangedEvent;
OT.StreamUpdatedEvent = Events.StreamUpdatedEvent;
OT.StylableComponent = require('./ot/styling/stylable_component.js');
OT.Subscriber = require('./ot/subscriber');
OT.SubscriberPeerConnection = require('./ot/peer_connection/subscriber_peer_connection.js');
OT.SubscribingState = require('./ot/subscriber/state.js');
OT.VideoDimensionsChangedEvent = Events.VideoDimensionsChangedEvent;
OT.VideoDisableWarningEvent = Events.VideoDisableWarningEvent;
OT.VideoElement = require('./helpers/video_element/index.js');
OT.VideoEnabledChangedEvent = Events.VideoEnabledChangedEvent;
OT.VideoOrientation = require('./helpers/video_orientation.js');
OT.WARN = logging.WARN;
OT.WidgetView = require('./helpers/widget_view.js');
OT._ = { getClientGuid: guidStorage.get };
// OT.addEventListener comes from OTHelpers.eventing(OT)
OT.analytics = require('./ot/analytics.js');
OT.audioContext = require('./helpers/audio_context.js');
OT.checkScreenSharingCapability = screenSharing.checkCapability;
OT.checkSystemRequirements = systemRequirements.check;
OT.components = {};
OT.debug = logging.debug;
// OT.dispatchEvent comes from OTHelpers.eventing(OT)
// OT.emit comes from OTHelpers.eventing(OT)
OT.error = logging.error;
OT.generateSimpleStateMachine = require('./ot/generate_simple_state_machine.js');
OT.getDevices = require('./ot/get_devices.js');
OT.getErrorTitleByCode = OTError.getTitleByCode;
OT.getLogs = logging.getLogs;

// This is misspelled in production too, being compatible here.
OT.getStatsAdpater = require('./ot/peer_connection/get_stats_adapter.js');

OT.getStatsHelpers = require('./ot/peer_connection/get_stats_helpers.js');
OT.handleJsException = OTError.handleJsException;
OT.httpTest = require('./ot/qos_testing/http_test.js');
OT.info = logging.info;

OT.initPublisher = require('./ot/publisher/init.js');

OT.initSession = function(apiKey, sessionId) {
  if (sessionId == null) {
    sessionId = apiKey;
    apiKey = null;
  }

  // Ugly hack, make sure OT.APIKEY is set
  // TODO: Yep, sure is ugly. It appears to be needed by raptor. We should fix this situation.
  // UPDATE: This hack is the only reason why we need to wrap the actual initSession.
  if (APIKEY.value.length === 0 && apiKey) {
    APIKEY.value = apiKey;
    OT.APIKEY = apiKey;
  }

  return initSession(apiKey, sessionId);
};

OT.isUnloaded = EnvironmentLoader.isUnloaded;
OT.log = logging.log;
// OT.off comes from OTHelpers.eventing(OT)
// OT.on comes from OTHelpers.eventing(OT)
OT.onLoad = EnvironmentLoader.onLoad;
OT.onUnload = EnvironmentLoader.onUnload;
// OT.once comes from OTHelpers.eventing(OT)

// Exposed here for partner usage.
OT.overrideGuidStorage = guidStorage.override;

OT.pickScreenSharingHelper = screenSharing.pickHelper;
OT.properties = properties;
OT.publishers = sessionObjects.publishers;
OT.registerScreenSharingExtension = screenSharing.registerExtension;
OT.registerScreenSharingExtensionHelper = screenSharing.registerExtensionHelper;
// OT.removeEventListener comes from OTHelpers.eventing(OT)
OT.reportIssue = require('./ot/report_issue.js');
OT.sessions = sessionObjects.sessions;
OT.setLogLevel = logging.setLogLevel;
OT.shouldLog = logging.shouldLog;
OT.subscribers = sessionObjects.subscribers;
// OT.trigger comes from OTHelpers.eventing(OT)
OT.upgradeSystemRequirements = systemRequirements.upgrade;
OT.warn = logging.warn;
OT.webrtcTest = require('./ot/qos_testing/webrtc_test.js');

// Tidy up everything on unload
EnvironmentLoader.onUnload(function() {
  sessionObjects.publishers.destroy();
  sessionObjects.subscribers.destroy();
  sessionObjects.sessions.destroy('unloaded');
});

if (properties.cssURL) {
  cssLoader(properties.cssURL);
}

/* global define */
// Register as a named AMD module, since TokBox could be concatenated with other
// files that may use define, but not via a proper concatenation script that
// understands anonymous AMD modules. A named AMD is safest and most robust
// way to register. Uppercase TB is used because AMD module names are
// derived from file names, and OpenTok is normally delivered in an uppercase
// file name.
if (typeof define === 'function' && define.amd) {
  define('TB', [], function() { return OT; });
}

OT.noConflict = require('./helpers/no_conflict.js')();

module.exports = OT;

/**
 * This method is deprecated. Use <a href="#on">on()</a> or <a href="#once">once()</a> instead.
 *
 * <p>
 * Registers a method as an event listener for a specific event.
 * </p>
 *
 * <p>
 * The OT object dispatches one type of event &#151; an <code>exception</code> event. The
 * following code adds an event listener for the <code>exception</code> event:
 * </p>
 *
 * <pre>
 * OT.addEventListener("exception", exceptionHandler);
 *
 * function exceptionHandler(event) {
 *    alert("exception event. \n  code == " + event.code + "\n  message == " + event.message);
 * }
 * </pre>
 *
 * <p>
 *   If a handler is not registered for an event, the event is ignored locally. If the event
 *   listener function does not exist, the event is ignored locally.
 * </p>
 * <p>
 *   Throws an exception if the <code>listener</code> name is invalid.
 * </p>
 *
 * @param {String} type The string identifying the type of event.
 *
 * @param {Function} listener The function to be invoked when the OT object dispatches the event.
 * @see <a href="#on">on()</a>
 * @see <a href="#once">once()</a>
 * @memberof OT
 * @method addEventListener
 *
 */

/**
 * This method is deprecated. Use <a href="#off">off()</a> instead.
 *
 * <p>
 * Removes an event listener for a specific event.
 * </p>
 *
 * <p>
 *   Throws an exception if the <code>listener</code> name is invalid.
 * </p>
 *
 * @param {String} type The string identifying the type of event.
 *
 * @param {Function} listener The event listener function to remove.
 *
 * @see <a href="#off">off()</a>
 * @memberof OT
 * @method removeEventListener
 */

/**
* Adds an event handler function for one or more events.
*
* <p>
* The OT object dispatches one type of event &#151; an <code>exception</code> event. The following
* code adds an event
* listener for the <code>exception</code> event:
* </p>
*
* <pre>
* OT.on("exception", function (event) {
*   // This is the event handler.
* });
* </pre>
*
* <p>You can also pass in a third <code>context</code> parameter (which is optional) to define the
* value of
* <code>this</code> in the handler method:</p>
*
* <pre>
* OT.on("exception",
*   function (event) {
*     // This is the event handler.
*   }),
*   session
* );
* </pre>
*
* <p>
* If you do not add a handler for an event, the event is ignored locally.
* </p>
*
* @param {String} type The string identifying the type of event.
* @param {Function} handler The handler function to process the event. This function takes the event
* object as a parameter.
* @param {Object} context (Optional) Defines the value of <code>this</code> in the event handler
* function.
*
* @memberof OT
* @method on
* @see <a href="#off">off()</a>
* @see <a href="#once">once()</a>
* @see <a href="#events">Events</a>
*/

/**
* Adds an event handler function for an event. Once the handler is called, the specified handler
* method is
* removed as a handler for this event. (When you use the <code>OT.on()</code> method to add an event
* handler, the handler
* is <i>not</i> removed when it is called.) The <code>OT.once()</code> method is the equivilent of
* calling the <code>OT.on()</code>
* method and calling <code>OT.off()</code> the first time the handler is invoked.
*
* <p>
* The following code adds a one-time event handler for the <code>exception</code> event:
* </p>
*
* <pre>
* OT.once("exception", function (event) {
*   console.log(event);
* }
* </pre>
*
* <p>You can also pass in a third <code>context</code> parameter (which is optional) to define the
* value of
* <code>this</code> in the handler method:</p>
*
* <pre>
* OT.once("exception",
*   function (event) {
*     // This is the event handler.
*   },
*   session
* );
* </pre>
*
* <p>
* The method also supports an alternate syntax, in which the first parameter is an object that is a
* hash map of
* event names and handler functions and the second parameter (optional) is the context for this in
* each handler:
* </p>
* <pre>
* OT.once(
*   {exeption: function (event) {
*     // This is the event handler.
*     }
*   },
*   session
* );
* </pre>
*
* @param {String} type The string identifying the type of event. You can specify multiple event
* names in this string,
* separating them with a space. The event handler will process the first occurence of the events.
* After the first event,
* the handler is removed (for all specified events).
* @param {Function} handler The handler function to process the event. This function takes the event
* object as a parameter.
* @param {Object} context (Optional) Defines the value of <code>this</code> in the event handler
* function.
*
* @memberof OT
* @method once
* @see <a href="#on">on()</a>
* @see <a href="#once">once()</a>
* @see <a href="#events">Events</a>
*/

/**
 * Removes an event handler.
 *
 * <p>Pass in an event name and a handler method, the handler is removed for that event:</p>
 *
 * <pre>OT.off("exceptionEvent", exceptionEventHandler);</pre>
 *
 * <p>If you pass in an event name and <i>no</i> handler method, all handlers are removed for that
 * events:</p>
 *
 * <pre>OT.off("exceptionEvent");</pre>
 *
 * <p>
 * The method also supports an alternate syntax, in which the first parameter is an object that is a
 * hash map of
 * event names and handler functions and the second parameter (optional) is the context for matching
 * handlers:
 * </p>
 * <pre>
 * OT.off(
 *   {
 *     exceptionEvent: exceptionEventHandler
 *   },
 *   this
 * );
 * </pre>
 *
 * @param {String} type (Optional) The string identifying the type of event. You can use a space to
 * specify multiple events, as in "eventName1 eventName2 eventName3". If you pass in no
 * <code>type</code> value (or other arguments), all event handlers are removed for the object.
 * @param {Function} handler (Optional) The event handler function to remove. If you pass in no
 * <code>handler</code>, all event handlers are removed for the specified event <code>type</code>.
 * @param {Object} context (Optional) If you specify a <code>context</code>, the event handler is
 * removed for all specified events and handlers that use the specified context.
 *
 * @memberof OT
 * @method off
 * @see <a href="#on">on()</a>
 * @see <a href="#once">once()</a>
 * @see <a href="#events">Events</a>
 */

/**
 * Dispatched by the OT class when the app encounters an exception.
 * Note that you set up an event handler for the <code>exception</code> event by calling the
 * <code>OT.on()</code> method.
 *
 * @name exception
 * @event
 * @borrows ExceptionEvent#message as this.message
 * @memberof OT
 * @see ExceptionEvent
 */
