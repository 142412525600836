'use strict';

var makeEverythingAttachToOTHelpers = require('./makeEverythingAttachToOTHelpers');

var cookies = {};
module.exports = cookies;

cookies.setCookie = function(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    // Store in browser cookie
    var date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    var expires = '; expires=' + date.toGMTString();
    document.cookie = key + '=' + value + expires + '; path=/';
  }
};

cookies.getCookie = function(key) {
  var value;

  try {
    value = localStorage.getItem(key);
    return value;
  } catch (err) {
    // Check browser cookies
    var nameEQ = key + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        value = c.substring(nameEQ.length, c.length);
      }
    }

    if (value) {
      return value;
    }
  }

  return null;
};

makeEverythingAttachToOTHelpers(cookies);
