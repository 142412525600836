'use strict';

var Widget = require('./behaviour/widget.js');

// NamePanel Chrome Widget
//
// mode (String)
// Whether to display the name. Possible values are: "auto" (the name is displayed
// when the stream is first displayed and when the user mouses over the display),
// "off" (the name is not displayed), and "on" (the name is displayed).
//
// displays a name
// can be shown/hidden
// can be destroyed
module.exports = function NamePanel(options) {
  var _name = options.name;

  if (!_name || _name.trim().length === '') {
    _name = null;

    // THere's no name, just flip the mode off
    options.mode = 'off';
  }

  this.setName = function(name) {
    if (!_name) { this.setDisplayMode('auto'); }
    _name = name;
    this.domElement.innerHTML = _name;
  }.bind(this);

  // Mixin common widget behaviour
  Widget(this, {
    mode: options.mode,
    nodeName: 'h1',
    htmlContent: _name,
    htmlAttributes: {
      className: 'OT_name OT_edge-bar-item'
    }
  });
};
