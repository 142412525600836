'use strict';

var MediaStreamTrack = require('./media_stream_track.js');
var VideoContainer = require('./video_container.js');

var MediaStream = function(options, plugin) {
  var Proto = function MediaStream() {};
  var mediaStream = new Proto();
  var audioTracks = [];
  var videoTracks = [];

  mediaStream.id = options.id;
  plugin.addRef(mediaStream);

  // TODO
  // mediaStream.ended =
  // mediaStream.onended =

  if (options.videoTracks) {
    options.videoTracks.map(function(track) {
      videoTracks.push(new MediaStreamTrack(options.id, track, plugin));
    });
  }

  if (options.audioTracks) {
    options.audioTracks.map(function(track) {
      audioTracks.push(new MediaStreamTrack(options.id, track, plugin));
    });
  }

  var hasTracksOfType = function(type) {
    var tracks = type === 'video' ? videoTracks : audioTracks;

    return tracks.some(function(track) {
      return track.enabled;
    });
  };

  mediaStream.getVideoTracks = function() { return videoTracks; };
  mediaStream.getAudioTracks = function() { return audioTracks; };

  mediaStream.getTrackById = function(id) {
    videoTracks.concat(audioTracks).forEach(function(track) {
      if (track.id === id) {
        return track;
      }

      // FIXME: I added this line to satisfy consistent-return in eslint, but I think this whole
      // method doesn't work / doesn't make sense / must not be tested.
      return undefined;
    });

    return null;
  };

  mediaStream.hasVideo = function() {
    return hasTracksOfType('video');
  };

  mediaStream.hasAudio = function() {
    return hasTracksOfType('audio');
  };

  mediaStream.addTrack = function(/* MediaStreamTrack */) {
    // TODO
  };

  mediaStream.removeTrack = function(/* MediaStreamTrack */) {
    // TODO
  };

  mediaStream.stop = function() {
    plugin._.stopMediaStream(mediaStream.id);
    plugin.removeRef(mediaStream);
  };

  mediaStream.destroy = function() {
    mediaStream.stop();
  };

  // Private MediaStream API
  mediaStream._ = {
    plugin: plugin,

    // Get a VideoContainer to render the stream in.
    render: function() {
      return new VideoContainer(plugin, mediaStream);
    }
  };

  return mediaStream;
};

MediaStream.fromJson = function(json, plugin) {
  if (!json) {
    return null; // TODO: This seems odd. Consider removing/throwing/logging.
  }

  return new MediaStream(JSON.parse(json), plugin);
};

module.exports = MediaStream;
