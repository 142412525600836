'use strict';

var once = require('lodash.once');

var OTPlugin = require('../otplugin/otplugin.js');

var env = require('../common-js-helpers/env.js');
var hasOpenTokSupport = require('./hasOpenTokSupport.js');

// Indicates if the browser supports RTCP Mux
//
// Broadly:
// * Older versions of Firefox (<= 25) don't support RTCP Mux
// * Older versions of Firefox (>= 26) support RTCP Mux (not tested yet)
// * Chrome support RTCP Mux
// * OT Plugin supports RTCP Mux
// * We assume NodeJs supports RTCP Mux (e.g. 'you're on your own' mode)
//
module.exports = once(function() {
  return hasOpenTokSupport() &&
              (env.name === 'Chrome' ||
                env.name === 'Node' ||
                OTPlugin.isInstalled());
});
