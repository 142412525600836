'use strict';

var assert = require('assert');

// A single element container. Simply models an array, delegating push and pop, and asserting if
// you try to store more than one element or pop when empty.
//
// When this is used, the alternative would be to either forgo the protective assertions or do
// inline duplicates of this functionality like this:
//
// var foo;
// ...
// assert(foo === undefined);
// foo = getFoo();
// ...
// assert(foo !== undefined);
// use(foo);
// foo = undefined;
//
// With a singleElementContainer, the above code becomes:
// ...
// var foo = createSingleElementContainer();
// ...
// foo.push(getFoo());
// ...
// use(foo.pop());

module.exports = function() {
  var container = {};
  var elements = [];

  container.push = function(value) {
    assert(elements.length === 0);
    elements.push(value);
  };

  container.pop = function() {
    assert(elements.length === 1);
    return elements.pop();
  };

  return container;
};
