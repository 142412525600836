'use strict';

var once = require('lodash.once');

module.exports = once(function() {
  return (
    typeof document !== 'undefined' &&
    'classList' in document.createElement('a')
  );
});
