'use strict';

// Rumor Messaging for JS
//
// https://tbwiki.tokbox.com/index.php/Rumor_:_Messaging_FrameWork
//
// @todo Rumor {
//     Add error codes for all the error cases
//     Add Dependability commands
// }

module.exports = {
  // This is used to subscribe to address/addresses. The address/addresses the
  // client specifies here is registered on the server. Once any message is sent to
  // that address/addresses, the client receives that message.
  SUBSCRIBE: 0,

  // This is used to unsubscribe to address / addresses. Once the client unsubscribe
  // to an address, it will stop getting messages sent to that address.
  UNSUBSCRIBE: 1,

  // This is used to send messages to arbitrary address/ addresses. Messages can be
  // anything and Rumor will not care about what is included.
  MESSAGE: 2,

  // This will be the first message that the client sends to the server. It includes
  // the uniqueId for that client connection and a disconnect_notify address that will
  // be notified once the client disconnects.
  CONNECT: 3,

  // This will be the message used by the server to notify an address that a
  // client disconnected.
  DISCONNECT: 4,

  //Enhancements to support Keepalives
  PING: 7,
  PONG: 8,
  STATUS: 9
};
