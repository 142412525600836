'use strict';

var deserializeMessage = require('./deserialize_message.js');

module.exports = function unboxFromRumorMessage(rumorMessage) {
  var message = deserializeMessage(rumorMessage.data);
  message.transactionId = rumorMessage.transactionId;
  message.fromAddress = rumorMessage.headers['X-TB-FROM-ADDRESS'];

  return message;
};
