'use strict';

var ajax = require('./ajax');
var env = require('./env');
var logging = require('./logging');
var util = require('./util');
var uuid = require('uuid');

// Singleton interval
var logQueue = [];
var queueRunning = false;

module.exports = function Analytics(loggingUrl, debugFn) {

  var endPoint = loggingUrl + '/logging/ClientEvent';
  var endPointQos = loggingUrl + '/logging/ClientQos';

  var reportedErrors = {};

  var send = function(data, isQos, callback) {
    ajax.post((isQos ? endPointQos : endPoint) + '?_=' + uuid.v4(), {
      body: data,
      xdomainrequest: (env.name === 'IE' && env.version < 10),
      overrideMimeType: 'text/plain',
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json'
      }
    }, callback);
  };

  var throttledPost = function() {
    // Throttle logs so that they only happen 1 at a time
    if (!queueRunning && logQueue.length > 0) {
      queueRunning = true;
      var curr = logQueue[0];

      // Remove the current item and send the next log
      var processNextItem = function() {
        logQueue.shift();
        queueRunning = false;
        throttledPost();
      };

      if (curr) {
        send(curr.data, curr.isQos, function(err) {
          if (err) {
            var debugMsg = 'Failed to send ClientEvent, moving on to the next item.';
            if (debugFn) {
              debugFn(debugMsg);
            } else {
              console.log(debugMsg);
            }

            if (curr.onComplete) {
              curr.onComplete();
            }
            // There was an error, move onto the next item
          }
          if (curr.onComplete) {
            curr.onComplete(err);
          }

          setTimeout(processNextItem, 50);
        });
      }
    }
  };

  var post = function(data, onComplete, isQos) {
    logQueue.push({
      data: data,
      onComplete: onComplete,
      isQos: isQos
    });

    throttledPost();
  };

  var shouldThrottleError = function(code, type, partnerId) {
    if (!partnerId) {
      return false;
    }

    var errKey = [partnerId, type, code].join('_');
    var msgLimit = 100;

    // msgLimit = DynamicConfig.get('exceptionLogging', 'messageLimitPerPartner', partnerId);
    // if (msgLimit === null || msgLimit === undefined) {
    //   return false;
    // }

    return (reportedErrors[errKey] || 0) <= msgLimit;
  };

  // Log an error via ClientEvents.
  //
  // @param [String] code
  // @param [String] type
  // @param [String] message
  // @param [Hash] details additional error details
  //
  // @param [Hash] options the options to log the client event with.
  // @option options [String] action The name of the Event that we are logging. E.g.
  //  'TokShowLoaded'. Required.
  // @option options [String] variation Usually used for Split A/B testing, when you
  //  have multiple variations of the +_action+.
  // @option options [String] payload The payload. Required.
  // @option options [String] sessionId The active OpenTok session, if there is one
  // @option options [String] connectionId The active OpenTok connectionId, if there is one
  // @option options [String] partnerId
  // @option options [String] guid ...
  // @option options [String] streamId ...
  // @option options [String] section ...
  // @option options [String] clientVersion ...
  //
  // Reports will be throttled to X reports (see exceptionLogging.messageLimitPerPartner
  // from the dynamic config for X) of each error type for each partner. Reports can be
  // disabled/enabled globally or on a per partner basis (per partner settings
  // take precedence) using exceptionLogging.enabled.
  //
  this.logError = function(code, type, message, details, options) {
    options = options || {};
    var partnerId = options.partnerId;

    if (shouldThrottleError(code, type, partnerId)) {
      //OT.log('ClientEvents.error has throttled an error of type ' + type + '.' +
      // code + ' for partner ' + (partnerId || 'No Partner Id'));
      return;
    }

    var errKey = [partnerId, type, code].join('_');
    var payload =  details ? details : null;

    reportedErrors[errKey] = typeof (reportedErrors[errKey]) !== 'undefined' ?
      reportedErrors[errKey] + 1 : 1;
    this.logEvent(util.extend(options, {
      action: type + '.' + code,
      payload: payload
    }), false);
  };

  // Log a client event to the analytics backend.
  //
  // @example Logs a client event called 'foo'
  //  this.logEvent({
  //      action: 'foo',
  //      payload: 'bar',
  //      sessionId: sessionId,
  //      connectionId: connectionId
  //  }, false)
  //
  // @param [Hash] data the data to log the client event with.
  // @param [Boolean] qos Whether this is a QoS event.
  // @param [Boolean] throttle A number specifying the ratio of events to be sent
  //        out of the total number of events (other events are not ignored). If not
  //        set to a number, all events are sent.
  // @param [Number] completionHandler A completion handler function to call when the
  //                 client event POST request succeeds or fails. If it fails, an error
  //                 object is passed into the function. (See throttledPost().)
  //
  this.logEvent = function(data, qos, throttle, completionHandler) {
    qos = qos || false;

    if (throttle && !isNaN(throttle)) {
      if (Math.random() > throttle) {
        logging.debug('skipping sending analytics due to throttle:', data);
        return;
      }
    }

    logging.debug('sending analytics:', data);

    // TODO: catch error when stringifying an object that has a circular reference
    data = JSON.stringify(data);

    post(data, completionHandler, qos);
  };

  // Log a client QOS to the analytics backend.
  // Log a client QOS to the analytics backend.
  // @option options [String] action The name of the Event that we are logging.
  //  E.g. 'TokShowLoaded'. Required.
  // @option options [String] variation Usually used for Split A/B testing, when
  //  you have multiple variations of the +_action+.
  // @option options [String] payload The payload. Required.
  // @option options [String] sessionId The active OpenTok session, if there is one
  // @option options [String] connectionId The active OpenTok connectionId, if there is one
  // @option options [String] partnerId
  // @option options [String] guid ...
  // @option options [String] streamId ...
  // @option options [String] section ...
  // @option options [String] clientVersion ...
  //
  this.logQOS = function(options) {
    this.logEvent(options, true);
  };
};
