'use strict';

function isEnded(stream) {
  return stream.getTracks().every(function(track) {
    return track.readyState === 'ended';
  });
}

module.exports = function listenForTracksEnded(stream, handler) {
  var tracks = [];

  function singleTrackEndedHandler() {
    if (isEnded(stream)) {
      stop();
      handler();
    }
  }

  function stop() {
    tracks.forEach(function(track) {
      track.removeEventListener('ended', singleTrackEndedHandler);
    });
  }

  stream.getTracks().forEach(function(track) {
    tracks.push(track);
    track.addEventListener('ended', singleTrackEndedHandler);
  });

  return {
    stop: stop
  };
};
