'use strict';

module.exports = function cssLoader(cssURL) {
  var style = document.createElement('link');
  style.type = 'text/css';
  style.media = 'screen';
  style.rel = 'stylesheet';
  style.href = cssURL;
  var head = document.head || document.getElementsByTagName('head')[0];
  head.appendChild(style);
};
