'use strict';

var OTPlugin = require('../../otplugin/otplugin.js');

module.exports = {
  isSupportedInThisBrowser: OTPlugin.isSupported(),
  autoRegisters: true,
  extensionRequired: true,
  getConstraintsShowsPermissionUI: false,
  sources: {
    screen: true,
    application: false,
    window: true,
    browser: false
  },
  register: function() {
    return {
      isInstalled: function(callback) {
        callback(OTPlugin.isInstalled());
      },
      getConstraints: function(source, constraints, callback) {
        constraints.video.mandatory.chromeMediaSource = source;

        callback(void 0, constraints);
      }
    };
  }
};
