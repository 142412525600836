'use strict';

var OTHelpers = require('../../common-js-helpers/OTHelpers.js');

// Manages N Chrome elements
module.exports = function Chrome(properties) {
  var _widgets = {};

  // Private helper function
  var _set = function(name, widget) {
    widget.parent = this;
    widget.appendTo(properties.parent);

    _widgets[name] = widget;

    this[name] = widget;
  };

  if (!properties.parent) {
    // @todo raise an exception
    return;
  }

  OTHelpers.eventing(this);

  this.destroy = function() {
    this.off();
    this.hideWhileLoading();

    for (var name in _widgets) {
      if (_widgets.hasOwnProperty(name)) {
        _widgets[name].destroy();
      }
    }
  };

  this.showAfterLoading = function() {
    for (var name in _widgets) {
      if (_widgets.hasOwnProperty(name)) {
        _widgets[name].showAfterLoading();
      }
    }
  };

  this.hideWhileLoading = function() {
    for (var name in _widgets) {
      if (_widgets.hasOwnProperty(name)) {
        _widgets[name].hideWhileLoading();
      }
    }
  };

  // Adds the widget to the chrome and to the DOM. Also creates a accessor
  // property for it on the chrome.
  //
  // @example
  //  chrome.set('foo', new FooWidget());
  //  chrome.foo.setDisplayMode('on');
  //
  // @example
  //  chrome.set({
  //      foo: new FooWidget(),
  //      bar: new BarWidget()
  //  });
  //  chrome.foo.setDisplayMode('on');
  //
  this.set = function(widgetName, widget) {
    if (typeof widgetName === 'string' && widget) {
      _set.call(this, widgetName, widget);

    } else {
      for (var name in widgetName) {
        if (widgetName.hasOwnProperty(name)) {
          _set.call(this, name, widgetName[name]);
        }
      }
    }
    return this;
  };
};
