'use strict';

// this is the actual OT.properties object
// values are replaced at build time by browserif + envify

module.exports = {
  version: "v2.9.4",

  // Whether or not to turn on debug logging by default
  debug: "<%= @debug %>",
  // The URL of the tokbox website
  websiteURL: "<%= @website_url %>",

  // The URL of the CDN
  cdnURL: "<%= @cdn_url %>",
  // The URL to use for logging
  loggingURL: "<%= @logging_url %>",

  // The anvil API URL
  apiURL: "<%= @api_url %>",

  // What protocol to use when connecting to the rumor web socket
  messagingProtocol: "wss",
  // What port to use when connection to the rumor web socket
  messagingPort: 443,

  // If this environment supports SSL
  supportSSL: "<%= @support_ssl %>",
  // The CDN to use if we're using SSL
  cdnURLSSL: "<%= @ssl_widget_url %>",
  // The URL to use for logging
  loggingURLSSL: "<%= @logging_url_ssl %>",

  // The anvil API URL to use if we're using SSL
  apiURLSSL: "<%= @api_url_ssl %>",

  minimumVersion: {
    firefox: 37,
    chrome: 39
  }
};
