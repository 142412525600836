'use strict';

module.exports = function noConflict() {
  var globalOT = global.OT;
  var globalTB = global.TB;
  return function() {
    var OT = global.OT;
    global.OT = globalOT;
    global.TB = globalTB;
    return OT;
  };
};
