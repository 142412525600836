'use strict';

var once = require('lodash.once');

var OTPlugin = require('../otplugin/otplugin.js');

var env = require('../common-js-helpers/env.js');

// Indicates whether this client supports the getUserMedia
// API.
//
module.exports = once(function() {
  if (env.name === 'Node') {
    return false;
  }

  return !!(global.navigator.webkitGetUserMedia ||
            global.navigator.mozGetUserMedia ||
            (global.navigator.mediaDevices && global.navigator.mediaDevices.getUserMedia) ||
            OTPlugin.isInstalled());
});
