'use strict';

var OTHelpers = require('../../common-js-helpers/OTHelpers.js');

var getStatsHelpers = {};
module.exports = getStatsHelpers;

getStatsHelpers.isVideoStat = function(stat) {
  // Chrome implementation only has this property for RTP video stat
  return stat.hasOwnProperty('googFrameWidthReceived') ||
    stat.hasOwnProperty('googFrameWidthInput') ||
    stat.mediaType === 'video';
};

getStatsHelpers.isAudioStat = function(stat) {
  // Chrome implementation only has this property for RTP audio stat
  return stat.hasOwnProperty('audioInputLevel') ||
    stat.hasOwnProperty('audioOutputLevel') ||
    stat.mediaType === 'audio';
};

getStatsHelpers.isInboundStat = function(stat) {
  return stat.hasOwnProperty('bytesReceived');
};

getStatsHelpers.parseStatCategory = function(stat) {
  var statCategory = {
    packetsLost: 0,
    packetsReceived: 0,
    bytesReceived: 0
  };

  if (stat.hasOwnProperty('packetsReceived')) {
    statCategory.packetsReceived = parseInt(stat.packetsReceived, 10);
  }
  if (stat.hasOwnProperty('packetsLost')) {
    statCategory.packetsLost = parseInt(stat.packetsLost, 10);
  }
  if (stat.hasOwnProperty('bytesReceived')) {
    statCategory.bytesReceived += parseInt(stat.bytesReceived, 10);
  }

  return statCategory;
};

getStatsHelpers.normalizeTimestamp = function(timestamp) {
  if (OTHelpers.isObject(timestamp) && 'getTime' in timestamp) {
    // Chrome as of 39 delivers a "kind of Date" object for timestamps
    // we duck check it and get the timestamp
    return timestamp.getTime();
  }

  return timestamp;
};
