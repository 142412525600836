'use strict';

var omit = require('lodash.omit');
var bluebird = require('bluebird');
var createPeerConnection = require('../../helpers/create_peer_connection.js');
var getStatsAdpater = require('./get_stats_adapter.js');
var IceCandidateProcessor = require('./ice_candidate_processor');
var logging = require('../logging.js');
var offerProcessor = require('./offer_processor.js');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');
var OTPlugin = require('../../otplugin/otplugin.js');
var PeerConnectionChannels = require('./peer_connection_channels.js');
var RaptorConstants = require('../messaging/raptor/raptor_constants.js');
var subscribeProcessor = require('./subscribe_processor.js');
var Qos = require('./qos.js');
var Bluebird = require('bluebird');

// Normalise these
var NativeRTCIceCandidate, NativeRTCSessionDescription;

if (!OTPlugin.isInstalled()) {
  NativeRTCIceCandidate = (global.RTCIceCandidate || global.mozRTCIceCandidate);
  NativeRTCSessionDescription = (global.RTCSessionDescription ||
                                 global.mozRTCSessionDescription);
} else {
  NativeRTCIceCandidate = OTPlugin.RTCIceCandidate;
  NativeRTCSessionDescription = OTPlugin.RTCSessionDescription;
}

// Helper function to forward Ice Candidates via +sendMessage+
var iceCandidateForwarder = function(sendMessage) {
  return function(event) {
    if (event.candidate) {
      sendMessage(RaptorConstants.Actions.CANDIDATE, {
        candidate: event.candidate.candidate,
        sdpMid: event.candidate.sdpMid || '',
        sdpMLineIndex: event.candidate.sdpMLineIndex || 0
      });
    } else {
      logging.debug('IceCandidateForwarder: No more ICE candidates.');
    }
  };
};

/*
 * Negotiates a WebRTC PeerConnection.
 *
 * Responsible for:
 * * offer-answer exchange
 * * iceCandidates
 * * notification of remote streams being added/removed
 *
 */
var PeerConnection = function(options) {
  var logAnalyticsEvent = options.logAnalyticsEvent;
  var isPublisher = options.isPublisher;
  var config = omit(options, ['isPublisher', 'logAnalyticsEvent']);

  var _peerConnection, _channels, _offer, _answer;
  var _peerConnectionCompletionHandlers = [];
  var _simulcastEnabledSettings = {};
  var _getStatsAdapter = getStatsAdpater();
  var _sendMessage = config.sendMessage;

  // OPENTOK-27106: This _readyToSendOffer mechanism is a workaround for a P2P IE->FF issue where
  // ice candidates sometimes take an excessive amount of time (~10 seconds) to be generated by the
  // IE plugin. FF will time out if there is a delay like this between receiving the offer and
  // receiving ice candidates, so the workaround holds back sending the offer until an ice candidate
  // appears.
  var _readyToSendOffer = bluebird.defer();

  var _iceProcessor = new IceCandidateProcessor();

  var _state = 'new';
  var api = {};

  Object.defineProperty(
    api,
    'signalingState',
    {
      get: function() {
        return _peerConnection.signalingState;
      },
      set: function(val) {
        // obviously they should not be doing this, but we'll mimic what the browser does.
        var result = _peerConnection.signalingState = val;
        return result;
      }
    }
  );

  OTHelpers.eventing(api);

  if (OTHelpers.env.name !== 'IE') {
    _readyToSendOffer.resolve();
  }

  // if ice servers doesn't exist Firefox will throw an exception. Chrome
  // interprets this as 'Use my default STUN servers' whereas FF reads it
  // as 'Don't use STUN at all'. *Grumble*
  if (!config.iceServers) { config.iceServers = []; }

  // Create and initialise the PeerConnection object. This deals with
  // any differences between the various browser implementations and
  // our own OTPlugin version.
  //
  // +completion+ is the function is call once we've either successfully
  // created the PeerConnection or on failure.
  //
  // +localWebRtcStream+ will be null unless the callee is representing
  // a publisher. This is an unfortunate implementation limitation
  // of OTPlugin, it's not used for vanilla WebRTC. Hopefully this can
  // be tidied up later.
  //
  var internalCreatePeerConnection = function(completion, localWebRtcStream) {
    if (_peerConnection) {
      completion.call(null, null, _peerConnection);
      return;
    }

    _peerConnectionCompletionHandlers.push(completion);

    if (_peerConnectionCompletionHandlers.length > 1) {
      // The PeerConnection is already being setup, just wait for
      // it to be ready.
      return;
    }

    var pcConstraints = {
      optional: [
        // This should be unnecessary, but the plugin has issues if we remove it. This needs
        // to be investigated.
        { DtlsSrtpKeyAgreement: true }
      ]
    };

    logging.debug('Creating peer connection config "' + JSON.stringify(config) + '".');

    if (!config.iceServers || config.iceServers.length === 0) {
      // This should never happen unless something is misconfigured
      logging.error('No ice servers present');
      logAnalyticsEvent('Error', 'noIceServers');
    }

    PeerConnection.createPeerConnection(
      config,
      pcConstraints,
      localWebRtcStream,
      attachEventsToPeerConnection
    );
  };

  // An auxiliary function to internalCreatePeerConnection. This binds the various event
  // callbacks once the peer connection is created.
  //
  // +err+ will be non-null if an err occured while creating the PeerConnection
  // +pc+ will be the PeerConnection object itself.
  //
  var attachEventsToPeerConnection = function(err, pc) {
    if (err) {
      triggerError('Failed to create PeerConnection, exception: ' +
          err.toString(), 'NewPeerConnection');

      _peerConnectionCompletionHandlers = [];
      return;
    }

    logging.debug('OT attachEventsToPeerConnection');
    _peerConnection = pc;
    _iceProcessor.setPeerConnection(pc);
    _channels = new PeerConnectionChannels(_peerConnection);
    if (config.channels) { _channels.addMany(config.channels); }

    var forwarder = iceCandidateForwarder(_sendMessage);

    _peerConnection.addEventListener(
      'icecandidate',
      function(event) {
        _readyToSendOffer.resolve();
        forwarder(event);
      },
      false
    );

    _peerConnection.addEventListener('addstream', onRemoteStreamAdded, false);
    _peerConnection.addEventListener('removestream', onRemoteStreamRemoved, false);
    _peerConnection.addEventListener('signalingstatechange', routeStateChanged, false);
    _peerConnection.addEventListener('negotiationneeded', function() {
      api.emit('negotiationNeeded');
    });

    var _previousIceState = _peerConnection.iceConnectionState;
    _peerConnection.addEventListener('iceconnectionstatechange', function(event) {
      var newIceState = event.target.iceConnectionState;
      api.trigger('iceConnectionStateChange', newIceState);

      if (_previousIceState !== 'disconnected' && newIceState === 'failed') {
        // the sequence disconnected => failure would indicate an abrupt disconnection (e.g. remote
        // peer closed the browser) or a network problem. We don't want to log that has a connection
        // establishment failure. This behavior is seen only in Chrome 47+

        triggerError('The stream was unable to connect due to a network error.' +
          ' Make sure your connection isn\'t blocked by a firewall.', 'ICEWorkflow');
      } else {
        logAnalyticsEvent('attachEventsToPeerConnection', 'iceconnectionstatechange',
          newIceState);
      }

      _previousIceState = newIceState;
    }, false);

    triggerPeerConnectionCompletion(null);
  };

  var triggerPeerConnectionCompletion = function() {
    while (_peerConnectionCompletionHandlers.length) {
      _peerConnectionCompletionHandlers.shift().call(null);
    }
  };

  // Clean up the Peer Connection and trigger the close event.
  // This function can be called safely multiple times, it will
  // only trigger the close event once (per PeerConnection object)
  var tearDownPeerConnection = function() {
    // Our connection is dead, stop processing ICE candidates
    if (_iceProcessor) {
      _iceProcessor.destroy();
      _iceProcessor = null;
    }

    qos.stopCollecting();

    if (_peerConnection !== null) {
      if (_peerConnection.destroy) {
        // OTPlugin defines a destroy method on PCs. This allows
        // the plugin to release any resources that it's holding.
        _peerConnection.destroy();
      }

      _peerConnection = null;
      api.trigger('close');
    }
  };

  var routeStateChanged = function() {
    var newState = _peerConnection.signalingState;
    api.emit('signalingStateChange', newState);

    if (newState === 'stable') {
      api.emit('signalingStateStable');
    }

    if (newState && newState !== _state) {
      _state = newState;
      logging.debug('PeerConnection.stateChange: ' + _state);

      switch (_state) {
        case 'closed':
          tearDownPeerConnection();
          break;
        default:
      }
    }
  };

  var qosCallback = function(parsedStats) {
    parsedStats.dataChannels = _channels.sampleQos();
    api.trigger('qos', parsedStats);
  };

  var getRemoteStreams = function() {
    var streams;

    if (_peerConnection.getRemoteStreams) {
      streams = _peerConnection.getRemoteStreams();
    } else if (_peerConnection.remoteStreams) {
      streams = _peerConnection.remoteStreams;
    } else {
      throw new Error('Invalid Peer Connection object implements no ' +
        'method for retrieving remote streams');
    }

    // Force streams to be an Array, rather than a 'Sequence' object,
    // which is browser dependent and does not behaviour like an Array
    // in every case.
    return Array.prototype.slice.call(streams);
  };

  /// PeerConnection signaling
  var onRemoteStreamAdded = function(event) {
    api.trigger('streamAdded', event.stream);
  };

  var onRemoteStreamRemoved = function(event) {
    api.trigger('streamRemoved', event.stream);
  };

  // ICE Negotiation messages

  // Relays a SDP payload (+sdp+), that is part of a message of type +messageType+
  // via the registered message delegators
  var relaySDP = function(messageType, sdp, uri) {
    _sendMessage(messageType, sdp, uri);
  };

  // Process an offer that
  var processOffer = function(message) {
    var offer = new NativeRTCSessionDescription({ type: 'offer', sdp: message.content.sdp });

    // Relays +answer+ Answer
    var relayAnswer = function(answer) {
      _iceProcessor.process();
      relaySDP(RaptorConstants.Actions.ANSWER, answer);
      qos.startCollecting(_peerConnection);
    };

    var reportError = function(message, errorReason, prefix) {
      triggerError('PeerConnection.offerProcessor ' + message + ': ' +
        errorReason, prefix);
    };

    internalCreatePeerConnection(function() {
      offerProcessor(
        _peerConnection,
        offer,
        relayAnswer,
        reportError
      );
    });
  };

  var processAnswer = function(message) {
    if (!message.content.sdp) {
      logging.error('PeerConnection.processMessage: Weird answer message, no SDP.');
      return;
    }

    _answer = new NativeRTCSessionDescription({ type: 'answer', sdp: message.content.sdp });
    _peerConnection.setRemoteDescription(
      _answer,
      function() {
        logging.debug('PeerConnection.processAnswer: setRemoteDescription Success');
        _iceProcessor.process();
      },
      function(errorReason) {
        triggerError('Error while setting RemoteDescription ' + errorReason, 'SetRemoteDescription');
      }
    );

    qos.startCollecting(_peerConnection);
  };

  var processSubscribe = function(message, reallyCreateTheOffer) {
    logging.debug('PeerConnection.processSubscribe: Sending offer to subscriber.');

    var simulcastEnabled = (message.content ?
      message.content.simulcastEnabled === true :
      _simulcastEnabledSettings[message.uri] === true
    );

    _simulcastEnabledSettings[message.uri] = simulcastEnabled;

    if (config.overrideSimulcastEnabled !== undefined) {
      simulcastEnabled = config.overrideSimulcastEnabled;
    }

    var simulcastStreams = (simulcastEnabled ?
      config.capableSimulcastStreams :
      1
    );

    if (simulcastStreams > 1) {
      api.trigger('simulcastEnabled');
    }

    internalCreatePeerConnection(function() {
      if (reallyCreateTheOffer) {
        subscribeProcessor(
          _peerConnection,
          simulcastStreams,
          config.offerConstraints,

          // Success: Relay Offer
          function(offer) {
            _offer = offer;
            _readyToSendOffer.promise.then(function() {
              relaySDP(RaptorConstants.Actions.OFFER, _offer, message.uri);
            });
          },

          // Failure
          function(message, errorReason, prefix) {
            triggerError('subscribeProcessor ' + message + ': ' +
              errorReason, prefix);
          }
        );
      }
    });
  };

  var triggerError = function(errorReason, prefix) {
    logging.error(errorReason);
    api.trigger('error', errorReason, prefix);
  };

  /**
   * Add a track to the underlying PeerConnection
   *
   * @param {object} track - the track to add
   * @param {object} stream - the stream to add it to
   * @return {RTCRtpSender}
   */
  api.addTrack = function(track, stream) {
    return new Bluebird.Promise(function(resolve, reject) {
      internalCreatePeerConnection(function(err) {
        if (err) { return reject(err); }
        resolve();
        return undefined;
      });
    })
      .then(function() {
        if (_peerConnection.addTrack) {
          return _peerConnection.addTrack(track, stream);
        }

        var pcStream = _peerConnection.getLocalStreams()[0];
        if (pcStream === undefined) {
          throw new Error('PeerConnection has no existing streams, cannot addTrack');
        }
        pcStream.addTrack(track);
        api.emit('negotiationNeeded');
        return undefined;
      })
      .then(function() {
        return new Bluebird.Promise(function(resolve) {
          api.once('signalingStateStable', function() {
            resolve();
          });
        }).timeout(15000, 'Renegotiation timed out');
      });
  };

  function FakeRTCRtpSender(track) {
    this.track = track;
  }

  /**
   * Remove a track from the underlying PeerConnection
   *
   * @param {RTCRtpSender} RTCRtpSender - the RTCRtpSender to remove
   */
  api.removeTrack = function(RTCRtpSender) {
    return bluebird.resolve()
      .then(function() {
        if (RTCRtpSender instanceof FakeRTCRtpSender) {
          _peerConnection.getLocalStreams()[0].removeTrack(RTCRtpSender.track);
          api.emit('negotiationNeeded');
          return bluebird.resolve();
        }
        return _peerConnection.removeTrack(RTCRtpSender);
      })
      .then(function() {
        return new bluebird.Promise(function(resolve) {
          api.once('signalingStateStable', function() {
            resolve();
          });
        });
      })
      .timeout(15000, 'Renegotiation timed out');
  };

  api.addLocalStream = function(webRTCStream) {
    internalCreatePeerConnection(function() {
      _peerConnection.addStream(webRTCStream);
    }, webRTCStream);
  };

  api.getLocalStreams = function() {
    return _peerConnection.getLocalStreams();
  };

  api.getSenders = function() {
    if (_peerConnection.getSenders) {
      return _peerConnection.getSenders();
    }

    return _peerConnection.getLocalStreams()[0].getTracks().map(function(track) {
      return new FakeRTCRtpSender(track);
    });
  };

  api.disconnect = function() {
    if (_iceProcessor) {
      _iceProcessor.destroy();
      _iceProcessor = null;
    }

    if (_peerConnection &&
        _peerConnection.signalingState &&
        _peerConnection.signalingState.toLowerCase() !== 'closed') {

      _peerConnection.close();

      if (OTHelpers.env.name === 'Firefox') {
        // FF seems to never go into the closed signalingState when the close
        // method is called on a PeerConnection. This means that we need to call
        // our cleanup code manually.
        //
        // * https://bugzilla.mozilla.org/show_bug.cgi?id=989936
        //
        setTimeout(tearDownPeerConnection);
      }
    }

    api.off();
  };

  api.createOfferWithIceRestart = function(subscriberUri) {
    processSubscribe({
      uri: subscriberUri
    }, true);
  };

  api.processMessage = function(type, message) {
    logging.debug('PeerConnection.processMessage: Received ' +
      type + ' from ' + message.fromAddress);

    logging.debug(message);

    switch (type) {
      case 'generateoffer':
        processSubscribe(message, message.reallyCreateTheOffer || OTHelpers.env.name === 'IE');
        break;

      case 'offer':
        processOffer(message);
        break;

      case 'answer':
      case 'pranswer':
        processAnswer(message);
        break;

      case 'candidate':
        var iceCandidate = new NativeRTCIceCandidate(message.content);
        _iceProcessor.addIceCandidate(iceCandidate).catch(function(err) {
          triggerError('Error while adding ICE candidate: ' + err.toString(), 'ICEWorkflow');
        });

        break;

      default:
        logging.debug('PeerConnection.processMessage: Received an unexpected message of type ' +
          type + ' from ' + message.fromAddress + ': ' + JSON.stringify(message));
    }

    return api;
  };

  api.setIceServers = function(iceServers) {
    if (iceServers) {
      config.iceServers = iceServers;
    }
  };

  api.remoteStreams = function() {
    return _peerConnection ? getRemoteStreams() : [];
  };

  api.getStats = function(callback) {
    if (!_peerConnection) {
      callback(new OTHelpers.Error('Cannot call getStats before there is a connection.',
      'NotConnectedError', {
        code: 1015
      }));
      return;
    }
    _getStatsAdapter(_peerConnection, callback);
  };

  var waitForChannel = function waitForChannel(timesToWait, label, options, completion) {
    var err;
    var channel = _channels.get(label, options);

    if (!channel) {
      if (timesToWait > 0) {
        setTimeout(
          waitForChannel.bind(null, timesToWait - 1, label, options, completion),
          200
        );

        return;
      }

      err = new OTHelpers.Error('A channel with that label and options could not be found. ' +
                            'Label:' + label + '. Options: ' + JSON.stringify(options));
    }

    completion(err, channel);
  };

  api.getDataChannel = function(label, options, completion) {
    if (!_peerConnection) {
      completion(new OTHelpers.Error('Cannot create a DataChannel before there is a connection.'));
      return;
    }
    // Wait up to 20 sec for the channel to appear, then fail
    waitForChannel(100, label, options, completion);
  };

  api.iceConnectionStateIsConnected = function() {
    return _peerConnection.iceConnectionState === 'connected' ||
      _peerConnection.iceConnectionState === 'completed';
  };

  var qos = new Qos(qosCallback, isPublisher);

  return api;
};

PeerConnection.createPeerConnection = createPeerConnection;

module.exports = PeerConnection;
