'use strict';

var once = require('lodash.once');

var OTPlugin = require('../otplugin/otplugin.js');

var env = require('../common-js-helpers/env.js');

// TODO Remove all PeerConnection stuff, that belongs to the messaging layer not the Media layer.
// Indicates whether this client supports the PeerConnection
// API.
//
// Chrome Issues:
// * The explicit prototype.addStream check is because webkitRTCPeerConnection was
// partially implemented, but not functional, in Chrome 22.
//
// Firefox Issues:
// * No real support before Firefox 19
// * Firefox 19 has issues with generating Offers.
// * Firefox 20 doesn't interoperate with Chrome.
//
module.exports = once(function() {
  if (env.name === 'Node') {
    return false;
  } else if (typeof global.webkitRTCPeerConnection === 'function' &&
                    !!global.webkitRTCPeerConnection.prototype.addStream) {
    return true;
  } else if (typeof global.RTCPeerConnection === 'function') {
    return true;
  } else if (typeof global.mozRTCPeerConnection === 'function' && env.version > 20.0) {
    return true;
  }

  return OTPlugin.isInstalled();
});
