'use strict';

var util = require('../util');

module.exports = function statable(
  self,
  possibleStates,
  initialState,
  stateChanged,
  stateChangedFailed
) {
  var previousState;
  var currentState = self.currentState = initialState;

  var setState = function(state) {
    if (currentState !== state) {
      if (possibleStates.indexOf(state) === -1) {
        if (stateChangedFailed && util.isFunction(stateChangedFailed)) {
          stateChangedFailed('invalidState', state);
        }
        return;
      }

      self.previousState = previousState = currentState;
      self.currentState = currentState = state;

      if (stateChanged && util.isFunction(stateChanged)) {
        stateChanged(state, previousState);
      }
    }
  };

  // Returns a number of states and returns true if the current state
  // is any of them.
  //
  // @example
  // if (this.is('connecting', 'connected')) {
  //   // do some stuff
  // }
  //
  self.is = function(/* state0:String, state1:String, ..., stateN:String */) {
    return Array.prototype.indexOf.call(arguments, currentState) !== -1;
  };

  // Returns a number of states and returns true if the current state
  // is none of them.
  //
  // @example
  // if (this.isNot('connecting', 'connected')) {
  //   // do some stuff
  // }
  //
  self.isNot = function(/* state0:String, state1:String, ..., stateN:String */) {
    return Array.prototype.indexOf.call(arguments, currentState) === -1;
  };

  return setState;
};
