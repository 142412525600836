'use strict';

/*
 * Transforms a raw audio level to produce a "smoother" animation when using displaying the
 * audio level. This transformer is state-full because it needs to keep the previous average
 * value of the signal for filtering.
 *
 * It applies a low pass filter to get rid of level jumps and apply a log scale.
 *
 * @constructor
 */
module.exports = function AudioLevelTransformer() {

  var _averageAudioLevel = null;

  /*
   *
   * @param {number} audioLevel a level in the [0,1] range
   * @returns {number} a level in the [0,1] range transformed
   */
  this.transform = function(audioLevel) {
    if (_averageAudioLevel === null || audioLevel >= _averageAudioLevel) {
      _averageAudioLevel = audioLevel;
    } else {
      // a simple low pass filter with a smoothing of 70
      _averageAudioLevel = audioLevel * 0.3 + _averageAudioLevel * 0.7;
    }

    // 1.5 scaling to map -30-0 dBm range to [0,1]
    var logScaled = (Math.log(_averageAudioLevel) / Math.LN10) / 1.5 + 1;

    return Math.min(Math.max(logScaled, 0), 1);
  };
};
