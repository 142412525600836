'use strict';

// Returns true if we think the DOM has been unloaded
// It detects this by looking for a global object, which
// should always exist until the DOM is cleaned up.

var uuid = require('uuid');

var canary = 'OT_CANARY_' + uuid();

global[canary] = {};

module.exports = function() {
  return !global[canary];
};
