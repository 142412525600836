'use strict';

var logging = require('../logging.js');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');

var START_MEDIA_SSRC = 10000;
var START_RTX_SSRC = 20000;

// Here are the structure of the rtpmap attribute and the media line, most of the
// complex Regular Expressions in this code are matching against one of these two
// formats:
// * a=rtpmap:<payload type> <encoding name>/<clock rate> [/<encoding parameters>]
// * m=<media> <port>/<number of ports> <proto> <fmts>
//
// References:
// * https://tools.ietf.org/html/rfc4566
// * http://en.wikipedia.org/wiki/Session_Description_Protocol
//
//

var SDPHelpers = {};
module.exports = SDPHelpers;

// Search through sdpLines to find the Media Line of type +mediaType+.
SDPHelpers.getMLineIndex = function getMLineIndex(sdpLines, mediaType) {
  var targetMLine = 'm=' + mediaType;

  // Find the index of the media line for +type+
  return OTHelpers.findIndex(sdpLines, function(line) {
    if (line.indexOf(targetMLine) !== -1) {
      return true;
    }

    return false;
  });
};

// Grab a M line of a particular +mediaType+ from sdpLines.
SDPHelpers.getMLine = function getMLine(sdpLines, mediaType) {
  var mLineIndex = SDPHelpers.getMLineIndex(sdpLines, mediaType);
  return mLineIndex > -1 ? sdpLines[mLineIndex] : void 0;
};

SDPHelpers.hasMLinePayloadType = function hasMLinePayloadType(sdpLines, mediaType, payloadType) {
  var mLine = SDPHelpers.getMLine(sdpLines, mediaType);
  var payloadTypes = SDPHelpers.getMLinePayloadTypes(mLine, mediaType);

  return payloadTypes.indexOf(payloadType) > -1;
};

// Extract the payload types for a give Media Line.
//
SDPHelpers.getMLinePayloadTypes = function getMLinePayloadTypes(mediaLine, mediaType) {
  var mLineSelector = new RegExp('^m=' + mediaType +
                        ' \\d+(/\\d+)? [a-zA-Z0-9/]+(( [a-zA-Z0-9/]+)+)$', 'i');

  // Get all payload types that the line supports
  var payloadTypes = mediaLine.match(mLineSelector);
  if (!payloadTypes || payloadTypes.length < 2) {
    // Error, invalid M line?
    return [];
  }

  return payloadTypes[2].trim().split(' ');
};

SDPHelpers.removeTypesFromMLine = function removeTypesFromMLine(mediaLine, payloadTypes) {
  var typesSuffix = /[0-9 ]*$/.exec(mediaLine)[0];
  var prefix = mediaLine.replace(typesSuffix, '');

  var newTypes = typesSuffix.split(' ').filter(function(type) {
    return type !== '' && payloadTypes.indexOf(type) === -1;
  });

  return prefix + ' ' + newTypes.join(' ');
};

// Remove all references to a particular encodingName from a particular media type
//
SDPHelpers.removeMediaEncoding = function removeMediaEncoding(sdp, mediaType, encodingName) {
  var payloadTypes, i, j, parts;
  var sdpLines = sdp.split('\r\n');
  var mLineIndex = SDPHelpers.getMLineIndex(sdpLines, mediaType);
  var mLine = mLineIndex > -1 ? sdpLines[mLineIndex] : void 0;
  var typesToRemove = [];

  if (mLineIndex === -1) {
    // Error, missing M line
    return sdpLines.join('\r\n');
  }

  // Get all payload types that the line supports
  payloadTypes = SDPHelpers.getMLinePayloadTypes(mLine, mediaType);
  if (payloadTypes.length === 0) {
    // Error, invalid M line?
    return sdpLines.join('\r\n');
  }

  // Find the payloadTypes of the codecs.
  // Allows multiple matches e.g. for CN.
  for (i = mLineIndex; i < sdpLines.length; i++) {
    if (sdpLines[i].indexOf('a=rtpmap:') === 0) {
      parts = sdpLines[i].split(' ');
      if (parts.length === 2 && parts[1].indexOf(encodingName + '/') === 0) {
        typesToRemove.push(parts[0].substr(9));
      }
    }
  }

  if (!typesToRemove.length) {
    // Not found.
    return sdpLines.join('\r\n');
  }

  // Also find any rtx which reference the removed codec.
  for (i = mLineIndex; i < sdpLines.length; i++) {
    if (sdpLines[i].indexOf('a=fmtp:') === 0) {
      parts = sdpLines[i].split(' ');
      for (j = 0; j < typesToRemove.length; j++) {
        if (parts.length === 2 && parts[1] === 'apt=' + typesToRemove[j]) {
          typesToRemove.push(parts[0].substr(7));
        }
      }
    }
  }

  // Remove any rtpmap, fmtp or rtcp-fb.
  sdpLines = sdpLines.filter(function(line) {
    for (var i = 0; i < typesToRemove.length; i++) {
      if (line.indexOf('a=rtpmap:' + typesToRemove[i] + ' ') === 0 ||
          line.indexOf('a=fmtp:' + typesToRemove[i] + ' ') === 0 ||
          line.indexOf('a=rtcp-fb:' + typesToRemove[i] + ' ') === 0) {
        return false;
      }
    }
    return true;
  });

  if (typesToRemove.length > 0 && mLineIndex > -1) {
    // Remove all the payload types and we've removed from the media line
    sdpLines[mLineIndex] = SDPHelpers.removeTypesFromMLine(mLine, typesToRemove);
  }

  return sdpLines.join('\r\n');
};

SDPHelpers.removeVideoCodec = function removeVideoCodec(sdp, codec) {
  return SDPHelpers.removeMediaEncoding(sdp, 'video', codec);
};

// Used to identify whether Video media (for a given set of SDP) supports
// retransmissions.
//
// The algorithm to do could be summarised as:
//
// IF ssrc-group:FID exists AND IT HAS AT LEAST TWO IDS THEN
//    we are using RTX
// ELSE IF "a=rtpmap: (\\d+):rtxPayloadId(/\\d+)? rtx/90000"
//          AND SDPHelpers.hasMLinePayloadType(sdpLines, 'Video', rtxPayloadId)
//    we are using RTX
// ELSE
//    we are not using RTX
//
// The ELSE IF clause basically covers the case where ssrc-group:FID
// is probably malformed or missing. In that case we verify whether
// we want RTX by looking at whether it's mentioned in the video
// media line instead.
//
var isUsingRTX = function isUsingRTX(sdpLines, videoAttrs) {
  var groupFID = videoAttrs.filterByName('ssrc-group:FID');
  var missingFID = groupFID.length === 0;

  if (!missingFID) {
    groupFID = groupFID[0].value.split(' ');
  } else {
    groupFID = [];
  }

  switch (groupFID.length) {
    case 0:
    case 1:
      // possibly no RTX, double check for the RTX payload type and that
      // the Video Media line contains that payload type
      //
      // Details: Look for a rtpmap line for rtx/90000
      //  If there is one, grab the payload ID for rtx
      //    Look to see if that payload ID is listed under the payload types for the m=Video line
      //      If it is: RTX
      //  else: No RTX for you

      var rtxAttr = videoAttrs.find(function(attr) {
        return attr.name.indexOf('rtpmap:') === 0 &&
               attr.value.indexOf('rtx/90000') > -1;
      });

      if (!rtxAttr) {
        return false;
      }

      var rtxPayloadId = rtxAttr.name.split(':')[1];
      if (rtxPayloadId.indexOf('/') > -1) { rtxPayloadId = rtxPayloadId.split('/')[0]; }
      return SDPHelpers.hasMLinePayloadType(sdpLines, 'video', rtxPayloadId);

    default:
      // two or more: definitely RTX
      logging.debug('SDP Helpers: There are more than two FIDs, RTX is definitely enabled');
      return true;
  }
};

// This returns an Array, which is decorated with several
// SDP specific helper methods.
//
SDPHelpers.getAttributesForMediaType = function getAttributesForMediaType(sdpLines, mediaType) {
  var ssrcStartIndex, ssrcEndIndex, regResult, ssrc, ssrcGroup, msidMatch, msid;
  var mLineIndex = SDPHelpers.getMLineIndex(sdpLines, mediaType);
  var matchOtherMLines = new RegExp('m=(^' + mediaType + ') ', 'i');
  var matchSSRCLines = new RegExp('a=ssrc:\\d+ .*', 'i');
  var matchSSRCGroup = new RegExp('a=ssrc-group:FID (\\d+).*?', 'i');
  var matchAttrLine = new RegExp('a=([a-z0-9:/-]+) (.*)', 'i');
  var attrs = [];

  for (var i = mLineIndex + 1; i < sdpLines.length; i++) {
    if (matchOtherMLines.test(sdpLines[i])) {
      break;
    }

    // Get the ssrc
    ssrcGroup = sdpLines[i].match(matchSSRCGroup);
    if (ssrcGroup) {
      ssrcStartIndex = i;
      ssrc = ssrcGroup[1];
    }

    // Get the msid
    msidMatch = sdpLines[i].match('a=ssrc:' + ssrc + ' msid:(.+)');
    if (msidMatch) {
      msid = msidMatch[1];
    }

    // find where the ssrc lines end
    var isSSRCLine = matchSSRCLines.test(sdpLines[i]);
    if (ssrcStartIndex !== undefined && ssrcEndIndex === undefined && !isSSRCLine ||
      i === sdpLines.length - 1) {
      ssrcEndIndex = i;
    }

    regResult = matchAttrLine.exec(sdpLines[i]);
    if (regResult && regResult.length === 3) {
      attrs.push({
        name: regResult[1],
        value: regResult[2]
      });
    }
  }

  /// The next section decorates the attributes array
  /// with some useful helpers.

  // Store references to the start and end indices
  // of the media section for this mediaType
  attrs.ssrcStartIndex = ssrcStartIndex;
  attrs.ssrcEndIndex = ssrcEndIndex;
  attrs.msid = msid;

  // Add filter support is
  if (!Array.prototype.filter) {
    attrs.filter = OTHelpers.filter.bind(OTHelpers, attrs);
  }

  if (!Array.prototype.find) {
    attrs.find = OTHelpers.find.bind(OTHelpers, attrs);
  }

  attrs.isUsingRTX = isUsingRTX.bind(null, sdpLines, attrs);

  attrs.filterByName = function(name) {
    return this.filter(function(attr) {
      return attr.name === name;
    });
  };

  return attrs;
};

// Modifies +sdp+ to enable Simulcast for +numberOfStreams+.
//
// Ok, here's the plan:
//  - add the 'a=ssrc-group:SIM' line, it will have numberOfStreams ssrcs
//  - if RTX then add one 'a=ssrc-group:FID', we need to add numberOfStreams lines
//  - add numberOfStreams 'a=ssrc:...' lines for the media ssrc
//  - if RTX then add numberOfStreams 'a=ssrc:...' lines for the RTX ssrc
//
// Re: media and rtx ssrcs:
// We just generate these. The Mantis folk would like us to use sequential numbers
// here for ease of debugging. We can use the same starting number each time as well.
// We should confirm with Oscar/Jose that whether we need to verify that the numbers
// that we choose don't clash with any other ones in the SDP.
//
// I think we do need to check but I can't remember.
//
// Re: The format of the 'a=ssrc:' lines
// Just use the following pattern:
//   a=ssrc:<Media or RTX SSRC> cname:localCname
//   a=ssrc:<Media or RTX SSRC> msid:<MSID>
//
// It doesn't matter that they are all the same and are static.
//
//
SDPHelpers.enableSimulcast = function enableSimulcast(sdp, numberOfStreams) {
  var linesToAdd, i;
  var sdpLines = sdp.split('\r\n');
  if (!SDPHelpers.getMLine(sdpLines, 'video')) {
    logging.debug('No video m-line, not enabling simulcast.');
    return sdp;
  }
  var videoAttrs = SDPHelpers.getAttributesForMediaType(sdpLines, 'video');

  if (videoAttrs.filterByName('ssrc-group:SIM').length > 0) {
    logging.debug('Simulcast is already enabled in this SDP, not attempting to enable again.');
    return sdp;
  }

  if (!videoAttrs.msid) {
    logging.debug('No local stream attached, not enabling simulcast.');
    return sdp;
  }

  var usingRTX = videoAttrs.isUsingRTX();
  var mediaSSRC = [];
  var rtxSSRC = [];

  // generate new media (and rtx if needed) ssrcs
  for (i = 0; i < numberOfStreams; ++i) {
    mediaSSRC.push(START_MEDIA_SSRC + i);
    if (usingRTX) { rtxSSRC.push(START_RTX_SSRC + i); }
  }

  linesToAdd = [
    'a=ssrc-group:SIM ' + mediaSSRC.join(' ')
  ];

  if (usingRTX) {
    for (i = 0; i < numberOfStreams; ++i) {
      linesToAdd.push('a=ssrc-group:FID ' + mediaSSRC[i] + ' ' + rtxSSRC[i]);
    }
  }

  for (i = 0; i < numberOfStreams; ++i) {
    linesToAdd.push('a=ssrc:' + mediaSSRC[i] + ' cname:localCname',
    'a=ssrc:' + mediaSSRC[i] + ' msid:' + videoAttrs.msid);

  }

  if (usingRTX) {
    for (i = 0; i < numberOfStreams; ++i) {
      linesToAdd.push('a=ssrc:' + rtxSSRC[i] + ' cname:localCname',
      'a=ssrc:' + rtxSSRC[i] + ' msid:' + videoAttrs.msid);
    }
  }

  // Replace the previous video ssrc section with our new video ssrc section by
  // deleting the old ssrcs section and inserting the new lines
  linesToAdd.unshift(videoAttrs.ssrcStartIndex, videoAttrs.ssrcEndIndex -
    videoAttrs.ssrcStartIndex);
  sdpLines.splice.apply(sdpLines, linesToAdd);

  return sdpLines.join('\r\n');
};

// Modifies +sdp+ to work around this bug: http://crbug.com/528089
// In Chrome if you call createOffer() on a peer that is recvonly, it generates SDP that is sendonly
// which doesn't work. Perhaps unsurprisingly. This fixes it.
SDPHelpers.fixChromeBug528089 = function(sdp) {
  return sdp.replace(/sendonly/g, 'recvonly');
};
