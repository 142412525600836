'use strict';

var Widget = require('./behaviour/widget.js');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');

module.exports = function VideoDisabledIndicator(options) {
  var videoDisabled = false;
  var warning = false;

  var updateClasses = function(element) {
    var shouldDisplay = ['auto', 'on'].indexOf(this.getDisplayMode()) > -1;

    OTHelpers.removeClass(element, 'OT_video-disabled OT_video-disabled-warning OT_active');

    if (!shouldDisplay) {
      return;
    }

    if (videoDisabled) {
      OTHelpers.addClass(element, 'OT_video-disabled');
    } else if (warning) {
      OTHelpers.addClass(element, 'OT_video-disabled-warning');
    }

    OTHelpers.addClass(element, 'OT_active');
  }.bind(this);

  this.disableVideo = function(value) {
    videoDisabled = value;
    if (value === true) {
      warning = false;
    }
    updateClasses(this.domElement);
  };

  this.setWarning = function(value) {
    warning = value;
    updateClasses(this.domElement);
  };

  // Mixin common widget behaviour
  Widget(this, {
    mode: options.mode || 'auto',
    nodeName: 'div',
    htmlAttributes: {
      className: 'OT_video-disabled-indicator'
    }
  });

  var parentSetDisplayMode = this.setDisplayMode.bind(this);
  this.setDisplayMode = function(mode) {
    parentSetDisplayMode(mode);
    updateClasses(this.domElement);
  };
};
