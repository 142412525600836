'use strict';

// Rumor Messaging for JS
//
// https://tbwiki.tokbox.com/index.php/Raptor_Messages_(Sent_as_a_RumorMessage_payload_in_JSON)
//
// @todo Raptor {
//     Look at disconnection cleanup: i.e. subscriber + publisher cleanup
//     Add error codes for all the error cases
//     Write unit tests for SessionInfo
//     Write unit tests for Session
//     Make use of the new DestroyedEvent
//     Capabilities must be part of the Raptor namespace
//     Add Dependability commands
//     Think about noConflict, or whether we should just use the OT namespace
//     Think about how to expose sessionObjects.publishers, sessionObjects.subscribers, and
//        sessionObjects.sessions if messaging was being included as a component
//     Another solution to the problem of having publishers/subscribers/etc would be to make
//        Raptor Socket a separate component from Dispatch (dispatch being more business logic)
//     Look at the coupling of sessionObjects.sessions to RaptorSocket
// }
//
// @todo Raptor Docs {
//   Document payload formats for incoming messages (what are the payloads for
//    STREAM CREATED/MODIFIED for example)
//   Document how keepalives work
//   Document all the Raptor actions and types
//   Document the session connect flow (including error cases)
// }

module.exports = {
  Actions: {
    //General
    CONNECT: 100,
    CREATE: 101,
    UPDATE: 102,
    DELETE: 103,
    STATE: 104,

    //Moderation
    FORCE_DISCONNECT: 105,
    FORCE_UNPUBLISH: 106,
    SIGNAL: 107,

    //Archives
    CREATE_ARCHIVE: 108,
    CLOSE_ARCHIVE: 109,
    START_RECORDING_SESSION: 110,
    STOP_RECORDING_SESSION: 111,
    START_RECORDING_STREAM: 112,
    STOP_RECORDING_STREAM: 113,
    LOAD_ARCHIVE: 114,
    START_PLAYBACK: 115,
    STOP_PLAYBACK: 116,

    //AppState
    APPSTATE_PUT: 117,
    APPSTATE_DELETE: 118,

    // JSEP
    OFFER: 119,
    ANSWER: 120,
    PRANSWER: 121,
    CANDIDATE: 122,
    SUBSCRIBE: 123,
    UNSUBSCRIBE: 124,
    QUERY: 125,
    SDP_ANSWER: 126,

    //KeepAlive
    PONG: 127,
    REGISTER: 128, //Used for registering streams.

    QUALITY_CHANGED: 129
  },

  Types: {
    //RPC
    RPC_REQUEST: 100,
    RPC_RESPONSE: 101,

    //EVENT
    STREAM: 102,
    ARCHIVE: 103,
    CONNECTION: 104,
    APPSTATE: 105,
    CONNECTIONCOUNT: 106,
    MODERATION: 107,
    SIGNAL: 108,
    SUBSCRIBER: 110,

    //JSEP Protocol
    JSEP: 109
  }
};
