'use strict';

var Promise = require('bluebird');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');

var canBeOrientatedMixin = require('./can_be_oriented_mixin.js');

module.exports = function PluginVideoElementWrapper(
  options,
  errorHandler,
  orientationChangedHandler,
  defaultAudioVolume
) {
  var _pluginVideoContainer, _parentDomElement;

  OTHelpers.eventing(this);

  canBeOrientatedMixin(this, function() {
    return _pluginVideoContainer.domElement;
  },
    orientationChangedHandler);

  /// Public methods

  this.domElement = function() {
    return _pluginVideoContainer ? _pluginVideoContainer.domElement : void 0;
  };

  this.videoWidth = function() {
    return _pluginVideoContainer ? _pluginVideoContainer.videoWidth() : void 0;
  };

  this.videoHeight = function() {
    return _pluginVideoContainer ? _pluginVideoContainer.videoHeight() : void 0;
  };

  this.imgData = function() {
    return _pluginVideoContainer ? _pluginVideoContainer.getImgData() : null;
  };

  // Append the Video DOM element to a parent node
  this.appendTo = function(parentDomElement) {
    _parentDomElement = parentDomElement;
    return this;
  };

  // Bind a stream to the video element.
  this.bindToStream = function(webRtcStream, completion) {
    if (!_parentDomElement) {
      completion('The VideoElement must attached to a DOM node before a stream can be bound');
      return undefined;
    }
    _pluginVideoContainer = webRtcStream._.render();
    this.trigger('videoElementCreated', this.domElement());
    _pluginVideoContainer.setFitMode(options.fitMode);
    _pluginVideoContainer.appendTo(_parentDomElement);
    _pluginVideoContainer.show(function(error) {
      completion(error);
    });

    return this;
  };

  // Unbind the currently bound stream from the video element.
  this.unbindStream = function() {
    // TODO: some way to tell OTPlugin to release that stream and controller

    if (_pluginVideoContainer) {
      _pluginVideoContainer.destroy();
      if (this.domElement() && this.domElement().parentNode) {
        this.domElement().parentNode.removeChild(this.domElement());
      }
      _parentDomElement = null;
      _pluginVideoContainer = null;
    }

    return this;
  };

  this.setAudioVolume = function(value) {
    if (_pluginVideoContainer) { _pluginVideoContainer.volume(value); }
  };

  this.getAudioVolume = function() {
    // Return the actual volume of the DOM element
    if (_pluginVideoContainer) { return _pluginVideoContainer.volume(); }
    return defaultAudioVolume;
  };

  // see https://wiki.mozilla.org/WebAPI/AudioChannels
  // The audioChannelType is not currently supported in the plugin.
  this.audioChannelType = function(/* type */) {
    return 'unknown';
  };

  this.getAudioInputLevel = function() {
    return Promise.resolve(_pluginVideoContainer.getAudioInputLevel());
  };

  this.whenTimeIncrements = function(callback, context) {
    // exists for compatibility with NativeVideoElement
    setTimeout(callback.bind(context));
  };

  this.destroy = function() {
    this.unbindStream();

    return void 0;
  };
};
