'use strict';

var OTHelpers = require('../common-js-helpers/OTHelpers.js');

module.exports = function(self, domElement) {
  var width = domElement.videoWidth;
  var height = domElement.videoHeight;
  var stopped = true;

  function actor() {
    if (stopped) {
      return;
    }
    if (width !== domElement.videoWidth || height !== domElement.videoHeight) {
      self.trigger('videoDimensionsChanged',
        { width: width, height: height },
        { width: domElement.videoWidth, height: domElement.videoHeight }
      );
      width = domElement.videoWidth;
      height = domElement.videoHeight;
    }
    waiter();
  }

  function waiter() {
    self.whenTimeIncrements(function() {
      OTHelpers.requestAnimationFrame(actor);
    });
  }

  self.startObservingSize = function() {
    stopped = false;
    waiter();
  };

  self.stopObservingSize = function() {
    stopped = true;
  };
};
