'use strict';

var OTHelpers = require('../../common-js-helpers/OTHelpers.js');

/*
 * A Publishers Microphone.
 *
 * TODO
 * * bind to changes in mute/unmute/volume/etc and respond to them
 */
module.exports = function Microphone(webRTCStream, muted) {
  var _muted;

  OTHelpers.defineProperties(this, {
    muted: {
      get: function() {
        return _muted;
      },
      set: function(muted) {
        if (_muted === muted) { return; }

        _muted = muted;

        var audioTracks = webRTCStream.getAudioTracks();

        for (var i = 0, num = audioTracks.length; i < num; ++i) {
          audioTracks[i].enabled = !_muted;
        }
      }
    }
  });

  // Set the initial value
  if (muted !== undefined) {
    this.muted(muted === true);

  } else if (webRTCStream.getAudioTracks().length) {
    this.muted(!webRTCStream.getAudioTracks()[0].enabled);

  } else {
    this.muted(false);
  }
};
