'use strict';

var OTPlugin = require('../otplugin/otplugin.js');

module.exports = function getNativeEnumerateDevices() {
  if (global.navigator.mediaDevices) {
    return global.navigator.mediaDevices.enumerateDevices;
  } else if (OTPlugin.isInstalled()) {
    return OTPlugin.mediaDevices.enumerateDevices;
  } else if (global.MediaStreamTrack && global.MediaStreamTrack.getSources) {
    return global.MediaStreamTrack.getSources;
  }
  // @todo perhaps we should throw here?
  return undefined;
};
