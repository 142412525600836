'use strict';

var analytics = require('../analytics.js');
var APIKEY = require('../api_key.js');
var Archiving = require('../chrome/archiving.js');
var audioContext = require('../../helpers/audio_context.js');
var AudioLevelMeter = require('../chrome/audio_level_meter.js');
var AudioLevelTransformer = require('../audio_level_transformer');
var BackingBar = require('../chrome/backing_bar.js');
var Bluebird = require('bluebird');
var Chrome = require('../chrome/chrome.js');
var ConnectivityAttemptPinger = require('../../helpers/connectivity_attempt_pinger.js');
var deviceHelpers = require('../../helpers/device_helpers.js');
var EnvironmentLoader = require('../environment_loader.js');
var Events = require('../events.js');
var ExceptionCodes = require('../exception_codes.js');
var calculateCapableSimulcastStreams = require('./calculateCapableSimulcastStreams.js');
var getUserMedia = require('../../helpers/get_user_media.js');
var IntervalRunner = require('../interval_runner.js');
var logging = require('../logging.js');
var Microphone = require('./microphone.js');
var MuteButton = require('../chrome/mute_button.js');
var NamePanel = require('../chrome/name_panel.js');
var OTError = require('../ot_error.js');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');
var parseIceServers = require('../messaging/raptor/parse_ice_servers.js');
var pick = require('lodash.pick');
var properties = require('../../helpers/properties.js');
var PublisherPeerConnection = require('../peer_connection/publisher_peer_connection.js');
var PublishingState = require('./state.js');
var screenSharing = require('../screensharing/screen_sharing.js');
var StreamChannel = require('../stream_channel.js');
var StylableComponent = require('../styling/stylable_component.js');
var systemRequirements = require('../system_requirements.js');
var uuid = require('uuid');
var VideoOrientation = require('../../helpers/video_orientation.js');
var waterfall = require('async').waterfall;
var WidgetView = require('../../helpers/widget_view.js');
var OTPlugin = require('../../otplugin/otplugin.js');

// The default constraints
var defaultConstraints = {
  audio: true,
  video: true
};

var PUBLISH_MAX_DELAY = require('./max_delay.js');

/**
 * The Publisher object  provides the mechanism through which control of the
 * published stream is accomplished. Calling the <code>OT.initPublisher()</code> method
 * creates a Publisher object. </p>
 *
 *  <p>The following code instantiates a session, and publishes an audio-video stream
 *  upon connection to the session: </p>
 *
 *  <pre>
 *  var apiKey = ''; // Replace with your API key. See https://tokbox.com/account
 *  var sessionID = ''; // Replace with your own session ID.
 *                      // See https://tokbox.com/developer/guides/create-session/.
 *  var token = ''; // Replace with a generated token that has been assigned the moderator role.
 *                  // See https://tokbox.com/developer/guides/create-token/.
 *
 *  var session = OT.initSession(apiKey, sessionID);
 *  session.connect(token, function(error) {
 *    if (error) {
 *      console.log(error.message);
 *    } else {
 *      // This example assumes that a DOM element with the ID 'publisherElement' exists
 *      var publisherProperties = {width: 400, height:300, name:"Bob's stream"};
 *      publisher = OT.initPublisher('publisherElement', publisherProperties);
 *      session.publish(publisher);
 *    }
 *  });
 *  </pre>
 *
 *      <p>This example creates a Publisher object and adds its video to a DOM element
 *      with the ID <code>publisherElement</code> by calling the <code>OT.initPublisher()</code>
 *      method. It then publishes a stream to the session by calling
 *      the <code>publish()</code> method of the Session object.</p>
 *
 * @property {Boolean} accessAllowed Whether the user has granted access to the camera
 * and microphone. The Publisher object dispatches an <code>accessAllowed</code> event when
 * the user grants access. The Publisher object dispatches an <code>accessDenied</code> event
 * when the user denies access.
 * @property {Element} element The HTML DOM element containing the Publisher. (<i>Note:</i>
 * when you set the <code>insertDefaultUI</code> option to <code>false</code> in the call to
 * <a href="OT.html#initPublisher">OT.initPublisher</a>, the <code>element</code> property
 * is undefined.)
 * @property {String} id The DOM ID of the Publisher.
 * @property {Stream} stream The {@link Stream} object corresponding the stream of
 * the Publisher.
 * @property {Session} session The {@link Session} to which the Publisher belongs.
 *
 * @see <a href="OT.html#initPublisher">OT.initPublisher</a>
 * @see <a href="Session.html#publish">Session.publish()</a>
 *
 * @class Publisher
 * @augments EventDispatcher
 */
var Publisher = function(options) {
  options = options || {};
  // Check that the client meets the minimum requirements, if they don't the upgrade
  // flow will be triggered.
  if (!systemRequirements.check()) {
    systemRequirements.upgrade();
    return;
  }

  options = options || {};

  var _widgetView,
      _videoElementFacade,
      _stream,
      _streamId,
      _webRTCStream,
      _session,
      _publishStartTime,
      _microphone,
      _chrome,
      _audioLevelMeter,
      _properties,
      _validResolutions,
      _state,
      _iceServers,
      _connectivityAttemptPinger,
      _attemptStartTime,
      _simulcastEnabled,
      _audioDevices,
      _videoDevices,
      _selectedVideoInputDeviceId,
      _selectedAudioInputDeviceId;

  var _guid = Publisher.nextId();
  var _peerConnections = {};
  var _loaded = false;
  var _validFrameRates = [1, 7, 15, 30];
  var _isScreenSharing = options && (
    options.videoSource === 'screen' ||
    options.videoSource === 'window' ||
    options.videoSource === 'tab' ||
    options.videoSource === 'browser' ||
    options.videoSource === 'application'
  );
  var _previousAnalyticsStats = {};
  var self = this;

  _properties = OTHelpers.defaults(options, {
    publishAudio: _isScreenSharing ? false : true,
    publishVideo: true,
    mirror: _isScreenSharing ? false : true,
    showControls: true,
    fitMode: _isScreenSharing ? 'contain' : 'cover',
    audioFallbackEnabled: _isScreenSharing ? false : true,
    maxResolution: _isScreenSharing ? { width: 1920, height: 1920 } : undefined,
    insertDefaultUI: true,
    enableRenegotiation: false
  });

  _validResolutions = {
    '320x240': { width: 320, height: 240 },
    '320x180': { width: 320, height: 180 },
    '640x480': { width: 640, height: 480 },
    '640x360': { width: 640, height: 360 },
    '1280x720': { width: 1280, height: 720 },
    '1280x960': { width: 1280, height: 960 }
  };

  OTHelpers.eventing(this);

  if (!_isScreenSharing) {
    var audioLevelRunner = new IntervalRunner(function() {
      if (_videoElementFacade) {
        _videoElementFacade.getAudioInputLevel()
          .then(function(audioInputLevel) {
            OTHelpers.requestAnimationFrame(function() {
              self.dispatchEvent(
                new Events.AudioLevelUpdatedEvent(audioInputLevel));
            });
          });
      }
    }, 60);

    this.on({
      'audioLevelUpdated:added': function(count) {
        if (count === 1) {
          audioLevelRunner.start();
        }
      },
      'audioLevelUpdated:removed': function(count) {
        if (count === 0) {
          audioLevelRunner.stop();
        }
      }
    });
  }

  /// Private Methods
  var getAllPeerConnections = function() {
    return Bluebird.all(Object.keys(_peerConnections).map(function(id) {
      return _peerConnections[id];
    }));
  };

  var logAnalyticsEvent = function(action, variation, payload, options, throttle) {
    var stats = OTHelpers.extend({
      action: action,
      variation: variation,
      payload: payload,
      sessionId: _session ? _session.sessionId : null,
      connectionId: _session && _session.isConnected() ? _session.connection.connectionId : null,
      partnerId: _session ? _session.apiKey : APIKEY.value,
      p2p: _session && _session.sessionInfo ? _session.sessionInfo.p2pEnabled : null,
      messagingServer: (_session && _session.sessionInfo) ? _session.sessionInfo.messagingServer :
        null,
      streamId: _streamId
    }, options);

    if (variation === 'Failure') {
      stats = OTHelpers.extend(_previousAnalyticsStats, stats);
    }
    _previousAnalyticsStats = pick(stats, 'sessionId', 'connectionId', 'partnerId');

    analytics.logEvent(stats, throttle);
  };

  var logConnectivityEvent = function(variation, payload, options) {
    if (variation === 'Attempt' || !_connectivityAttemptPinger) {
      _attemptStartTime = new Date().getTime();
      _connectivityAttemptPinger = new ConnectivityAttemptPinger({
        action: 'Publish',
        sessionId: _session ? _session.sessionId : null,
        connectionId: _session &&
          _session.isConnected() ? _session.connection.connectionId : null,
        partnerId: _session ? _session.apiKey : APIKEY.value,
        p2p: _session && _session.sessionInfo ? _session.sessionInfo.p2pEnabled : null,
        messagingServer: (_session && _session.sessionInfo) ? _session.sessionInfo.messagingServer :
          null,
        streamId: _streamId
      });
    }

    if (!options || options.failureReason !== 'Non-fatal') {

      if (variation === 'Failure') {
        // We don't want to log an invalid sequence in this case because it was a
        // non-fatal failure
        _connectivityAttemptPinger.setVariation(variation);
      }

      if (variation === 'Failure' || variation === 'Success' || variation === 'Cancel') {
        if (!options) { options = {}; }
        OTHelpers.extend(options, {
          attemptDuration: new Date().getTime() - _attemptStartTime
        });
        payload = payload || {};
        OTHelpers.extend(payload, {
          videoInputDevices: _videoDevices,
          audioInputDevices: _audioDevices,
          videoInputDeviceCount: _videoDevices ? _videoDevices.length : undefined,
          audioInputDeviceCount: _audioDevices ? _audioDevices.length : undefined,
          selectedVideoInputDeviceId: _selectedVideoInputDeviceId,
          selectedAudioInputDeviceId: _selectedAudioInputDeviceId
        });
      }

      logAnalyticsEvent('Publish', variation, payload, options);
    }
  };

  var logRepublish = function(variation, payload) {
    logAnalyticsEvent('ICERestart', variation, payload);
  };

  var recordQOS = function(connection, parsedStats) {
    var domElement;
    if (_widgetView && _widgetView.domElement) {
      domElement = _widgetView.domElement;
    } else if (_widgetView && _widgetView.video() && _widgetView.video().domElement()) {
      // If we're using insertDefaultUI=false then there is no container
      domElement = _widgetView.video().domElement();
    }
    var QoSBlob = {
      widgetType: 'Publisher',
      sessionId: _session ? _session.sessionId : null,
      connectionId: _session && _session.isConnected() ?
        _session.connection.connectionId : null,
      partnerId: _session ? _session.apiKey : APIKEY.value,
      streamId: _streamId,
      width: domElement ? Number(OTHelpers.width(domElement).replace('px', ''))
        : undefined,
      height: domElement ? Number(OTHelpers.height(domElement).replace('px', ''))
        : undefined,
      audioTrack: _webRTCStream && _webRTCStream.getAudioTracks().length > 0,
      hasAudio: _properties.publishAudio,
      videoTrack: _webRTCStream && _webRTCStream.getVideoTracks().length > 0,
      hasVideo: _properties.publishVideo,
      videoSource: _isScreenSharing && options.videoSource ||
        _properties.constraints.video && 'Camera' || null,
      version: properties.version,
      mediaServerName: _session ? _session.sessionInfo.mediaServerName : null,
      apiServer: properties.apiURL,
      p2p: _session ? _session.sessionInfo.p2pEnabled : null,
      messagingServer: _session ? _session.sessionInfo.messagingServer : null,
      duration: _publishStartTime ?
        Math.round((new Date().getTime() - _publishStartTime.getTime()) / 1000) : 0,
      remoteConnectionId: connection.id,
      scalableVideo: !!_simulcastEnabled
    };

    analytics.logQOS(OTHelpers.extend(QoSBlob, parsedStats));
    self.trigger('qos', parsedStats);
  };

  // Returns the video dimensions. Which could either be the ones that
  // the developer specific in the videoDimensions property, or just
  // whatever the video element reports.
  //
  // If all else fails then we'll just default to 640x480
  //
  var getVideoDimensions = function() {
    var streamWidth, streamHeight;

    // We set the streamWidth and streamHeight to be the minimum of the requested
    // resolution and the actual resolution.
    if (_properties.videoDimensions) {
      streamWidth = Math.min(_properties.videoDimensions.width,
        (_videoElementFacade && _videoElementFacade.videoWidth()) || 640);
      streamHeight = Math.min(_properties.videoDimensions.height,
        (_videoElementFacade && _videoElementFacade.videoHeight()) || 480);
    } else {
      streamWidth = (_videoElementFacade && _videoElementFacade.videoWidth()) || 640;
      streamHeight = (_videoElementFacade && _videoElementFacade.videoHeight()) || 480;
    }

    return {
      width: streamWidth,
      height: streamHeight
    };
  };

  /// Private Events

  var stateChangeFailed = function(changeFailed) {
    logging.error('OT.Publisher State Change Failed: ', changeFailed.message);
    logging.debug(changeFailed);
  };

  var onLoaded = function() {
    if (_state.isDestroyed()) {
      // The publisher was destroyed before loading finished
      return;
    }

    logging.debug('OT.Publisher.onLoaded');

    _state.set('MediaBound');

    // If we have a session and we haven't created the stream yet then
    // wait until that is complete before hiding the loading spinner
    _widgetView.loading(self.session ? !_stream : false);

    _loaded = true;

    if (self.element) {
      // Only create the chrome if we have an element to insert it into
      // for insertDefautlUI:false we don't create the chrome
      createChrome.call(self);
    }

    self.trigger('initSuccess');
    self.trigger('loaded', self);
  };

  var onLoadFailure = function(reason) {
    var errorCode = ExceptionCodes.P2P_CONNECTION_FAILED;
    var options = {
      failureReason: 'PeerConnectionError',
      failureCode: errorCode,
      failureMessage: reason
    };
    logConnectivityEvent('Failure', null, options);

    _state.set('Failed');
    self.trigger('publishComplete', new OTError(errorCode,
      'OT.Publisher PeerConnection Error: ' + reason));

    OTError.handleJsException(
      'OT.Publisher PeerConnection Error: ' + reason,
      ExceptionCodes.P2P_CONNECTION_FAILED,
      {
        session: _session,
        target: self
      }
    );
  };

  // Clean up our LocalMediaStream
  var cleanupLocalStream = function() {
    if (_webRTCStream) {
      // Stop revokes our access cam and mic access for this instance
      // of localMediaStream.
      if (global.MediaStreamTrack && global.MediaStreamTrack.prototype.stop) {
        // Newer spec
        _webRTCStream.getTracks()
                      .forEach(function(track) { track.stop(); });
      } else {
        // Older spec
        _webRTCStream.stop();
      }

      _webRTCStream = null;
    }
  };

  var onStreamAvailable = function(webOTStream) {
    logging.debug('OT.Publisher.onStreamAvailable');

    _state.set('BindingMedia');

    cleanupLocalStream();
    _webRTCStream = webOTStream;

    var findSelectedDeviceId = function(tracks, devices) {
      // Store the device labels to log later
      var selectedDeviceId;
      tracks.forEach(function(track) {
        if (track.hasOwnProperty('deviceId')) {
          // IE adds a deviceId property to the track
          selectedDeviceId = track.deviceId.toString();
        } else if (track.label && devices) {
          var selectedDevice = OTHelpers.find(devices, function(el) {
            return el.label === track.label;
          });
          if (selectedDevice) {
            selectedDeviceId = selectedDevice.deviceId;
          }
        }
      });
      return selectedDeviceId;
    };

    _selectedVideoInputDeviceId = findSelectedDeviceId(_webRTCStream.getVideoTracks(),
      _videoDevices);
    _selectedAudioInputDeviceId = findSelectedDeviceId(_webRTCStream.getAudioTracks(),
      _audioDevices);

    _microphone = new Publisher.Microphone(_webRTCStream, !_properties.publishAudio);
    self.publishVideo(_properties.publishVideo &&
      _webRTCStream.getVideoTracks().length > 0);

    self.accessAllowed = true;
    self.dispatchEvent(new Events.Event(Events.Event.names.ACCESS_ALLOWED, false));

    var videoContainerOptions = {
      muted: true,
      error: onVideoError
    };

    _videoElementFacade = _widgetView.bindVideo(_webRTCStream,
                                      videoContainerOptions,
                                      function(err) {
                                        if (err) {
                                          onLoadFailure(err);
                                          return;
                                        }

                                        onLoaded();
                                      });
  };

  var onPublishingTimeout = function(session) {
    logging.error('OT.Publisher.onPublishingTimeout');

    self.trigger('publishComplete', new OTError(ExceptionCodes.UNABLE_TO_PUBLISH,
      'Could not publish in a reasonable amount of time'));

    var options = {
      failureReason: 'ICEWorkflow',
      failureCode: ExceptionCodes.UNABLE_TO_PUBLISH,
      failureMessage: 'OT.Publisher failed to publish in a reasonable amount of time (timeout)'
    };
    logConnectivityEvent('Failure', null, options);

    OTError.handleJsException(
      options.failureReason,
      options.failureCode,
      {
        session: _session,
        target: self
      }
    );

    if (session.isConnected() && self.streamId) {
      session._.streamDestroy(self.streamId);
    }

    // Disconnect immediately, rather than wait for the WebSocket to
    // reply to our destroyStream message.
    self.disconnect();

    self.session = _session = null;

    // We're back to being a stand-alone publisher again.
    if (!_state.isDestroyed()) { _state.set('MediaBound'); }

    if (_connectivityAttemptPinger) {
      _connectivityAttemptPinger.stop();
      _connectivityAttemptPinger = null;
    }
  };

  var onStreamAvailableError = function(error) {
    logging.error('OT.Publisher.onStreamAvailableError ' + error.name + ': ' + error.message);

    _state.set('Failed');
    self.trigger('publishComplete', new OTError(ExceptionCodes.UNABLE_TO_PUBLISH,
        error.message));

    if (_widgetView) { _widgetView.destroy(); }

    var options = {
      failureReason: 'GetUserMedia',
      failureCode: ExceptionCodes.UNABLE_TO_PUBLISH,
      failureMessage: 'OT.Publisher failed to access camera/mic: ' + error.message
    };
    logConnectivityEvent('Failure', null, options);

    OTError.handleJsException(
      options.failureReason,
      options.failureCode,
      {
        session: _session,
        target: self
      }
    );
  };

  var onScreenSharingError = function(error) {
    logging.error('OT.Publisher.onScreenSharingError ' + error.message);
    _state.set('Failed');

    self.trigger('publishComplete', new OTError(ExceptionCodes.UNABLE_TO_PUBLISH,
      'Screensharing: ' + error.message));

    var options = {
      failureReason: 'ScreenSharing',
      failureMessage: error.message
    };
    logConnectivityEvent('Failure', null, options);
  };

  // The user has clicked the 'deny' button the the allow access dialog, or it's
  // set to always deny, or the access was denied due to HTTP restrictions;
  var onAccessDenied = function(error) {
    if (OTHelpers.env.protocol !== 'https:') {
      if (_isScreenSharing) {
        /**
         * in http:// the browser will deny permission without asking the
         * user. There is also no way to tell if it was denied by the
         * user, or prevented from the browser.
         */
        error.message += ' Note: https:// is required for screen sharing.';
      } else if (OTHelpers.env.name === 'Chrome' && OTHelpers.env.hostName !== 'localhost') {
        error.message = 'Chrome requires HTTPS for camera and microphone access.';
      }
    }

    logging.error('OT.Publisher.onStreamAvailableError Permission Denied');

    _state.set('Failed');
    var errorMessage = 'OT.Publisher Access Denied: Permission Denied' +
        (error.message ? ': ' + error.message : '');
    var errorCode = ExceptionCodes.UNABLE_TO_PUBLISH;
    self.trigger('publishComplete', new OTError(errorCode, errorMessage));

    var payload = {
      reason: 'AccessDenied'
    };
    logConnectivityEvent('Cancel', payload);

    self.dispatchEvent(new Events.Event(Events.Event.names.ACCESS_DENIED));
  };

  var onAccessDialogOpened = function() {
    logAnalyticsEvent('accessDialog', 'Opened');

    self.dispatchEvent(new Events.Event(Events.Event.names.ACCESS_DIALOG_OPENED, true));
  };

  var onAccessDialogClosed = function() {
    logAnalyticsEvent('accessDialog', 'Closed');

    self.dispatchEvent(new Events.Event(Events.Event.names.ACCESS_DIALOG_CLOSED, false));
  };

  var onVideoError = function(errorCode, errorReason) {
    logging.error('OT.Publisher.onVideoError');

    var message = errorReason + (errorCode ? ' (' + errorCode + ')' : '');
    logAnalyticsEvent('stream', null, { reason: 'OT.Publisher while playing stream: ' + message });

    _state.set('Failed');

    if (_state.isAttemptingToPublish()) {
      self.trigger('publishComplete', new OTError(ExceptionCodes.UNABLE_TO_PUBLISH,
          message));
    } else {
      self.trigger('error', message);
    }

    OTError.handleJsException('OT.Publisher error playing stream: ' + message,
    ExceptionCodes.UNABLE_TO_PUBLISH, {
      session: _session,
      target: self
    });
  };

  var onPeerDisconnected = function(peerConnection) {
    logging.debug('Subscriber has been disconnected from the Publisher\'s PeerConnection');

    self.cleanupSubscriber(peerConnection.remoteConnection().id);
  };

  var onPeerConnectionFailure = function(code, reason, peerConnection, prefix) {
    if (prefix === 'ICEWorkflow' && _session.sessionInfo.reconnection && _loaded) {
      logging.debug('Ignoring peer connection failure due to possibility of reconnection.');
      return;
    }

    var errorCode;
    if (prefix === 'ICEWorkflow') {
      errorCode = ExceptionCodes.PUBLISHER_ICE_WORKFLOW_FAILED;
    } else {
      errorCode = ExceptionCodes.UNABLE_TO_PUBLISH;
    }
    var payload = {
      hasRelayCandidates: peerConnection.hasRelayCandidates()
    };
    var options = {
      failureReason: prefix ? prefix : 'PeerConnectionError',
      failureCode: errorCode,
      failureMessage: (prefix ? prefix : '') + ':Publisher PeerConnection with connection ' +
        (peerConnection && peerConnection.remoteConnection &&
        peerConnection.remoteConnection().id) + ' failed: ' + reason
    };
    if (_state.isPublishing()) {
      // We're already publishing so this is a Non-fatal failure, must be p2p and one of our
      // peerconnections failed
      options.reason = 'Non-fatal';
    } else {
      self.trigger('publishComplete', new OTError(ExceptionCodes.UNABLE_TO_PUBLISH,
          payload.message));
    }
    logConnectivityEvent('Failure', payload, options);

    OTError.handleJsException('OT.Publisher PeerConnection Error: ' + reason, errorCode, {
      session: _session,
      target: self
    });

    // We don't call cleanupSubscriber as it also logs a
    // disconnected analytics event, which we don't want in this
    // instance. The duplication is crufty though and should
    // be tidied up.

    delete _peerConnections[peerConnection.remoteConnection().id];
  };

  var onIceRestartSuccess = function(connectionId) {
    logRepublish('Success', { remoteConnectionId: connectionId });
  };

  var onIceRestartFailure = function(connectionId) {
    logRepublish('Failure', {
      reason: 'ICEWorkflow',
      message: 'OT.Publisher PeerConnection Error: ' +
        'The stream was unable to connect due to a network error.' +
        ' Make sure your connection isn\'t blocked by a firewall.',
      remoteConnectionId: connectionId
    });
  };

  var onSimulcastEnabled = function() {
    _simulcastEnabled = true;
  };

  /// Private Helpers

  // Assigns +stream+ to this publisher. The publisher listens
  // for a bunch of events on the stream so it can respond to
  // changes.
  var assignStream = function(stream) {
    self.stream = _stream = stream;
    _stream.on('destroyed', self.disconnect, self);

    _state.set('Publishing');
    _widgetView.loading(!_loaded);
    _publishStartTime = new Date();

    self.trigger('publishComplete', null, self);

    self.dispatchEvent(new Events.StreamEvent('streamCreated', stream, null, false));

    logConnectivityEvent('Success');
  };

  var createPeerConnectionForRemote = function(remoteConnection, peerConnectionId, uri) {
    var peerConnection = _peerConnections[peerConnectionId];
    var startConnectingTime = OTHelpers.now();

    if (peerConnection) { return peerConnection; }

    logAnalyticsEvent('createPeerConnection', 'Attempt');

    remoteConnection.on('destroyed', self.cleanupSubscriber.bind(self, peerConnectionId));

    // Calculate the number of streams to use. 1 for normal, >1 for Simulcast
    var capableSimulcastStreams = calculateCapableSimulcastStreams({
      browserName: OTHelpers.env.name,
      isScreenSharing: _isScreenSharing,
      sessionInfo: _session.sessionInfo,
      constraints: _properties.constraints,
      videoDimensions: getVideoDimensions()
    });

    peerConnection = new Publisher.PublisherPeerConnection({
      remoteConnection: remoteConnection,
      session: _session,
      streamId: _streamId,
      webRTCStream: _webRTCStream,
      channels: _properties.channels,
      capableSimulcastStreams: capableSimulcastStreams,
      overrideSimulcastEnabled: options._enableSimulcast,
      subscriberUri: uri,
      logAnalyticsEvent: logAnalyticsEvent
    });

    peerConnection.on({
      connected: function() {
        var payload = {
          pcc: parseInt(OTHelpers.now() - startConnectingTime, 10),
          hasRelayCandidates: peerConnection.hasRelayCandidates()
        };
        logAnalyticsEvent('createPeerConnection', 'Success', payload);
      },
      disconnected: onPeerDisconnected,
      error: onPeerConnectionFailure,
      qos: recordQOS,
      iceRestartSuccess: onIceRestartSuccess.bind(undefined, peerConnectionId),
      iceRestartFailure: onIceRestartFailure.bind(undefined, peerConnectionId),
      simulcastEnabled: onSimulcastEnabled
    });

    _peerConnections[peerConnectionId] = new Bluebird.Promise(function(resolve, reject) {
      peerConnection.init(_iceServers, function(err) {
        if (err) { return reject(err); }
        resolve(peerConnection);
        return undefined;
      });
    });
    return _peerConnections[peerConnectionId];
  };

  /// Chrome

  // If mode is false, then that is the mode. If mode is true then we'll
  // definitely display  the button, but we'll defer the model to the
  // Publishers buttonDisplayMode style property.
  var chromeButtonMode = function(mode) {
    if (mode === false) { return 'off'; }

    var defaultMode = self.getStyle('buttonDisplayMode');

    // The default model is false, but it's overridden by +mode+ being true
    if (defaultMode === false) { return 'on'; }

    // defaultMode is either true or auto.
    return defaultMode;
  };

  var updateChromeForStyleChange = function(key, value) {
    if (!_chrome) { return; }

    switch (key) {
      case 'nameDisplayMode':
        _chrome.name.setDisplayMode(value);
        _chrome.backingBar.setNameMode(value);
        break;

      case 'showArchiveStatus':
        logAnalyticsEvent('showArchiveStatus', 'styleChange', { mode: value ? 'on' : 'off' });
        _chrome.archive.setShowArchiveStatus(value);
        break;

      case 'archiveStatusDisplayMode':
        _chrome.archive.setShowArchiveStatus(value !== 'off');
        break;

      case 'buttonDisplayMode':
        _chrome.muteButton.setDisplayMode(value);
        _chrome.backingBar.setMuteMode(value);
        break;

      case 'audioLevelDisplayMode':
        _chrome.audioLevel.setDisplayMode(value);
        break;

      case 'backgroundImageURI':
        _widgetView.setBackgroundImageURI(value);
        break;

      default:
    }
  };

  var createChrome = function() {

    if (!self.getStyle('showArchiveStatus')) {
      logAnalyticsEvent('showArchiveStatus', 'createChrome', { mode: 'off' });
    }

    var widgets = {
      backingBar: new BackingBar({
        nameMode: !_properties.name ? 'off' : self.getStyle('nameDisplayMode'),
        muteMode: chromeButtonMode.call(self, self.getStyle('buttonDisplayMode'))
      }),

      name: new NamePanel({
        name: _properties.name,
        mode: self.getStyle('nameDisplayMode')
      }),

      archive: new Archiving({
        show: self.getStyle('showArchiveStatus') && self.getStyle('showArchiveStatus') !== 'off',
        archiving: false
      })
    };

    if (!(_properties.audioSource === null || _properties.audioSource === false)) {
      widgets.muteButton = new MuteButton({
        muted: _properties.publishAudio === false,
        mode: chromeButtonMode.call(self, self.getStyle('buttonDisplayMode'))
      });
    }

    var audioLevelTransformer = new AudioLevelTransformer();

    var audioLevelUpdatedHandler = function(evt) {
      var audioLevel = audioLevelTransformer.transform(evt.audioLevel);

      // @FIXME
      // We force the audio level value to be zero here if audio is disabled
      // because the AudioLevelMeter cannot currently differentiate
      // between video being disabled and audio being disabled.
      // To be fixed as part of OPENTOK-29865
      if (!_properties.publishAudio) {
        audioLevel = 0;
      }

      _audioLevelMeter.setValue(audioLevel);
    };

    _audioLevelMeter = new Publisher.AudioLevelMeter({
      mode: self.getStyle('audioLevelDisplayMode')
    });

    _audioLevelMeter.watchVisibilityChanged(function(visible) {
      if (visible) {
        self.on('audioLevelUpdated', audioLevelUpdatedHandler);
      } else {
        self.off('audioLevelUpdated', audioLevelUpdatedHandler);
      }
    });

    _audioLevelMeter.audioOnly(!_properties.publishVideo && _properties.publishAudio);

    widgets.audioLevel = _audioLevelMeter;

    if (_widgetView && _widgetView.domElement) {
      _chrome = new Chrome({
        parent: _widgetView.domElement
      }).set(widgets).on({
        muted: self.publishAudio.bind(self, false),
        unmuted: self.publishAudio.bind(self, true)
      });
    }
  };

  var reset = function() {
    if (_chrome) {
      _chrome.destroy();
      _chrome = null;
    }

    self.disconnect();

    _microphone = null;

    if (_videoElementFacade) {
      _videoElementFacade.destroy();
      _videoElementFacade = null;
    }

    cleanupLocalStream();

    if (_widgetView) {
      _widgetView.destroy();
      _widgetView = null;
    }

    if (_session) {
      self._.unpublishFromSession(_session, 'reset');
    }

    self.id = null;
    self.stream = _stream = null;
    _loaded = false;

    self.session = _session = null;

    if (!_state.isDestroyed()) { _state.set('NotPublishing'); }
  };

  StylableComponent(self, {
    showArchiveStatus: true,
    nameDisplayMode: 'auto',
    buttonDisplayMode: 'auto',
    audioLevelDisplayMode: _isScreenSharing ? 'off' : 'auto',
    archiveStatusDisplayMode: 'auto',
    backgroundImageURI: null
  }, _properties.showControls, function(payload) {
    logAnalyticsEvent('SetStyle', 'Publisher', payload, null, 0.1);
  });

  var refreshAudioVideoUI = function(hasVideo, hasAudio) {
    if (_widgetView) {
      _widgetView.audioOnly(!hasVideo);
      _widgetView.showPoster(!hasVideo);
    }

    if (_audioLevelMeter) {
      _audioLevelMeter.audioOnly(!hasVideo && hasAudio);
    }
  };

  this.publish = function(targetElement) {
    logging.debug('OT.Publisher: publish');

    if (_state.isAttemptingToPublish() || _state.isPublishing()) {
      reset();
    }
    _state.set('GetUserMedia');

    var audioDeviceId, videoDeviceId;

    if (!_properties.constraints) {
      _properties.constraints = OTHelpers.clone(defaultConstraints);

      // detect which version of constraints scheme we should be using
      var usingRangeBasedConstraints = global.navigator.mediaDevices
        && global.navigator.mediaDevices.getUserMedia && !_isScreenSharing;

      if (
        _properties.audioSource === null || _properties.audioSource === false ||
        (!_properties.publishAudio && _properties.enableRenegotiation)
      ) {
        _properties.constraints.audio = false;
        _properties.publishAudio = false;
      } else {
        if (_isScreenSharing) {
          if (typeof _properties.audioSource !== 'undefined') {
            logging.warn('Invalid audioSource passed to Publisher - when using screen sharing no ' +
              'audioSource may be used');
          }

          _properties.constraints.audio = false;

        } else if (typeof _properties.audioSource === 'object' &&
          _properties.audioSource.deviceId == null) {
          logging.warn('Invalid audioSource passed to Publisher. Expected either a device ' +
            'ID or device.');
        } else if (_properties.audioSource) {
          // picking the device id configuration
          audioDeviceId = _properties.audioSource.deviceId != null
            ? _properties.audioSource.deviceId : _properties.audioSource;
        }

        if (audioDeviceId) {
          if (typeof _properties.constraints.audio !== 'object') {
            _properties.constraints.audio = {};
          }

          // constraints extended defaults
          if (usingRangeBasedConstraints) {
            _properties.constraints.audio.deviceId = {};
          } else {
            if (!_properties.constraints.audio.mandatory) {
              _properties.constraints.audio.mandatory = {};
            }
            if (!_properties.constraints.audio.optional) {
              _properties.constraints.audio.optional = [];
            }
          }

          // setting constraints actual values
          if (usingRangeBasedConstraints) {
            _properties.constraints.audio.deviceId.exact = audioDeviceId;
          } else {
            _properties.constraints.audio.mandatory.sourceId = audioDeviceId;
          }
        }
      }

      if (
        _properties.videoSource === null ||
        _properties.videoSource === false ||
        (!_properties.publishVideo && _properties.enableRenegotiation)
      ) {
        _properties.constraints.video = false;
        _properties.publishVideo = false;
      } else {

        if (typeof _properties.videoSource === 'object' &&
          _properties.videoSource.deviceId == null) {
          logging.warn('Invalid videoSource passed to Publisher. Expected either a device ' +
            'ID or device.');
        } else if (_properties.videoSource) {
          videoDeviceId = _properties.videoSource.deviceId != null
            ? _properties.videoSource.deviceId : _properties.videoSource;
        }

        var _setupVideoDefaults = function() {
          if (typeof _properties.constraints.video !== 'object') {
            _properties.constraints.video = {};
          }

          if (usingRangeBasedConstraints) {
            [
              'width',
              'height',
              'frameRate',
              'deviceId'
            ].forEach(function(videoConstraint) {
              if (!_properties.constraints.video[videoConstraint]) {
                _properties.constraints.video[videoConstraint] = {};
              }
            });
          } else {
            if (!_properties.constraints.video.mandatory) {
              _properties.constraints.video.mandatory = {};
            }
            if (!_properties.constraints.video.optional) {
              _properties.constraints.video.optional = [];
            }
          }
        };

        if (videoDeviceId) {
          // _isScreenSharing is handled by the extension helpers
          if (!_isScreenSharing) {
            _setupVideoDefaults();

            if (usingRangeBasedConstraints) {
              // the mediaDevices spec uses constraints based on ideal/exact value
              _properties.constraints.video.deviceId.exact = videoDeviceId;
            } else {
              _properties.constraints.video.mandatory.sourceId = videoDeviceId;
            }
          }
        }

        if (_properties.resolution) {
          if (!_validResolutions.hasOwnProperty(_properties.resolution)) {
            logging.warn('Invalid resolution passed to the Publisher. Got: ' +
              _properties.resolution + ' expecting one of "' +
              Object.keys(_validResolutions).join('","') + '"');
          } else {
            _properties.videoDimensions = _validResolutions[_properties.resolution];
            _setupVideoDefaults();
            if (usingRangeBasedConstraints) {
              var width = _properties.videoDimensions.width;
              var height = _properties.videoDimensions.height;

              _properties.constraints.video.advanced = [
                {
                  width: { min: width, max: width },
                  height: { min: height, max: height }
                },
                {
                  width: { ideal: width },
                  height: { ideal: height }
                }
              ];
            } else {
              _properties.constraints.video.optional =
                _properties.constraints.video.optional.concat([
                  { minWidth: _properties.videoDimensions.width },
                  { maxWidth: _properties.videoDimensions.width },
                  { minHeight: _properties.videoDimensions.height },
                  { maxHeight: _properties.videoDimensions.height }
                ]);
            }
          }
        }

        if (_properties.maxResolution) {
          _setupVideoDefaults();
          if (_properties.maxResolution.width > 1920) {
            logging.warn(
              'Invalid maxResolution passed to the Publisher. maxResolution.width must ' +
              'be less than or equal to 1920'
            );

            _properties.maxResolution.width = 1920;
          }
          if (_properties.maxResolution.height > 1920) {
            logging.warn(
              'Invalid maxResolution passed to the Publisher. maxResolution.height must ' +
              'be less than or equal to 1920'
            );

            _properties.maxResolution.height = 1920;
          }

          _properties.videoDimensions = _properties.maxResolution;

          _setupVideoDefaults();
          if (usingRangeBasedConstraints) {
            _properties.constraints.video.width.max =
              _properties.videoDimensions.width;
            _properties.constraints.video.height.max =
              _properties.videoDimensions.height;
          } else {
            _properties.constraints.video.mandatory.maxWidth =
              _properties.videoDimensions.width;
            _properties.constraints.video.mandatory.maxHeight =
              _properties.videoDimensions.height;
          }
        }

        if (_properties.frameRate !== void 0 &&
          _validFrameRates.indexOf(_properties.frameRate) === -1) {
          logging.warn('Invalid frameRate passed to the publisher got: ' +
            _properties.frameRate + ' expecting one of ' + _validFrameRates.join(','));
          delete _properties.frameRate;
        } else if (_properties.frameRate) {
          _setupVideoDefaults();
          if (usingRangeBasedConstraints) {
            _properties.constraints.video.frameRate.ideal = _properties.frameRate;
          } else {
            _properties.constraints.video.optional =
              _properties.constraints.video.optional.concat([
                { minFrameRate: _properties.frameRate },
                { maxFrameRate: _properties.frameRate }
              ]);
          }
        }
      }
    } else {
      logging.warn('You have passed your own constraints not using ours');
    }

    if (_properties.style) {
      self.setStyle(_properties.style, null, true);
    }

    if (_properties.name) {
      _properties.name = _properties.name.toString();
    }

    if (_properties.hasOwnProperty('usePreviousDeviceSelection')) {
      if (OTHelpers.env.protocol !== 'https:') {
        logging.warn('Note: https:// is required for usePreviousDeviceSelection');
      }
      if (OTHelpers.env.name !== 'IE') {
        logging.warn('Note: usePreviousDeviceSelection only works in Internet Explorer');
      }
      if (OTPlugin.hasOwnProperty('settings')) {
        // If the usePreviousDeviceSelection property is passed in then use that
        OTPlugin.settings.usePreviousDeviceSelection = _properties.usePreviousDeviceSelection;
      }
    }

    if (typeof _properties.minVideoBitrate !== 'undefined') {
      if (typeof _properties.minVideoBitrate !== 'number' ||
        isNaN(_properties.minVideoBitrate) ||
        _properties.minVideoBitrate <= 0) {
        logging.warn(
          'Invalid minVideoBitrate passed to the Publisher. minVideoBitrate must ' +
          'be a positive number'
        );

        delete _properties.minVideoBitrate;
      }
    }

    _properties.classNames = 'OT_root OT_publisher';

    // Defer actually creating the publisher DOM nodes until we know
    // the DOM is actually loaded.
    EnvironmentLoader.onLoad(function() {
      _widgetView = new Publisher.WidgetView(targetElement, _properties);
      self.id = _widgetView.domId();
      self.element = _widgetView.domElement;

      _widgetView.on('videoDimensionsChanged', function(oldValue, newValue) {
        if (_stream) {
          _stream.setVideoDimensions(newValue.width, newValue.height);
        }
        self.dispatchEvent(
          new Events.VideoDimensionsChangedEvent(self, oldValue, newValue)
        );
      });

      _widgetView.on('mediaStopped', function() {
        var event = new Events.MediaStoppedEvent(self);

        self.dispatchEvent(event, function() {
          if (!event.isDefaultPrevented()) {
            if (_session) {
              self._.unpublishFromSession(_session, 'mediaStopped');
            } else {
              self.destroy('mediaStopped');
            }
          }
        });
      });

      _widgetView.on('videoElementCreated', function(element) {
        var event = new Events.VideoElementCreatedEvent(element);
        self.dispatchEvent(event);
      });

      waterfall([
        function(cb) {
          if (_isScreenSharing) {
            screenSharing.checkCapability(function(response) {
              if (!response.supported) {
                onScreenSharingError(
                  new Error('Screen Sharing is not supported in this browser')
                );
              } else if (response.extensionRegistered === false) {
                onScreenSharingError(
                  new Error('Screen Sharing support in this browser requires an extension, but ' +
                    'one has not been registered.')
                );
              } else if (response.extensionRequired &&
                response.extensionInstalled === false) {
                onScreenSharingError(
                  new Error('Screen Sharing support in this browser requires an extension, but ' +
                    'the extension is not installed.')
                );
              } else {

                var helper = screenSharing.pickHelper();

                if (helper.proto.getConstraintsShowsPermissionUI) {
                  onAccessDialogOpened();
                }

                helper.instance.getConstraints(options.videoSource, _properties.constraints,
                  function(err, constraints) {
                    if (helper.proto.getConstraintsShowsPermissionUI) {
                      onAccessDialogClosed();
                    }
                    if (err) {
                      if (err.message === 'PermissionDeniedError') {
                        onAccessDenied(err);
                      } else {
                        onScreenSharingError(err);
                      }
                    } else {
                      _properties.constraints = constraints;
                      cb();
                    }
                  });
              }
            });
          } else {
            deviceHelpers.shouldAskForDevices(function(devices) {
              if (!devices.video) {
                logging.warn('Setting video constraint to false, there are no video sources');
                _properties.constraints.video = false;
              }
              if (!devices.audio) {
                logging.warn('Setting audio constraint to false, there are no audio sources');
                _properties.constraints.audio = false;
              }
              _videoDevices = devices.videoDevices;
              _audioDevices = devices.audioDevices;
              cb();
            });
          }
        },

        function() {

          if (_state.isDestroyed()) {
            return;
          }


          Publisher.getUserMedia(
            _properties.constraints,
            onStreamAvailable,
            onStreamAvailableError,
            onAccessDialogOpened,
            onAccessDialogClosed,
            onAccessDenied
          );
        }

      ]);

    }, self);

    return self;
  };

  function addTrack(kind) {
    var track = _webRTCStream.getTracks().filter(function(track) {
      return track.kind === kind;
    });
    return getAllPeerConnections().then(function(peerConnections) {
      return Bluebird.all(peerConnections.map(function(peerConnection) {
        return peerConnection.addTrack(track[0], _webRTCStream);
      }));
    });
  }

  function removeTrack(kind) {
    return getAllPeerConnections().then(function(peerConnections) {
      return Bluebird.all(peerConnections.map(function(peerConnection) {
        var senders = peerConnection.getSenders().filter(function(sender) {
          return sender.track.kind === kind && sender.track.enabled;
        });

        if (senders.length) {
          return Bluebird.all(senders.map(function(sender) {
            return peerConnection.removeTrack(sender);
          }));
        }

        return undefined;
      }));
    });
  }

  /**
  * Starts publishing audio (if it is currently not being published)
  * when the <code>value</code> is <code>true</code>; stops publishing audio
  * (if it is currently being published) when the <code>value</code> is <code>false</code>.
  *
  * @param {Boolean} value Whether to start publishing audio (<code>true</code>)
  * or not (<code>false</code>).
  *
  * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
  * @see <a href="Stream.html#hasAudio">Stream.hasAudio</a>
  * @see StreamPropertyChangedEvent
  * @method #publishAudio
  * @memberOf Publisher
  */
  this.publishAudio = function(value, callback) {
    callback = callback || function() {};
    _properties.publishAudio = value;

    refreshAudioVideoUI(_properties.publishVideo, _properties.publishAudio);

    if (_chrome) { _chrome.muteButton.muted(!value); }

    if (!_properties.enableRenegotiation && _microphone) {
      _microphone.muted(!value);
    }

    if (_session && _stream) {
      _stream.setChannelActiveState('audio', value);
      if (_properties.enableRenegotiation) {
        logAnalyticsEvent('publishAudioRenegotiation', 'Attempt', value);
        if (value && _webRTCStream.getAudioTracks().length === 0) {
          _properties.constraints.audio = _properties.constraints.audio || true;

          var replaceTrackLogic = function(peerConnection) {
            peerConnection.getSenders().forEach(peerConnection.removeTrack.bind(peerConnection));
            _webRTCStream.getTracks().forEach(function(track) {
              peerConnection.addTrack(track, _webRTCStream);
            });
          };

          getUserMedia(_properties.constraints)
            .then(setNewStream)
            .then(bindVideo)
            .then(replaceTracks.bind(null, replaceTrackLogic))
            .then(function() {
              logAnalyticsEvent('publishAudioRenegotiation', 'Success');
              return;
            })
            .then(callback.bind(undefined, null), function(err) {
              logAnalyticsEvent('publishAudioRenegotiation', 'Failure', err.message);
              callback(err);
            });
        } else {
          this.publishAudioTrack(value)
            .then(function() {
              logAnalyticsEvent('publishAudioRenegotiation', 'Success');
              return;
            })
            .then(callback.bind(undefined, null), function(err) {
              logAnalyticsEvent('publishAudioRenegotiation', 'Failure');
              callback(err);
            });
        }
      } else {
        callback(null);
      }
    } else {
      // once publishComplete is complete, we can attempt to call publishAudio again as we will now
      // have access to the stream. this route also ensures the callback is called appropriately
      // @todo this may cause issues with people toggling audio on and off under renegotiations
      // I have chosen to ignore it here, as the renegotiation code should handle this robustly
      // anyway!
      self.once('publishComplete', function() {
        self.publishAudio(_properties.publishAudio, callback);
      });
    }

    return self;
  };

  this.publishAudioTrack = function(value) {
    return (value ? addTrack : removeTrack)('audio');
  };

  this.publishVideoTrack = function(value) {
    return (value ? addTrack : removeTrack)('video');
  };

  /**
  * Starts publishing video (if it is currently not being published)
  * when the <code>value</code> is <code>true</code>; stops publishing video
  * (if it is currently being published) when the <code>value</code> is <code>false</code>.
  *
  * @param {Boolean} value Whether to start publishing video (<code>true</code>)
  * or not (<code>false</code>).
  *
  * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
  * @see <a href="Stream.html#hasVideo">Stream.hasVideo</a>
  * @see StreamPropertyChangedEvent
  * @method #publishVideo
  * @memberOf Publisher
  */
  this.publishVideo = function(value, callback) {
    callback = callback || function() {};
    var oldValue = _properties.publishVideo;
    _properties.publishVideo = value;

    if (_session && _stream && _properties.publishVideo !== oldValue) {
      _stream.setChannelActiveState('video', value);
    }

    refreshAudioVideoUI(_properties.publishVideo, _properties.publishAudio);

    if (_properties.enableRenegotiation) {
      logAnalyticsEvent('publishVideoRenegotiation', 'Attempt', value);
      if (value && _webRTCStream.getVideoTracks().length === 0) {
        _properties.constraints.video = _properties.constraints.video || true;

        var replaceTrackLogic = function(peerConnection) {
          peerConnection.getSenders().forEach(peerConnection.removeTrack.bind(peerConnection));
          _webRTCStream.getTracks().forEach(function(track) {
            peerConnection.addTrack(track, _webRTCStream);
          });
        };

        getUserMedia(_properties.constraints)
          .then(setNewStream)
          .then(bindVideo)
          .then(replaceTracks.bind(null, replaceTrackLogic))
          .then(function() {
            logAnalyticsEvent('publishVideoRenegotiation', 'Success');
            return;
          })
          .then(callback.bind(undefined, null), function(err) {
            logAnalyticsEvent('publishVideoRenegotiation', 'Failure', err.message);
            callback(err);
          });
      } else {
        this.publishVideoTrack(value)
          .then(function() {
            logAnalyticsEvent('publishVideoRenegotiation', 'Success');
            return;
          })
          .then(callback.bind(undefined, null), function(err) {
            logAnalyticsEvent('publishVideoRenegotiation', 'Failure', err.message);
            callback(err);
          });
      }
    } else {
      // We currently do this event if the value of publishVideo has not changed
      // This is because the state of the video tracks enabled flag may not match
      // the value of publishVideo at this point. This will be tidied up shortly.
      if (_webRTCStream) {
        var videoTracks = _webRTCStream.getVideoTracks();
        for (var i = 0, num = videoTracks.length; i < num; ++i) {
          videoTracks[i].enabled = value;
        }
      }

      callback(null);
    }

    return self;
  };

  /**
  * Deletes the Publisher object and removes it from the HTML DOM.
  * <p>
  * The Publisher object dispatches a <code>destroyed</code> event when the DOM
  * element is removed.
  * </p>
  * @method #destroy
  * @memberOf Publisher
  * @return {Publisher} The Publisher.
  */
  this.destroy = function(/* unused */ reason, quiet) {
    if (_state.isAttemptingToPublish()) {
      if (_connectivityAttemptPinger) {
        _connectivityAttemptPinger.stop();
        _connectivityAttemptPinger = null;
      }
      if (_session) {
        logConnectivityEvent('Cancel', { reason: 'destroy' });
      }
    }

    if (_state.isDestroyed()) { return self; }
    _state.set('Destroyed');

    reset();

    if (quiet !== true) {
      self.dispatchEvent(
        new Events.DestroyedEvent(
          Events.Event.names.PUBLISHER_DESTROYED,
          self,
          reason
        ),
        self.off.bind(self)
      );
    }

    return self;
  };

  /**
  * @methodOf Publisher
  * @private
  */
  this.disconnect = function() {
    // Close the connection to each of our subscribers
    Object
      .keys(_peerConnections)
      .forEach(function(id) {
        self.cleanupSubscriber(id);
      });
  };

  this.cleanupSubscriber = function(peerConnectionId) {
    var peerConnectionAsync = _peerConnections[peerConnectionId];

    if (!peerConnectionAsync) {
      return;
    }

    peerConnectionAsync
      .then(function(peerConnection) {
        peerConnection.destroy();
        delete _peerConnections[peerConnectionId];

        logAnalyticsEvent('disconnect', 'PeerConnection', { subscriberConnection: peerConnectionId });
      });
  };

  this.processMessage = function(type, fromConnection, message) {
    var peerConnectionId = message.params && message.params.subscriber;

    // Symphony will not have a subscriberId so we'll fallback to using the connectionId for it.
    // Also fallback to the connectionId if it is equal to 'INVALID-STREAM' (See OPENTOK-30029).
    if (!peerConnectionId || peerConnectionId === 'INVALID-STREAM') {
      peerConnectionId = fromConnection.id;
    }

    logging.debug('OT.Publisher.processMessage: Received ' + type + ' from ' + peerConnectionId);
    logging.debug(message);

    switch (type) {
      case 'unsubscribe':
        self.cleanupSubscriber(peerConnectionId);
        break;

      default:
        createPeerConnectionForRemote(fromConnection, peerConnectionId, message.uri)
          .then(function(peerConnection) {
            peerConnection.processMessage(type, message);
          })
          .catch(function(err) {
            logging.error('OT.Publisher failed to create a peerConnection', err);
          });
    }
  };

  /**
  * Returns the base-64-encoded string of PNG data representing the Publisher video.
  *
  *   <p>You can use the string as the value for a data URL scheme passed to the src parameter of
  *   an image file, as in the following:</p>
  *
  * <pre>
  *  var imgData = publisher.getImgData();
  *
  *  var img = document.createElement("img");
  *  img.setAttribute("src", "data:image/png;base64," + imgData);
  *  var imgWin = window.open("about:blank", "Screenshot");
  *  imgWin.document.write("&lt;body&gt;&lt;/body&gt;");
  *  imgWin.document.body.appendChild(img);
  * </pre>
  *
  * @method #getImgData
  * @memberOf Publisher
  * @return {String} The base-64 encoded string. Returns an empty string if there is no video.
  */

  this.getImgData = function() {
    if (!_loaded) {
      logging.error(
        'OT.Publisher.getImgData: Cannot getImgData before the Publisher is publishing.'
      );

      return null;
    }

    return _videoElementFacade.imgData();
  };

  var bindVideo = function() {
    var videoContainerOptions = {
      muted: true,
      error: onVideoError
    };

    return new Bluebird.Promise(function(resolve, reject) {
      var callback = function(err) {
        if (err) {
          onLoadFailure(err);
          return reject(err);
        }
        resolve();
        return undefined;
      };
      _videoElementFacade = _widgetView.bindVideo(_webRTCStream, videoContainerOptions, callback);
    });
  };

  var getUserMedia = function(constraints) {
    return new Bluebird.Promise(function(resolve, reject) {
      Publisher.getUserMedia(
        constraints,
        resolve,
        function(error) {
          onStreamAvailableError(error);
          reject(error);
        },
        onAccessDialogOpened,
        onAccessDialogClosed,
        function(error) {
          onAccessDenied(error);
          reject(error);
        }
      );
    });
  };

  var setNewStream = function(newStream) {
    cleanupLocalStream();
    _webRTCStream = newStream;
    _microphone = new Publisher.Microphone(_webRTCStream, !_properties.publishAudio);
  };


  var replaceTracks = function(replaceTrackLogic) {
    replaceTrackLogic = replaceTrackLogic || function(peerConnection) {
      peerConnection.getSenders().forEach(function(sender) {
        if (sender.track.kind === 'audio' && _webRTCStream.getAudioTracks().length) {
          return sender.replaceTrack(_webRTCStream.getAudioTracks()[0]);
        } else if (sender.track.kind === 'video' && _webRTCStream.getVideoTracks().length) {
          return sender.replaceTrack(_webRTCStream.getVideoTracks()[0]);
        }
        return undefined;
      });
    };

    return getAllPeerConnections().then(function(peerConnections) {
      var tasks = [];
      peerConnections.map(replaceTrackLogic);
      return Bluebird.all(tasks);
    });
  };

  // API Compatibility layer for Flash Publisher, this could do with some tidyup.
  this._ = {
    publishToSession: function(session) {
      // Add session property to Publisher
      self.session = _session = session;

      _streamId = uuid();
      var createStream = function() {

        // Bail if this.session is gone, it means we were unpublished
        // before createStream could finish.
        if (!_session) { return; }

        // make sure we trigger an error if we are not getting any "ack" after a reasonable
        // amount of time
        var publishGuardingTo = setTimeout(function() {
          onPublishingTimeout(session);
        }, Publisher.PUBLISH_MAX_DELAY);

        self.on('publishComplete', function() {
          clearTimeout(publishGuardingTo);
        });


        _state.set('PublishingToSession');

        var onStreamRegistered = function(err, streamId, message) {
          if (err) {
            // @todo we should respect err.code here and translate it to the local
            // client equivalent.
            var errorCode, errorMessage;
            var knownErrorCodes = [403, 404, 409];
            if (err.code && knownErrorCodes.indexOf(err.code) > -1) {
              errorCode = ExceptionCodes.UNABLE_TO_PUBLISH;
              errorMessage = err.message;
            } else {
              errorCode = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
              errorMessage = 'Unexpected server response. Try this operation again later.';
            }

            var options = {
              failureReason: 'Publish',
              failureCode: errorCode,
              failureMessage: errorMessage
            };
            logConnectivityEvent('Failure', null, options);
            if (_state.isAttemptingToPublish()) {
              self.trigger('publishComplete', new OTError(errorCode, errorMessage));
            }

            OTError.handleJsException(
              err.message,
              errorCode,
              {
                session: _session,
                target: self
              }
            );

            return;
          }

          self.streamId = _streamId = streamId;
          _iceServers = parseIceServers(message);
        };

        var streamDimensions = getVideoDimensions();
        var streamChannels = [];

        if (!(_properties.videoSource === null || _properties.videoSource === false)) {
          streamChannels.push(new StreamChannel({
            id: 'video1',
            type: 'video',
            active: _properties.publishVideo,
            orientation: VideoOrientation.ROTATED_NORMAL,
            frameRate: _properties.frameRate,
            width: streamDimensions.width,
            height: streamDimensions.height,
            source: _isScreenSharing ? 'screen' : 'camera',
            fitMode: _properties.fitMode
          }));
        }

        if (!(_properties.audioSource === null || _properties.audioSource === false)) {
          streamChannels.push(new StreamChannel({
            id: 'audio1',
            type: 'audio',
            active: _properties.publishAudio
          }));
        }

        session._.streamCreate(
          _properties.name || '',
          _streamId,
          _properties.audioFallbackEnabled,
          streamChannels,
          _properties.minVideoBitrate,
          onStreamRegistered
        );
      };

      if (_loaded) {
        createStream.call(self);
      } else {
        self.on('initSuccess', createStream, self);
      }

      logConnectivityEvent('Attempt', {
        dataChannels: _properties.channels
      });

      return self;
    },

    unpublishFromSession: function(session, reason) {
      if (!_session || session.id !== _session.id) {
        logging.warn('The publisher ' + _guid + ' is trying to unpublish from a session ' +
          session.id + ' it is not attached to (it is attached to ' +
          (_session && _session.id || 'no session') + ')');
        return self;
      }

      if (session.isConnected() && self.stream) {
        session._.streamDestroy(self.stream.id);
      }

      // Disconnect immediately, rather than wait for the WebSocket to
      // reply to our destroyStream message.
      self.disconnect();
      if (_state.isAttemptingToPublish()) {
        logConnectivityEvent('Cancel', { reason: 'unpublish' });
      }
      self.session = _session = null;

      // We're back to being a stand-alone publisher again.
      if (!_state.isDestroyed()) { _state.set('MediaBound'); }

      if (_connectivityAttemptPinger) {
        _connectivityAttemptPinger.stop();
        _connectivityAttemptPinger = null;
      }
      logAnalyticsEvent('unpublish', 'Success', { sessionId: session.id });

      self._.streamDestroyed(reason);

      return self;
    },

    unpublishStreamFromSession: function(stream, session, reason) {
      if (!_streamId || stream.id !== _streamId) {
        logging.warn('The publisher ' + _guid + ' is trying to destroy a stream ' +
          stream.id + ' that is not attached to it (it has ' +
          (_streamId || 'no stream') + ' attached to it)');
        return self;
      }

      return self._.unpublishFromSession(session, reason);
    },

    streamDestroyed: function(reason) {
      if (['reset'].indexOf(reason) < 0) {
        var event = new Events.StreamEvent('streamDestroyed', _stream, reason, true);
        var defaultAction = function() {
          if (!event.isDefaultPrevented()) {
            self.destroy();
          }
        };
        self.dispatchEvent(event, defaultAction);
      }
    },

    archivingStatus: function(status) {
      if (_chrome) {
        _chrome.archive.setArchiving(status);
      }

      return status;
    },

    webRtcStream: function() {
      return _webRTCStream;
    },

    switchTracks: function() {
      return getUserMedia(_properties.constraints)
        .then(setNewStream)
        .then(bindVideo)
        .then(replaceTracks);
    },

    /**
     * @param {string=} windowId
     */
    switchAcquiredWindow: function(windowId) {

      if (OTHelpers.env.name !== 'Firefox' || OTHelpers.env.version < 38) {
        throw new Error('switchAcquiredWindow is an experimental method and is not supported by' +
        'the current platform');
      }

      if (typeof windowId !== 'undefined') {
        if (typeof _properties.constraints.video === 'boolean') {
          // video could just be true or false, where true means "yes I want
          // video" without configuring any other details.

          if (_properties.constraints.video === false) {
            // This doesn't even make sense as video can't be disabled if this
            // window acquisition thing was to work. Probably developer error...
            throw new Error('Cannot switchAcquiredWindow when there is no video');
          }

          // video === true and video === {} actually mean the same thing. We'll
          // change it to {} here so that we can safely add a `browserWindow`
          // property below.
          _properties.constraints.video = {};
        }

        _properties.constraints.video.browserWindow = windowId;
      }

      logAnalyticsEvent('SwitchAcquiredWindow', 'Attempt', {
        constraints: _properties.constraints
      });

      var switchTracksPromise = self._.switchTracks();

      // "listening" promise completion just for analytics
      switchTracksPromise.then(function() {
        logAnalyticsEvent('SwitchAcquiredWindow', 'Success', {
          constraints: _properties.constraints
        });
      }, function(error) {
        logAnalyticsEvent('SwitchAcquiredWindow', 'Failure', {
          error: error,
          constraints: _properties.constraints
        });
      });

      return switchTracksPromise;
    },

    getDataChannel: function(label, options, completion) {
      var pc = _peerConnections[Object.keys(_peerConnections)[0]];

      // @fixme this will fail if it's called before we have a PublisherPeerConnection.
      // I.e. before we have a subscriber.
      if (!pc) {
        completion(new OTHelpers.Error('Cannot create a DataChannel before there is a subscriber.'));
        return;
      }

      pc.then(function(peerConnection) {
        peerConnection.getDataChannel(label, options, completion);
      });
    },

    iceRestart: function(force) {
      getAllPeerConnections().then(function(peerConnections) {
        peerConnections.forEach(function(peerConnection) {
          var remoteConnectionId = peerConnection.remoteConnection().connectionId;

          if (force || !peerConnection.iceConnectionStateIsConnected()) {
            logRepublish('Attempt', { remoteConnectionId: remoteConnectionId });
            peerConnection.createOfferWithIceRestart();
          } else {
            logging.debug('Publisher: Skipping ice restart for ' + remoteConnectionId + ', we are connected.');
          }
        });
      });
    }
  };

  this.detectDevices = function() {
    logging.warn('Fixme: Haven\'t implemented detectDevices');
  };

  this.detectMicActivity = function() {
    logging.warn('Fixme: Haven\'t implemented detectMicActivity');
  };

  this.getEchoCancellationMode = function() {
    logging.warn('Fixme: Haven\'t implemented getEchoCancellationMode');
    return 'fullDuplex';
  };

  this.setMicrophoneGain = function() {
    logging.warn('Fixme: Haven\'t implemented setMicrophoneGain');
  };

  this.getMicrophoneGain = function() {
    logging.warn('Fixme: Haven\'t implemented getMicrophoneGain');
    return 0.5;
  };

  this.setCamera = function() {
    logging.warn('Fixme: Haven\'t implemented setCamera');
  };

  this.setMicrophone = function() {
    logging.warn('Fixme: Haven\'t implemented setMicrophone');
  };

  // Platform methods:

  this.guid = function() {
    return _guid;
  };

  this.videoElement = function() {
    return _videoElementFacade.domElement();
  };

  this.setStream = assignStream;

  this.isWebRTC = true;

  this.isLoading = function() {
    return _widgetView && _widgetView.loading();
  };

  /**
  * Returns the width, in pixels, of the Publisher video. This may differ from the
  * <code>resolution</code> property passed in as the <code>properties</code> property
  * the options passed into the <code>OT.initPublisher()</code> method, if the browser
  * does not support the requested resolution.
  *
  * @method #videoWidth
  * @memberOf Publisher
  * @return {Number} the width, in pixels, of the Publisher video.
  */
  this.videoWidth = function() {
    return _videoElementFacade.videoWidth();
  };

  /**
  * Returns the height, in pixels, of the Publisher video. This may differ from the
  * <code>resolution</code> property passed in as the <code>properties</code> property
  * the options passed into the <code>OT.initPublisher()</code> method, if the browser
  * does not support the requested resolution.
  *
  * @method #videoHeight
  * @memberOf Publisher
  * @return {Number} the height, in pixels, of the Publisher video.
  */
  this.videoHeight = function() {
    return _videoElementFacade.videoHeight();
  };

  // Make read-only: element, guid, _.webRtcStream

  this.on('styleValueChanged', updateChromeForStyleChange, this);
  _state = new PublishingState(stateChangeFailed);

  this.accessAllowed = false;

  /**
  * Dispatched when the user has clicked the Allow button, granting the
  * app access to the camera and microphone. The Publisher object has an
  * <code>accessAllowed</code> property which indicates whether the user
  * has granted access to the camera and microphone.
  * @see Event
  * @name accessAllowed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the user has clicked the Deny button, preventing the
  * app from having access to the camera and microphone.
  * @see Event
  * @name accessDenied
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the Allow/Deny dialog box is opened. (This is the dialog box in which
  * the user can grant the app access to the camera and microphone.)
  * @see Event
  * @name accessDialogOpened
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the Allow/Deny box is closed. (This is the dialog box in which the
  * user can grant the app access to the camera and microphone.)
  * @see Event
  * @name accessDialogClosed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched periodically to indicate the publisher's audio level. The event is dispatched
  * up to 60 times per second, depending on the browser. The <code>audioLevel</code> property
  * of the event is audio level, from 0 to 1.0. See {@link AudioLevelUpdatedEvent} for more
  * information.
  * <p>
  * The following example adjusts the value of a meter element that shows volume of the
  * publisher. Note that the audio level is adjusted logarithmically and a moving average
  * is applied:
  * <p>
  * <pre>
  * var movingAvg = null;
  * publisher.on('audioLevelUpdated', function(event) {
  *   if (movingAvg === null || movingAvg <= event.audioLevel) {
  *     movingAvg = event.audioLevel;
  *   } else {
  *     movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
  *   }
  *
  *   // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
  *   var logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
  *   logLevel = Math.min(Math.max(logLevel, 0), 1);
  *   document.getElementById('publisherMeter').value = logLevel;
  * });
  * </pre>
  * <p>This example shows the algorithm used by the default audio level indicator displayed
  * in an audio-only Publisher.
  *
  * @name audioLevelUpdated
  * @event
  * @memberof Publisher
  * @see AudioLevelUpdatedEvent
  */

  /**
   * The publisher has started streaming to the session.
   * @name streamCreated
   * @event
   * @memberof Publisher
   * @see StreamEvent
   * @see <a href="Session.html#publish">Session.publish()</a>
   */

  /**
   * The publisher has stopped streaming to the session. The default behavior is that
   * the Publisher object is removed from the HTML DOM. The Publisher object dispatches a
   * <code>destroyed</code> event when the element is removed from the HTML DOM. If you call the
   * <code>preventDefault()</code> method of the event object in the event listener, the default
   * behavior is prevented, and you can, optionally, retain the Publisher for reuse or clean it up
   * using your own code.
   * @name streamDestroyed
   * @event
   * @memberof Publisher
   * @see StreamEvent
   */

  /**
  * Dispatched when the Publisher element is removed from the HTML DOM. When this event
  * is dispatched, you may choose to adjust or remove HTML DOM elements related to the publisher.
  * @name destroyed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the video dimensions of the video change. This can only occur in when the
  * <code>stream.videoType</code> property is set to <code>"screen"</code> (for a screen-sharing
  * video stream), when the user resizes the window being captured. This event object has a
  * <code>newValue</code> property and an <code>oldValue</code> property, representing the new and
  * old dimensions of the video. Each of these has a <code>height</code> property and a
  * <code>width</code> property, representing the height and width, in pixels.
  * @name videoDimensionsChanged
  * @event
  * @memberof Publisher
  * @see VideoDimensionsChangedEvent
*/

  /**
  * Dispatched when the Publisher's video element is created. Add a listener for this event when
  * you set the <code>insertDefaultUI</code> option to <code>false</code> in the call to the
  * <a href="OT.html#initPublisher">OT.initPublisher()</a> method. The <code>element</code>
  * property of the event object is a reference to the Publisher's <code>video</code> element
  * (or in Internet Explorer the <code>object</code> element containing the video). Add it to
  * the HTML DOM to display the video. When you set the <code>insertDefaultUI</code> option to
  * <code>false</code>, the <code>video</code> (or <code>object</code>) element is not automatically
  * inserted into the DOM.
  * <p>
  * Add a listener for this event only if you have set the <code>insertDefaultUI</code> option to
  * <code>false</code>. If you have not set <code>insertDefaultUI</code> option to
  * <code>false</code>, do not move the <code>video</code> (or <code>object</code>) element in
  * in the HTML DOM. Doing so causes the Publisher object to be destroyed.
  *
  * @name videoElementCreated
  * @event
  * @memberof Publisher
  * @see VideoElementCreatedEvent
  */

  /**
   * The user has stopped screen-sharing for the published stream. This event is only dispatched
   * for screen-sharing video streams.
   * @name mediaStopped
   * @event
   * @memberof Publisher
   * @see StreamEvent
   */
};

// Helper function to generate unique publisher ids
Publisher.nextId = uuid;

Publisher.audioContext = audioContext;
Publisher.AudioLevelMeter = AudioLevelMeter;
Publisher.getUserMedia = getUserMedia;
Publisher.Microphone = Microphone;
Publisher.PublisherPeerConnection = PublisherPeerConnection;
Publisher.WidgetView = WidgetView;
Publisher.PUBLISH_MAX_DELAY = PUBLISH_MAX_DELAY;

module.exports = Publisher;
