'use strict';

var uuid = require('uuid');
var OTPlugin = require('../otplugin/otplugin.js');
var OTHelpers = require('../common-js-helpers/OTHelpers.js');
var logging = require('../ot/logging.js');
var VideoElementFacade = require('./video_element/index.js');

var miniWidth = 128;
var miniHeight = 128;
var microWidth = 64;
var microHeight = 64;

function fixMini(container, width, height) {
  var w = parseInt(width, 10);
  var h = parseInt(height, 10);

  if (w < microWidth || h < microHeight) {
    OTHelpers.addClass(container, 'OT_micro');
  } else {
    OTHelpers.removeClass(container, 'OT_micro');
  }
  if (w < miniWidth || h < miniHeight) {
    OTHelpers.addClass(container, 'OT_mini');
  } else {
    OTHelpers.removeClass(container, 'OT_mini');
  }
}

var getOrCreateContainer = function getOrCreateContainer(elementOrDomId, insertMode) {
  var container,
      domId;

  if (elementOrDomId && elementOrDomId.nodeName) {
    // It looks like we were given a DOM element. Grab the id or generate
    // one if it doesn't have one.
    container = elementOrDomId;
    if (!container.getAttribute('id') || container.getAttribute('id').length === 0) {
      container.setAttribute('id', 'OT_' + uuid());
    }

    domId = container.getAttribute('id');
  } else if (elementOrDomId) {
    // We may have got an id, try and get it's DOM element.
    container = OTHelpers('#' + elementOrDomId).get(0);
    if (container) { domId = elementOrDomId; }
  }

  if (!domId) {
    domId = 'OT_' + uuid().replace(/-/g, '_');
  }

  if (!container) {
    container = OTHelpers.createElement('div', { id: domId });
    container.style.backgroundColor = '#000000';
    document.body.appendChild(container);
  } else if (!(insertMode == null || insertMode === 'replace')) {
    var placeholder = document.createElement('div');
    placeholder.id = ('OT_' + uuid());
    if (insertMode === 'append') {
      container.appendChild(placeholder);
      container = placeholder;
    } else if (insertMode === 'before') {
      container.parentNode.insertBefore(placeholder, container);
      container = placeholder;
    } else if (insertMode === 'after') {
      container.parentNode.insertBefore(placeholder, container.nextSibling);
      container = placeholder;
    }
  } else {
    OTHelpers.emptyElement(container);
  }

  return container;
};

// Creates the standard container that the Subscriber and Publisher use to hold
// their video element and other chrome.
var WidgetView = function(targetElement, properties) {

  var widgetView = {};
  var oldContainerStyles = {};
  var loading = true;
  var audioOnly = false;
  var fitMode = 'cover';
  var sizeObserver,
      _videoElementFacade,
      videoObserver,
      posterContainer,
      loadingContainer,
      width,
      height,
      container;

  if (properties.insertDefaultUI !== false) {
    container = getOrCreateContainer(targetElement, properties && properties.insertMode);
  }

  var widgetContainer = document.createElement('div');

  OTHelpers.eventing(widgetView);

  if (properties && container) {
    width = properties.width;
    height = properties.height;

    if (width) {
      if (typeof width === 'number') {
        width += 'px';
      }
    }

    if (height) {
      if (typeof height === 'number') {
        height += 'px';
      }
    }

    container.style.width = width ? width : '264px';
    container.style.height = height ? height : '198px';
    container.style.overflow = 'hidden';
    fixMini(container, width || '264px', height || '198px');

    if (properties.mirror) {
      OTHelpers.addClass(container, 'OT_mirrored');
    }

    if (properties.fitMode === 'contain') {
      fitMode = 'contain';
    } else if (properties.fitMode !== 'cover') {
      logging.warn('Invalid fit value "' + properties.fitMode + '" passed. ' +
      'Only "contain" and "cover" can be used.');
    }

    if (properties.classNames) { OTHelpers.addClass(container, properties.classNames); }

    OTHelpers(container).addClass('OT_loading OT_fit-mode-' + fitMode);
  }

  OTHelpers.addClass(widgetContainer, 'OT_widget-container');
  widgetContainer.style.width = '100%'; //container.style.width;
  widgetContainer.style.height = '100%'; // container.style.height;

  if (container) {
    container.appendChild(widgetContainer);
  }

  loadingContainer = document.createElement('div');
  OTHelpers.addClass(loadingContainer, 'OT_video-loading');
  widgetContainer.appendChild(loadingContainer);

  posterContainer = document.createElement('div');
  OTHelpers.addClass(posterContainer, 'OT_video-poster');
  widgetContainer.appendChild(posterContainer);

  if (container) {
    oldContainerStyles.width = container.offsetWidth;
    oldContainerStyles.height = container.offsetHeight;
  }

  if (!OTPlugin.isInstalled() && container) {
    // Observe changes to the width and height and update the aspect ratio
    sizeObserver = OTHelpers(container).observeSize(
      function(size) {
        var width = size.width;
        var height = size.height;

        fixMini(container, width, height);
      })[0];

    // @todo observe if the video container or the video element get removed
    // if they do we should do some cleanup
    videoObserver = OTHelpers.observeNodeOrChildNodeRemoval(container, function(removedNodes) {
      if (!_videoElementFacade) { return; }

      // This assumes a video element being removed is the main video element. This may
      // not be the case.
      var videoRemoved = removedNodes.some(function(node) {
        return node === widgetContainer || node.nodeName === 'VIDEO';
      });

      if (videoRemoved) {
        _videoElementFacade.destroy();
        _videoElementFacade = null;
      }
    });
  }

  widgetView.destroy = function() {
    if (sizeObserver) {
      sizeObserver.disconnect();
      sizeObserver = null;
    }

    if (videoObserver) {
      videoObserver.disconnect();
      videoObserver = null;
    }

    if (_videoElementFacade) {
      _videoElementFacade.destroy();
      _videoElementFacade = null;
    }

    if (container) {
      OTHelpers.removeElement(container);
      container = null;
    }
  };

  widgetView.setBackgroundImageURI = function(bgImgURI) {
    OTHelpers.css(posterContainer, 'backgroundImage', 'url(' + bgImgURI + ')');
    OTHelpers.css(posterContainer, 'backgroundSize', 'contain');
    OTHelpers.css(posterContainer, 'opacity', '1.0');
  };

  if (properties && properties.style && properties.style.backgroundImageURI) {
    widgetView.setBackgroundImageURI(properties.style.backgroundImageURI);
  }

  /**
   * @returns {VideoElementFacade}
   */
  widgetView.bindVideo = function(webRTCStream, options, completion) {
    // remove the old video element if it exists
    // @todo this might not be safe, publishers/subscribers use this as well...

    options.fitMode = fitMode;

    if (typeof options.audioVolume !== 'undefined') {
      options.audioVolume = parseFloat(options.audioVolume);
    }

    if (_videoElementFacade) {
      _videoElementFacade.destroy();
      _videoElementFacade = null;
    }

    var onError = options && options.error ? options.error : void 0;
    delete options.error;

    var newVideoElementFacade = new WidgetView.VideoElementFacade(options, onError);

    if (newVideoElementFacade.domElement()) {
      widgetView.trigger('videoElementCreated', newVideoElementFacade.domElement());
    }
    newVideoElementFacade.on('videoElementCreated', function(element) {
      widgetView.trigger('videoElementCreated', element);
    });

    // Initialize the audio volume
    if (typeof options.audioVolume !== 'undefined') {
      newVideoElementFacade.setAudioVolume(options.audioVolume);
    }

    // makes the incoming audio streams take priority (will impact only FF OS for now)
    newVideoElementFacade.audioChannelType('telephony');

    newVideoElementFacade.appendTo(widgetContainer);

    newVideoElementFacade.bindToStream(webRTCStream, function(err) {
      if (err) {
        newVideoElementFacade.destroy();
        completion(err);
        return;
      }

      _videoElementFacade = newVideoElementFacade;

      newVideoElementFacade.on({
        videoDimensionsChanged: function(oldValue, newValue) {
          widgetView.trigger('videoDimensionsChanged', oldValue, newValue);
        },
        mediaStopped: function() {
          widgetView.trigger('mediaStopped');
        }
      });

      completion(null, newVideoElementFacade);
    });

    if (properties.insertDefaultUI !== false) {
      OTHelpers.addClass(newVideoElementFacade.domElement(), 'OT_video-element');
    }

    return newVideoElementFacade;
  };

  OTHelpers.defineProperties(widgetView, {

    video: {
      get: function() {
        return _videoElementFacade;
      }
    },

    showPoster: {
      get: function() {
        return !OTHelpers.isDisplayNone(posterContainer);
      },
      set: function(newValue) {
        if (newValue) {
          OTHelpers.show(posterContainer);
        } else {
          OTHelpers.hide(posterContainer);
        }
      }
    },

    poster: {
      get: function() {
        return OTHelpers.css(posterContainer, 'backgroundImage');
      },
      set: function(src) {
        OTHelpers.css(posterContainer, 'backgroundImage', 'url(' + src + ')');
      }
    },

    loading: {
      get: function() { return loading; },
      set: function(l) {
        loading = l;

        if (container) {
          if (loading) {
            OTHelpers.addClass(container, 'OT_loading');
          } else {
            OTHelpers.removeClass(container, 'OT_loading');
          }
        }
      }
    },

    audioOnly: {
      get: function() { return audioOnly; },
      set: function(a) {
        audioOnly = a;

        if (audioOnly) {
          OTHelpers.addClass(container, 'OT_audio-only');
        } else {
          OTHelpers.removeClass(container, 'OT_audio-only');
        }
      }
    },

    domId: {
      get: function() { return container && container.getAttribute('id'); }
    }

  });

  widgetView.domElement = container;

  widgetView.addError = function(errorMsg, helpMsg, classNames) {
    if (container) {
      container.innerHTML = '<p>' + errorMsg +
        (helpMsg ? ' <span class="ot-help-message">' + helpMsg + '</span>' : '') +
        '</p>';
      OTHelpers.addClass(container, classNames || 'OT_subscriber_error');
      if (container.querySelector('p').offsetHeight > container.offsetHeight) {
        container.querySelector('span').style.display = 'none';
      }
    }
  };

  return widgetView;
};

// This is bound here so that it can be mocked in testing. Feels like a smell that's a symptom of
// larger problems to me, but I'm just maintaining existing behaviour right now.
WidgetView.VideoElementFacade = VideoElementFacade;

module.exports = WidgetView;
