'use strict';

module.exports = function EventEmitter() {
  var ee = {};

  var eventHandlers = {};

  ee.on = function(name, callback, context) {
    if (!eventHandlers.hasOwnProperty(name)) {
      eventHandlers[name] = [];
    }

    eventHandlers[name].push([callback, context]);
    return ee;
  };

  ee.off = function(name, callback, context) {
    if (!eventHandlers.hasOwnProperty(name) ||
        eventHandlers[name].length === 0) {
      return undefined; // TODO: Wouldn't it be more consistent to return ee?
    }

    eventHandlers[name] = eventHandlers[name].filter(function(listener) {
      return listener[0] !== callback && listener[1] !== context;
    });

    return ee;
  };

  ee.once = function(name, callback, context) {
    var fn = function() {
      ee.off(name, fn);
      return callback.apply(context, arguments);
    };

    ee.on(name, fn);
    return ee;
  };

  ee.emit = function(name, args) {
    setTimeout(function() {
      if (!eventHandlers.hasOwnProperty(name) || eventHandlers[name].length === 0) {
        return;
      }

      eventHandlers[name].forEach(function(handler) {
        handler[0].apply(handler[1], args);
      });
    });

    return ee;
  };

  return ee;
};
