'use strict';

var fixBackgroundImageURI = require('./fix_background_image_uri.js');
var logging = require('../logging.js');
var Style = require('./style.js');
var styleHashLogFilter = require('./style_hash_log_filter.js');

/* Stylable Notes
 * Some bits are controlled by multiple flags, i.e. buttonDisplayMode and nameDisplayMode.
 * When there are multiple flags how is the final setting chosen?
 * When some style bits are set updates will need to be pushed through to the Chrome
 */

// Mixes the StylableComponent behaviour into the +self+ object. It will
// also set the default styles to +initialStyles+.
//
// @note This Mixin is dependent on OT.Eventing.
//
//
// @example
//
//  function SomeObject {
//      StylableComponent(this, {
//          name: 'SomeObject',
//          foo: 'bar'
//      });
//  }
//
//  var obj = new SomeObject();
//  obj.getStyle('foo');        // => 'bar'
//  obj.setStyle('foo', 'baz')
//  obj.getStyle('foo');        // => 'baz'
//  obj.getStyle();             // => {name: 'SomeObject', foo: 'baz'}
//
module.exports = function StylableComponent(
  self,
  initalStyles,
  showControls,
  logSetStyleWithPayload
) {
  if (!self.trigger) {
    throw new Error('OT.StylableComponent is dependent on the mixin OTHelpers.eventing. ' +
      'Ensure that this is included in the object before StylableComponent.');
  }

  logSetStyleWithPayload = logSetStyleWithPayload || function() {};

  var _readOnly = false;

  // Broadcast style changes as the styleValueChanged event
  var onStyleChange = function(key, value, oldValue) {
    if (oldValue) {
      self.trigger('styleValueChanged', key, value, oldValue);
    } else {
      self.trigger('styleValueChanged', key, value);
    }
  };

  if (showControls === false) {
    initalStyles = {
      buttonDisplayMode: 'off',
      nameDisplayMode: 'off',
      audioLevelDisplayMode: 'off',
      videoDisabledDisplayMode: 'off'
    };

    _readOnly = true;
    logSetStyleWithPayload({
      showControls: false
    });
  }

  var _style = new Style(initalStyles, onStyleChange);

  /**
   * Returns an object that has the properties that define the current user interface controls of
   * the Publisher. You can modify the properties of this object and pass the object to the
   * <code>setStyle()</code> method of thePublisher object. (See the documentation for
   * <a href="#setStyle">setStyle()</a> to see the styles that define this object.)
   * @return {Object} The object that defines the styles of the Publisher.
   * @see <a href="#setStyle">setStyle()</a>
   * @method #getStyle
   * @memberOf Publisher
   */

  /**
   * Returns an object that has the properties that define the current user interface controls of
   * the Subscriber. You can modify the properties of this object and pass the object to the
   * <code>setStyle()</code> method of the Subscriber object. (See the documentation for
   * <a href="#setStyle">setStyle()</a> to see the styles that define this object.)
   * @return {Object} The object that defines the styles of the Subscriber.
   * @see <a href="#setStyle">setStyle()</a>
   * @method #getStyle
   * @memberOf Subscriber
   */
  // If +key+ is falsly then all styles will be returned.
  self.getStyle = function(key) {
    return _style.get(key);
  };

  /**
   * Sets properties that define the appearance of some user interface controls of the Publisher.
   *
   * <p>You can either pass one parameter or two parameters to this method.</p>
   *
   * <p>If you pass one parameter, <code>style</code>, it is an object that has the following
   * properties:
   *
   *     <ul>
   *       <li><code>audioLevelDisplayMode</code> (String) &mdash; How to display the audio level
   *       indicator. Possible values are: <code>"auto"</code> (the indicator is displayed when the
   *       video is disabled), <code>"off"</code> (the indicator is not displayed), and
   *       <code>"on"</code> (the indicator is always displayed).</li>
   *
   *       <li><code>archiveStatusDisplayMode</code> (String) &mdash; How to display the archive
   *       status indicator. Possible values are: <code>"auto"</code> (the indicator is displayed
   *       when the session is being recorded), <code>"off"</code> (the indicator is not displayed).
   *       If you disable the archive status display indicator, you can display your own user
   *       interface notifications based on the <code>archiveStarted</code> and
   *       <code>archiveStopped</code> events dispatched by the Session object.</li>
   *
   *       <li><code>backgroundImageURI</code> (String) &mdash; A URI for an image to display as
   *       the background image when a video is not displayed. (A video may not be displayed if
   *       you call <code>publishVideo(false)</code> on the Publisher object). You can pass an http
   *       or https URI to a PNG, JPEG, or non-animated GIF file location. You can also use the
   *       <code>data</code> URI scheme (instead of http or https) and pass in base-64-encrypted
   *       PNG data, such as that obtained from the
   *       <a href="Publisher.html#getImgData">Publisher.getImgData()</a> method. For example,
   *       you could set the property to <code>"data:VBORw0KGgoAA..."</code>, where the portion of
   *       the string after <code>"data:"</code> is the result of a call to
   *       <code>Publisher.getImgData()</code>. If the URL or the image data is invalid, the
   *       property is ignored (the attempt to set the image fails silently).</li>
   *
   *       <li><code>buttonDisplayMode</code> (String) &mdash; How to display the microphone
   *       controls. Possible values are: <code>"auto"</code> (controls are displayed when the
   *       stream is first displayed and when the user mouses over the display), <code>"off"</code>
   *       (controls are not displayed), and <code>"on"</code> (controls are always displayed).</li>
   *
   *       <li><code>nameDisplayMode</code> (String) &#151; Whether to display the stream name.
   *       Possible values are: <code>"auto"</code> (the name is displayed when the stream is first
   *       displayed and when the user mouses over the display), <code>"off"</code> (the name is not
   *       displayed), and <code>"on"</code> (the name is always displayed).</li>
   *   </ul>
   * </p>
   *
   * <p>For example, the following code passes one parameter to the method:</p>
   *
   * <pre>myPublisher.setStyle({nameDisplayMode: "off"});</pre>
   *
   * <p>If you pass two parameters, <code>style</code> and <code>value</code>, they are
   * key-value pair that define one property of the display style. For example, the following
   * code passes two parameter values to the method:</p>
   *
   * <pre>myPublisher.setStyle("nameDisplayMode", "off");</pre>
   *
   * <p>You can set the initial settings when you call the <code>Session.publish()</code>
   * or <code>OT.initPublisher()</code> method. Pass a <code>style</code> property as part of the
   * <code>properties</code> parameter of the method.</p>
   *
   * <p>The OT object dispatches an <code>exception</code> event if you pass in an invalid style
   * to the method. The <code>code</code> property of the ExceptionEvent object is set to 1011.</p>
   *
   * @param {Object} style Either an object containing properties that define the style, or a
   * String defining this single style property to set.
   * @param {String} value The value to set for the <code>style</code> passed in. Pass a value
   * for this parameter only if the value of the <code>style</code> parameter is a String.</p>
   *
   * @see <a href="#getStyle">getStyle()</a>
   * @return {Publisher} The Publisher object
   * @see <a href="#setStyle">setStyle()</a>
   *
   * @see <a href="Session.html#subscribe">Session.publish()</a>
   * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
   * @method #setStyle
   * @memberOf Publisher
   */

  /**
   * Sets properties that define the appearance of some user interface controls of the Subscriber.
   *
   * <p>You can either pass one parameter or two parameters to this method.</p>
   *
   * <p>If you pass one parameter, <code>style</code>, it is an object that has the following
   * properties:
   *
   *     <ul>
   *       <li><code>audioLevelDisplayMode</code> (String) &mdash; How to display the audio level
   *       indicator. Possible values are: <code>"auto"</code> (the indicator is displayed when the
   *       video is disabled), <code>"off"</code> (the indicator is not displayed), and
   *       <code>"on"</code> (the indicator is always displayed).</li>
   *
   *       <li><code>backgroundImageURI</code> (String) &mdash; A URI for an image to display as
   *       the background image when a video is not displayed. (A video may not be displayed if
   *       you call <code>subscribeToVideo(false)</code> on the Publisher object). You can pass an
   *       http or https URI to a PNG, JPEG, or non-animated GIF file location. You can also use the
   *       <code>data</code> URI scheme (instead of http or https) and pass in base-64-encrypted
   *       PNG data, such as that obtained from the
   *       <a href="Subscriber.html#getImgData">Subscriber.getImgData()</a> method. For example,
   *       you could set the property to <code>"data:VBORw0KGgoAA..."</code>, where the portion of
   *       the string after <code>"data:"</code> is the result of a call to
   *       <code>Publisher.getImgData()</code>. If the URL or the image data is invalid, the
   *       property is ignored (the attempt to set the image fails silently).</li>
   *
   *       <li><code>buttonDisplayMode</code> (String) &mdash; How to display the speaker
   *       controls. Possible values are: <code>"auto"</code> (controls are displayed when the
   *       stream is first displayed and when the user mouses over the display), <code>"off"</code>
   *       (controls are not displayed), and <code>"on"</code> (controls are always displayed).</li>
   *
   *       <li><code>nameDisplayMode</code> (String) &#151; Whether to display the stream name.
   *       Possible values are: <code>"auto"</code> (the name is displayed when the stream is first
   *       displayed and when the user mouses over the display), <code>"off"</code> (the name is not
   *       displayed), and <code>"on"</code> (the name is always displayed).</li>
   *
   *       <li><code>videoDisabledDisplayMode</code> (String) &#151; Whether to display the video
   *       disabled indicator and video disabled warning icons for a Subscriber. These icons
   *       indicate that the video has been disabled (or is in risk of being disabled for
   *       the warning icon) due to poor stream quality. Possible values are: <code>"auto"</code>
   *       (the icons are automatically when the displayed video is disabled or in risk of being
   *       disabled due to poor stream quality), <code>"off"</code> (do not display the icons), and
   *       <code>"on"</code> (display the icons).</li>
   *   </ul>
   * </p>
   *
   * <p>For example, the following code passes one parameter to the method:</p>
   *
   * <pre>mySubscriber.setStyle({nameDisplayMode: "off"});</pre>
   *
   * <p>If you pass two parameters, <code>style</code> and <code>value</code>, they are key-value
   * pair that define one property of the display style. For example, the following code passes
   * two parameter values to the method:</p>
   *
   * <pre>mySubscriber.setStyle("nameDisplayMode", "off");</pre>
   *
   * <p>You can set the initial settings when you call the <code>Session.subscribe()</code> method.
   * Pass a <code>style</code> property as part of the <code>properties</code> parameter of the
   * method.</p>
   *
   * <p>The OT object dispatches an <code>exception</code> event if you pass in an invalid style
   * to the method. The <code>code</code> property of the ExceptionEvent object is set to 1011.</p>
   *
   * @param {Object} style Either an object containing properties that define the style, or a
   * String defining this single style property to set.
   * @param {String} value The value to set for the <code>style</code> passed in. Pass a value
   * for this parameter only if the value of the <code>style</code> parameter is a String.</p>
   *
   * @returns {Subscriber} The Subscriber object.
   *
   * @see <a href="#getStyle">getStyle()</a>
   * @see <a href="#setStyle">setStyle()</a>
   *
   * @see <a href="Session.html#subscribe">Session.subscribe()</a>
   * @method #setStyle
   * @memberOf Subscriber
   */

  self.setStyle = function(keyOrStyleHash, value, silent) {
    if (_readOnly) {
      logging.warn('Calling setStyle() has no effect because the' +
        'showControls option was set to false');

      return this;
    }

    var styleHash;

    if (typeof keyOrStyleHash !== 'string') {
      styleHash = keyOrStyleHash;
    } else {
      styleHash = {};
      styleHash[keyOrStyleHash] = value;
    }

    if (styleHash.backgroundImageURI) {
      styleHash.backgroundImageURI = fixBackgroundImageURI(styleHash.backgroundImageURI);
    }

    _style.setAll(styleHash, silent);

    logSetStyleWithPayload(
      styleHashLogFilter(styleHash)
    );

    return this;
  };
};
