'use strict';

module.exports = {
  JS_EXCEPTION: 2000,
  AUTHENTICATION_ERROR: 1004,
  INVALID_SESSION_ID: 1005,
  CONNECT_FAILED: 1006,
  CONNECT_REJECTED: 1007,
  CONNECTION_TIMEOUT: 1008,
  NOT_CONNECTED: 1010,
  INVALID_PARAMETER: 1011,
  P2P_CONNECTION_FAILED: 1013,
  API_RESPONSE_FAILURE: 1014,
  TERMS_OF_SERVICE_FAILURE: 1026,
  UNABLE_TO_PUBLISH: 1500,
  UNABLE_TO_SUBSCRIBE: 1501,
  UNABLE_TO_FORCE_DISCONNECT: 1520,
  UNABLE_TO_FORCE_UNPUBLISH: 1530,
  PUBLISHER_ICE_WORKFLOW_FAILED: 1553,
  SUBSCRIBER_ICE_WORKFLOW_FAILED: 1554,
  UNEXPECTED_SERVER_RESPONSE: 2001,
  REPORT_ISSUE_ERROR: 2011
};
