'use strict';

var makeEverythingAttachToOTHelpers = require('./makeEverythingAttachToOTHelpers');

var domExtras = {};
module.exports = domExtras;

domExtras.isElementNode = function(node) {
  return node && typeof node === 'object' && node.nodeType === 1;
};

domExtras.createElement = function(nodeName, attributes, children, doc) {
  var element = (doc || document).createElement(nodeName);
  attributes = attributes || {};

  Object.keys(attributes).forEach(function(name) {
    if (typeof (attributes[name]) === 'object') {
      if (!element[name]) {
        element[name] = {};
      }

      var subAttrs = attributes[name];

      Object.keys(subAttrs).forEach(function(n) {
        element[name][n] = subAttrs[n];
      });
    } else if (name === 'className') {
      element.className = attributes[name];
    } else {
      element.setAttribute(name, attributes[name]);
    }
  });

  var setChildren = function(child) {
    if (typeof child === 'string') {
      element.innerHTML += child;
    } else {
      element.appendChild(child);
    }
  };

  if (Array.isArray(children)) {
    children.forEach(setChildren);
  } else if (children) {
    setChildren(children);
  }

  return element;
};

makeEverythingAttachToOTHelpers(domExtras);
