'use strict';

var Anvil          = require('../anvil.js');
var Bluebird       = require('bluebird');
var ExceptionCodes = require('../exception_codes.js');
var logging        = require('../logging.js');

// This sequence defines the delay before retry. Therefore a 0 indicates
// that a retry should happen immediately.
//
// e.g. 0, 600, 1200 means retry immediately, then in 600 ms, then in 1200ms
//
var retryDelays = [0, 600, 1200];

// These codes are possibly transient and it's worth retrying if a Anvil request
// fails with one of these codes.
var transientErrorCodes = [
  ExceptionCodes.CONNECT_FAILED,
  ExceptionCodes.UNEXPECTED_SERVER_RESPONSE
];

var SessionInfo = function(rawSessionInfo) {
  logging.log('SessionInfo Response:');
  logging.log(rawSessionInfo);

  /*jshint camelcase:false*/
  //jscs:disable requireCamelCaseOrUpperCaseIdentifiers
  this.sessionId = rawSessionInfo.session_id;
  this.partnerId = rawSessionInfo.partner_id;
  this.messagingServer = rawSessionInfo.messaging_server_url;
  this.mediaServerName = rawSessionInfo.media_server_hostname;
  this.messagingURL = rawSessionInfo.messaging_url;
  this.symphonyAddress = rawSessionInfo.symphony_address;

  if (rawSessionInfo.properties) {
    // `simulcast` is tri-state:
    // true: simulcast is on for this session
    // false: simulcast is off for this session
    // undefined: the developer can choose
    //
    this.simulcast = rawSessionInfo.properties.simulcast;

    this.reconnection = rawSessionInfo.properties.reconnection === undefined ?
      false : rawSessionInfo.properties.reconnection;

    this.renegotiation = rawSessionInfo.properties.renegotiation === undefined ?
      false : rawSessionInfo.properties.renegotiation;

    this.p2pEnabled = !!(rawSessionInfo.properties.p2p &&
      rawSessionInfo.properties.p2p.preference &&
      rawSessionInfo.properties.p2p.preference.value === 'enabled');

    this.rememberDeviceChoiceIE = rawSessionInfo.properties.rememberDeviceChoiceIE === undefined ?
      false : rawSessionInfo.properties.rememberDeviceChoiceIE;
  } else {
    this.p2pEnabled = false;
  }
};

// Retrieves Session Info for +session+. The SessionInfo object will be passed
// to the +onSuccess+ callback. The +onFailure+ callback will be passed an error
// object and the DOMEvent that relates to the error.
//
SessionInfo.get = function(id, token) {
  var remainingRetryDelays = retryDelays.slice();

  var attempt = function(err, resolve, reject) {
    if (remainingRetryDelays.length === 0) {
      reject(err);
      return;
    }

    Anvil.get('session/' + id + '?extended=true', token).then(function(anvilResponse) {
      resolve(new SessionInfo(anvilResponse));
    }, function(err) {
      if (transientErrorCodes.indexOf(err.code) > -1) {
        // This error is possibly transient, so retry
        setTimeout(function() {
          attempt(err, resolve, reject);
        }, remainingRetryDelays.shift());
      } else {
        reject(err);
      }
    });
  };

  return new Bluebird.Promise(function(resolve, reject) {
    attempt(void 0, resolve, reject);
  });
};

module.exports = SessionInfo;
