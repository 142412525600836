'use strict';

var Message = require('./rumor_message.js');
var OTPlugin = require('../../../otplugin/otplugin.js');

module.exports = function PluginSocket(messagingURL, events) {
  var webSocket;
  var state = 'initializing';

  OTPlugin.initRumorSocket(messagingURL, function(err, rumorSocket) {
    if (err) {
      state = 'closed';
      events.onClose({ code: 4999 });
    } else if (state === 'initializing') {
      webSocket = rumorSocket;

      webSocket.onOpen(function() {
        state = 'open';
        events.onOpen();
      });
      webSocket.onClose(function(error) {
        state = 'closed'; /* CLOSED */
        events.onClose({ code: error });
      });
      webSocket.onError(function(error) {
        state = 'closed'; /* CLOSED */
        events.onError(error);
        /* native websockets seem to do this, so should we */
        events.onClose({ code: error });
      });

      webSocket.onMessage(function(type, addresses, headers, payload) {
        var msg = new Message(type, addresses, headers, payload);
        events.onMessage(msg);
      });

      webSocket.open();
    } else {
      this.close();
    }
  }.bind(this));

  this.close = function(drainBuffer, removeEventHandlers) {
    if (removeEventHandlers) {
      var empty = function() {};

      webSocket.onOpen(function() {
        state = 'open';
        empty();
      });
      webSocket.onClose(function() {
        state = 'closed'; /* CLOSED */
        empty();
      });
      webSocket.onError(function() {
        state = 'closed'; /* CLOSED */
        empty();
      });

      webSocket.onMessage(empty);
    }

    if (state === 'initializing' || state === 'closed') {
      state = 'closed';
      return;
    }

    webSocket.close(1000, '');
  };

  this.send = function(msg) {
    if (state === 'open') {
      webSocket.send(msg);
    }
  };

  this.isClosed = function() {
    return state === 'closed';
  };

};
