'use strict';

var once = require('lodash.once');

var env = require('../common-js-helpers/env.js');
var hasOpenTokSupport = require('./hasOpenTokSupport.js');

module.exports = once(function() {
  return hasOpenTokSupport() && env.name === 'Chrome';
});
