'use strict';

var uuid = require('uuid');
var Bluebird = require('bluebird');
var ExceptionCodes = require('./exception_codes.js');
var OTHelpers = require('../common-js-helpers/OTHelpers.js');
var properties = require('../helpers/properties.js');

var Anvil = {};
module.exports = Anvil;

// @todo These aren't the same for all resource types.
var httpToClientCode = {
  400: ExceptionCodes.INVALID_SESSION_ID,
  403: ExceptionCodes.AUTHENTICATION_ERROR,
  404: ExceptionCodes.INVALID_SESSION_ID,
  409: ExceptionCodes.TERMS_OF_SERVICE_FAILURE
};

var anvilErrors = {
  RESPONSE_BADLY_FORMED: {
    code: null,
    message: 'Unknown error: JSON response was badly formed'
  },

  UNEXPECTED_SERVER_RESPONSE: {
    code: ExceptionCodes.UNEXPECTED_SERVER_RESPONSE,
    message: 'Unexpected server response. Try this operation again later.'
  }
};

// Transform an error that we don't understand (+originalError+) to a general
// UNEXPECTED_SERVER_RESPONSE one. We also include the original error details
// on the `error.details` property.
//
var normaliseUnexpectedError = function normaliseUnexpectedError(originalError) {
  var error = OTHelpers.clone(anvilErrors.UNEXPECTED_SERVER_RESPONSE);

  // We don't know this code...capture whatever the original
  // error and code were for debugging purposes.
  error.details = {
    originalCode: originalError.code.toString(),
    originalMessage: originalError.message
  };

  return error;
};

Anvil.getRequestParams = function getApiRequestOptions(resourcePath, token) {
  var url = properties.apiURL + '/' + resourcePath;
  var options;
  var clientVersion = 'js-' + properties.version.replace(/^v/, '');

  if (OTHelpers.env.name === 'IE' && OTHelpers.env.version < 10) {
    url = url + '&format=json&token=' + encodeURIComponent(token) +
                '&version=1&cache=' + uuid() +
                '&client_version=' + clientVersion;

    options = {
      xdomainrequest: true
    };
  } else {
    options = {
      headers: {
        'X-OPENTOK-AUTH': token,
        'X-TB-VERSION': 1,
        'X-TB-CLIENT-VERSION': clientVersion
      }
    };
  }

  return {
    url: url,
    options: options
  };
};

Anvil.getErrorsFromHTTP = function(httpError) {
  if (!httpError) {
    return false;
  }

  var error = {
    code: httpError.target && httpError.target.status
  };

  return error;
};

Anvil.getErrorsFromResponse = function getErrorsFromResponse(responseJson) {
  if (!Array.isArray(responseJson)) {
    return anvilErrors.RESPONSE_BADLY_FORMED;
  }

  var error = OTHelpers.find(responseJson, function(node) {
    return node.error !== void 0 && node.error !== null;
  });

  if (!error) {
    return false;
  }

  // Yup :-(
  error = error.error;
  error.message = error.errorMessage && error.errorMessage.message;

  return error;
};

var normaliseError = function normaliseError(error, responseText) {
  if (error.code && !httpToClientCode[error.code]) {
    error = normaliseUnexpectedError(error);
  } else {
    error.code = httpToClientCode[error.code];
  }

  if (responseText.length === 0) {
    // This is a weird edge case that usually means that there was connectivity
    // loss after Anvil sent the response but before the client had fully received it
    error.code = ExceptionCodes.CONNECT_FAILED;
    responseText = 'Response body was empty, probably due to connectivity loss';
  } else if (!error.code) {
    error = normaliseUnexpectedError(error);
  }

  if (!error.details) { error.details = {}; }
  error.details.responseText = responseText;

  return error;
};

Anvil.get = function getFromAnvil(resourcePath, token) {
  var params = Anvil.getRequestParams(resourcePath, token);

  return new Bluebird.Promise(function(resolve, reject) {
    OTHelpers.getJSON(params.url, params.options, function(httpError, responseJson) {
      var err = Anvil.getErrorsFromHTTP(httpError) || Anvil.getErrorsFromResponse(responseJson);
      var responseText;

      if (err) {
        responseText = responseJson && !OTHelpers.isEmpty(responseJson) ?
                                        JSON.stringify(responseJson) : '';
        err = normaliseError(err, responseText);

        reject(new OTHelpers.Error(err.message, 'AnvilError', {
          code: err.code,
          details: err.details
        }));

        return;
      }

      resolve(responseJson[0]);
    });
  });
};
