'use strict';

// core modules
var assert = require('assert');

// Takes a function of the form function() => promise (no args and returns a promise) and returns
// a function of the same form which prevents concurrent requests by returning the pending result
// when additional requests are made before the first is fulfilled.

module.exports = function(request) {
  var responsePromise;

  return function() {
    assert(arguments.length === 0);

    if (responsePromise) {
      return responsePromise;
    }

    responsePromise = request().finally(function() {
      responsePromise = undefined;
    });

    return responsePromise;
  };
};
