'use strict';

var OTPlugin = require('../../otplugin/otplugin.js');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');
var logging = require('../logging.js');

var requiredPublisherKeys = ['audioCodec', 'audioSentBytes', 'audioSentPackets',
  'audioSentPacketsLost', 'videoCodec', 'videoHeight', 'videoHeightInput', 'videoSentBytes',
  'videoFrameRateSent', 'videoSentPackets', 'videoRtt', 'videoSentPacketsLost', 'videoWidthInput',
  'videoWidth'];

var requiredSubscriberKeys = ['audioCodec', 'audioRecvBytes', 'audioRecvPackets',
  'audioRecvPacketsLost', 'videoCodec', 'videoHeight', 'videoRecvBytes', 'videoRecvPackets',
  'videoRecvPacketsLost', 'videoFrameRateReceived', 'videoRtt', 'videoWidth'];

//
// There are three implementations of stats parsing in this file.
// 1. For Chrome: Chrome is currently using an older version of the API
// 2. For OTPlugin: The plugin is using a newer version of the API that
//    exists in the latest WebRTC codebase
// 3. For Firefox: FF is using a version that looks a lot closer to the
//    current spec.
//
// I've attempted to keep the three implementations from sharing any code,
// accordingly you'll notice a bunch of duplication between the three.
//
// This is acceptable as the goal is to be able to remove each implementation
// as it's no longer needed without any risk of affecting the others. If there
// was shared code between them then each removal would require an audit of
// all the others.
//
//

///
// Get Stats using the older API. Used by all current versions
// of Chrome.
//
var parseStatsOldAPI = function parseStatsOldAPI(peerConnection,
                                                 prevStats,
                                                 currentStats,
                                                 isPublisher,
                                                 completion) {

  /* this parses a result if there it contains the video bitrate */
  var parseVideoStats = function(result) {
    if (result.stat('googFrameRateSent')) {
      currentStats.videoSentBytes = Number(result.stat('bytesSent'));
      currentStats.videoSentPackets = Number(result.stat('packetsSent'));
      currentStats.videoSentPacketsLost = Number(result.stat('packetsLost'));
      currentStats.videoRtt = Number(result.stat('googRtt'));
      currentStats.videoFrameRate = Number(result.stat('googFrameRateInput'));
      currentStats.videoWidth = Number(result.stat('googFrameWidthSent'));
      currentStats.videoHeight = Number(result.stat('googFrameHeightSent'));
      currentStats.videoFrameRateSent = Number(result.stat('googFrameRateSent'));
      currentStats.videoWidthInput = Number(result.stat('googFrameWidthInput'));
      currentStats.videoHeightInput = Number(result.stat('googFrameHeightInput'));
      currentStats.videoCodec = result.stat('googCodecName');
    } else if (result.stat('googFrameRateReceived')) {
      currentStats.videoRecvBytes = Number(result.stat('bytesReceived'));
      currentStats.videoRecvPackets = Number(result.stat('packetsReceived'));
      currentStats.videoRecvPacketsLost = Number(result.stat('packetsLost'));
      currentStats.videoFrameRate = Number(result.stat('googFrameRateOutput'));
      currentStats.videoFrameRateReceived = Number(result.stat('googFrameRateReceived'));
      currentStats.videoFrameRateDecoded = Number(result.stat('googFrameRateDecoded'));
      currentStats.videoWidth = Number(result.stat('googFrameWidthReceived'));
      currentStats.videoHeight = Number(result.stat('googFrameHeightReceived'));
      currentStats.videoCodec = result.stat('googCodecName');
    }

    return null;
  };

  var parseAudioStats = function(result) {
    if (result.stat('audioInputLevel')) {
      currentStats.audioSentPackets = Number(result.stat('packetsSent'));
      currentStats.audioSentPacketsLost = Number(result.stat('packetsLost'));
      currentStats.audioSentBytes =  Number(result.stat('bytesSent'));
      currentStats.audioCodec = result.stat('googCodecName');
      currentStats.audioRtt = Number(result.stat('googRtt'));
    } else if (result.stat('audioOutputLevel')) {
      currentStats.audioRecvPackets = Number(result.stat('packetsReceived'));
      currentStats.audioRecvPacketsLost = Number(result.stat('packetsLost'));
      currentStats.audioRecvBytes =  Number(result.stat('bytesReceived'));
      currentStats.audioCodec = result.stat('googCodecName');
    }
  };

  var parseStatsReports = function(stats) {
    if (stats.result) {
      var resultList = stats.result();
      for (var resultIndex = 0; resultIndex < resultList.length; resultIndex++) {
        var result = resultList[resultIndex];

        if (result.stat) {
          if (result.stat('googActiveConnection') === 'true') {
            if (result.stat('googChannelId').indexOf('audio') > -1) {
              currentStats.audioLocalAddress = result.stat('googLocalAddress');
              currentStats.audioRemoteAddress = result.stat('googRemoteAddress');
              currentStats.audioLocalCandidateType = result.stat('googLocalCandidateType');
              currentStats.audioRemoteCandidateType = result.stat('googRemoteCandidateType');
              currentStats.audioTransportType = result.stat('googTransportType');
            } else if (result.stat('googChannelId').indexOf('video') > -1) {
              currentStats.videoLocalAddress = result.stat('googLocalAddress');
              currentStats.videoRemoteAddress = result.stat('googRemoteAddress');
              currentStats.videoLocalCandidateType = result.stat('googLocalCandidateType');
              currentStats.videoRemoteCandidateType = result.stat('googRemoteCandidateType');
              currentStats.videoTransportType = result.stat('googTransportType');
            }
          }

          parseAudioStats(result);
          parseVideoStats(result);
        }
      }

      // For audio-video publishers in Chrome, there are no corresponding video reports for these
      if (currentStats.videoFrameRateSent && !currentStats.videoLocalAddress) {
        currentStats.videoLocalAddress = currentStats.audioLocalAddress;
        currentStats.videoRemoteAddress = currentStats.audioRemoteAddress;
        currentStats.videoLocalCandidateType = currentStats.audioLocalCandidateType;
        currentStats.videoRemoteCandidateType = currentStats.audioLocalCandidateType;
        currentStats.videoTransportType = currentStats.audioTransportType;
      }
    }

    completion(null, currentStats);
  };

  peerConnection.getStats(parseStatsReports);
};

///
// Get Stats for the OT Plugin, newer than Chromes version, but
// still not in sync with the spec.
//
var parseStatsOTPlugin = function parseStatsOTPlugin(peerConnection,
                                                  prevStats,
                                                  currentStats,
                                                  isPublisher,
                                                  completion) {

  var onStatsError = function onStatsError(error) {
    completion(error);
  };

  ///
  // From the Audio Tracks
  // * audioBytesTransferred
  //
  var parseAudioStats = function(statsReport) {
    if (statsReport.audioInputLevel) {
      currentStats.audioSentBytes = Number(statsReport.bytesSent);
      currentStats.audioSentPackets = Number(statsReport.packetsSent);
      currentStats.audioSentPacketsLost = Number(statsReport.packetsLost);
      currentStats.audioRtt = Number(statsReport.googRtt);
      currentStats.audioCodec = statsReport.googCodecName;
    } else if (statsReport.audioOutputLevel) {
      currentStats.audioRecvBytes = Number(statsReport.bytesReceived);
      currentStats.audioRecvPackets = Number(statsReport.packetsReceived);
      currentStats.audioRecvPacketsLost = Number(statsReport.packetsLost);
      currentStats.audioCodec = statsReport.googCodecName;
    }
  };

  ///
  // From the Video Tracks
  // * frameRate
  // * videoBytesTransferred
  //
  var parseVideoStats = function(statsReport) {
    if (statsReport.googFrameHeightSent) {
      currentStats.videoSentBytes = Number(statsReport.bytesSent);
      currentStats.videoSentPackets = Number(statsReport.packetsSent);
      currentStats.videoSentPacketsLost = Number(statsReport.packetsLost);
      currentStats.videoRtt = Number(statsReport.googRtt);
      currentStats.videoCodec = statsReport.googCodecName;
      currentStats.videoWidth = Number(statsReport.googFrameWidthSent);
      currentStats.videoHeight = Number(statsReport.googFrameHeightSent);
      currentStats.videoFrameRateSent = Number(statsReport.googFrameRateSent);
      currentStats.videoWidthInput = Number(statsReport.googFrameWidthInput);
      currentStats.videoHeightInput = Number(statsReport.googFrameHeightInput);
    } else if (statsReport.googFrameHeightReceived) {
      currentStats.videoRecvBytes =   Number(statsReport.bytesReceived);
      currentStats.videoRecvPackets = Number(statsReport.packetsReceived);
      currentStats.videoRecvPacketsLost = Number(statsReport.packetsLost);
      currentStats.videoRtt = Number(statsReport.googRtt);
      currentStats.videoCodec = statsReport.googCodecName;
      currentStats.videoFrameRateReceived = Number(statsReport.googFrameRateReceived);
      currentStats.videoFrameRateDecoded = Number(statsReport.googFrameRateDecoded);
      currentStats.videoWidth = Number(statsReport.googFrameWidthReceived);
      currentStats.videoHeight = Number(statsReport.googFrameHeightReceived);
    }

    if (statsReport.googFrameRateInput) {
      currentStats.videoFrameRate = Number(statsReport.googFrameRateInput);
    } else if (statsReport.googFrameRateOutput) {
      currentStats.videoFrameRate = Number(statsReport.googFrameRateOutput);
    }
  };

  var isStatsForVideoTrack = function(statsReport) {
    return statsReport.googFrameHeightSent !== void 0 ||
            statsReport.googFrameHeightReceived !== void 0 ||
            currentStats.videoBytesTransferred !== void 0 ||
            statsReport.googFrameRateSent !== void 0;
  };

  var isStatsForIceCandidate = function(statsReport) {
    return statsReport.googActiveConnection === 'true';
  };

  peerConnection.getStats(null, function(statsReports) {
    statsReports.forEach(function(statsReport) {
      if (isStatsForIceCandidate(statsReport)) {
        if (statsReport.googChannelId.indexOf('audio') > -1) {
          currentStats.audioLocalAddress = statsReport.googLocalAddress;
          currentStats.audioRemoteAddress = statsReport.googRemoteAddress;
          currentStats.audioLocalCandidateType = statsReport.googLocalCandidateType;
          currentStats.audioRemoteCandidateType = statsReport.googRemoteCandidateType;
          currentStats.audioTransportType = statsReport.googTransportType;
        } else if (statsReport.googChannelId.indexOf('video') > -1) {
          currentStats.videoLocalAddress = statsReport.googLocalAddress;
          currentStats.videoRemoteAddress = statsReport.googRemoteAddress;
          currentStats.videoLocalCandidateType = statsReport.googLocalCandidateType;
          currentStats.videoRemoteCandidateType = statsReport.googRemoteCandidateType;
          currentStats.videoTransportType = statsReport.googTransportType;
        }
      } else if (isStatsForVideoTrack(statsReport)) {
        parseVideoStats(statsReport);
      } else {
        parseAudioStats(statsReport);
      }
    });

    // For publishers with audio, there are no corresponding video reports for these
    if (currentStats.videoFrameRateSent && !currentStats.videoLocalAddress) {
      currentStats.videoLocalAddress = currentStats.audioLocalAddress;
      currentStats.videoRemoteAddress = currentStats.audioRemoteAddress;
      currentStats.videoLocalCandidateType = currentStats.audioLocalCandidateType;
      currentStats.videoRemoteCandidateType = currentStats.audioLocalCandidateType;
      currentStats.videoTransportType = currentStats.audioTransportType;
    }

    extendStats(currentStats, isPublisher);
    completion(null, currentStats);
  }, onStatsError);
};

///
// Get Stats using the newer API.
//
var parseStatsNewAPI = function parseStatsNewAPI(peerConnection,
                                                 prevStats,
                                                 currentStats,
                                                 isPublisher,
                                                 completion) {

  var onStatsError = function onStatsError(error) {
    completion(error);
  };

  var parseAudioStats = function(result) {
    if (result.type === 'outboundrtp') {
      currentStats.audioSentPackets = result.packetsSent;
      currentStats.audioSentPacketsLost = result.packetsLost;
      currentStats.audioSentBytes =  result.bytesSent;
    } else if (result.type === 'inboundrtp') {
      currentStats.audioRecvPackets = result.packetsReceived;
      currentStats.audioRecvPacketsLost = result.packetsLost;
      currentStats.audioRecvBytes =  result.bytesReceived;
    }
  };

  var parseVideoStats = function(result) {
    currentStats.videoFrameRate = Math.round(result.framerateMean);
    if (result.type === 'outboundrtp') {
      currentStats.videoSentPackets = result.packetsSent;
      currentStats.videoSentPacketsLost = result.packetsLost;
      currentStats.videoSentBytes =  result.bytesSent;
    } else if (result.type === 'inboundrtp') {
      currentStats.videoRecvPackets = result.packetsReceived;
      currentStats.videoRecvPacketsLost = result.packetsLost;
      currentStats.videoRecvBytes =  result.bytesReceived;
    }
  };

  peerConnection.getStats(null, function(stats) {
    var localCandidateType, remoteCandidateType, localAddress, remoteAddress, transportType, key;
    for (key in stats) {
      if (stats.hasOwnProperty(key)) {
        var res = stats[key];
        if (res.type === 'outboundrtp' || res.type === 'inboundrtp') {
          if (res.id.indexOf('rtp') !== -1) {
            if (res.id.indexOf('audio') !== -1) {
              parseAudioStats(res);
            } else if (res.id.indexOf('video') !== -1) {
              parseVideoStats(res);
            }
          }
          if (res.hasOwnProperty('mozRtt')) {
            // mozRtt comes on the rtcp stats and so won't be caught above
            if (res.mediaType === 'video') {
              currentStats.videoRtt = res.mozRtt;
            } else if (res.mediaType === 'audio') {
              currentStats.audioRtt = res.mozRtt;
            }
          }
        } else if (res.type === 'localcandidate') {
          localCandidateType = res.candidateType;
          localAddress = res.ipAddress + ':' + res.portNumber;
          transportType = res.transport;
        } else if (res.type === 'remotecandidate') {
          remoteCandidateType = res.candidateType;
          remoteAddress = res.ipAddress + ':' + res.portNumber;
        }
      }
    }

    if (currentStats.audioRecvBytes || currentStats.audioSentBytes) {
      currentStats.audioLocalCandidateType = localCandidateType;
      currentStats.audioLocalAddress = localAddress;
      currentStats.audioRemoteCandidateType = remoteCandidateType;
      currentStats.audioRemoteAddress = remoteAddress;
      currentStats.audioTransportType = transportType;
    }

    if (currentStats.videoRecvBytes || currentStats.videoSentBytes) {
      currentStats.videoLocalCandidateType = localCandidateType;
      currentStats.videoLocalAddress = localAddress;
      currentStats.videoRemoteCandidateType = remoteCandidateType;
      currentStats.videoRemoteAddress = remoteAddress;
      currentStats.videoTransportType = transportType;
    }

    extendStats(currentStats, isPublisher);
    completion(null, currentStats);
  }, onStatsError);
};

var parseQOS = function(peerConnection, prevStats, currentStats, isPublisher, completion) {
  if (OTPlugin.isInstalled()) {
    parseQOS = parseStatsOTPlugin;
    return parseStatsOTPlugin(peerConnection, prevStats, currentStats, isPublisher, completion);
  } else if (OTHelpers.env.name === 'Firefox' && OTHelpers.env.version >= 27) {
    parseQOS = parseStatsNewAPI;
    return parseStatsNewAPI(peerConnection, prevStats, currentStats, isPublisher, completion);
  }

  parseQOS = parseStatsOldAPI;
  return parseStatsOldAPI(peerConnection, prevStats, currentStats, isPublisher, completion);
};

var extendStats = function(stats, isPublisher) {
  var requiredKeys = isPublisher ? requiredPublisherKeys : requiredSubscriberKeys;
  requiredKeys.forEach(function(key) {
    if (!stats.hasOwnProperty(key)) {
      stats[key] = null;
    }
  });
};

var Qos = function(qosCallback, isPublisher) {
  var _peerConnection;
  var _creationTime = OTHelpers.now();

  var calculateQOS = function calculateQOS(prevStats, interval) {
    if (!_peerConnection) {
      // We don't have a PeerConnection yet, or we did and
      // it's been closed. Either way we're done.
      return;
    }

    var now = OTHelpers.now();

    var currentStats = {
      timeStamp: now,
      duration: Math.round((now - _creationTime) / 1000),
      period: Math.round((now - prevStats.timeStamp) / 1000)
    };

    var onParsedStats = function(err, parsedStats) {
      if (err) {
        logging.error('Failed to Parse QOS Stats: ' + JSON.stringify(err));
        return;
      }

      var periodicStats = OTHelpers.clone(currentStats);

      // The following stats are reported with cumulative values:
      var periodicDataKeys = ['audioSentBytes', 'audioSentPackets', 'audioSentPacketsLost',
        'videoSentBytes', 'videoSentPackets', 'videoSentPacketsLost', 'audioRecvBytes',
        'audioRecvPackets', 'audioRecvPacketsLost', 'videoRecvBytes', 'videoRecvPackets',
        'videoRecvPacketsLost'];

      // This adjusts the QoS values to be periodic, rather than cumulative:
      periodicDataKeys.forEach(function(dataKey) {
        periodicStats[dataKey] = prevStats && prevStats[dataKey] ?
          currentStats[dataKey] - prevStats[dataKey] :
          currentStats[dataKey];
      });

      qosCallback(periodicStats, prevStats);

      var nextInterval = interval === Qos.INITIAL_INTERVAL ?
        Qos.INTERVAL - Qos.INITIAL_INTERVAL :
        Qos.INTERVAL;

      // Recalculate the stats
      setTimeout(calculateQOS.bind(null, parsedStats, nextInterval),
        interval);
    };

    parseQOS(_peerConnection, prevStats, currentStats, isPublisher, onParsedStats);
  };

  this.startCollecting = function(peerConnection) {
    if (!peerConnection || !peerConnection.getStats) {
      // this peerConnection doesn't support getStats, bail
      return;
    }

    if (_peerConnection) {
      _peerConnection = peerConnection;
    } else {
      _peerConnection = peerConnection;
      calculateQOS({ timeStamp: OTHelpers.now() }, Qos.INITIAL_INTERVAL);
    }
  };

  this.stopCollecting = function() {
    _peerConnection = null;
  };
};

// Send stats after 1 sec
Qos.INITIAL_INTERVAL = 1000;
// Recalculate the stats every 30 sec
Qos.INTERVAL = 30000;

module.exports = Qos;
