'use strict';

// See http://www.w3.org/TR/2010/WD-html5-20101019/video.html#error-codes
var _videoErrorCodes = {};

// Checking for global.MediaError for IE compatibility, just so we don't throw
// exceptions when the script is included
if (global.MediaError) {
  _videoErrorCodes[global.MediaError.MEDIA_ERR_ABORTED] = 'The fetching process for the media ' +
    'resource was aborted by the user agent at the user\'s request.';
  _videoErrorCodes[global.MediaError.MEDIA_ERR_NETWORK] = 'A network error of some description ' +
    'caused the user agent to stop fetching the media resource, after the resource was ' +
    'established to be usable.';
  _videoErrorCodes[global.MediaError.MEDIA_ERR_DECODE] = 'An error of some description ' +
    'occurred while decoding the media resource, after the resource was established to be ' +
    ' usable.';
  _videoErrorCodes[global.MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED] = 'The media resource ' +
    'indicated by the src attribute was not suitable.';
}

module.exports = function videoElementErrorCodeToStr(errorCode) {
  return _videoErrorCodes[parseInt(errorCode, 10)] || 'An unknown error occurred.';
};
