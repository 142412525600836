'use strict';

module.exports = function calculateCapableSimulcastStreams(opt) {
  if (
    opt.browserName !== 'Chrome' ||
    opt.isScreenSharing ||
    opt.sessionInfo.p2pEnabled ||
    !opt.constraints.video
  ) {
    // We only support simulcast on Chrome, and when not using
    // screensharing.
    return 1;
  }

  // HD and above gets three streams. Otherwise they get 2.
  if (opt.videoDimensions.width > 640 && opt.videoDimensions.height > 480) {
    return 3;
  }

  return 2;
};
