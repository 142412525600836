'use strict';

var makeEverythingAttachToOTHelpers = require('./makeEverythingAttachToOTHelpers');

var util = exports;

// OT Helper Methods
//
// helpers.js                           <- the root file
// helpers/lib/{helper topic}.js        <- specialised helpers for specific tasks/topics
//                                          (i.e. video, dom, etc)
//
// @example Getting a DOM element by it's id
//  var element = OTHelpers('#domId');
//
//

/*jshint browser:true, smarttabs:true*/

// RegEx to detect CSS Id selectors
util.detectIdSelectors = /^#([\w-]*)$/;

util.isFunction = function(obj) {
  return !!obj && (obj.toString().indexOf('()') !== -1 ||
    Object.prototype.toString.call(obj) === '[object Function]');
};

util.isNone = function(obj) {
  return obj === void 0 || obj === null;
};

util.isObject = function(obj) {
  return obj === Object(obj);
};

util.isArray = Array.isArray;

util.isEmpty = function(obj) {
  if (obj === null || obj === void 0) {
    return true;
  }

  if (Array.isArray(obj) || typeof (obj) === 'string') {
    return obj.length === 0;
  }

  // Objects without enumerable owned properties are empty.
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
};

util.find = function(array, iter, ctx) {
  if (!util.isFunction(iter)) {
    throw new TypeError('iter must be a function');
  }

  var any = void 0;
  for (var idx = 0, count = array.length || 0; idx < count; ++idx) {
    if (idx in array) {
      if (iter.call(ctx, array[idx], idx)) {
        any = array[idx];
        break;
      }
    }
  }
  return any;
};

util.findIndex = function(array, iter, ctx) {
  if (!util.isFunction(iter)) {
    throw new TypeError('iter must be a function');
  }

  for (var i = 0, count = array.length || 0; i < count; ++i) {
    if (i in array && iter.call(ctx, array[i], i, array)) {
      return i;
    }
  }

  return -1;
};

// Extend a target object with the properties from one or
// more source objects
//
// @example:
//    dest = OTHelpers.extend(dest, source1, source2, source3);
//
util.extend = Object.assign || function(target /*, source1[, source2, ..., , sourceN]*/) {
  if (target === undefined || target === null) {
    throw new TypeError('Cannot convert first argument to object');
  }

  var to = Object(target);
  for (var i = 1; i < arguments.length; i++) {
    var nextSource = arguments[i];
    if (nextSource === undefined || nextSource === null) {
      continue;
    }
    nextSource = Object(nextSource);

    var keysArray = Object.keys(Object(nextSource));
    for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
      var nextKey = keysArray[nextIndex];
      var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
      if (desc !== undefined && desc.enumerable) {
        to[nextKey] = nextSource[nextKey];
      }
    }
  }

  return to;
};


// Ensures that the target object contains certain defaults.
//
// @example
//   var options = OTHelpers.defaults(options, {
//     loading: true     // loading by default
//   });
//
util.defaults = function(target /*, defaults1[, defaults2, ..., , defaultsN]*/) {
  if (target === undefined || target === null) {
    throw new TypeError('Cannot convert first argument to object');
  }

  var to = Object(target);
  for (var i = 1; i < arguments.length; i++) {
    var nextSource = arguments[i];
    if (nextSource === undefined || nextSource === null) {
      continue;
    }
    nextSource = Object(nextSource);

    var keysArray = Object.keys(Object(nextSource));
    for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
      var nextKey = keysArray[nextIndex];
      if (!to.hasOwnProperty(nextKey)) {
        var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
        if (desc !== undefined && desc.enumerable) {
          to[nextKey] = nextSource[nextKey];
        }
      }
    }
  }

  return to;
};

util.clone = function(obj) {
  if (!util.isObject(obj)) {
    return obj;
  }

  return Array.isArray(obj) ? obj.slice() : util.extend({}, obj);
};

// Returns the number of millisceonds since the the UNIX epoch, this is functionally
// equivalent to executing new Date().getTime().
//
// Where available, we use 'performance.now' which is more accurate and reliable,
// otherwise we default to new Date().getTime().
util.now = (function() {
  var performance = global.performance || {};
  var navigationStart;
  var now =  performance.now       ||
             performance.mozNow    ||
             performance.msNow     ||
             performance.oNow      ||
             performance.webkitNow;

  if (now) {
    now = now.bind(performance);
    navigationStart = performance.timing.navigationStart;

    return  function() { return navigationStart + now(); };
  }

  return function() { return new Date().getTime(); };
})();

var generatePropertyFunction = function(object, getter, setter) {
  if (getter && !setter) {
    return function() {
      return getter.call(object);
    };
  }

  if (getter && setter) {
    return function(value) {
      if (value !== void 0) {
        setter.call(object, value);
      }
      return getter.call(object);
    };
  }

  return function(value) {
    if (value !== void 0) {
      setter.call(object, value);
    }
  };
};

util.defineProperties = function(object, getterSetters) {
  Object.keys(getterSetters).forEach(function(key) {
    object[key] = generatePropertyFunction(object, getterSetters[key].get,
      getterSetters[key].set);
  });
};

makeEverythingAttachToOTHelpers(util);
