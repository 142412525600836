'use strict';

var Events = require('./events.js');
var OTHelpers = require('../common-js-helpers/OTHelpers.js');

module.exports = function Archive(id, name, status) {
  this.id = id;
  this.name = name;
  this.status = status;

  this._ = {};

  OTHelpers.eventing(this);

  // Mass update, called by Raptor.Dispatcher
  this._.update = function(attributes) {
    for (var key in attributes) {
      if (!attributes.hasOwnProperty(key)) {
        continue;
      }
      var oldValue = this[key];
      this[key] = attributes[key];

      var event = new Events.ArchiveUpdatedEvent(this, key, oldValue, this[key]);
      this.dispatchEvent(event);
    }
  }.bind(this);

  this.destroy = function() {};
};
