'use strict';

var makeEverythingAttachToOTHelpers = require('./makeEverythingAttachToOTHelpers');

var casting = {};
module.exports = casting;

casting.castToBoolean = function(value, defaultValue) {
  if (value === undefined) {
    return defaultValue;
  }

  return value === 'true' || value === true;
};

casting.roundFloat = function(value, places) {
  return Number(value.toFixed(places));
};

makeEverythingAttachToOTHelpers(casting);
