'use strict';

module.exports = function MediaStreamTrack(mediaStreamId, options, plugin) {
  var Proto = function MediaStreamTrack() {};
  var mediaStreamTrack = new Proto();

  mediaStreamTrack.id = options.id;
  mediaStreamTrack.kind = options.kind;
  mediaStreamTrack.label = options.label;
  mediaStreamTrack.streamId = mediaStreamId;

  var enabled = options.enabled === true || options.enabled === 'true';

  Object.defineProperty(mediaStreamTrack, 'enabled', {
    get: function() { return enabled; },
    set: function(newEnabledValue) {
      enabled = newEnabledValue === true || newEnabledValue === 'true';

      if (enabled) {
        plugin._.enableMediaStreamTrack(mediaStreamId, mediaStreamTrack.id);
      } else {
        plugin._.disableMediaStreamTrack(mediaStreamId, mediaStreamTrack.id);
      }
    }
  });

  // Deprecated: use .enabled property directly
  mediaStreamTrack.setEnabled = function(newEnabledValue) {
    mediaStreamTrack.enabled = newEnabledValue;
  };

  return mediaStreamTrack;
};
