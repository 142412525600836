'use strict';

// community modules
var assign = require('lodash.assign');
var OTHelpers = require('../common-js-helpers/OTHelpers.js');
var Promise = require('bluebird');
var uuid = require('uuid');

// local modules
var curryCallAsync = require('./curry_call_async.js');
var empiricDelay = require('./empiric_delay.js');
var EventEmitter = require('./event_emitter.js');
var logging = require('./logging.js');
var refCountBehaviour = require('./ref_count_behaviour.js');
var waitForReadySignal = require('./wait_for_ready_signal.js');

var PROXY_LOAD_TIMEOUT = 5000;

var objectTimeouts = {};

var clearGlobalCallback = function clearGlobalCallback(callbackId) {
  if (!callbackId) {
    return; // TODO: Is this really a valid call? Would it be better to throw?
  }

  if (objectTimeouts[callbackId]) {
    clearTimeout(objectTimeouts[callbackId]);
    objectTimeouts[callbackId] = null;
  }

  if (global[callbackId]) {
    try {
      delete global[callbackId];
    } catch (err) {
      global[callbackId] = void 0;
    }
  }
};

var waitOnGlobalCallback = function waitOnGlobalCallback(callbackId, completion) {
  objectTimeouts[callbackId] = setTimeout(function() {
    clearGlobalCallback(callbackId);
    completion('The object timed out while loading.');
  }, PROXY_LOAD_TIMEOUT);

  global[callbackId] = function() {
    clearGlobalCallback(callbackId);

    var args = Array.prototype.slice.call(arguments);
    args.unshift(null);
    completion.apply(null, args);
  };
};

var generateCallbackID = function generateCallbackID() {
  return 'OTPlugin_loaded_' + uuid().replace(/\-+/g, '');
};

var generateObjectHtml = function generateObjectHtml(callbackId, optionsParam) {
  var options = optionsParam || {};
  var objBits = [];

  var attrs = [
    'type="' + options.mimeType + '"',
    'id="' + callbackId + '_obj"',
    'tb_callback_id="' + callbackId + '"',
    'width="0" height="0"'
  ];

  var params = {
    userAgent: OTHelpers.env.userAgent.toLowerCase(),
    windowless: options.windowless,
    onload: callbackId
  };

  if (options.isVisible !== true) {
    attrs.push('visibility="hidden"');
  }

  objBits.push('<object ' + attrs.join(' ') + '>');

  for (var name in params) {
    if (params.hasOwnProperty(name)) {
      objBits.push('<param name="' + name + '" value="' + params[name] + '" />');
    }
  }

  objBits.push('</object>');
  return objBits.join('');
};

var createObject = function createObject(callbackId, optionsParam, completion) {
  var options = optionsParam || {};

  var html = generateObjectHtml(callbackId, options);
  var doc = options.doc || global.document;

  doc.body.insertAdjacentHTML('beforeend', html);
  var object = doc.body.querySelector('#' + callbackId + '_obj');

  completion(void 0, object);
};

// Reference counted wrapper for a plugin object
var createPluginProxy = function(options, completion) {
  var Proto = function PluginProxy() {};
  var pluginProxy = new Proto();

  pluginProxy.ready = Promise.defer(); // TODO: is this just a duplicate of readiness.isReady?

  assign(pluginProxy, EventEmitter());

  // Calling this will bind a listener to the devicesChanged events that
  // the plugin emits and then rebroadcast them.
  pluginProxy.listenForDeviceChanges = function() {
    pluginProxy.ready.promise.then(function() {
      return Promise.delay(empiricDelay);
    }).then(function() {
      pluginProxy._.registerXCallback('devicesChanged', function() {
        var args = Array.prototype.slice.call(arguments);
        logging.debug(args);
        pluginProxy.emit('devicesChanged', args);
      });
    });
  };

  refCountBehaviour(pluginProxy);

  // Assign +plugin+ to this object and setup all the public
  // accessors that relate to the DOM Object.
  //
  var setPlugin = function setPlugin(plugin) {
    if (plugin) {
      pluginProxy._ = plugin;
      pluginProxy.parentElement = plugin.parentElement;
      pluginProxy.OTHelpers = OTHelpers(plugin);
    } else {
      pluginProxy._ = null;
      pluginProxy.parentElement = null;
      pluginProxy.OTHelpers = OTHelpers();
    }
  };

  pluginProxy.uuid = generateCallbackID();

  pluginProxy.isValid = function() {
    return pluginProxy._.valid;
  };

  pluginProxy.destroy = function() {
    pluginProxy.removeAllRefs();
    setPlugin(null);

    // Let listeners know that they should do any final book keeping
    // that relates to us.
    pluginProxy.emit('destroy');
  };

  pluginProxy.enumerateDevices = function(completion) {
    pluginProxy._.enumerateDevices(completion);
  };

  // Initialise

  // The next statement creates the raw plugin object accessor on the Proxy.
  // This is null until we actually have created the Object.
  setPlugin(null);

  waitOnGlobalCallback(pluginProxy.uuid, function(err) {
    if (err) {
      completion('The plugin with the mimeType of ' +
                      options.mimeType + ' timed out while loading: ' + err);

      pluginProxy.destroy();
      return;
    }

    pluginProxy._.setAttribute('id', 'tb_plugin_' + pluginProxy._.uuid);
    pluginProxy._.removeAttribute('tb_callback_id');
    pluginProxy.uuid = pluginProxy._.uuid;
    pluginProxy.id = pluginProxy._.id;

    // TODO: This guard shouldn't be necessary, and if it is we should throw an error if it's not
    // there.
    if (pluginProxy._.on) {
      // If the plugin supports custom events we'll use them
      pluginProxy._.on(-1, {
        customEvent: curryCallAsync(function() {
          var args = Array.prototype.slice.call(arguments);
          pluginProxy.emit(args.shift(), args);
        })
      });
    }

    waitForReadySignal(pluginProxy, function(err) {
      if (err) {
        completion('Error while starting up plugin ' + pluginProxy.uuid + ': ' + err);
        pluginProxy.destroy();
        return;
      }

      completion(void 0, pluginProxy);
    });
  });

  createObject(pluginProxy.uuid, options, function(err, plugin) {
    if (err) {
      logging.error(err); // TODO: would it be better to throw?
      return;
    }

    setPlugin(plugin);
  });

  // TODO: Why is there both a completion handler and a return value?
  return pluginProxy;
};

// Specialisation for the MediaCapturer API surface
var makeMediaCapturerProxy = function makeMediaCapturerProxy(mediaCapturer) {
  mediaCapturer.selectSources = function() {
    return mediaCapturer._.selectSources.apply(mediaCapturer._, arguments);
  };

  mediaCapturer.listenForDeviceChanges();
  return mediaCapturer;
};

// Specialisation for the MediaPeer API surface
var makeMediaPeerProxy = function makeMediaPeerProxy(mediaPeer) {
  mediaPeer.setStream = function(stream, completion) {
    mediaPeer._.setStream(
      stream,
      mediaPeer._.msMatchesSelector('.OT_publisher.OT_mirrored object')
    );

    if (completion) {
      // TODO Investigate whether there is a good way to detect
      // when the media is flowing

      // This fires a little too soon.
      setTimeout(completion, 200);
    }

    return mediaPeer;
  };

  return mediaPeer;
};

module.exports = {
  createPluginProxy: createPluginProxy,
  makeMediaPeerProxy: makeMediaPeerProxy,
  makeMediaCapturerProxy: makeMediaCapturerProxy
};
