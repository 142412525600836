'use strict';

var createLinearResolver = require('./create_linear_resolver.js');
var memoize = require('lodash.memoize');
var requestCompressor = require('./request_compressor.js');
var requestSerializer = require('./request_serializer.js');

// Takes a function of the form function(input?) => promise (one optional argument and returns a
// promise) and returns a function of the same form which prevents concurrent requests by combining
// requestCompressor and requestSerializer. When concurrent requests are made, they will be
// compressed when the input is the same, and serialized when the input is different.

module.exports = function(request) {
  var serializedRequest = requestSerializer(request);

  var rawGetCompressedRequest = function(input) {
    return requestCompressor(function() {
      return serializedRequest(input);
    });
  };

  // This is an uncommon way to use memoize. It's not being done to improve the performance of
  // rawGetCompressedRequest* but to lazily create a compressedRequest for each input and recall the
  // existing compressedRequest when that input is used again later.
  // * (there is a broader performance goal of reducing requests here though)
  //
  // Another way to think of this is that a pure function is like a map, and memoize is usually used
  // to speed up a pure function while preserving behaviour. Here a not-pure function becomes pure
  // and like a map by changing its behaviour. rawGetCompressedRequest is not pure because it
  // generates a new compressedRequest instance each time even if the input is the same. memoize is
  // used to make it pure, so that it is like a map that always returns the same compressedRequest
  // for each input.
  //
  // (The verb form of request is being used here, not the noun form.)
  //
  var getCompressedRequest = memoize(rawGetCompressedRequest, createLinearResolver());

  return function(input) {
    var compressedRequest = getCompressedRequest(input);
    return compressedRequest();
  };
};
