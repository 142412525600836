'use strict';

var logging = require('./logging.js');
var OTHelpers = require('../common-js-helpers/OTHelpers.js');

module.exports = function VideoContainer(plugin, stream) {
  var Proto = function VideoContainer() {};
  var videoContainer = new Proto();
  var audioInputLevelMonitoringEnabled = false;

  videoContainer.domElement = plugin._;
  videoContainer.OTHelpers = OTHelpers(plugin._);
  videoContainer.parentElement = plugin._.parentNode;

  plugin.addRef(videoContainer);

  videoContainer.appendTo = function(parentDomElement) {
    if (parentDomElement && plugin._.parentNode !== parentDomElement) {
      logging.debug('VideoContainer appendTo', parentDomElement);
      parentDomElement.appendChild(plugin._);
      videoContainer.parentElement = parentDomElement;
    }
  };

  videoContainer.show = function(completion) {
    logging.debug('VideoContainer show');
    plugin._.removeAttribute('width');
    plugin._.removeAttribute('height');
    plugin.setStream(stream, completion);
    OTHelpers.show(plugin._);
    return videoContainer;
  };

  videoContainer.setSize = function(width, height) {
    plugin._.setAttribute('width', width);
    plugin._.setAttribute('height', height);
    return videoContainer;
  };

  videoContainer.getAudioInputLevel = function() {
    // we don't need to expose a mechanism to enable / disable the monitoring
    // so we use this opportunity the keep the API simple
    if (!audioInputLevelMonitoringEnabled) {
      plugin._.enableVolumeLevelMonitoring(true);
      audioInputLevelMonitoringEnabled = true;
    }
    return plugin._.getInputLevel();
  };

  videoContainer.setFitMode = function(fitMode) {
    plugin._.fitMode(fitMode);
  };

  videoContainer.width = function(newWidth) {
    if (newWidth !== void 0) {
      logging.debug('VideoContainer set width to ' + newWidth);
      plugin._.setAttribute('width', newWidth);
    }

    return plugin._.getAttribute('width');
  };

  videoContainer.height = function(newHeight) {
    if (newHeight !== void 0) {
      logging.debug('VideoContainer set height to ' + newHeight);
      plugin._.setAttribute('height', newHeight);
    }

    return plugin._.getAttribute('height');
  };

  videoContainer.volume = function(newVolume) {
    if (newVolume !== void 0) {
      // TODO
      logging.debug('VideoContainer setVolume not implemented: called with ' + newVolume);
    } else {
      logging.debug('VideoContainer getVolume not implemented');
    }

    return 0.5;
  };

  videoContainer.getImgData = function() {
    return plugin._.getImgData('image/png');
  };

  videoContainer.videoWidth = function() {
    return plugin._.videoWidth;
  };

  videoContainer.videoHeight = function() {
    return plugin._.videoHeight;
  };

  videoContainer.destroy = function() {
    plugin._.setStream(null);
    plugin.removeRef(videoContainer);
  };

  return videoContainer;
};
