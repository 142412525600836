'use strict';

var once = require('lodash.once');

var env = require('../common-js-helpers/env.js');
var hasGetUserMediaCapability = require('./hasGetUserMediaCapability.js');
var hasPeerConnectionCapability = require('./hasPeerConnectionCapability.js');
var logging = require('../ot/logging.js');
var properties = require('./properties.js');

// Indicates whether this client supports WebRTC
//
// This is defined as: getUserMedia + PeerConnection + exceeds min browser version
//
module.exports = once(function() {
  if (properties) {
    var minimumVersions = properties.minimumVersion || {};
    var minimumVersion = minimumVersions[env.name.toLowerCase()];

    if (minimumVersion && env.versionGreaterThan(minimumVersion)) {
      logging.debug('Support for', env.name, 'is disabled because we require',
        minimumVersion, 'but this is', env.version);
      return false;
    }
  }

  if (env.name === 'Node') {
    // Node works, even though it doesn't have getUserMedia
    return true;
  }

  return hasGetUserMediaCapability() && hasPeerConnectionCapability();
});
