'use strict';

var createSingleElementContainer = require('./create_single_element_container.js');
var curryCallAsync = require('../../curry_call_async.js');
var once = require('lodash.once');
var Promise = require('bluebird');
var requestManager = require('./request_manager.js');
var RTCStatsReport = require('../../rtc/rtc_stats_report.js');

// The plugin exposes an inflexible and non-standard version of getStats that does not allow
// concurrent calls and gives results by calling a success or error callback that can only be
// registered once via registerXCallback (you can't provide new callbacks for each call). JS
// code that simulates this part of the plugin behaviour can be found in tests/mocks.
//
// The purpose of this adaptor is to transform the getStats provided by the plugin to one that has
// no restrictions on concurrency and returns a promise. Concurrent getStats for the same track are
// compressed into a single request to the plugin's getStats and concurrent getStats for different
// tracks are serialized so that the plugin doesn't receive a getStats call until it has fulfilled
// the previous one.
//
// To be more in line with webrtc (and preserve the previous api otplugin.js exposed), there is
// another thin wrapper around this adaptor which takes success and error callbacks instead of
// returning a promise.

module.exports = function(pluginElement, id) {
  var callbacks = createSingleElementContainer();

  var registerXCallbacks = once(function() {
    pluginElement.registerXCallback('getStatsSuccess', curryCallAsync(function(statsReportJson) {
      callbacks.pop().success(statsReportJson);
    }));

    pluginElement.registerXCallback('getStatsError', curryCallAsync(function(err) {
      callbacks.pop().error(err);
    }));
  });

  return requestManager(function(mediaStreamTrack) {
    return new Promise(function(resolve, reject) {
      registerXCallbacks();

      callbacks.push({
        success: function(statsReportJson) {
          resolve(new RTCStatsReport(JSON.parse(statsReportJson)));
        },
        error: reject
      });

      pluginElement.getStats(id, mediaStreamTrack);
    });
  });
};
