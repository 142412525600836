'use strict';

var createPeerConnection = require('../../helpers/create_peer_connection.js');
var logging = require('../logging.js');
var getStatsAdpater = require('../peer_connection/get_stats_adapter.js');
var getStatsHelpers = require('../peer_connection/get_stats_helpers.js');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');
var OTPlugin = require('../../otplugin/otplugin.js');
var VideoElementFacade = require('../../helpers/video_element/index.js');

/**
 * @returns {Promise.<{packetLostRation: number, roundTripTime: number}>}
 */
module.exports = function webrtcTest(config) {

  var _getStats = getStatsAdpater();
  var _mediaConfig = config.mediaConfig;
  var _localStream = config.localStream;

  // todo copied from peer_connection.js
  // Normalise these
  // var NativeRTCSessionDescription;
  var NativeRTCIceCandidate;
  if (!OTPlugin.isInstalled()) {
    // order is very important: 'RTCSessionDescription' defined in Firefox Nighly but useless
    // NativeRTCSessionDescription = (global.mozRTCSessionDescription ||
    //   global.RTCSessionDescription);
    NativeRTCIceCandidate = (global.RTCIceCandidate || global.mozRTCIceCandidate);
  } else {
    // NativeRTCSessionDescription = OTPlugin.RTCSessionDescription;
    NativeRTCIceCandidate = OTPlugin.RTCIceCandidate;
  }

  function isCandidateRelay(candidate) {
    return candidate.candidate.indexOf('relay') !== -1;
  }

  /**
   * Create video a element attaches it to the body and put it visually outside the body.
   *
   * @returns {VideoElementFacade}
   */
  function createVideoElementForTest() {
    var videoElement = new VideoElementFacade({ attributes: { muted: true } });
    videoElement.domElement().style.position = 'absolute';
    videoElement.domElement().style.top = '-9999%';
    videoElement.appendTo(document.body);
    return videoElement;
  }

  function createPeerConnectionForTest() {
    return new OTHelpers.RSVP.Promise(function(resolve, reject) {
      createPeerConnection({
        iceServers: _mediaConfig.iceServers
      }, {},
        null,
        function(error, pc) {
          if (error) {
            reject(new OTHelpers.Error('createPeerConnection failed', 1600, error));
          } else {
            resolve(pc);
          }
        }
      );
    });
  }

  function createOffer(pc) {
    return new OTHelpers.RSVP.Promise(function(resolve, reject) {
      pc.createOffer(resolve, reject);
    });
  }

  function attachMediaStream(videoElement, webRtcStream) {
    return new OTHelpers.RSVP.Promise(function(resolve, reject) {
      videoElement.bindToStream(webRtcStream, function(error) {
        if (error) {
          reject(new OTHelpers.Error('bindToStream failed', 1600, error));
        } else {
          resolve();
        }
      });
    });
  }

  function addIceCandidate(pc, candidate) {
    return new OTHelpers.RSVP.Promise(function(resolve, reject) {
      pc.addIceCandidate(new NativeRTCIceCandidate({
        sdpMLineIndex: candidate.sdpMLineIndex,
        candidate: candidate.candidate
      }), resolve, reject);
    });
  }

  function setLocalDescription(pc, offer) {
    return new OTHelpers.RSVP.Promise(function(resolve, reject) {
      pc.setLocalDescription(offer, resolve, function(error) {
        reject(new OTHelpers.Error('setLocalDescription failed', 1600, error));
      });
    });
  }

  function setRemoteDescription(pc, offer) {
    return new OTHelpers.RSVP.Promise(function(resolve, reject) {
      pc.setRemoteDescription(offer, resolve, function(error) {
        reject(new OTHelpers.Error('setRemoteDescription failed', 1600, error));
      });
    });
  }

  function createAnswer(pc) {
    return new OTHelpers.RSVP.Promise(function(resolve, reject) {
      pc.createAnswer(resolve, function(error) {
        reject(new OTHelpers.Error('createAnswer failed', 1600, error));
      });
    });
  }

  function getStats(pc) {
    return new OTHelpers.RSVP.Promise(function(resolve, reject) {
      _getStats(pc, function(error, stats) {
        if (error) {
          reject(new OTHelpers.Error('geStats failed', 1600, error));
        } else {
          resolve(stats);
        }
      });
    });
  }

  function createOnIceCandidateListener(pc) {
    return function(event) {
      if (event.candidate && isCandidateRelay(event.candidate)) {
        addIceCandidate(pc, event.candidate).catch(function() {
          logging.warn('An error occurred while adding a ICE candidate during webrtc test');
        });
      }
    };
  }

  /**
   * @param {{videoBytesReceived: number, audioBytesReceived: number, startTs: number}} statsSamples
   * @returns {number} the bandwidth in bits per second
   */
  function calculateBandwidth(statsSamples) {
    return (((statsSamples.videoBytesReceived + statsSamples.audioBytesReceived) * 8) /
      (OTHelpers.now() - statsSamples.startTs)) * 1000;
  }

  /**
   * @returns {Promise.<{packetLostRation: number, roundTripTime: number}>}
   */
  function collectPeerConnectionStats(localPc, remotePc) {

    var SAMPLING_DELAY = 1000;

    return new OTHelpers.RSVP.Promise(function(resolve) {

      var collectionActive = true;

      var _statsSamples = {
        startTs: OTHelpers.now(),
        packetLostRatioSamplesCount: 0,
        packetLostRatio: 0,
        roundTripTimeSamplesCount: 0,
        roundTripTime: 0,
        videoBytesReceived: 0,
        audioBytesReceived: 0
      };

      function sample() {

        OTHelpers.RSVP.Promise.all([
          getStats(localPc).then(function(stats) {
            stats.forEach(function(stat) {
              if (getStatsHelpers.isVideoStat(stat)) {
                var rtt = null;

                if (stat.hasOwnProperty('googRtt')) {
                  rtt = parseInt(stat.googRtt, 10);
                } else if (stat.hasOwnProperty('mozRtt')) {
                  rtt = stat.mozRtt;
                }

                if (rtt !== null && rtt > -1) {
                  _statsSamples.roundTripTimeSamplesCount++;
                  _statsSamples.roundTripTime += rtt;
                }
              }
            });
          }),

          getStats(remotePc).then(function(stats) {
            stats.forEach(function(stat) {
              if (getStatsHelpers.isVideoStat(stat)) {
                if (stat.hasOwnProperty('packetsReceived') &&
                  stat.hasOwnProperty('packetsLost')) {

                  var packetLost = parseInt(stat.packetsLost, 10);
                  var packetsReceived = parseInt(stat.packetsReceived, 10);
                  if (packetLost >= 0 && packetsReceived > 0) {
                    _statsSamples.packetLostRatioSamplesCount++;
                    _statsSamples.packetLostRatio += packetLost * 100 / packetsReceived;
                  }
                }

                if (stat.hasOwnProperty('bytesReceived')) {
                  _statsSamples.videoBytesReceived = parseInt(stat.bytesReceived, 10);
                }
              } else if (getStatsHelpers.isAudioStat(stat)) {
                if (stat.hasOwnProperty('bytesReceived')) {
                  _statsSamples.audioBytesReceived = parseInt(stat.bytesReceived, 10);
                }
              }
            });
          })
        ])
          .then(function() {
            // wait and trigger another round of collection
            setTimeout(function() {
              if (collectionActive) {
                sample();
              }
            }, SAMPLING_DELAY);
          });
      }

      // start the sampling "loop"
      sample();

      /**
       * @param {boolean} extended marks the test results as extended
       */
      function stopCollectStats(extended) {
        collectionActive = false;

        var pcStats = {
          packetLostRatio: _statsSamples.packetLostRatioSamplesCount > 0 ?
            _statsSamples.packetLostRatio /= _statsSamples.packetLostRatioSamplesCount * 100 : null,
          roundTripTime: _statsSamples.roundTripTimeSamplesCount > 0 ?
            _statsSamples.roundTripTime /= _statsSamples.roundTripTimeSamplesCount : null,
          bandwidth: calculateBandwidth(_statsSamples),
          extended: extended
        };

        resolve(pcStats);
      }

      // sample for the nominal delay
      // if the bandwidth is bellow the threshold at the end we give an extra time
      setTimeout(function() {

        if (calculateBandwidth(_statsSamples) < _mediaConfig.thresholdBitsPerSecond) {
          // give an extra delay in case it was transient bandwidth problem
          setTimeout(function() {
            stopCollectStats(true);
          }, _mediaConfig.extendedDuration * 1000);
        } else {
          stopCollectStats(false);
        }

      }, _mediaConfig.duration * 1000);
    });
  }

  return OTHelpers.RSVP.Promise
    .all([createPeerConnectionForTest(), createPeerConnectionForTest()])
    .then(function(pcs) {

      var localPc = pcs[0];
      var remotePc = pcs[1];
      var localVideo = createVideoElementForTest();
      var remoteVideo = createVideoElementForTest();

      attachMediaStream(localVideo, _localStream);
      localPc.addStream(_localStream);

      var remoteStream;
      remotePc.onaddstream = function(evt) {
        remoteStream = evt.stream;
        attachMediaStream(remoteVideo, remoteStream);
      };

      localPc.onicecandidate = createOnIceCandidateListener(remotePc);
      remotePc.onicecandidate = createOnIceCandidateListener(localPc);

      function dispose() {
        localVideo.destroy();
        remoteVideo.destroy();
        localPc.close();
        remotePc.close();
      }

      return createOffer(localPc)
        .then(function(offer) {
          return OTHelpers.RSVP.Promise.all([
            setLocalDescription(localPc, offer),
            setRemoteDescription(remotePc, offer)
          ]);
        })
        .then(function() {
          return createAnswer(remotePc);
        })
        .then(function(answer) {
          return OTHelpers.RSVP.Promise.all([
            setLocalDescription(remotePc, answer),
            setRemoteDescription(localPc, answer)
          ]);
        })
        .then(function() {
          return collectPeerConnectionStats(localPc, remotePc);
        })
        .then(function(value) {
          dispose();
          return value;
        }, function(error) {
          dispose();
          throw error;
        });
    });
};
