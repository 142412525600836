'use strict';

var OTHelpers = require('../common-js-helpers/OTHelpers.js');

module.exports = function MediaConstraints(userConstraints) {
  var constraints = OTHelpers.clone(userConstraints);

  this.hasVideo = constraints.video !== void 0 && constraints.video !== false;
  this.hasAudio = constraints.audio !== void 0 && constraints.audio !== false;

  if (constraints.video === true) {
    constraints.video = {};
  }

  if (constraints.audio === true) {
    constraints.audio = {};
  }

  if (this.hasVideo && !constraints.video.mandatory) {
    constraints.video.mandatory = {};
  }

  if (this.hasAudio && !constraints.audio.mandatory) {
    constraints.audio.mandatory = {};
  }

  this.screenSharing = this.hasVideo &&
                (constraints.video.mandatory.chromeMediaSource === 'screen' ||
                 constraints.video.mandatory.chromeMediaSource === 'window');

  this.audio = constraints.audio;
  this.video = constraints.video;

  this.setVideoSource = function(sourceId) {
    if (sourceId !== void 0) {
      constraints.video.mandatory.sourceId =  sourceId;
    } else {
      delete constraints.video;
    }
  };

  this.setAudioSource = function(sourceId) {
    if (sourceId !== void 0) {
      constraints.audio.mandatory.sourceId =  sourceId;
    } else {
      delete constraints.audio;
    }
  };

  this.toHash = function() {
    return constraints;
  };
};
