'use strict';

var bluebird = require('bluebird');
var uuid = require('uuid');

var curryCallAsync = require('../curry_call_async.js');
var createGetStatsAdaptor = require('./create_get_stats_adaptor/create_get_stats_adaptor.js');
var empiricDelay = require('../empiric_delay.js');
var logging = require('../logging.js');
var MediaStream = require('../media_stream');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');
var RTCIceCandidate = require('../rtc/rtc_ice_candidate.js');
var RTCSessionDescription = require('../rtc/rtc_session_description.js');

// Our RTCPeerConnection shim, it should look like a normal PeerConection
// from the outside, but it actually delegates to our plugin.
//
var PeerConnection = function(iceServers, options, plugin, readyParam) {
  var Proto = function PeerConnection() {};
  var peerConnection = new Proto();
  var id = uuid();
  var candidates = [];
  var inited = false;
  var deferMethods = [];
  var ready = curryCallAsync(readyParam);
  var events, streamAdded;

  plugin.addRef(peerConnection);

  events = {
    addstream: [],
    removestream: [],
    icecandidate: [],
    signalingstatechange: [],
    iceconnectionstatechange: []
  };

  var onAddIceCandidate = function onAddIceCandidate() {/* success */};

  var canAddCandidate = function canAddCandidate() {
    return peerConnection.localDescription && peerConnection.remoteDescription;
  };

  var onAddIceCandidateFailed = function onAddIceCandidateFailed(err) {
    logging.error('Failed to process candidate');
    logging.error(err);
  };

  var maybeProcessPendingCandidates = function maybeProcessPendingCandidates() {
    if (canAddCandidate()) {
      for (var i = 0; i < candidates.length; ++i) {
        plugin._.addIceCandidate(id, candidates[i], onAddIceCandidate, onAddIceCandidateFailed);
      }
    }
  };

  var deferMethod = function deferMethod(method) {
    return function() {
      if (inited === true) {
        return method.apply(peerConnection, arguments);
      }

      deferMethods.push([method, arguments]);

      // FIXME: Adding the following line to satisfy consistent-return, but this needs to be fixed
      // properly - probably shouldn't return a value above.
      return undefined;
    };
  };

  var processDeferredMethods = function processDeferredMethods() {
    var m;
    while ((m = deferMethods.shift())) {
      m[0].apply(peerConnection, m[1]);
    }
  };

  var triggerEvent = function triggerEvent(/* eventName [, arg1, arg2, ..., argN] */) {
    var args = Array.prototype.slice.call(arguments);
    var eventName = args.shift();

    if (!events.hasOwnProperty(eventName)) {
      logging.error('PeerConnection does not have an event called: ' + eventName);
      return;
    }

    events[eventName].forEach(function(listener) {
      listener.apply(null, args);
    });
  };

  var bindAndDelegateEvents = function bindAndDelegateEvents(events) {
    for (var name in events) {
      if (events.hasOwnProperty(name)) {
        events[name] = curryCallAsync(events[name]);
      }
    }

    plugin._.on(id, events);
  };

  var addStream = function addStream(streamJson) {
    setTimeout(function() {
      var stream = MediaStream.fromJson(streamJson, plugin);
      var event = { stream: stream, target: peerConnection };

      if (peerConnection.onaddstream && OTHelpers.isFunction(peerConnection.onaddstream)) {
        OTHelpers.callAsync(peerConnection.onaddstream, event);
      }

      triggerEvent('addstream', event);
    }, empiricDelay);
  };

  var removeStream = function removeStream(streamJson) {
    var stream = MediaStream.fromJson(streamJson, plugin);
    var event = { stream: stream, target: peerConnection };

    if (peerConnection.onremovestream && OTHelpers.isFunction(peerConnection.onremovestream)) {
      OTHelpers.callAsync(peerConnection.onremovestream, event);
    }

    triggerEvent('removestream', event);
  };

  var iceCandidate = function iceCandidate(candidateSdp, sdpMid, sdpMLineIndex) {
    var candidate = new RTCIceCandidate({
      candidate: candidateSdp,
      sdpMid: sdpMid,
      sdpMLineIndex: sdpMLineIndex
    });

    var event = { candidate: candidate, target: peerConnection };

    if (peerConnection.onicecandidate && OTHelpers.isFunction(peerConnection.onicecandidate)) {
      OTHelpers.callAsync(peerConnection.onicecandidate, event);
    }

    triggerEvent('icecandidate', event);
  };

  var signalingStateChange = function signalingStateChange(state) {
    peerConnection.signalingState = state;
    var event = { state: state, target: peerConnection };

    if (peerConnection.onsignalingstatechange &&
            OTHelpers.isFunction(peerConnection.onsignalingstatechange)) {
      OTHelpers.callAsync(peerConnection.onsignalingstatechange, event);
    }

    triggerEvent('signalingstate', event);
  };

  var iceConnectionChange = function iceConnectionChange(state) {
    peerConnection.iceConnectionState = state;
    var event = { state: state, target: peerConnection };

    if (peerConnection.oniceconnectionstatechange &&
            OTHelpers.isFunction(peerConnection.oniceconnectionstatechange)) {
      OTHelpers.callAsync(peerConnection.oniceconnectionstatechange, event);
    }

    triggerEvent('iceconnectionstatechange', event);
  };

  peerConnection.createOffer = deferMethod(function(success, error, constraints) {
    logging.debug('createOffer', constraints);
    plugin._.createOffer(id, function(type, sdp) {
      success(new RTCSessionDescription({
        type: type,
        sdp: sdp
      }));
    }, error, constraints || {});
  });

  peerConnection.createAnswer = deferMethod(function(success, error, constraints) {
    logging.debug('createAnswer', constraints);
    plugin._.createAnswer(id, function(type, sdp) {
      success(new RTCSessionDescription({
        type: type,
        sdp: sdp
      }));
    }, error, constraints || {});
  });

  peerConnection.setLocalDescription = deferMethod(function(description, success, error) {
    logging.debug('setLocalDescription');

    plugin._.setLocalDescription(id, description, function() {
      peerConnection.localDescription = description;

      if (success) {
        success.call(null);
      }

      maybeProcessPendingCandidates();
    }, error);
  });

  peerConnection.setRemoteDescription = deferMethod(function(description, success, error) {
    logging.debug('setRemoteDescription');

    plugin._.setRemoteDescription(id, description, function() {
      peerConnection.remoteDescription = description;

      if (success) {
        success.call(null);
      }

      maybeProcessPendingCandidates();
    }, error);
  });

  peerConnection.addIceCandidate = deferMethod(function(candidate) {
    logging.debug('addIceCandidate');

    if (canAddCandidate()) {
      plugin._.addIceCandidate(id, candidate, onAddIceCandidate, onAddIceCandidateFailed);
    } else {
      candidates.push(candidate);
    }
  });

  peerConnection.addStream = deferMethod(function(stream) {
    var constraints = {};
    plugin._.addStream(id, stream, constraints);
  });

  peerConnection.removeStream = deferMethod(function(stream) {
    plugin._.removeStream(id, stream);
  });

  peerConnection.getRemoteStreams = function() {
    var remoteStreams = plugin._.getRemoteStreams(id);
    if (remoteStreams) {
      return remoteStreams.map(function(stream) {
        return MediaStream.fromJson(stream, plugin);
      });
    }
    return [];
  };

  peerConnection.getLocalStreams = function() {
    return plugin._.getLocalStreams(id).map(function(stream) {
      return MediaStream.fromJson(stream, plugin);
    });
  };

  peerConnection.getStreamById = function(streamId) {
    return MediaStream.fromJson(plugin._.getStreamById(id, streamId), plugin);
  };

  var adaptedGetStats = createGetStatsAdaptor(plugin._, id);

  peerConnection.getStats = deferMethod(function(mediaStreamTrack, success, error) {
    streamAdded.then(function() {
      var getStatsPromise = adaptedGetStats(mediaStreamTrack);

      getStatsPromise.then(success);
      getStatsPromise.catch(error);
    });
  });

  peerConnection.close = function() {
    plugin._.destroyPeerConnection(id);
    plugin.removeRef(this);
  };

  peerConnection.destroy = function() {
    peerConnection.close();
  };

  peerConnection.addEventListener = function(event, handler /* [, useCapture] we ignore this */) {
    if (events[event] === void 0) {
      return;
    }

    events[event].push(handler);
  };

  peerConnection.removeEventListener = function(
    event,
    handler /* [, useCapture] we ignore this */
  ) {
    if (events[event] === void 0) {
      return;
    }

    events[event] = events[event].filter(function(fn) {
      return fn !== handler;
    });
  };

  // These should appear to be null, instead of undefined, if no
  // callbacks are assigned. This more closely matches how the native
  // objects appear and allows 'if (pc.onsignalingstatechange)' type
  // feature detection to work.
  peerConnection.onaddstream = null;
  peerConnection.onremovestream = null;
  peerConnection.onicecandidate = null;
  peerConnection.onsignalingstatechange = null;
  peerConnection.oniceconnectionstatechange = null;

  // Both username and credential must exist, otherwise the plugin throws an error
  iceServers.iceServers.forEach(function(iceServer) {
    if (!iceServer.username) {
      iceServer.username = '';
    }
    if (!iceServer.credential) {
      iceServer.credential = '';
    }
  });

  if (!plugin._.initPeerConnection(id, iceServers, options)) {
    ready(new OTHelpers.Error('Failed to initialise PeerConnection'));
    return undefined;
  }

  // This will make sense once init becomes async
  bindAndDelegateEvents({
    addStream: addStream,
    removeStream: removeStream,
    iceCandidate: iceCandidate,
    signalingStateChange: signalingStateChange,
    iceConnectionChange: iceConnectionChange
  });

  streamAdded = new bluebird.Promise(function(resolve) {
    if (plugin._.getLocalStreams(id).length > 0) {
      resolve();
    } else {
      peerConnection.addEventListener('addstream', function() {
        setTimeout(function() {
          resolve();
        }, 200);
      });
    }
  });

  inited = true;
  processDeferredMethods();
  ready(void 0, peerConnection);

  return peerConnection;
};

PeerConnection.create = function(iceServers, options, plugin, ready) {
  var pc = new PeerConnection(iceServers, options, plugin, function(err) {
    if (err) {
      ready(err);
    } else {
      ready(void 0, pc);
    }
  });
};

module.exports = PeerConnection;
