'use strict';

var logging = require('../../logging');

module.exports = function Event() {
  return function(type, cancelable) {
    this.type = type;
    this.cancelable = cancelable !== undefined ? cancelable : true;

    var _defaultPrevented = false;

    this.preventDefault = function() {
      if (this.cancelable) {
        _defaultPrevented = true;
      } else {
        logging.warn('Event.preventDefault :: Trying to preventDefault ' +
          'on an Event that isn\'t cancelable');
      }
    };

    this.isDefaultPrevented = function() {
      return _defaultPrevented;
    };
  };
};
