'use strict';

var once = require('lodash.once');

var OTPlugin = require('../otplugin/otplugin.js');

var env = require('../common-js-helpers/env.js');
var hasOpenTokSupport = require('./hasOpenTokSupport.js');

// TODO Remove all transport stuff, that belongs to the messaging layer not the Media layer.
// Indicates if the browser supports bundle
//
// Broadly:
// * Firefox doesn't support bundle
// * Chrome support bundle
// * OT Plugin supports bundle
// * We assume NodeJs supports bundle (e.g. 'you're on your own' mode)
//
module.exports = once(function() {
  return hasOpenTokSupport() && (
    env.name === 'Chrome' ||
    env.name === 'Node' ||
    OTPlugin.isInstalled()
  );
});
