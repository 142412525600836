'use strict';

module.exports = function curryCallAsync(fn) {
  return function() {
    var args = arguments;

    setTimeout(function() {
      fn.apply(undefined, args);
    });
  };
};
