'use strict';
var util = require('../util');

function toJson(object) {
  try {
    return JSON.stringify(object);
  } catch (e) {
    return object.toString();
  }
}

module.exports = function toDebugStringIE9(object) {
  var components = [];

  if (typeof object === 'undefined') {
    // noop
  } else if (object === null) {
    components.push('NULL');
  } else if (Array.isArray(object)) {
    for (var i = 0; i < object.length; ++i) {
      components.push(toJson(object[i]));
    }
  } else if (util.isObject(object)) {
    Object.keys(object).forEach(function(key) {
      var stringValue;

      if (!util.isFunction(object[key])) {
        stringValue = toJson(object[key]);
      } else {
        stringValue = 'function ' + key + '()';
      }

      components.push(key + ': ' + stringValue);
    });
  } else if (util.isFunction(object)) {
    try {
      components.push(object.toString());
    } catch (e) {
      components.push('function()');
    }
  } else {
    components.push(object.toString());
  }

  return components.join(', ');
};
