'use strict';

var assign = require('lodash.assign');
var pick = require('lodash.pick');
var OTHelpers = require('../common-js-helpers/OTHelpers.js');
var OTPlugin = require('../otplugin/otplugin.js');
var incompleteProperties = require('../../conf/properties.js');

// Augments properties from conf. Makes it better and stronger!

var properties = OTHelpers.clone(incompleteProperties);

properties.debug = properties.debug === 'true' || properties.debug === true;
properties.supportSSL = properties.supportSSL === 'true' || properties.supportSSL === true;

if (global.OTProperties) {
  // Allow global.OTProperties to override cdnURL, configURL, assetURL and cssURL
  // TODO: Can we find a better solution here? This is the only place where I'm allowing this kind
  // of global interaction post-modularization.

  // override "authorized" properties with the global settings
  assign(properties, pick(global.OTProperties, 'cdnURL', 'cdnURLSSL', 'configURL', 'assetURL', 'cssURL'));
}

if (!properties.cdnURLSSL && global.location) {
  properties.cdnURLSSL = global.location.protocol + '//' + global.location.host;
}

if (!properties.cdnURL && global.location) {
  properties.cdnURL = global.location.protocol + '//' + global.location.host;
}

if (!properties.assetURL) {
  if (properties.supportSSL) {
    properties.assetURL = properties.cdnURLSSL + '/webrtc/' + properties.version;
  } else {
    properties.assetURL = properties.cdnURL + '/webrtc/' + properties.version;
  }
}

var isIE89 = OTHelpers.env.name === 'IE' && OTHelpers.env.version <= 9;
if (!(isIE89 && global.location.protocol.indexOf('https') < 0)) {
  properties.apiURL = properties.apiURLSSL;
  properties.loggingURL = properties.loggingURLSSL;
}

OTPlugin.setPathToInstaller(properties.assetURL + '/plugin/');

// at this stage if cssURL is still not defined (no one set it using global.OTProperties) we
// consider it an oversight and we are forcing a default value
if (!properties.hasOwnProperty('cssURL')) {
  properties.cssURL =
    typeof undefined !== 'undefined' ?
      undefined : properties.assetURL + '/css/TB.min.css';
}

module.exports = properties;
