'use strict';

var curryCallAsync = require('./curry_call_async.js');
var logging = require('./logging.js');
var OTHelpers = require('../common-js-helpers/OTHelpers.js');

module.exports = function RumorSocket(plugin, server) {
  var Proto = function RumorSocket() {};
  var rumorSocket = new Proto();
  var connected = false;
  var rumorID, _onOpen, _onClose;

  try {
    rumorID = plugin._.RumorInit(server, '');
  } catch (e) {
    logging.error('Error creating the Rumor Socket: ', e.message);
  }

  if (!rumorID) {
    throw new Error('Could not initialise OTPlugin rumor connection');
  }

  rumorSocket.open = function() {
    connected = true;
    plugin._.RumorOpen(rumorID);
  };

  rumorSocket.close = function(code, reason) {
    if (connected) {
      connected = false;
      plugin._.RumorClose(rumorID, code, reason);
    }

    plugin.removeRef(rumorSocket);
  };

  rumorSocket.destroy = function() {
    this.close();
  };

  rumorSocket.send = function(msg) {
    plugin._.RumorSend(rumorID, msg.type, msg.toAddress,
      JSON.parse(JSON.stringify(msg.headers)), msg.data);
  };

  rumorSocket.onOpen = function(callback) {
    _onOpen = callback;
  };

  rumorSocket.onClose = function(callback) {
    _onClose = callback;
  };

  rumorSocket.onError = function(callback) {
    plugin._.SetOnRumorError(rumorID, curryCallAsync(callback));
  };

  rumorSocket.onMessage = function(callback) {
    plugin._.SetOnRumorMessage(rumorID, curryCallAsync(callback));
  };

  plugin.addRef(rumorSocket);

  plugin._.SetOnRumorOpen(rumorID, curryCallAsync(function() {
    if (_onOpen && OTHelpers.isFunction(_onOpen)) {
      _onOpen.call(null);
    }
  }));

  plugin._.SetOnRumorClose(rumorID, curryCallAsync(function(code) {
    _onClose(code);

    // We're done. Clean up ourselves
    plugin.removeRef(rumorSocket);
  }));

  return rumorSocket;
};
