'use strict';

var assign = require('lodash.assign');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');
var parseIceServers = require('../messaging/raptor/parse_ice_servers.js');
var PeerConnection = require('./peer_connection.js');
var RaptorConstants = require('../messaging/raptor/raptor_constants.js');
var setCertificates = require('./set_certificates.js');

/*
 * Abstracts PeerConnection related stuff away from Subscriber.
 *
 * Responsible for:
 * * setting up the underlying PeerConnection (delegates to PeerConnections)
 * * triggering a connected event when the Peer connection is opened
 * * triggering a disconnected event when the Peer connection is closed
 * * creating a video element when a stream is added
 * * responding to stream removed intelligently
 * * providing a destroy method
 * * providing a processMessage method
 *
 * Once the PeerConnection is connected and the video element playing it
 * triggers the connected event
 *
 * Triggers the following events
 * * connected
 * * disconnected
 * * remoteStreamAdded
 * * remoteStreamRemoved
 * * error
 *
 */

module.exports = function SubscriberPeerConnection(remoteConnection, session, stream,
  subscriber, subscriberUri, properties, logAnalyticsEvent) {
  var _subscriberPeerConnection = this;
  var _peerConnection;
  var _destroyed = false;
  var _hasRelayCandidates = false;
  var _awaitingIceRestart = false;

  // Private
  var _onPeerClosed = function() {
    this.destroy();
    if (_awaitingIceRestart) {
      this.trigger('iceRestartFailure', this);
    }
    this.trigger('disconnected', this);
  };

  var _onRemoteStreamAdded = function(remoteRTCStream) {
    this.trigger('remoteStreamAdded', remoteRTCStream, this);
  };

  var _onRemoteStreamRemoved = function(remoteRTCStream) {
    this.trigger('remoteStreamRemoved', remoteRTCStream, this);
  };

  // Note: All Peer errors are fatal right now.
  var _onPeerError = function(errorReason, prefix) {
    this.trigger('error', null, errorReason, this, prefix);
  };

  var _onIceConnectionStateChange = function(state) {
    if (_awaitingIceRestart && (state === 'connected' || state === 'completed')) {
      _awaitingIceRestart = false;
      this.trigger('iceRestartSuccess');
    }
    this.trigger('iceConnectionStateChange', state);
  };

  var _onsignalingStateChange = function(state) {
    this.trigger('signalingStateChange', state);
  };

  var _onsignalingStateStable = function(state) {
    this.trigger('signalingStateStable', state);
  };

  var _relayMessageToPeer = function sendMessageToPeer(type, payload) {
    if (!_hasRelayCandidates) {
      var extractCandidates = type === RaptorConstants.Actions.CANDIDATE ||
                              type === RaptorConstants.Actions.OFFER ||
                              type === RaptorConstants.Actions.ANSWER ||
                              type === RaptorConstants.Actions.PRANSWER;

      if (extractCandidates) {
        var message = (
          type === RaptorConstants.Actions.CANDIDATE ?
          payload.candidate :
          payload.sdp
        );

        _hasRelayCandidates = message.indexOf('typ relay') !== -1;
      }
    }

    switch (type) {
      case RaptorConstants.Actions.ANSWER:
      case RaptorConstants.Actions.PRANSWER:
        this.trigger('connected');

        session._.jsepAnswerP2p(stream.id, subscriber.widgetId, payload.sdp);
        break;

      case RaptorConstants.Actions.OFFER:
        session._.jsepOffer(subscriberUri, payload.sdp);
        break;

      case RaptorConstants.Actions.CANDIDATE:
        session._.jsepCandidateP2p(stream.id, subscriber.widgetId, payload);
        break;

      default:
    }
  }.bind(this);

  // Helper method used by subscribeToAudio/subscribeToVideo
  var _setEnabledOnStreamTracksCurry = function(isVideo) {
    var method = 'get' + (isVideo ? 'Video' : 'Audio') + 'Tracks';

    return function(enabled) {
      if (!_peerConnection) {
        // We haven't created the peer connection yet, so there are no remote streams right now.
        // Subscriber will try again after onRemoteStreamAdded so this works out ok.
        return;
      }

      var tracks, stream;
      var remoteStreams = _peerConnection.remoteStreams();

      if (remoteStreams.length === 0 || !remoteStreams[0][method]) {
        // either there is no remote stream or we are in a browser that doesn't
        // expose the media tracks (Firefox)
        return;
      }

      for (var i = 0, num = remoteStreams.length; i < num; ++i) {
        stream = remoteStreams[i];
        tracks = stream[method]();

        for (var k = 0, numTracks = tracks.length; k < numTracks; ++k) {
          // Only change the enabled property if it's different
          // otherwise we get flickering of the video
          if (tracks[k].enabled !== enabled) { tracks[k].enabled = enabled; }
        }
      }
    };
  };

  var _onQOS = function _onQOS(parsedStats, prevStats) {
    this.trigger('qos', parsedStats, prevStats);
  }.bind(this);

  OTHelpers.eventing(this);

  // Public
  this.destroy = function() {
    if (_destroyed) { return; }
    _destroyed = true;

    if (_peerConnection) {
      // Unsubscribe us from the stream, if it hasn't already been destroyed
      if (session && session.isConnected() && stream && !stream.destroyed) {
        // Notify the server components
        session._.subscriberDestroy(stream, subscriber);
      }

      // Ref: OPENTOK-2458 disable all audio tracks before removing it.
      this.subscribeToAudio(false);
      _peerConnection.disconnect();
    }

    _peerConnection = null;
    this.off();
  };

  this.getDataChannel = function(label, options, completion) {
    _peerConnection.getDataChannel(label, options, completion);
  };

  this.processMessage = function(type, message) {
    _peerConnection.processMessage(type, message);
  };

  this.getStats = function(callback) {
    if (_peerConnection) {
      _peerConnection.getStats(callback);
    } else {
      callback(new OTHelpers.Error('Subscriber is not connected cannot getStats',
      'NotConnectedError', {
        code: 1015
      }));
    }
  };

  this.subscribeToAudio = _setEnabledOnStreamTracksCurry(false);
  this.subscribeToVideo = _setEnabledOnStreamTracksCurry(true);

  this.hasRelayCandidates = function() {
    return _hasRelayCandidates;
  };

  this.createOfferWithIceRestart = function() {
    _awaitingIceRestart = true;
    return _peerConnection.createOfferWithIceRestart(subscriberUri);
  };

  this.iceConnectionStateIsConnected = function() {
    return _peerConnection.iceConnectionStateIsConnected();
  };

  // Init
  this.init = function(completion) {
    var pcConfig = {
      offerConstraints: {}
    };

    if (session.sessionInfo.reconnection) {
      pcConfig.offerConstraints.iceRestart = true;
    }

    setCertificates(pcConfig, function(err, pcConfigWithCerts) {
      if (err) {
        completion(err);
        return;
      }

      var peerConnectionConfig = assign(
        { logAnalyticsEvent: logAnalyticsEvent },
        pcConfigWithCerts
      );

      _peerConnection = new PeerConnection(
        assign({ sendMessage: _relayMessageToPeer }, peerConnectionConfig)
      );

      _peerConnection.on({
        close: _onPeerClosed,
        streamAdded: _onRemoteStreamAdded,
        streamRemoved: _onRemoteStreamRemoved,
        signalingStateChange: _onsignalingStateChange,
        signalingStateStable: _onsignalingStateStable,
        error: _onPeerError,
        qos: _onQOS,
        iceConnectionStateChange: _onIceConnectionStateChange
      }, _subscriberPeerConnection);

      // If there are already remoteStreams, add them immediately
      if (_peerConnection.remoteStreams().length > 0) {
        _peerConnection.remoteStreams().forEach(_onRemoteStreamAdded, _subscriberPeerConnection);
      } else {
        // We only bother with the PeerConnection negotiation if we don't already
        // have a remote stream.

        var channelsToSubscribeTo;

        if (properties.subscribeToVideo || properties.subscribeToAudio) {
          var audio = stream.getChannelsOfType('audio');
          var video = stream.getChannelsOfType('video');

          channelsToSubscribeTo = audio.map(function(channel) {
            return {
              id: channel.id,
              type: channel.type,
              active: properties.subscribeToAudio
            };
          }).concat(video.map(function(channel) {
            var props = {
              id: channel.id,
              type: channel.type,
              active: properties.subscribeToVideo,
              restrictFrameRate: properties.restrictFrameRate !== void 0 ?
                properties.restrictFrameRate : false
            };

            if (properties.preferredFrameRate !== void 0) {
              props.preferredFrameRate = parseFloat(properties.preferredFrameRate);
            }

            if (properties.preferredHeight !== void 0) {
              props.preferredHeight = parseInt(properties.preferredHeight, 10);
            }

            if (properties.preferredWidth !== void 0) {
              props.preferredWidth = parseInt(properties.preferredWidth, 10);
            }

            return props;
          }));
        }

        session._.subscriberCreate(
          stream,
          subscriber,
          channelsToSubscribeTo,
          function(err, message) {
            if (err) {
              _subscriberPeerConnection.trigger(
                'error',
                err.message,
                _subscriberPeerConnection,
                'Subscribe'
              );
            }
            if (_peerConnection) {
              _peerConnection.setIceServers(parseIceServers(message));
            }
          }
        );

        completion(undefined, _subscriberPeerConnection);
      }
    });
  };
};
