'use strict';

var OTHelpers = require('../../common-js-helpers/OTHelpers.js');

var RTCPeerConnection = global.RTCPeerConnection || global.mozRTCPeerConnection;

module.exports = function(pcConfig, completion) {
  if (
    OTHelpers.env.name === 'Firefox' &&
    RTCPeerConnection &&
    RTCPeerConnection.generateCertificate
  ) {
    RTCPeerConnection.generateCertificate({
      name: 'RSASSA-PKCS1-v1_5',
      hash: 'SHA-256',
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1])
    }).catch(function(err) {
      completion(err);
    }).then(function(cert) {
      pcConfig.certificates = [cert];
      completion(undefined, pcConfig);
    });
  } else {
    setTimeout(function() {
      completion(undefined, pcConfig);
    });
  }
};
