'use strict';

var curryCallAsync = require('./curry_call_async.js');
var logging = require('./logging.js');

module.exports = function(pluginProxy, completion) {
  var internalReadyCallback = function() {
    // It's not safe to do most plugin operations until the plugin
    // is ready for us to do so. We use ready as a guard in.
    pluginProxy.ready.resolve();

    pluginProxy.ready.promise.then(function(err) {
      if (err) {
        logging.error('Error while starting up plugin ' + pluginProxy.uuid + ': ' + err);
        completion(err);
        return;
      }

      logging.debug('Plugin ' + pluginProxy.id + ' is loaded');
      completion(void 0, pluginProxy);
    });
  };

  // Only the main plugin has an initialise method
  if (pluginProxy._.initialise) {
    pluginProxy.on('ready', curryCallAsync(internalReadyCallback));
    pluginProxy._.initialise();
  } else {
    internalReadyCallback();
  }
};
