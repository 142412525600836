'use strict';

var uuid = require('uuid');

var OTHelpers = require('../../../common-js-helpers/OTHelpers.js');

var hasBundleCapability = require('../../../helpers/hasBundleCapability.js');
var hasRTCPMuxCapability = require('../../../helpers/hasRTCPMuxCapability.js');
var serializeMessage = require('./serialize_message.js');
var supportedCryptoScheme = require('../../../helpers/supported_crypto_scheme.js');
var properties = require('../../../helpers/properties.js');

var Message = {};
module.exports = Message;

Message.offer = function(uri, offerSdp) {
  return serializeMessage({
    method: 'offer',
    uri: uri,
    content: {
      sdp: offerSdp
    }
  });
};

Message.connections = {};

Message.connections.create = function(apiKey, sessionId, connectionId, capabilities) {
  return serializeMessage({
    method: 'create',
    uri: '/v2/partner/' + apiKey + '/session/' + sessionId + '/connection/' + connectionId,
    content: {
      userAgent: OTHelpers.env.userAgent,
      clientVersion: 'js-' + properties.version.replace('v', ''),
      capabilities: capabilities || []
    }
  });
};

Message.connections.destroy = function(apiKey, sessionId, connectionId) {
  return serializeMessage({
    method: 'delete',
    uri: '/v2/partner/' + apiKey + '/session/' + sessionId + '/connection/' + connectionId,
    content: {}
  });
};

Message.sessions = {};

Message.sessions.get = function(apiKey, sessionId) {
  return serializeMessage({
    method: 'read',
    uri: '/v2/partner/' + apiKey + '/session/' + sessionId,
    content: {}
  });
};

Message.streams = {};

Message.streams.get = function(apiKey, sessionId, streamId) {
  return serializeMessage({
    method: 'read',
    uri: '/v2/partner/' + apiKey + '/session/' + sessionId + '/stream/' + streamId,
    content: {}
  });
};

Message.streams.channelFromOTChannel = function(channel) {
  var raptorChannel = {
    id: channel.id,
    type: channel.type,
    active: channel.active
  };

  if (channel.type === 'video') {
    raptorChannel.width = channel.width;
    raptorChannel.height = channel.height;
    raptorChannel.orientation = channel.orientation;
    raptorChannel.frameRate = channel.frameRate;
    if (channel.source !== 'default') {
      raptorChannel.source = channel.source;
    }
    raptorChannel.fitMode = channel.fitMode;
  }

  return raptorChannel;
};

Message.streams.create = function(apiKey, sessionId, streamId, name,
  audioFallbackEnabled, channels, minBitrate, maxBitrate) {
  var messageContent = {
    id: streamId,
    name: name,
    audioFallbackEnabled: audioFallbackEnabled,
    channel: channels.map(function(channel) {
      return Message.streams.channelFromOTChannel(channel);
    })
  };

  if (minBitrate) {
    messageContent.minBitrate = Math.round(minBitrate);
  }
  if (maxBitrate) {
    messageContent.maxBitrate = Math.round(maxBitrate);
  }

  return serializeMessage({
    method: 'create',
    uri: '/v2/partner/' + apiKey + '/session/' + sessionId + '/stream/' + streamId,
    content: messageContent
  });
};

Message.streams.destroy = function(apiKey, sessionId, streamId) {
  return serializeMessage({
    method: 'delete',
    uri: '/v2/partner/' + apiKey + '/session/' + sessionId + '/stream/' + streamId,
    content: {}
  });
};

Message.streams.answer = function(apiKey, sessionId, streamId, answerSdp) {
  return serializeMessage({
    method: 'answer',
    uri: '/v2/partner/' + apiKey + '/session/' + sessionId + '/stream/' + streamId,
    content: {
      sdp: answerSdp
    }
  });
};

Message.streams.candidate = function(apiKey, sessionId, streamId, candidate) {
  return serializeMessage({
    method: 'candidate',
    uri: '/v2/partner/' + apiKey + '/session/' + sessionId + '/stream/' + streamId,
    content: candidate
  });
};

Message.streamChannels = {};

Message.streamChannels.update =
  function(apiKey, sessionId, streamId, channelId, attributes) {
    return serializeMessage({
      method: 'update',
      uri: '/v2/partner/' + apiKey + '/session/' + sessionId + '/stream/' +
        streamId + '/channel/' + channelId,
      content: attributes
    });
  };

Message.subscribers = {};

Message.subscribers.uri = function(apiKey, sessionId, streamId, subscriberId) {
  return '/v2/partner/' + apiKey + '/session/' + sessionId +
    '/stream/' + streamId + '/subscriber/' + subscriberId;
};

Message.subscribers.create =
  function(apiKey, sessionId, streamId, subscriberId, connectionId, channelsToSubscribeTo) {
    var content = {
      id: subscriberId,
      connection: connectionId,
      keyManagementMethod: supportedCryptoScheme(),
      bundleSupport: hasBundleCapability(),
      rtcpMuxSupport: hasRTCPMuxCapability()
    };

    if (channelsToSubscribeTo) { content.channel = channelsToSubscribeTo; }

    return serializeMessage({
      method: 'create',
      uri: '/v2/partner/' + apiKey + '/session/' + sessionId +
        '/stream/' + streamId + '/subscriber/' + subscriberId,
      content: content
    });
  };

Message.subscribers.destroy =
  function(apiKey, sessionId, streamId, subscriberId) {
    return serializeMessage({
      method: 'delete',
      uri: '/v2/partner/' + apiKey + '/session/' + sessionId +
        '/stream/' + streamId + '/subscriber/' + subscriberId,
      content: {}
    });
  };

Message.subscribers.update =
  function(apiKey, sessionId, streamId, subscriberId, attributes) {
    return serializeMessage({
      method: 'update',
      uri: '/v2/partner/' + apiKey + '/session/' + sessionId +
      '/stream/' + streamId + '/subscriber/' + subscriberId,
      content: attributes
    });
  };

Message.subscribers.candidate =
  function(apiKey, sessionId, streamId, subscriberId, candidate) {
    return serializeMessage({
      method: 'candidate',
      uri: '/v2/partner/' + apiKey + '/session/' + sessionId +
        '/stream/' + streamId + '/subscriber/' + subscriberId,
      content: candidate
    });
  };

Message.subscribers.answer =
  function(apiKey, sessionId, streamId, subscriberId, answerSdp) {
    return serializeMessage({
      method: 'answer',
      uri: '/v2/partner/' + apiKey + '/session/' + sessionId +
      '/stream/' + streamId + '/subscriber/' + subscriberId,
      content: {
        sdp: answerSdp
      }
    });
  };

Message.subscriberChannels = {};

Message.subscriberChannels.update =
  function(apiKey, sessionId, streamId, subscriberId, channelId, attributes) {
    return serializeMessage({
      method: 'update',
      uri: '/v2/partner/' + apiKey + '/session/' + sessionId +
      '/stream/' + streamId + '/subscriber/' + subscriberId + '/channel/' + channelId,
      content: attributes
    });
  };

Message.signals = {};

Message.signals.create =
  function(apiKey, sessionId, toAddress, type, data) {
    var content = {};
    if (type !== void 0) { content.type = type; }
    if (data !== void 0) { content.data = data; }

    return serializeMessage({
      method: 'signal',
      uri: '/v2/partner/' + apiKey + '/session/' + sessionId +
        (toAddress !== void 0 ? '/connection/' + toAddress : '') + '/signal/' + uuid(),
      content: content
    });
  };
