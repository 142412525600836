'use strict';

var Bluebird = require('bluebird');

// Ensures that promisified functions run in serial.
// Perhaps this module should be called "serialize," but that seems too generic.
//
// @memberof OTHelpers
// @private
//
// @return The throttled function.
//
module.exports = function(fn) {

  // We start our queue with a resolved promise. We do this
  // because we want queue to be thenable, but we also want
  // it to execute the first element of the queue immediately.
  var queue = Bluebird.Promise.resolve();

  return function() {
    var args = Array.prototype.slice.call(arguments);
    queue = queue.then(function() {
      // execute our function with args when the previous
      // steps have finished.
      return fn.apply(null, args);
    });

    return queue;
  };
};
