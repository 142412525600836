module.exports={
  "_args": [
    [
      {
        "raw": "@opentok/opentok-plugin@https://registry.npmjs.org/@opentok/opentok-plugin/-/opentok-plugin-1.2.1-alpha.74.tgz",
        "scope": "@opentok",
        "escapedName": "@opentok%2fopentok-plugin",
        "name": "@opentok/opentok-plugin",
        "rawSpec": "https://registry.npmjs.org/@opentok/opentok-plugin/-/opentok-plugin-1.2.1-alpha.74.tgz",
        "spec": "https://registry.npmjs.org/@opentok/opentok-plugin/-/opentok-plugin-1.2.1-alpha.74.tgz",
        "type": "remote"
      },
      "/Users/jenkins/jenkins/workspace/webrtc-js-build-s3"
    ]
  ],
  "_from": "@opentok/opentok-plugin@1.2.1-alpha.74",
  "_id": "@opentok/opentok-plugin@1.2.1-alpha.74",
  "_inCache": true,
  "_location": "/@opentok/opentok-plugin",
  "_phantomChildren": {},
  "_requested": {
    "raw": "@opentok/opentok-plugin@https://registry.npmjs.org/@opentok/opentok-plugin/-/opentok-plugin-1.2.1-alpha.74.tgz",
    "scope": "@opentok",
    "escapedName": "@opentok%2fopentok-plugin",
    "name": "@opentok/opentok-plugin",
    "rawSpec": "https://registry.npmjs.org/@opentok/opentok-plugin/-/opentok-plugin-1.2.1-alpha.74.tgz",
    "spec": "https://registry.npmjs.org/@opentok/opentok-plugin/-/opentok-plugin-1.2.1-alpha.74.tgz",
    "type": "remote"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/@opentok/opentok-plugin/-/opentok-plugin-1.2.1-alpha.74.tgz",
  "_shasum": "f3a74c0a727bd1b0e4cfe85fe24bf5cf7d5a232c",
  "_shrinkwrap": null,
  "_spec": "@opentok/opentok-plugin@https://registry.npmjs.org/@opentok/opentok-plugin/-/opentok-plugin-1.2.1-alpha.74.tgz",
  "_where": "/Users/jenkins/jenkins/workspace/webrtc-js-build-s3",
  "dependencies": {},
  "devDependencies": {},
  "name": "@opentok/opentok-plugin",
  "optionalDependencies": {},
  "publicVersion": "1.2.1.74",
  "readme": "ERROR: No README data found!",
  "version": "1.2.1-alpha.74"
}
