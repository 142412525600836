'use strict';

var Widget = require('./behaviour/widget');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');
var Promise = require('bluebird');
var EventEmitter = require('events');

module.exports = function AudioLevelMeter(options) {
  var _audioLevelMeter = this;
  var _eventEmitter = new EventEmitter();

  var _meterBarElement,
      _voiceOnlyIconElement,
      _meterValueElement,
      _value,
      _lastComputedVisibility;

  // display the widget by default but can be hidden when calling hideWhileLoading
  var _displayAroundLoading = true;

  var _audioOnly = false;
  var _displayMode = 'auto';

  var _maxValue = options.maxValue || 1;
  var _minValue = options.minValue || 0;

  function onCreate() {
    _meterBarElement = OTHelpers.createElement('div', {
      className: 'OT_audio-level-meter__bar'
    }, '');
    _meterValueElement = OTHelpers.createElement('div', {
      className: 'OT_audio-level-meter__value'
    }, '');
    _voiceOnlyIconElement = OTHelpers.createElement('div', {
      className: 'OT_audio-level-meter__audio-only-img'
    }, '');

    var domElement = _audioLevelMeter.domElement;
    domElement.appendChild(_meterBarElement);
    domElement.appendChild(_voiceOnlyIconElement);
    domElement.appendChild(_meterValueElement);

    _audioLevelMeter.watchVisibilityChanged(function(visible) {
      if (visible) {
        OTHelpers.removeClass(_audioLevelMeter.domElement, 'OT_hide-forced');
      } else {
        OTHelpers.addClass(_audioLevelMeter.domElement, 'OT_hide-forced');
      }
    });
  }

  function onDestroy() {
    _eventEmitter.removeAllListeners('visibilityChanged');
  }

  function updateView() {
    var percentSize = _value * 100 / (_maxValue - _minValue);
    _meterValueElement.style.width = _meterValueElement.style.height = 2 * percentSize + '%';
    _meterValueElement.style.top = _meterValueElement.style.right = -percentSize + '%';
  }

  // computes the visibility value from the different "inputs" variables and asynchronously triggers
  // the internal "visibilityChanged" events if the value changed from last time it was computed
  function computeVisibility() {
    var computedVisibility = (_audioOnly && _displayMode === 'auto' || _displayMode === 'on')
      && _displayAroundLoading;
    if (_lastComputedVisibility !== computedVisibility) {
      _lastComputedVisibility = computedVisibility;
      _eventEmitter.emit('visibilityChanged', computedVisibility);
    }
  }

  OTHelpers.defineProperties(_audioLevelMeter, {
    audioOnly: {
      get: function() { return _audioOnly; },
      set: function(audioOnly) {
        _audioOnly = audioOnly;
        computeVisibility();
      }
    }
  });

  _audioLevelMeter.setValue = function(value) {
    _value = value;
    updateView();
  };

  /**
   * Registers an callback to be executed when the visibility of the audio level meter changes.
   * "true" means the widget is shown.
   * The contracts is that the handlers should not try to change the visibility of the widget by
   * changing the value of visibility "inputs" (setting "audioOnly", "displayMode" or calling
   * "hideWhileLoading" and "showAfterLoading")
   *
   * @param {function(boolean)} cb the callback to be executed when the display value changes.
   * The callback is also executed with the last computed value when registered.
   * @returns {function} a callback to unregister the handler
   */
  _audioLevelMeter.watchVisibilityChanged = function(cb) {
    _eventEmitter.on('visibilityChanged', cb);
    Promise.resolve().then(function() {
      cb(_lastComputedVisibility);
    });
    return function stopWatching() {
      _eventEmitter.removeListener('visibilityChanged', cb);
    };
  };

  // Mixin common widget behaviour
  var widgetOptions = {
    mode: options ? options.mode : 'auto',
    nodeName: 'div',
    htmlAttributes: {
      className: 'OT_audio-level-meter'
    },
    onCreate: onCreate,
    onDestroy: onDestroy
  };

  Widget(this, widgetOptions);

  // The methods underneath are mixed in by "Widget" but overridden
  // Doing so, we can bypass it and compute the display value ourselves without relying on CSS
  _audioLevelMeter.setDisplayMode = function(mode) {
    _displayMode = mode;
    computeVisibility();
  };

  _audioLevelMeter.getDisplayMode = function() {
    return _displayMode;
  };

  _audioLevelMeter.showAfterLoading = function() {
    _displayAroundLoading = true;
    computeVisibility();
  };

  _audioLevelMeter.hideWhileLoading = function() {
    _displayAroundLoading = false;
    computeVisibility();
  };

  // compute the initial visibility value
  computeVisibility();
};
