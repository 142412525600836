'use strict';

/*
 * Executes the provided callback thanks to <code>global.setInterval</code>.
 *
 * @param {function()} callback
 * @param {number} frequency how many times per second we want to execute the callback
 * @constructor
 */
module.exports = function IntervalRunner(callback, frequency) {
  var _callback = callback;
  var _frequency = frequency;
  var _intervalId = null;

  this.start = function() {
    _intervalId = global.setInterval(_callback, 1000 / _frequency);
  };

  this.stop = function() {
    global.clearInterval(_intervalId);
    _intervalId = null;
  };
};
