'use strict';

var uuid                      = require('uuid');
var Bluebird                  = require('bluebird');
var analytics                 = require('../analytics.js');
var APIKEY                    = require('../api_key.js');
var Capabilities              = require('../capabilities.js');
var ConnectivityAttemptPinger = require('../../helpers/connectivity_attempt_pinger.js');
var Events                    = require('../events.js');
var ExceptionCodes            = require('../exception_codes.js');
var httpTest                  = require('../qos_testing/http_test.js');
var initPublisher             = require('../publisher/init.js');
var hasIceRestartsCapability  = require('../../helpers/hasIceRestartsCapability.js');
var logging                   = require('../logging.js');
var omit                      = require('lodash.omit');
var OTError                   = require('../ot_error.js');
var OTHelpers                 = require('../../common-js-helpers/OTHelpers.js');
var OTPlugin                  = require('../../otplugin/otplugin.js');
var properties                = require('../../helpers/properties.js');
var Publisher                 = require('../publisher');
var RaptorSocket              = require('../messaging/raptor/raptor_socket.js');
var SessionDispatcher         = require('../messaging/raptor/session_dispatcher.js');
var SessionInfo               = require('./info.js');
var sessionObjects            = require('./objects.js');
var sessionTag                = require('./tag.js');
var Subscriber                = require('../subscriber');
var systemRequirements        = require('../system_requirements.js');
var webrtcTest                = require('../qos_testing/webrtc_test.js');

// TODO: Export Session instead of doing this indirection. This is unfortunately necessary right now
// because of some unusual mocking needs when testing.
var SessionHandle = {};
module.exports = SessionHandle;

/**
 * The Session object returned by the <code>OT.initSession()</code> method provides access to
 * much of the OpenTok functionality.
 *
 * @class Session
 * @augments EventDispatcher
 *
 * @property {Capabilities} capabilities A {@link Capabilities} object that includes information
 * about the capabilities of the client. All properties of the <code>capabilities</code> object
 * are undefined until you have connected to a session and the completion handler for the
 * <code>Session.connect()</code> method has been called without error.
 * @property {Connection} connection The {@link Connection} object for this session. The
 * connection property is only available once the completion handler for the
 * <code>Session.connect()</code> method has been called successfully. See the
 * <a href="#connect">Session.connect()</a> method and the {@link Connection} class.
 * @property {String} sessionId The session ID for this session. You pass this value into the
 * <code>OT.initSession()</code> method when you create the Session object. (Note: a Session
 * object is not connected to the OpenTok server until you call the connect() method of the
 * object and its completion handler is called without error. See the
 * <a href="OT.html#initSession">OT.initSession()</a> and
 * the <a href="#connect">Session.connect()</a>
 * methods.) For more information on sessions and session IDs, see
 * <a href="https://tokbox.com/opentok/tutorials/create-session/">Session creation</a>.
 */
SessionHandle.Session = function(apiKey, sessionId) {
  OTHelpers.eventing(this);
  this._tag = sessionTag;

  // Check that the client meets the minimum requirements, if they don't the upgrade
  // flow will be triggered.
  if (!systemRequirements.check()) {
    systemRequirements.upgrade();
    return;
  }

  if (sessionId == null) {
    sessionId = apiKey;
    apiKey = null;
  }

  this.id = this.sessionId = sessionId;

  var _socket, _connectivityAttemptPinger, _token, _p2p, _messagingServer, _attemptStartTime;
  var _initialConnection = true;
  var _apiKey = apiKey;
  var _session = this;
  var _sessionId = sessionId;
  var _widgetId = uuid();
  var _connectionId = uuid();

  var setState = OTHelpers.statable(this, [
    'disconnected', 'connecting', 'connected', 'disconnecting'
  ], 'disconnected');

  this.connection = null;
  this.connections = new OTHelpers.Collection();
  this.streams = new OTHelpers.Collection();
  this.archives = new OTHelpers.Collection();

  //--------------------------------------
  //  MESSAGE HANDLERS
  //--------------------------------------

  // The duplication of this and sessionConnectionFailed will go away when
  // session and messenger are refactored
  var sessionConnectFailed = function(reason, code) {
    setState('disconnected');

    logging.error(reason);

    this.trigger('sessionConnectFailed',
      new OTError(code || ExceptionCodes.CONNECT_FAILED, reason));

    OTError.handleJsException(reason, code || ExceptionCodes.CONNECT_FAILED, {
      session: this
    });
  };

  var sessionDisconnectedHandler = function(event) {
    var reason = event.reason;
    if (reason === 'networkTimedout') {
      reason = 'networkDisconnected';
      this.logEvent('Connect', 'TimeOutDisconnect', { reason: event.reason });
    } else {
      this.logEvent('Connect', 'Disconnected', { reason: event.reason });
    }

    var publicEvent = new Events.SessionDisconnectEvent('sessionDisconnected', reason);

    reset();
    disconnectComponents.call(this, reason);

    var defaultAction = function() {
      // Although part of the defaultAction for sessionDisconnected we have
      // chosen to still destroy Publishers within the session as there is
      // another mechanism to stop a Publisher from being destroyed.

      // Publishers use preventDefault on the Publisher streamDestroyed event
      destroyPublishers.call(this, publicEvent.reason);

      if (!publicEvent.isDefaultPrevented()) {
        destroySubscribers.call(this, publicEvent.reason);
      }
    }.bind(this);

    this.dispatchEvent(publicEvent, defaultAction);
  };

  var connectionCreatedHandler = function(connection) {
    // We don't broadcast events for the symphony connection
    if (connection.id.match(/^symphony\./)) { return; }

    this.dispatchEvent(new Events.ConnectionEvent(
      Events.Event.names.CONNECTION_CREATED,
      connection
    ));
  };

  var connectionDestroyedHandler = function(connection, reason) {
    // We don't broadcast events for the symphony connection
    if (connection.id.match(/^symphony\./)) { return; }

    // Don't delete the connection if it's ours. This only happens when
    // we're about to receive a session disconnected and session disconnected
    // will also clean up our connection.
    if (connection.id === _socket.id()) { return; }

    this.dispatchEvent(
      new Events.ConnectionEvent(
        Events.Event.names.CONNECTION_DESTROYED,
        connection,
        reason
      )
    );
  };

  var streamCreatedHandler = function(stream) {
    if (stream.connection.id !== this.connection.id) {
      this.dispatchEvent(new Events.StreamEvent(
        Events.Event.names.STREAM_CREATED,
        stream,
        null,
        false
      ));
    }
  };

  var streamPropertyModifiedHandler = function(event) {
    var stream = event.target;
    var propertyName = event.changedProperty;
    var newValue = event.newValue;

    if (propertyName === 'videoDisableWarning' || propertyName === 'audioDisableWarning') {
      return; // These are not public properties, skip top level event for them.
    }

    if (propertyName === 'videoDimensions') {
      newValue = { width: newValue.width, height: newValue.height };
    }

    this.dispatchEvent(new Events.StreamPropertyChangedEvent(
      Events.Event.names.STREAM_PROPERTY_CHANGED,
      stream,
      propertyName,
      event.oldValue,
      newValue
    ));
  };

  var streamDestroyedHandler = function(stream, reason) {

    // if the stream is one of ours we delegate handling
    // to the publisher itself.
    if (stream.connection.id === this.connection.id) {
      sessionObjects.publishers.where({ streamId: stream.id }).forEach(function(publisher) {
        publisher._.unpublishStreamFromSession(stream, this, reason);
      }, this);

      return;
    }

    var event = new Events.StreamEvent('streamDestroyed', stream, reason, true);

    var defaultAction = function() {
      if (!event.isDefaultPrevented()) {
        // If we are subscribed to any of the streams we should unsubscribe
        sessionObjects.subscribers.where({ streamId: stream.id }).forEach(function(subscriber) {
          if (subscriber.session.id === this.id) {
            if (subscriber.stream) {
              subscriber.destroy('streamDestroyed');
            }
          }
        },
          this
        );
      }
      // @TODO Add a else with a one time warning that this no longer cleans up the publisher
    }.bind(this);

    this.dispatchEvent(event, defaultAction);
  };

  var archiveCreatedHandler = function(archive) {
    this.dispatchEvent(new Events.ArchiveEvent('archiveStarted', archive));
  };

  var archiveDestroyedHandler = function(archive) {
    this.dispatchEvent(new Events.ArchiveEvent('archiveDestroyed', archive));
  };

  var archiveUpdatedHandler = function(event) {
    var archive = event.target;
    var propertyName = event.changedProperty;
    var newValue = event.newValue;

    if (propertyName === 'status' && newValue === 'stopped') {
      this.dispatchEvent(new Events.ArchiveEvent('archiveStopped', archive));
    } else {
      this.dispatchEvent(new Events.ArchiveEvent('archiveUpdated', archive));
    }
  };

  var init = function() {
    _session.token = _token = null;
    setState('disconnected');
    _session.connection = null;
    _session.capabilities = new Capabilities([]);
    _session.connections.destroy();
    _session.streams.destroy();
    _session.archives.destroy();
  };

  // Put ourselves into a pristine state
  var reset = function() {
    // reset connection id now so that following calls to testNetwork and connect will share
    // the same new session id. We need to reset here because testNetwork might be call after
    // and it is always called before the session is connected
    // on initial connection we don't reset
    _connectionId = uuid();
    init();
  };

  var disconnectComponents = function(reason) {
    sessionObjects.publishers.where({ session: this }).forEach(function(publisher) {
      publisher.disconnect(reason);
    });

    sessionObjects.subscribers.where({ session: this }).forEach(function(subscriber) {
      subscriber.disconnect();
    });
  };

  var destroyPublishers = function(reason) {
    sessionObjects.publishers.where({ session: this }).forEach(function(publisher) {
      publisher._.streamDestroyed(reason);
    });
  };

  var destroySubscribers = function(reason) {
    sessionObjects.subscribers.where({ session: this }).forEach(function(subscriber) {
      subscriber.destroy(reason);
    });
  };

  var connectMessenger = function() {
    logging.debug('OT.Session: connecting to Raptor');

    var socketUrl = this.sessionInfo.messagingURL;
    var symphonyUrl = properties.symphonyAddresss || this.sessionInfo.symphonyAddress;

    _socket = new SessionHandle.Session.RaptorSocket(
      _connectionId,
      _widgetId,
      socketUrl,
      symphonyUrl,
      SessionDispatcher(this)
    );

    _socket.connect(_token, this.sessionInfo, function(error, sessionState) {
      if (error) {
        var payload = omit(error, ['code', 'message', 'reason']);
        var options;
        if (error.code || error.message || error.reason) {
          options = {
            failureCode: error.code,
            failureMessage: error.message,
            failureReason: error.reason
          };
        }
        _socket = void 0;
        this.logConnectivityEvent('Failure', payload, options);

        sessionConnectFailed.call(this, error.message, error.code);
        return;
      }

      logging.debug('OT.Session: Received session state from Raptor', sessionState);

      this.connection = this.connections.get(_socket.id());
      if (this.connection) {
        this.capabilities = this.connection.permissions;
      }

      setState('connected');

      this.logConnectivityEvent('Success', { connectionId: this.connection.id });

      // Listen for our own connection's destroyed event so we know when we've been disconnected.
      this.connection.on('destroyed', sessionDisconnectedHandler, this);

      // Listen for connection updates
      this.connections.on({
        add: connectionCreatedHandler,
        remove: connectionDestroyedHandler
      }, this);

      // Listen for stream updates
      this.streams.on({
        add: streamCreatedHandler,
        remove: streamDestroyedHandler,
        update: streamPropertyModifiedHandler
      }, this);

      this.archives.on({
        add: archiveCreatedHandler,
        remove: archiveDestroyedHandler,
        update: archiveUpdatedHandler
      }, this);

      this.dispatchEvent(
        new Events.SessionConnectEvent(Events.Event.names.SESSION_CONNECTED),
        function() {
          this.connections._triggerAddEvents(); // { id: this.connection.id }
          this.streams._triggerAddEvents(); // { id: this.stream.id }
          this.archives._triggerAddEvents();
        }.bind(this)
      );

    }.bind(this));
  };

  var getSessionInfo = function() {
    if (this.is('connecting')) {
      var session = this;
      this.logEvent('SessionInfo', 'Attempt');

      SessionInfo.get(sessionId, _token).then(
        onSessionInfoResponse,
        function(error) {
          session.logConnectivityEvent('Failure', null, {
            failureReason: 'GetSessionInfo',
            failureCode: (error.code || 'No code'),
            failureMessage: error.message
          });

          sessionConnectFailed.call(session,
                error.message + (error.code ? ' (' + error.code + ')' : ''),
                error.code);
        }
      );
    }
  };

  var onSessionInfoResponse = function(sessionInfo) {
    if (OTPlugin.hasOwnProperty('settings')) {
      OTPlugin.settings.usePreviousDeviceSelection = sessionInfo.rememberDeviceChoiceIE;
    }

    if (this.is('connecting')) {
      this.sessionInfo = sessionInfo;
      _p2p = sessionInfo.p2pEnabled;
      _messagingServer = sessionInfo.messagingServer;
      this.logEvent('SessionInfo', 'Success', null, {
        features: {
          reconnection: OTHelpers.castToBoolean(sessionInfo.reconnection),
          renegotiation: OTHelpers.castToBoolean((hasIceRestartsCapability() &&
            sessionInfo.renegotiation)),
          simulcast: sessionInfo.simulcast === undefined ? false :
            sessionInfo.simulcast && OTHelpers.env.name === 'Chrome'
        }
      }, {
        messagingServer: sessionInfo.messagingServer
      });

      var overrides = properties.sessionInfoOverrides;
      if (overrides != null && typeof overrides === 'object') {
        this.sessionInfo = OTHelpers.defaults(overrides, this.sessionInfo);
      }
      if (this.sessionInfo.partnerId && this.sessionInfo.partnerId !== _apiKey) {
        this.apiKey = _apiKey = this.sessionInfo.partnerId;

        var reason = 'Authentication Error: The API key does not match the token or session.';

        this.logEvent('SessionInfo', 'Failure', null, {
          failureCode: ExceptionCodes.AUTHENTICATION_ERROR,
          failureReason: 'Authentication',
          failureMessage: reason
        });

        sessionConnectFailed.call(this, reason, ExceptionCodes.AUTHENTICATION_ERROR);
      } else {
        connectMessenger.call(this);
      }
    }
  }.bind(this);

  // Check whether we have permissions to perform the action.
  var permittedTo = function(action) {
    return this.capabilities.permittedTo(action);
  }.bind(this);

  // This is a placeholder until error handling can be rewritten
  var dispatchError = function(code, message, completionHandler) {
    logging.error(code, message);

    if (completionHandler && OTHelpers.isFunction(completionHandler)) {
      completionHandler.call(null, new OTError(code, message));
    }

    OTError.handleJsException(message, code, {
      session: this
    });
  }.bind(this);

  this.logEvent = function(action, variation, payload, options) {
    var event = {
      action: action,
      variation: variation,
      payload: payload,
      sessionId: _sessionId,
      messagingServer: _messagingServer,
      p2p: _p2p,
      partnerId: _apiKey
    };

    event.connectionId = _connectionId;

    if (options) { event = OTHelpers.extend(options, event); }
    analytics.logEvent(event);
  };

  /**
   * @typedef {Object} Stats
   * @property {number} bytesSentPerSecond
   * @property {number} bytesReceivedPerSecond
   * @property {number} packetLossRatio
   * @property {number} rtt
   */

  function getTestNetworkConfig(token) {
    return new Bluebird.Promise(function(resolve, reject) {
      OTHelpers.getJSON(
        [properties.apiURL, '/v2/partner/', _apiKey, '/session/', _sessionId, '/connection/',
          _connectionId, '/testNetworkConfig'].join(''),
        {
          headers: { 'X-OPENTOK-AUTH': token }
        },
        function(errorEvent, response) {
          if (errorEvent) {
            var error = JSON.parse(errorEvent.target.responseText);
            if (error.code === -1) {
              reject(new OTHelpers.Error('Unexpected HTTP error codes ' +
              errorEvent.target.status, '2001'));
            } else if (error.code === 10001 || error.code === 10002) {
              reject(new OTHelpers.Error(error.message, '1004'));
            } else {
              reject(new OTHelpers.Error(error.message, error.code));
            }
          } else {
            resolve(response);
          }
        });
    });
  }

  /**
   * @param {string} token
   * @param {Publisher} publisher
   * @param {function(?OTHelpers.Error, Stats=)} callback
   */
  this.testNetwork = function(token, publisher, callback) {
    _session.logEvent('TestNetwork');

    if (this.isConnected()) {
      callback(new OTHelpers.Error('Session connected, cannot test network', 1015));
      return;
    }

    var webRtcStreamPromise = new Bluebird.Promise(
      function(resolve, reject) {
        var webRtcStream = publisher._.webRtcStream();
        if (webRtcStream) {
          resolve(webRtcStream);
        } else {

          var onAccessAllowed = function() {
            unbind();
            resolve(publisher._.webRtcStream());
          };

          var onPublishComplete = function(error) {
            if (error) {
              unbind();
              reject(error);
            }
          };

          var unbind = function() {
            publisher.off('publishComplete', onPublishComplete);
            publisher.off('accessAllowed', onAccessAllowed);
          };

          publisher.on('publishComplete', onPublishComplete);
          publisher.on('accessAllowed', onAccessAllowed);

        }
      });

    var testConfig, webrtcStats;

    Bluebird.Promise.all([getTestNetworkConfig(token), webRtcStreamPromise])
      .then(function(values) {
        var webRtcStream = values[1];
        testConfig = values[0];
        return webrtcTest({ mediaConfig: testConfig.media, localStream: webRtcStream });
      })
      .then(function(stats) {
        logging.debug('Received stats from webrtcTest: ', stats);
        if (stats.bandwidth < testConfig.media.thresholdBitsPerSecond) {
          return Bluebird.Promise.reject(new OTHelpers.Error(
            'The detect bandwidth from the WebRTC stage of the test was not sufficient to run ' +
            'the HTTP stage of the test', 1553
          ));
        }

        webrtcStats = stats;
        return undefined;
      })
      .then(function() {
        // run the HTTP test only if the PC test was not extended
        if (!webrtcStats.extended) {
          return httpTest({ httpConfig: testConfig.http });
        }
        return undefined;
      })
      .then(function(httpStats) {
        var stats = {
          uploadBitsPerSecond: httpStats ? httpStats.uploadBandwidth : webrtcStats.bandwidth,
          downloadBitsPerSecond: httpStats ? httpStats.downloadBandwidth : webrtcStats.bandwidth,
          packetLossRatio: webrtcStats.packetLostRatio,
          roundTripTimeMilliseconds: webrtcStats.roundTripTime
        };
        callback(null, stats);
        // IE9 (ES3 JS engine) requires bracket notation for "catch" keyword
      }).catch(function(error) {
        callback(error);
      });
  };

  this.logConnectivityEvent = function(variation, payload, options) {
    if (variation === 'Attempt' || !_connectivityAttemptPinger) {
      _attemptStartTime = new Date().getTime();
      var pingerOptions = {
        action: 'Connect',
        sessionId: _sessionId,
        p2p: this.sessionInfo ? this.sessionInfo.p2pEnabled : null,
        messagingServer: this.sessionInfo ? this.sessionInfo.messagingServer : null,
        partnerId: _apiKey
      };
      if (this.connection && this.connection.id) {
        pingerOptions = event.connectionId = this.connection.id;
      } else if (_connectionId) {
        pingerOptions.connectionId = _connectionId;
      }
      _connectivityAttemptPinger = new ConnectivityAttemptPinger(pingerOptions);
    }
    _connectivityAttemptPinger.setVariation(variation);
    if (variation === 'Failure' || variation === 'Success' || variation === 'Cancel') {
      if (!options) { options = {}; }
      OTHelpers.extend(options, {
        attemptDuration: new Date().getTime() - _attemptStartTime
      });
    }
    this.logEvent('Connect', variation, payload, options);
  };

  /**
  * Connects to an OpenTok session.
  * <p>
  *  Upon a successful connection, the completion handler (the second parameter of the method) is
  *  invoked without an error object passed in. (If there is an error connecting, the completion
  *  handler is invoked with an error object.) Make sure that you have successfully connected to the
  *  session before calling other methods of the Session object.
  * </p>
  *  <p>
  *    The Session object dispatches a <code>connectionCreated</code> event when any client
  *    (including your own) connects to the session.
  *  </p>
  *
  *  <h5>
  *  Example
  *  </h5>
  *  <p>
  *  The following code initializes a session and sets up an event listener for when the session
  *  connects:
  *  </p>
  *  <pre>
  *  var apiKey = ""; // Replace with your API key. See https://tokbox.com/account
  *  var sessionID = ""; // Replace with your own session ID.
  *                      // See https://tokbox.com/developer/guides/create-session/.
  *  var token = ""; // Replace with a generated token.
  *                  // See https://tokbox.com/developer/guides/create-token/.
  *
  *  var session = OT.initSession(apiKey, sessionID);
  *  session.connect(token, function(error) {
  *    if (error) {
  *      console.log(error.message);
  *    } else {
  *      // You have connected to the session. You could publish a stream now.
  *    }
  *  });
  *  </pre>
  *  <p>
  *
  *  <h5>
  *  Events dispatched:
  *  </h5>
  *
  *  <p>
  *    <code>exception</code> (<a href="ExceptionEvent.html">ExceptionEvent</a>) &#151; Dispatched
  *    by the OT class locally in the event of an error.
  *  </p>
  *  <p>
  *    <code>connectionCreated</code> (<a href="ConnectionEvent.html">ConnectionEvent</a>) &#151;
  *      Dispatched by the Session object on all clients connected to the session.
  *  </p>
  *  <p>
  *    <code>sessionConnected</code> (<a href="SessionConnectEvent.html">SessionConnectEvent</a>)
  *      &#151; Dispatched locally by the Session object when the connection is established. However,
  *      you can pass a completion handler function in as the second parameter of the
  *      <code>connect()</code> and use this function instead of a listener for the
  *      <code>sessionConnected</code> event.
  *  </p>
  *
  * @param {String} token The session token. You generate a session token using our
  * <a href="https://tokbox.com/opentok/libraries/server/">server-side libraries</a> or at your
  * <a href="https://tokbox.com/account">TokBox account</a> page. For more information, see
  * <a href="https://tokbox.com/opentok/tutorials/create-token/">Connection token creation</a>.
  *
  * @param {Function} completionHandler (Optional) A function to be called when the call to the
  * <code>connect()</code> method succeeds or fails. This function takes one parameter &mdash;
  * <code>error</code> (see the <a href="Error.html">Error</a> object).
  * On success, the <code>completionHandler</code> function is not passed any
  * arguments. On error, the function is passed an <code>error</code> object parameter
  * (see the <a href="Error.html">Error</a> object). The
  * <code>error</code> object has two properties: <code>code</code> (an integer) and
  * <code>message</code> (a string), which identify the cause of the failure. The following
  * code adds a <code>completionHandler</code> when calling the <code>connect()</code> method:
  * <pre>
  * session.connect(token, function (error) {
  *   if (error) {
  *       console.log(error.message);
  *   } else {
  *     console.log("Connected to session.");
  *   }
  * });
  * </pre>
  * <p>
  * Note that upon connecting to the session, the Session object dispatches a
  * <code>sessionConnected</code> event in addition to calling the <code>completionHandler</code>.
  * The SessionConnectEvent object, which defines the <code>sessionConnected</code> event,
  * includes <code>connections</code> and <code>streams</code> properties, which
  * list the connections and streams in the session when you connect.
  * </p>
  *
  * @see SessionConnectEvent
  * @method #connect
  * @memberOf Session
*/
  this.connect = function(token) {

    if (arguments.length > 1 &&
      (typeof arguments[0] === 'string' || typeof arguments[0] === 'number') &&
      typeof arguments[1] === 'string') {
      if (apiKey == null) { _apiKey = token.toString(); }
      token = arguments[1];
    }

    // The completion handler is always the last argument.
    var completionHandler = arguments[arguments.length - 1];

    if (this.is('connecting', 'connected')) {
      logging.warn('OT.Session: Cannot connect, the session is already ' + this.state);
      return this;
    }

    init();
    setState('connecting');
    this.token = _token = !OTHelpers.isFunction(token) && token;

    // Get a new widget ID when reconnecting.
    if (_initialConnection) {
      _initialConnection = false;
    } else {
      _widgetId = uuid();
    }

    if (completionHandler && OTHelpers.isFunction(completionHandler)) {
      this.once('sessionConnected', completionHandler.bind(null, undefined));
      this.once('sessionConnectFailed', completionHandler);
    }

    if (_apiKey == null || OTHelpers.isFunction(_apiKey)) {
      setTimeout(sessionConnectFailed.bind(this,
        'API Key is undefined. You must pass an API Key to initSession.',
        ExceptionCodes.AUTHENTICATION_ERROR
      ));

      return this;
    }

    this.logConnectivityEvent('Attempt');

    if (!_sessionId || OTHelpers.isObject(_sessionId) || Array.isArray(_sessionId)) {
      var errorMsg;
      if (!_sessionId) {
        errorMsg = 'SessionID is undefined. You must pass a sessionID to initSession.';
      } else {
        errorMsg = 'SessionID is not a string. You must use string as the session ID passed into ' +
          'OT.initSession().';
        _sessionId = _sessionId.toString();
      }
      setTimeout(sessionConnectFailed.bind(this,
        errorMsg,
        ExceptionCodes.INVALID_SESSION_ID
      ));

      this.logConnectivityEvent('Failure', null, {
        failureReason: 'ConnectToSession',
        failureCode: ExceptionCodes.INVALID_SESSION_ID,
        failureMessage: errorMsg
      });
      return this;
    }

    this.apiKey = _apiKey = _apiKey.toString();

    // TODO: Ugly hack, make sure APIKEY is set
    if (APIKEY.value.length === 0) {
      APIKEY.value = _apiKey;
    }

    getSessionInfo.call(this);
    return this;
  };

  /**
  * Disconnects from the OpenTok session.
  *
  * <p>
  * Calling the <code>disconnect()</code> method ends your connection with the session. In the
  * course of terminating your connection, it also ceases publishing any stream(s) you were
  * publishing.
  * </p>
  * <p>
  * Session objects on remote clients dispatch <code>streamDestroyed</code> events for any
  * stream you were publishing. The Session object dispatches a <code>sessionDisconnected</code>
  * event locally. The Session objects on remote clients dispatch <code>connectionDestroyed</code>
  * events, letting other connections know you have left the session. The
  * {@link SessionDisconnectEvent} and {@link StreamEvent} objects that define the
  * <code>sessionDisconnect</code> and <code>connectionDestroyed</code> events each have a
  * <code>reason</code> property. The <code>reason</code> property lets the developer determine
  * whether the connection is being terminated voluntarily and whether any streams are being
  * destroyed as a byproduct of the underlying connection's voluntary destruction.
  * </p>
  * <p>
  * If the session is not currently connected, calling this method causes a warning to be logged.
  * See <a href="OT.html#setLogLevel">OT.setLogLevel()</a>.
  * </p>
  *
  * <p>
  * <i>Note:</i> If you intend to reuse a Publisher object created using
  * <code>OT.initPublisher()</code> to publish to different sessions sequentially, call either
  * <code>Session.disconnect()</code> or <code>Session.unpublish()</code>. Do not call both.
  * Then call the <code>preventDefault()</code> method of the Publisher's <code>streamDestroyed</code>
  * event object to prevent the Publisher object from being removed from the page. Be sure to
  * call <code>preventDefault()</code> only if the <code>connection.connectionId</code> property
  * of the Stream object in the event matches the <code>connection.connectionId</code> property of
  * your Session object (to ensure that you are preventing the default behavior for your published
  * streams, not for other streams that you subscribe to).
  * </p>
  *
  * <h5>
  * Events dispatched:
  * </h5>
  * <p>
  * <code>sessionDisconnected</code>
  * (<a href="SessionDisconnectEvent.html">SessionDisconnectEvent</a>)
  * &#151; Dispatched locally when the connection is disconnected.
  * </p>
  * <p>
  * <code>connectionDestroyed</code> (<a href="ConnectionEvent.html">ConnectionEvent</a>) &#151;
  * Dispatched on other clients, along with the <code>streamDestroyed</code> event (as warranted).
  * </p>
  *
  * <p>
  * <code>streamDestroyed</code> (<a href="StreamEvent.html">StreamEvent</a>) &#151;
  * Dispatched on other clients if streams are lost as a result of the session disconnecting.
  * </p>
  *
  * @method #disconnect
  * @memberOf Session
*/
  var disconnect = function disconnect(drainSocketBuffer) {
    if (_socket && _socket.isNot('disconnected')) {
      if (_socket.isNot('disconnecting')) {
        setState('disconnecting');
        _socket.disconnect(drainSocketBuffer);
      }
    } else {
      reset();
    }
  };

  this.disconnect = function(drainSocketBuffer) {
    disconnect(drainSocketBuffer !== void 0 ? drainSocketBuffer : true);
  };

  this.destroy = function(reason) {
    this.streams.destroy();
    this.connections.destroy();
    this.archives.destroy();
    disconnect(reason !== 'unloaded');
  };

  /**
  * The <code>publish()</code> method starts publishing an audio-video stream to the session.
  * The audio-video stream is captured from a local microphone and webcam. Upon successful
  * publishing, the Session objects on all connected clients dispatch the
  * <code>streamCreated</code> event.
  * </p>
  *
  * <!--JS-ONLY-->
  * <p>You pass a Publisher object as the one parameter of the method. You can initialize a
  * Publisher object by calling the <a href="OT.html#initPublisher">OT.initPublisher()</a>
  * method. Before calling <code>Session.publish()</code>.
  * </p>
  *
  * <p>This method takes an alternate form: <code>publish([targetElement:String,
  * properties:Object]):Publisher</code> &#151; In this form, you do <i>not</i> pass a Publisher
  * object into the function. Instead, you pass in a <code>targetElement</code> (the ID of the
  * DOM element that the Publisher will replace) and a <code>properties</code> object that
  * defines options for the Publisher (see <a href="OT.html#initPublisher">OT.initPublisher()</a>.)
  * The method returns a new Publisher object, which starts sending an audio-video stream to the
  * session. The remainder of this documentation describes the form that takes a single Publisher
  * object as a parameter.
  *
  * <p>
  *   A local display of the published stream is created on the web page by replacing
  *         the specified element in the DOM with a streaming video display. The video stream
  *         is automatically mirrored horizontally so that users see themselves and movement
  *         in their stream in a natural way. If the width and height of the display do not match
  *         the 4:3 aspect ratio of the video signal, the video stream is cropped to fit the
  *         display.
  * </p>
  *
  * <p>
  *   If calling this method creates a new Publisher object and the OpenTok library does not
  *   have access to the camera or microphone, the web page alerts the user to grant access
  *   to the camera and microphone.
  * </p>
  *
  * <p>
  * The OT object dispatches an <code>exception</code> event if the user's role does not
  * include permissions required to publish. For example, if the user's role is set to subscriber,
  * then they cannot publish. You define a user's role when you create the user token
  * (see <a href="https://tokbox.com/developer/guides/create-token/">Token creation overview</a>).
  * You pass the token string as a parameter of the <code>connect()</code> method of the Session
  * object. See <a href="ExceptionEvent.html">ExceptionEvent</a> and
  * <a href="OT.html#on">OT.on()</a>.
  * </p>
  *     <p>
  *     The application throws an error if the session is not connected.
  *     </p>
  *
  * <h5>Events dispatched:</h5>
  * <p>
  * <code>exception</code> (<a href="ExceptionEvent.html">ExceptionEvent</a>) &#151; Dispatched
  * by the OT object. This can occur when user's role does not allow publishing (the
  * <code>code</code> property of event object is set to 1500); it can also occur if the c
  * onnection fails to connect (the <code>code</code> property of event object is set to 1013).
  * WebRTC is a peer-to-peer protocol, and it is possible that connections will fail to connect.
  * The most common cause for failure is a firewall that the protocol cannot traverse.</li>
  * </p>
  * <p>
  * <code>streamCreated</code> (<a href="StreamEvent.html">StreamEvent</a>) &#151;
  * The stream has been published. The Session object dispatches this on all clients
  * subscribed to the stream, as well as on the publisher's client.
  * </p>
  *
  * <h5>Example</h5>
  *
  * <p>
  *   The following example publishes a video once the session connects:
  * </p>
  * <pre>
  * var apiKey = ""; // Replace with your API key. See https://tokbox.com/account
  * var sessionId = ""; // Replace with your own session ID.
  *                     // https://tokbox.com/developer/guides/create-session/.
  * var token = ""; // Replace with a generated token that has been assigned the publish role.
  *                 // See https://tokbox.com/developer/guides/create-token/.
  * var session = OT.initSession(apiKey, sessionID);
  * session.connect(token, function(error) {
  *   if (error) {
  *     console.log(error.message);
  *   } else {
  *     var publisherOptions = {width: 400, height:300, name:"Bob's stream"};
  *     // This assumes that there is a DOM element with the ID 'publisher':
  *     publisher = OT.initPublisher('publisher', publisherOptions);
  *     session.publish(publisher);
  *   }
  * });
  * </pre>
  *
  * @param {Publisher} publisher A Publisher object, which you initialize by calling the
  * <a href="OT.html#initPublisher">OT.initPublisher()</a> method.
  *
  * @param {Function} completionHandler (Optional) A function to be called when the call to the
  * <code>publish()</code> method succeeds or fails. This function takes one parameter &mdash;
  * <code>error</code>. On success, the <code>completionHandler</code> function is not passed any
  * arguments. On error, the function is passed an <code>error</code> object parameter
  * (see the <a href="Error.html">Error</a> object). The
  * <code>error</code> object has two properties: <code>code</code> (an integer) and
  * <code>message</code> (a string), which identify the cause of the failure. Calling
  * <code>publish()</code> fails if the role assigned to your token is not "publisher" or
  * "moderator"; in this case <code>error.code</code> is set to 1500. Calling
  * <code>publish()</code> also fails the client fails to connect; in this case
  * <code>error.code</code> is set to 1013. The following code adds a
  * <code>completionHandler</code> when calling the <code>publish()</code> method:
  * <pre>
  * session.publish(publisher, null, function (error) {
  *   if (error) {
  *     console.log(error.message);
  *   } else {
  *     console.log("Publishing a stream.");
  *   }
  * });
  * </pre>
  *
  * @returns The Publisher object for this stream.
  *
  * @method #publish
  * @memberOf Session
*/
  this.publish = function(publisher, properties, completionHandler) {
    if (typeof publisher === 'function') {
      completionHandler = publisher;
      publisher = undefined;
    }
    if (typeof properties === 'function') {
      completionHandler = properties;
      properties = undefined;
    }
    if (this.isNot('connected')) {
      analytics.logError(
        1010,
        'OT.exception',
        'We need to be connected before you can publish',
        null,
        {
          action: 'Publish',
          variation: 'Failure',
          failureReason: 'unconnected',
          failureCode: ExceptionCodes.NOT_CONNECTED,
          failureMessage: 'We need to be connected before you can publish',
          sessionId: _sessionId,
          streamId: (publisher && publisher.stream) ? publisher.stream.id : null,
          p2p: this.sessionInfo ? this.sessionInfo.p2pEnabled : undefined,
          messagingServer: this.sessionInfo ? this.sessionInfo.messagingServer : null,
          partnerId: _apiKey
        }
      );

      if (completionHandler && OTHelpers.isFunction(completionHandler)) {
        dispatchError(ExceptionCodes.NOT_CONNECTED,
          'We need to be connected before you can publish', completionHandler);
      }

      return null;
    }

    if (!permittedTo('publish')) {
      var errorMessage = 'This token does not allow publishing. The role must be at least ' +
        '`publisher` to enable this functionality';
      var options = {
        failureReason: 'Permission',
        failureCode: ExceptionCodes.UNABLE_TO_PUBLISH,
        failureMessage: errorMessage
      };
      this.logEvent('Publish', 'Failure', null, options);
      dispatchError(ExceptionCodes.UNABLE_TO_PUBLISH, errorMessage, completionHandler);
      return null;
    }

    // If the user has passed in an ID of a element then we create a new publisher.
    if (!publisher || typeof (publisher) === 'string' || OTHelpers.isElementNode(publisher)) {
      // Initiate a new Publisher with the new session credentials
      publisher = initPublisher(publisher, properties);

    } else if (publisher instanceof Publisher) {

      // If the publisher already has a session attached to it we can
      if ('session' in publisher && publisher.session && 'sessionId' in publisher.session) {
        // send a warning message that we can't publish again.
        if (publisher.session.sessionId === this.sessionId) {
          logging.warn('Cannot publish ' + publisher.guid() + ' again to ' +
            this.sessionId + '. Please call session.unpublish(publisher) first.');
        } else {
          logging.warn('Cannot publish ' + publisher.guid() + ' publisher already attached to ' +
            publisher.session.sessionId + '. Please call session.unpublish(publisher) first.');
        }
      }

    } else {
      dispatchError(ExceptionCodes.UNABLE_TO_PUBLISH,
        'Session.publish :: First parameter passed in is neither a ' +
        'string nor an instance of the Publisher',
        completionHandler);
      return undefined;
    }

    publisher.once('publishComplete', function() {
      var args = Array.prototype.slice.call(arguments);
      var err = args[0];

      if (err) {
        err.message = 'Session.publish :: ' + err.message;
        args[0] = err;

        logging.error(err.code, err.message);
      }

      if (completionHandler && OTHelpers.isFunction(completionHandler)) {
        completionHandler.apply(null, args);
      }
    });

    // Add publisher reference to the session
    publisher._.publishToSession(this);

    // return the embed publisher
    return publisher;
  };

  /**
  * Ceases publishing the specified publisher's audio-video stream
  * to the session. By default, the local representation of the audio-video stream is
  * removed from the web page. Upon successful termination, the Session object on every
  * connected web page dispatches
  * a <code>streamDestroyed</code> event.
  * </p>
  *
  * <p>
  * To prevent the Publisher from being removed from the DOM, add an event listener for the
  * <code>streamDestroyed</code> event dispatched by the Publisher object and call the
  * <code>preventDefault()</code> method of the event object.
  * </p>
  *
  * <p>
  * <i>Note:</i> If you intend to reuse a Publisher object created using
  * <code>OT.initPublisher()</code> to publish to different sessions sequentially, call
  * either <code>Session.disconnect()</code> or <code>Session.unpublish()</code>. Do not call
  * both. Then call the <code>preventDefault()</code> method of the <code>streamDestroyed</code>
  * or <code>sessionDisconnected</code> event object to prevent the Publisher object from being
  * removed from the page. Be sure to call <code>preventDefault()</code> only if the
  * <code>connection.connectionId</code> property of the Stream object in the event matches the
  * <code>connection.connectionId</code> property of your Session object (to ensure that you are
  * preventing the default behavior for your published streams, not for other streams that you
  * subscribe to).
  * </p>
  *
  * <h5>Events dispatched:</h5>
  *
  * <p>
  * <code>streamDestroyed</code> (<a href="StreamEvent.html">StreamEvent</a>) &#151;
  * The stream associated with the Publisher has been destroyed. Dispatched on by the
  * Publisher on on the Publisher's browser. Dispatched by the Session object on
  * all other connections subscribing to the publisher's stream.
  * </p>
  *
  * <h5>Example</h5>
  *
  * The following example publishes a stream to a session and adds a Disconnect link to the
  * web page. Clicking this link causes the stream to stop being published.
  *
  * <pre>
  * &lt;script&gt;
  *   var apiKey = ""; // Replace with your API key. See https://tokbox.com/account
  *   var sessionID = ""; // Replace with your own session ID.
  *                    // See https://tokbox.com/developer/guides/create-session/.
  *   var token = ""; // Replace with a generated token.
  *                   // See https://tokbox.com/developer/guides/create-token/.
  *   var publisher;
  *   var session = OT.initSession(apiKey, sessionID);
  *   session.connect(token, function(error) {
  *     if (error) {
  *       console.log(error.message);
  *     } else {
  *       // This assumes that there is a DOM element with the ID 'publisher':
  *       publisher = OT.initPublisher('publisher');
  *       session.publish(publisher);
  *     }
  *   });
  *
  *   function unpublish() {
  *     session.unpublish(publisher);
  *   }
  * &lt;/script&gt;
  *
  * &lt;body&gt;
  *
  *     &lt;div id="publisherContainer/&gt;
  *     &lt;br/&gt;
  *
  *     &lt;a href="javascript:unpublish()"&gt;Stop Publishing&lt;/a&gt;
  *
  * &lt;/body&gt;
  *
  * </pre>
  *
  * @see <a href="#publish">publish()</a>
  *
  * @see <a href="StreamEvent.html">streamDestroyed event</a>
  *
  * @param {Publisher} publisher</span> The Publisher object to stop streaming.
  *
  * @method #unpublish
  * @memberOf Session
*/
  this.unpublish = function(publisher) {
    if (!publisher) {
      logging.error('OT.Session.unpublish: publisher parameter missing.');
      return;
    }

    // Unpublish the localMedia publisher
    publisher._.unpublishFromSession(this, 'unpublished');
  };

  /**
  * Subscribes to a stream that is available to the session. You can get an array of
  * available streams from the <code>streams</code> property of the <code>sessionConnected</code>
  * and <code>streamCreated</code> events (see
  * <a href="SessionConnectEvent.html">SessionConnectEvent</a> and
  * <a href="StreamEvent.html">StreamEvent</a>).
  * </p>
  * <p>
  * The subscribed stream is displayed on the local web page by replacing the specified element
  * in the DOM with a streaming video display. If the width and height of the display do not
  * match the 4:3 aspect ratio of the video signal, the video stream is cropped to fit
  * the display. If the stream lacks a video component, a blank screen with an audio indicator
  * is displayed in place of the video stream.
  * </p>
  *
  * <p>
  * The application throws an error if the session is not connected<!--JS-ONLY--> or if the
  * <code>targetElement</code> does not exist in the HTML DOM<!--/JS-ONLY-->.
  * </p>
  *
  * <h5>Example</h5>
  *
  * The following code subscribes to other clients' streams:
  *
  * <pre>
  * var apiKey = ""; // Replace with your API key. See https://tokbox.com/account
  * var sessionID = ""; // Replace with your own session ID.
  *                     // See https://tokbox.com/developer/guides/create-session/.
  * var token = ""; // Replace with a generated token.
  *                 // See https://tokbox.com/developer/guides/create-token/.
  *
  * var session = OT.initSession(apiKey, sessionID);
  * session.on("streamCreated", function(event) {
  *   subscriber = session.subscribe(event.stream, targetElement);
  * });
  * session.connect(token);
  * </pre>
  *
  * @param {Stream} stream The Stream object representing the stream to which we are trying to
  * subscribe.
  *
  * @param {Object} targetElement (Optional) The DOM element or the <code>id</code> attribute of
  * the existing DOM element used to determine the location of the Subscriber video in the HTML
  * DOM. See the <code>insertMode</code> property of the <code>properties</code> parameter. If
  * you do not specify a <code>targetElement</code>, the application appends a new DOM element
  * to the HTML <code>body</code>.
  *
  * @param {Object} properties This is an object that contains the following properties:
  *    <ul>
  *       <li><code>audioVolume</code> (Number) &#151; The desired audio volume, between 0 and
  *       100, when the Subscriber is first opened (default: 50). After you subscribe to the
  *       stream, you can adjust the volume by calling the
  *       <a href="Subscriber.html#setAudioVolume"><code>setAudioVolume()</code> method</a> of the
  *       Subscriber object. This volume setting affects local playback only; it does not affect
  *       the stream's volume on other clients.</li>
  *
  *       <li>
  *         <code>fitMode</code> (String) &#151; Determines how the video is displayed if the its
  *           dimensions do not match those of the DOM element. You can set this property to one of
  *           the following values:
  *           <p>
  *           <ul>
  *             <li>
  *               <code>"cover"</code> &mdash; The video is cropped if its dimensions do not match
  *               those of the DOM element. This is the default setting for videos that have a
  *               camera as the source (for Stream objects with the <code>videoType</code> property
  *               set to <code>"camera"</code>).
  *             </li>
  *             <li>
  *               <code>"contain"</code> &mdash; The video is letterboxed if its dimensions do not
  *               match those of the DOM element. This is the default setting for screen-sharing
  *               videos (for Stream objects with the <code>videoType</code> property set to
  *               <code>"screen"</code>).
  *             </li>
  *           </ul>
  *       </li>
  *
  *       <li>
  *       <code>height</code> (Number) &#151; The desired initial height of the displayed
  *       video in the HTML page (default: 198 pixels). You can specify the number of pixels as
  *       either a number (such as 300) or a string ending in "px" (such as "300px"). Or you can
  *       specify a percentage of the size of the parent element, with a string ending in "%"
  *       (such as "100%"). <i>Note:</i> To resize the video, adjust the CSS of the subscriber's
  *       DOM element (the <code>element</code> property of the Subscriber object) or (if the
  *       height is specified as a percentage) its parent DOM element (see
  *       <a href="https://tokbox.com/developer/guides/customize-ui/js/#video_resize_reposition">
  *       Resizing or repositioning a video</a>).
  *       </li>
  *       <li>
  *       <strong>insertDefaultUI</strong> (Boolean) &#151; Whether to use the default OpenTok UI
  *       (<code>true</code>, the default) or not (<code>false</code>). The default UI element
  *       contains user interface controls, a video loading indicator, and automatic video cropping
  *       or letterboxing, in addition to the video. (If you leave <code>insertDefaultUI</code>
  *       set to <code>true</code>, you can control individual UI settings using the
  *       <code>fitMode</code>, <code>showControls</code>, and <code>style</code> options.)
  *       <p>
  *       If you set this option to <code>false</code>, OpenTok.js does not insert a default UI
  *       element in the HTML DOM, and the <code>element</code> property of the Subscriber object is
  *       undefined. The Subscriber object dispatches a
  *       <a href="Subscriber.html#event:videoElementCreated">videoElementCreated</a> event when
  *       the <code>video</code> element (or in Internet Explorer the <code>object</code> element
  *       containing the video) is created. The <code>element</code> property of the event object
  *       is a reference to the Subscriber's <code>video</code> (or <code>object</code>) element.
  *       Add it to the HTML DOM to display the video.
  *       <p>
  *       Set this option to <code>false</code> if you want to move the Publisher's
  *       <code>video</code> element (or its <code>object</code> element in Internet Explorer) in
  *       the HTML DOM.
  *       <p>
  *       If you set this to <code>false</code>, do not set the <code>targetElement</code>
  *       parameter. (This results in an error passed into to the <code>OT.initPublisher()</code>
  *       callback function.) To add the video to the HTML DOM, add an event listener for the
  *       <code>videoElementCreated</code> event, and then add the <code>element</code> property of
  *       the event object into the HTML DOM.
  *       </li>
  *       <li>
  *         <code>insertMode</code> (String) &#151; Specifies how the Subscriber object will
  *         be inserted in the HTML DOM. See the <code>targetElement</code> parameter. This
  *         string can have the following values:
  *         <p>
  *         <ul>
  *           <li><code>"replace"</code> &#151; The Subscriber object replaces contents of the
  *             targetElement. This is the default.</li>
  *           <li><code>"after"</code> &#151; The Subscriber object is a new element inserted
  *             after the targetElement in the HTML DOM. (Both the Subscriber and targetElement
  *             have the same parent element.)</li>
  *           <li><code>"before"</code> &#151; The Subscriber object is a new element inserted
  *             before the targetElement in the HTML DOM. (Both the Subsciber and targetElement
  *             have the same parent element.)</li>
  *           <li><code>"append"</code> &#151; The Subscriber object is a new element added as a
  *             child of the targetElement. If there are other child elements, the Subscriber is
  *             appended as the last child element of the targetElement.</li>
  *         </ul></p>
  *   <p> Do not move the publisher element or its parent elements in the DOM
  *   heirarchy. Use CSS to resize or reposition the publisher video's element
  *   (the <code>element</code> property of the Publisher object) or its parent element (see
  *   <a href="https://tokbox.com/developer/guides/customize-ui/js/#video_resize_reposition">
  *   Resizing or repositioning a video</a>).</p>
  *   </li>
  *   <li>
  *   <code>preferredFrameRate</code> (Number) &#151; The preferred frame rate of the subscriber's
  *   video. Lowering the preferred frame rate lowers video quality on the subscribing client,
  *   but it also reduces network and CPU usage. You may want to use a lower frame rate for
  *   subscribers to a stream that is less important than other streams.
  *   <p>
  *   Not every frame rate is available to a subscriber. When you set the preferred frame rate for
  *   the subscriber, OpenTok.js picks the best frame rate available that matches your setting.
  *   The frame rates available are based on the value of the Subscriber object's
  *   <code>stream.frameRate</code> property, which represents the maximum value available for the
  *   stream. The actual frame rates available depend, dynamically, on network and CPU resources
  *   available to the publisher and subscriber.
  *   <p>
  *   You can dynamically change the preferred frame rate used by calling the
  *   <code>setPreferredFrameRate()</code> method of the Subscriber object.
  *   </li>
  *   <li>
  *   <code>preferredResolution</code> (Object) &#151; The preferred resolution of the subscriber's
  *   video. Set this to an object with two properties: <code>width</code> and <code>height</code>
  *   (both numbers), such as <code>{width: 320, height: 240}</code>. Lowering the preferred video
  *   resolution lowers video quality on the subscribing client, but it also reduces network and CPU
  *   usage. You may want to use a lower resolution based on the dimensions of subscriber's video on
  *   the web page. You may want to use a resolution for subscribers to a stream that is less
  *   important (and smaller) than other streams.
  *   <p>
  *   Not every resolution is available to a subscriber. When you set the preferred resolution,
  *   OpenTok.js and the video encoder pick the best resolution available that matches your
  *   setting. The resolutions available depend on the resolution of the published stream.
  *   The Subscriber object's <code>stream.resolution</code> property  represents the highest
  *   resolution available for the stream. Each of the resolutions available for a stream will use
  *   the same aspect ratio. The actual resolutions available depend, dynamically, on network
  *   and CPU resources available to the publisher and subscriber.
  *   <p>
  *   You can dynamically change the preferred video resolution used by calling the
  *   <code>setPreferredResolution()</code> method of the Subscriber object.
  *   </li>
  *   <li>
  *   <code>showControls</code> (Boolean) &#151; Whether to display the built-in user interface
  *   controls for the Subscriber (default: <code>true</code>). These controls include the name
  *   display, the audio level indicator, the speaker control button, the video disabled indicator,
  *   and the video disabled warning icon. You can turn off all user interface controls by setting
  *   this property to <code>false</code>. You can control the display of individual user interface
  *   controls by leaving this property set to <code>true</code> (the default) and setting
  *   individual properties of the <code>style</code> property.
  *   </li>
  *   <li>
  *   <code>style</code> (Object) &#151; An object containing properties that define the initial
  *   appearance of user interface controls of the Subscriber. The <code>style</code> object
  *   includes the following properties:
  *     <ul>
  *       <li><code>audioLevelDisplayMode</code> (String) &mdash; How to display the audio level
  *       indicator. Possible values are: <code>"auto"</code> (the indicator is displayed when the
  *       video is disabled), <code>"off"</code> (the indicator is not displayed), and
  *       <code>"on"</code> (the indicator is always displayed).</li>
  *
  *       <li><code>backgroundImageURI</code> (String) &mdash; A URI for an image to display as
  *       the background image when a video is not displayed. (A video may not be displayed if
  *       you call <code>subscribeToVideo(false)</code> on the Subscriber object). You can pass an
  *       http or https URI to a PNG, JPEG, or non-animated GIF file location. You can also use the
  *       <code>data</code> URI scheme (instead of http or https) and pass in base-64-encrypted
  *       PNG data, such as that obtained from the
  *       <a href="Subscriber.html#getImgData">Subscriber.getImgData()</a> method. For example,
  *       you could set the property to <code>"data:VBORw0KGgoAA..."</code>, where the portion of
  *       the string after <code>"data:"</code> is the result of a call to
  *       <code>Subscriber.getImgData()</code>. If the URL or the image data is invalid, the
  *       property is ignored (the attempt to set the image fails silently).</li>
  *
  *       <li><code>buttonDisplayMode</code> (String) &mdash; How to display the speaker controls
  *       Possible values are: <code>"auto"</code> (controls are displayed when the stream is first
  *       displayed and when the user mouses over the display), <code>"off"</code> (controls are not
  *       displayed), and <code>"on"</code> (controls are always displayed).</li>
  *
  *       <li><code>nameDisplayMode</code> (String) &#151; Whether to display the stream name.
  *       Possible values are: <code>"auto"</code> (the name is displayed when the stream is first
  *       displayed and when the user mouses over the display), <code>"off"</code> (the name is not
  *       displayed), and <code>"on"</code> (the name is always displayed).</li>
  *
  *       <li><code>videoDisabledDisplayMode</code> (String) &#151; Whether to display the video
  *       disabled indicator and video disabled warning icons for a Subscriber. These icons
  *       indicate that the video has been disabled (or is in risk of being disabled for
  *       the warning icon) due to poor stream quality. This style only applies to the Subscriber
  *       object. Possible values are: <code>"auto"</code> (the icons are automatically when the
  *       displayed video is disabled or in risk of being disabled due to poor stream quality),
  *       <code>"off"</code> (do not display the icons), and <code>"on"</code> (display the
  *       icons). The default setting is <code>"auto"</code></li>
  *   </ul>
  *   </li>
  *
  *       <li><code>subscribeToAudio</code> (Boolean) &#151; Whether to initially subscribe to audio
  *       (if available) for the stream (default: <code>true</code>).</li>
  *
  *       <li><code>subscribeToVideo</code> (Boolean) &#151; Whether to initially subscribe to video
  *       (if available) for the stream (default: <code>true</code>).</li>
  *
  *       <li><code>testNetwork</code> (Boolean) &#151; Whether, when subscribing to a stream
  *       published by the local client, you want to have the stream come from the OpenTok Media
  *       Router (<code>true</code>) or if you want the DOM to simply to display the local camera's
  *       video (<code>false</code>). Set this to <code>true</code> when you want to use the
  *       <a href="Subscriber.html#getStats">Subscriber.getStats()</a> method to check statistics
  *       for a stream you publish. This setting only applies to streams published by the local
  *       client in a session that uses the OpenTok Media Router (sessions with the
  *       <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
  *       set to routed), not in sessions with the media mode set to relayed. The default value is
  *       <code>false</code>.</li>
  *
  *       <li>
  *       <code>width</code> (Number) &#151; The desired initial width of the displayed
  *       video in the HTML page (default: 264 pixels). You can specify the number of pixels as
  *       either a number (such as 400) or a string ending in "px" (such as "400px"). Or you can
  *       specify a percentage of the size of the parent element, with a string ending in "%"
  *       (such as "100%"). <i>Note:</i> To resize the video, adjust the CSS of the subscriber's
  *       DOM element (the <code>element</code> property of the Subscriber object) or (if the
  *       width is specified as a percentage) its parent DOM element (see
  *       <a href="https://tokbox.com/developer/guides/customize-ui/js/#video_resize_reposition">
  *       Resizing or repositioning a video</a>).
  *       </li>
  *
  *    </ul>
  *
  * @param {Function} completionHandler (Optional) A function to be called when the call to the
  * <code>subscribe()</code> method succeeds or fails. This function takes one parameter &mdash;
  * <code>error</code>. On success, the <code>completionHandler</code> function is not passed any
  * arguments. On error, the function is passed an <code>error</code> object, defined by the
  * <a href="Error.html">Error</a> class, has two properties: <code>code</code> (an integer) and
  * <code>message</code> (a string), which identify the cause of the failure. The following
  * code adds a <code>completionHandler</code> when calling the <code>subscribe()</code> method:
  * <pre>
  * session.subscribe(stream, "subscriber", null, function (error) {
  *   if (error) {
  *     console.log(error.message);
  *   } else {
  *     console.log("Subscribed to stream: " + stream.id);
  *   }
  * });
  * </pre>
  *
  * @signature subscribe(stream, targetElement, properties, completionHandler)
  * @returns {Subscriber} The Subscriber object for this stream. Stream control functions
  * are exposed through the Subscriber object.
  * @method #subscribe
  * @memberOf Session
*/
  this.subscribe = function(stream, targetElement, properties, completionHandler) {
    if (typeof targetElement === 'function') {
      completionHandler = targetElement;
      targetElement = undefined;
      properties = undefined;
    }

    if (typeof properties === 'function') {
      completionHandler = properties;
      properties = undefined;
    }

    if (!this.connection || !this.connection.connectionId) {
      dispatchError(ExceptionCodes.UNABLE_TO_SUBSCRIBE,
                    'Session.subscribe :: Connection required to subscribe',
                    completionHandler);
      return undefined;
    }

    if (!stream) {
      dispatchError(ExceptionCodes.UNABLE_TO_SUBSCRIBE,
                    'Session.subscribe :: stream cannot be null',
                    completionHandler);
      return undefined;
    }

    if (!stream.hasOwnProperty('streamId')) {
      dispatchError(ExceptionCodes.UNABLE_TO_SUBSCRIBE,
                    'Session.subscribe :: invalid stream object',
                    completionHandler);
      return undefined;
    }

    if (properties && properties.insertDefaultUI === false && targetElement) {
      dispatchError(ExceptionCodes.INVALID_PARAMETER,
                    'You cannot specify a target element if insertDefaultUI is false',
                    completionHandler);
      return undefined;
    }

    if (targetElement && targetElement.insertDefaultUI === false) {
      // You can omit the targetElement property if you set insertDefaultUI to false
      properties = targetElement;
      targetElement = undefined;
    }

    var subscriber = new Subscriber(targetElement, OTHelpers.extend(properties || {}, {
      stream: stream,
      session: this
    }), function(err) {

      if (err) {
        var errorCode, errorMessage;
        var knownErrorCodes = [400, 403];

        if (!err.code && knownErrorCodes.indexOf(err.code) > -1) {
          errorCode = ExceptionCodes.UNABLE_TO_SUBSCRIBE; // TODO: this is untested
          errorMessage = 'Session.subscribe :: ' + err.message;
        } else {
          errorCode = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
          errorMessage = 'Unexpected server response. Try this operation again later.';
        }

        dispatchError(errorCode, errorMessage, completionHandler);

      } else if (completionHandler && OTHelpers.isFunction(completionHandler)) {
        completionHandler.apply(null, arguments);
      }

    });

    sessionObjects.subscribers.add(subscriber);

    return subscriber;

  };

  /**
  * Stops subscribing to a stream in the session. the display of the audio-video stream is
  * removed from the local web page.
  *
  * <h5>Example</h5>
  * <p>
  * The following code subscribes to other clients' streams. For each stream, the code also
  * adds an Unsubscribe link.
  * </p>
  * <pre>
  * var apiKey = ""; // Replace with your API key. See See https://tokbox.com/account
  * var sessionID = ""; // Replace with your own session ID.
  *                     // See https://tokbox.com/developer/guides/create-session/.
  * var token = ""; // Replace with a generated token.
  *                 // See https://tokbox.com/developer/guides/create-token/.
  * var streams = [];
  *
  * var session = OT.initSession(apiKey, sessionID);
  * session.on("streamCreated", function(event) {
  *     var stream = event.stream;
  *     displayStream(stream);
  * });
  * session.connect(token);
  *
  * function displayStream(stream) {
  *     var div = document.createElement('div');
  *     div.setAttribute('id', 'stream' + stream.streamId);
  *
  *     var subscriber = session.subscribe(stream, div);
  *     subscribers.push(subscriber);
  *
  *     var aLink = document.createElement('a');
  *     aLink.setAttribute('href', 'javascript: unsubscribe("' + subscriber.id + '")');
  *     aLink.innerHTML = "Unsubscribe";
  *
  *     var streamsContainer = document.getElementById('streamsContainer');
  *     streamsContainer.appendChild(div);
  *     streamsContainer.appendChild(aLink);
  *
  *     streams = event.streams;
  * }
  *
  * function unsubscribe(subscriberId) {
  *     console.log("unsubscribe called");
  *     for (var i = 0; i &lt; subscribers.length; i++) {
  *         var subscriber = subscribers[i];
  *         if (subscriber.id == subscriberId) {
  *             session.unsubscribe(subscriber);
  *         }
  *     }
  * }
  * </pre>
  *
  * @param {Subscriber} subscriber The Subscriber object to unsubcribe.
  *
  * @see <a href="#subscribe">subscribe()</a>
  *
  * @method #unsubscribe
  * @memberOf Session
*/
  this.unsubscribe = function(subscriber) {
    if (!subscriber) {
      var errorMsg = 'OT.Session.unsubscribe: subscriber cannot be null';
      logging.error(errorMsg);
      throw new Error(errorMsg);
    }

    if (!subscriber.stream) {
      logging.warn('OT.Session.unsubscribe:: tried to unsubscribe a subscriber that had no stream');
      return false;
    }

    logging.debug('OT.Session.unsubscribe: subscriber ' + subscriber.id);

    subscriber.destroy();

    return true;
  };

  /**
  * Returns an array of local Subscriber objects for a given stream.
  *
  * @param {Stream} stream The stream for which you want to find subscribers.
  *
  * @returns {Array} An array of {@link Subscriber} objects for the specified stream.
  *
  * @see <a href="#unsubscribe">unsubscribe()</a>
  * @see <a href="Subscriber.html">Subscriber</a>
  * @see <a href="StreamEvent.html">StreamEvent</a>
  * @method #getSubscribersForStream
  * @memberOf Session
*/
  this.getSubscribersForStream = function(stream) {
    return sessionObjects.subscribers.where({ streamId: stream.id });
  };

  /**
  * Returns the local Publisher object for a given stream.
  *
  * @param { Stream } stream The stream for which you want to find the Publisher.
  *
  * @returns { Publisher } A Publisher object for the specified stream. Returns
  * <code>null</code> if there is no local Publisher object
  * for the specified stream.
  *
  * @see <a href="#forceUnpublish">forceUnpublish()</a>
  * @see <a href="Subscriber.html">Subscriber</a>
  * @see <a href="StreamEvent.html">StreamEvent</a>
  *
  * @method #getPublisherForStream
  * @memberOf Session
*/
  this.getPublisherForStream = function(stream) {
    var streamId,
        errorMsg;

    if (typeof stream === 'string') {
      streamId = stream;
    } else if (typeof stream === 'object' && stream && stream.hasOwnProperty('id')) {
      streamId = stream.id;
    } else {
      errorMsg = 'Session.getPublisherForStream :: Invalid stream type';
      logging.error(errorMsg);
      throw new Error(errorMsg);
    }

    return sessionObjects.publishers.where({ streamId: streamId })[0];
  };

  // Private Session API: for internal OT use only
  this._ = {
    jsepCandidateP2p: function(streamId, subscriberId, candidate) {
      return _socket.jsepCandidateP2p(streamId, subscriberId, candidate);
    },

    jsepCandidate: function(streamId, candidate) {
      return _socket.jsepCandidate(streamId, candidate);
    },

    jsepOffer: function(streamId, offerSdp) {
      return _socket.jsepOffer(streamId, offerSdp);
    },

    jsepAnswer: function(streamId, answerSdp) {
      return _socket.jsepAnswer(streamId, answerSdp);
    },

    jsepAnswerP2p: function(streamId, subscriberId, answerSdp) {
      return _socket.jsepAnswerP2p(streamId, subscriberId, answerSdp);
    },

    reconnecting: function() {
      this.dispatchEvent(new Events.SessionReconnectingEvent());
    }.bind(this),

    reconnected: function() {
      this.dispatchEvent(new Events.SessionReconnectedEvent());
      if (this.sessionInfo.renegotiation) {
        sessionObjects.publishers.where({ session: this }).forEach(function(publisher) {
          publisher._.iceRestart();
        });
        sessionObjects.subscribers.where({ session: this }).forEach(function(subscriber) {
          subscriber._.iceRestart();
        });
      }
    }.bind(this),

    // session.on("signal", function(SignalEvent))
    // session.on("signal:{type}", function(SignalEvent))
    dispatchSignal: function(fromConnection, type, data) {
      var event = new Events.SignalEvent(type, data, fromConnection);
      event.target = this;

      // signal a "signal" event
      // NOTE: trigger doesn't support defaultAction, and therefore preventDefault.
      this.trigger(Events.Event.names.SIGNAL, event);

      // signal an "signal:{type}" event" if there was a custom type
      if (type) { this.dispatchEvent(event); }
    }.bind(this),

    subscriberCreate: function(stream, subscriber, channelsToSubscribeTo, completion) {
      return _socket.subscriberCreate(stream.id, subscriber.widgetId,
        channelsToSubscribeTo, completion);
    },

    subscriberDestroy: function(stream, subscriber) {
      return _socket.subscriberDestroy(stream.id, subscriber.widgetId);
    },

    subscriberUpdate: function(stream, subscriber, attributes) {
      return _socket.subscriberUpdate(stream.id, subscriber.widgetId, attributes);
    },

    subscriberChannelUpdate: function(stream, subscriber, channel, attributes) {
      return _socket.subscriberChannelUpdate(stream.id, subscriber.widgetId, channel.id,
        attributes);
    },

    streamCreate: function(name, streamId, audioFallbackEnabled, channels, minBitrate, completion) {
      _socket.streamCreate(
        name,
        streamId,
        audioFallbackEnabled,
        channels,
        minBitrate,
        void 0, // Do not expose maxBitrate to the end user
        completion
      );
    },

    streamDestroy: function(streamId) {
      _socket.streamDestroy(streamId);
    },

    streamChannelUpdate: function(stream, channel, attributes) {
      _socket.streamChannelUpdate(stream.id, channel.id, attributes);
    }
  };

  /**
  * Sends a signal to each client or a specified client in the session. Specify a
  * <code>to</code> property of the <code>signal</code> parameter to limit the signal to
  * be sent to a specific client; otherwise the signal is sent to each client connected to
  * the session.
  * <p>
  * The following example sends a signal of type "foo" with a specified data payload ("hello")
  * to all clients connected to the session:
  * <pre>
  * session.signal({
  *     type: "foo",
  *     data: "hello"
  *   },
  *   function(error) {
  *     if (error) {
  *       console.log("signal error: " + error.message);
  *     } else {
  *       console.log("signal sent");
  *     }
  *   }
  * );
  * </pre>
  * <p>
  * Calling this method without specifying a recipient client (by setting the <code>to</code>
  * property of the <code>signal</code> parameter) results in multiple signals sent (one to each
  * client in the session). For information on charges for signaling, see the
  * <a href="http://tokbox.com/pricing">OpenTok pricing</a> page.
  * <p>
  * The following example sends a signal of type "foo" with a data payload ("hello") to a
  * specific client connected to the session:
  * <pre>
  * session.signal({
  *     type: "foo",
  *     to: recipientConnection; // a Connection object
  *     data: "hello"
  *   },
  *   function(error) {
  *     if (error) {
  *       console.log("signal error: " + error.message);
  *     } else {
  *       console.log("signal sent");
  *     }
  *   }
  * );
  * </pre>
  * <p>
  * Add an event handler for the <code>signal</code> event to listen for all signals sent in
  * the session. Add an event handler for the <code>signal:type</code> event to listen for
  * signals of a specified type only (replace <code>type</code>, in <code>signal:type</code>,
  * with the type of signal to listen for). The Session object dispatches these events. (See
  * <a href="#events">events</a>.)
  *
  * @param {Object} signal An object that contains the following properties defining the signal:
  * <ul>
  *   <li><code>data</code> &mdash; (String) The data to send. The limit to the length of data
  *     string is 8kB. Do not set the data string to <code>null</code> or
  *     <code>undefined</code>.</li>
  *   <li><code>retryAfterReconnect</code>&mdash; (Boolean) Upon reconnecting to the session,
  *     whether to send any signals that were initiated while disconnected. If your client loses its
  *     connection to the OpenTok session, due to a drop in network connectivity, the client
  *     attempts to reconnect to the session, and the Session object dispatches a
  *     <code>reconnecting</code> event. By default, signals initiated while disconnected are
  *     sent when (and if) the client reconnects to the OpenTok session. You can prevent this by
  *     setting the <code>retryAfterReconnect</code> property to <code>false</code>. (The default
  *     value is <code>true</code>.)
  *   <li><code>to</code> &mdash; (Connection) A <a href="Connection.html">Connection</a>
  *      object corresponding to the client that the message is to be sent to. If you do not
  *      specify this property, the signal is sent to all clients connected to the session.</li>
  *   <li><code>type</code> &mdash; (String) The type of the signal. You can use the type to
  *     filter signals when setting an event handler for the <code>signal:type</code> event
  *     (where you replace <code>type</code> with the type string). The maximum length of the
  *     <code>type</code> string is 128 characters, and it must contain only letters (A-Z and a-z),
  *     numbers (0-9), '-', '_', and '~'.</li>
  *   </li>
  * </ul>
  *
  * <p>Each property is optional. If you set none of the properties, you will send a signal
  * with no data or type to each client connected to the session.</p>
  *
  * @param {Function} completionHandler A function that is called when sending the signal
  * succeeds or fails. This function takes one parameter &mdash; <code>error</code>.
  * On success, the <code>completionHandler</code> function is not passed any
  * arguments. On error, the function is passed an <code>error</code> object, defined by the
  * <a href="Error.html">Error</a> class. The <code>error</code> object has the following
  * properties:
  *
  * <ul>
  *   <li><code>code</code> &mdash; (Number) An error code, which can be one of the following:
  *     <table class="docs_table">
  *         <tr>
  *           <td>400</td> <td>One of the signal properties is invalid.</td>
  *         </tr>
  *         <tr>
  *           <td>404</td> <td>The client specified by the <code>to</code> property is not connected
  *                        to the session.</td>
  *         </tr>
  *         <tr>
  *           <td>413</td> <td>The <code>type</code> string exceeds the maximum length (128 bytes),
  *                        or the <code>data</code> string exceeds the maximum size (8 kB).</td>
  *         </tr>
  *         <tr>
  *           <td>500</td> <td>You are not connected to the OpenTok session.</td>
  *         </tr>
  *      </table>
  *   </li>
  *   <li><code>message</code> &mdash; (String) A description of the error.</li>
  * </ul>
  *
  * <p>Note that the <code>completionHandler</code> success result (<code>error == null</code>)
  * indicates that the options passed into the <code>Session.signal()</code> method are valid
  * and the signal was sent. It does <i>not</i> indicate that the signal was successfully
  * received by any of the intended recipients.
  *
  * @method #signal
  * @memberOf Session
  * @see <a href="#event:signal">signal</a> and <a href="#event:signal:type">signal:type</a> events
*/
  this.signal = function(options, completion) {
    var _options = options;
    var _completion = completion;

    if (OTHelpers.isFunction(_options)) {
      _completion = _options;
      _options = null;
    }

    if (this.isNot('connected')) {
      var notConnectedErrorMsg = 'Unable to send signal - you are not connected to the session.';
      dispatchError(500, notConnectedErrorMsg, _completion);
      return;
    }

    _socket.signal(_options, _completion, this.logEvent);
    if (options && options.data && typeof options.data !== 'string') {
      logging.warn('Signaling of anything other than Strings is deprecated. ' +
              'Please update the data property to be a string.');
    }
  };

  /**
  *   Forces a remote connection to leave the session.
  *
  * <p>
  *   The <code>forceDisconnect()</code> method is normally used as a moderation tool
  *        to remove users from an ongoing session.
  * </p>
  * <p>
  *   When a connection is terminated using the <code>forceDisconnect()</code>,
  *        <code>sessionDisconnected</code>, <code>connectionDestroyed</code> and
  *        <code>streamDestroyed</code> events are dispatched in the same way as they
  *        would be if the connection had terminated itself using the <code>disconnect()</code>
  *        method. However, the <code>reason</code> property of a {@link ConnectionEvent} or
  *        {@link StreamEvent} object specifies <code>"forceDisconnected"</code> as the reason
  *        for the destruction of the connection and stream(s).
  * </p>
  * <p>
  *   While you can use the <code>forceDisconnect()</code> method to terminate your own connection,
  *        calling the <code>disconnect()</code> method is simpler.
  * </p>
  * <p>
  *   The OT object dispatches an <code>exception</code> event if the user's role
  *   does not include permissions required to force other users to disconnect.
  *   You define a user's role when you create the user token (see the
  *   <a href="https://tokbox.com/developer/guides/create-token/">Token creation overview</a>).
  *   See <a href="ExceptionEvent.html">ExceptionEvent</a> and <a href="OT.html#on">OT.on()</a>.
  * </p>
  * <p>
  *   The application throws an error if the session is not connected.
  * </p>
  *
  * <h5>Events dispatched:</h5>
  *
  * <p>
  *   <code>connectionDestroyed</code> (<a href="ConnectionEvent.html">ConnectionEvent</a>) &#151;
  *     On clients other than which had the connection terminated.
  * </p>
  * <p>
  *   <code>exception</code> (<a href="ExceptionEvent.html">ExceptionEvent</a>) &#151;
  *     The user's role does not allow forcing other user's to disconnect (<code>event.code =
  *     1530</code>),
  *   or the specified stream is not publishing to the session (<code>event.code = 1535</code>).
  * </p>
  * <p>
  *   <code>sessionDisconnected</code>
  *   (<a href="SessionDisconnectEvent.html">SessionDisconnectEvent</a>) &#151;
  *     On the client which has the connection terminated.
  * </p>
  * <p>
  *   <code>streamDestroyed</code> (<a href="StreamEvent.html">StreamEvent</a>) &#151;
  *     If streams are stopped as a result of the connection ending.
  * </p>
  *
  * @param {Connection} connection The connection to be disconnected from the session.
  * This value can either be a <a href="Connection.html">Connection</a> object or a connection
  * ID (which can be obtained from the <code>connectionId</code> property of the Connection object).
  *
  * @param {Function} completionHandler (Optional) A function to be called when the call to the
  * <code>forceDiscononnect()</code> method succeeds or fails. This function takes one parameter
  * &mdash; <code>error</code>. On success, the <code>completionHandler</code> function is
  * not passed any arguments. On error, the function is passed an <code>error</code> object
  * parameter. The <code>error</code> object, defined by the <a href="Error.html">Error</a>
  * class, has two properties: <code>code</code> (an integer)
  * and <code>message</code> (a string), which identify the cause of the failure.
  * Calling <code>forceDisconnect()</code> fails if the role assigned to your
  * token is not "moderator"; in this case <code>error.code</code> is set to 1520. The following
  * code adds a <code>completionHandler</code> when calling the <code>forceDisconnect()</code>
  * method:
  * <pre>
  * session.forceDisconnect(connection, function (error) {
  *   if (error) {
  *     console.log(error);
  *   } else {
  *     console.log("Connection forced to disconnect: " + connection.id);
  *   }
  * });
  * </pre>
  *
  * @method #forceDisconnect
  * @memberOf Session
*/

  this.forceDisconnect = function(connectionOrConnectionId, completionHandler) {
    if (this.isNot('connected')) {
      var notConnectedErrorMsg = 'Cannot call forceDisconnect(). You are not ' +
                                 'connected to the session.';
      dispatchError(ExceptionCodes.NOT_CONNECTED, notConnectedErrorMsg, completionHandler);
      return;
    }

    var connectionId = (
      typeof connectionOrConnectionId === 'string' ?
      connectionOrConnectionId :
      connectionOrConnectionId.id
    );

    var invalidParameterErrorMsg = (
      'Invalid Parameter. Check that you have passed valid parameter values into the method call.'
    );

    if (!connectionId) {
      dispatchError(
        ExceptionCodes.INVALID_PARAMETER,
        invalidParameterErrorMsg,
        completionHandler
      );

      return;
    }

    var notPermittedErrorMsg = 'This token does not allow forceDisconnect. ' +
      'The role must be at least `moderator` to enable this functionality';

    if (!permittedTo('forceDisconnect')) {
      // if this throws an error the handleJsException won't occur
      dispatchError(
        ExceptionCodes.UNABLE_TO_FORCE_DISCONNECT,
        notPermittedErrorMsg,
        completionHandler
      );

      return;
    }

    _socket.forceDisconnect(connectionId, function(err) {
      if (err) {
        dispatchError(
          ExceptionCodes.INVALID_PARAMETER,
          invalidParameterErrorMsg,
          completionHandler
        );
      } else if (completionHandler && OTHelpers.isFunction(completionHandler)) {
        completionHandler.apply(null, arguments);
      }
    });
  };

  /**
  * Forces the publisher of the specified stream to stop publishing the stream.
  *
  * <p>
  * Calling this method causes the Session object to dispatch a <code>streamDestroyed</code>
  * event on all clients that are subscribed to the stream (including the client that is
  * publishing the stream). The <code>reason</code> property of the StreamEvent object is
  * set to <code>"forceUnpublished"</code>.
  * </p>
  * <p>
  * The OT object dispatches an <code>exception</code> event if the user's role
  * does not include permissions required to force other users to unpublish.
  * You define a user's role when you create the user token (see the
  * <a href="https://tokbox.com/developer/guides/create-token/">Token creation overview</a>).
  * You pass the token string as a parameter of the <code>connect()</code> method of the Session
  * object. See <a href="ExceptionEvent.html">ExceptionEvent</a> and
  * <a href="OT.html#on">OT.on()</a>.
  * </p>
  *
  * <h5>Events dispatched:</h5>
  *
  * <p>
  *   <code>exception</code> (<a href="ExceptionEvent.html">ExceptionEvent</a>) &#151;
  *     The user's role does not allow forcing other users to unpublish.
  * </p>
  * <p>
  *   <code>streamDestroyed</code> (<a href="StreamEvent.html">StreamEvent</a>) &#151;
  *     The stream has been unpublished. The Session object dispatches this on all clients
  *     subscribed to the stream, as well as on the publisher's client.
  * </p>
  *
  * @param {Stream} stream The stream to be unpublished.
  *
  * @param {Function} completionHandler (Optional) A function to be called when the call to the
  * <code>forceUnpublish()</code> method succeeds or fails. This function takes one parameter
  * &mdash; <code>error</code>. On success, the <code>completionHandler</code> function is
  * not passed any arguments. On error, the function is passed an <code>error</code> object
  * parameter. The <code>error</code> object, defined by the <a href="Error.html">Error</a>
  * class, has two properties: <code>code</code> (an integer)
  * and <code>message</code> (a string), which identify the cause of the failure. Calling
  * <code>forceUnpublish()</code> fails if the role assigned to your token is not "moderator";
  * in this case <code>error.code</code> is set to 1530. The following code adds a
  * <code>completionHandler</code> when calling the <code>forceUnpublish()</code> method:
  * <pre>
  * session.forceUnpublish(stream, function (error) {
  *   if (error) {
  *       console.log(error);
  *     } else {
  *       console.log("Connection forced to disconnect: " + connection.id);
  *     }
  *   });
  * </pre>
  *
  * @method #forceUnpublish
  * @memberOf Session
*/
  this.forceUnpublish = function(streamOrStreamId, completionHandler) {
    if (this.isNot('connected')) {
      var notConnectedErrorMsg = 'Cannot call forceUnpublish(). You are not ' +
                                 'connected to the session.';
      dispatchError(ExceptionCodes.NOT_CONNECTED, notConnectedErrorMsg, completionHandler);
      return;
    }

    var notPermittedErrorMsg = 'This token does not allow forceUnpublish. ' +
      'The role must be at least `moderator` to enable this functionality';

    if (permittedTo('forceUnpublish')) {
      var stream = typeof streamOrStreamId === 'string' ?
        this.streams.get(streamOrStreamId) : streamOrStreamId;

      _socket.forceUnpublish(stream.id, function(err) {
        if (err) {
          dispatchError(ExceptionCodes.UNABLE_TO_FORCE_UNPUBLISH,
            notPermittedErrorMsg, completionHandler);
        } else if (completionHandler && OTHelpers.isFunction(completionHandler)) {
          completionHandler.apply(null, arguments);
        }
      });
    } else {
      // if this throws an error the handleJsException won't occur
      dispatchError(ExceptionCodes.UNABLE_TO_FORCE_UNPUBLISH,
        notPermittedErrorMsg, completionHandler);
    }
  };

  this.isConnected = function() {
    return this.is('connected');
  };

  this.capabilities = new Capabilities([]);

  /**
   * Dispatched when an archive recording of the session starts.
   *
   * @name archiveStarted
   * @event
   * @memberof Session
   * @see ArchiveEvent
   * @see <a href="http://www.tokbox.com/opentok/tutorials/archiving">Archiving overview</a>
   */

  /**
   * Dispatched when an archive recording of the session stops.
   *
   * @name archiveStopped
   * @event
   * @memberof Session
   * @see ArchiveEvent
   * @see <a href="http://www.tokbox.com/opentok/tutorials/archiving">Archiving overview</a>
   */

  /**
   * Dispatched when a new client (including your own) has connected to the session, and for
   * every client in the session when you first connect. (The Session object also dispatches
   * a <code>sessionConnected</code> event when your local client connects.)
   *
   * @name connectionCreated
   * @event
   * @memberof Session
   * @see ConnectionEvent
   * @see <a href="OT.html#initSession">OT.initSession()</a>
   */

  /**
   * A client, other than your own, has disconnected from the session.
   * @name connectionDestroyed
   * @event
   * @memberof Session
   * @see ConnectionEvent
   */

  /**
   * The client has connected to an OpenTok session. This event is dispatched asynchronously
   * in response to a successful call to the <code>connect()</code> method of a Session
   * object. Before calling the <code>connect()</code> method, initialize the session by
   * calling the <code>OT.initSession()</code> method. For a code example and more details,
   * see <a href="#connect">Session.connect()</a>.
   * @name sessionConnected
   * @event
   * @memberof Session
   * @see SessionConnectEvent
   * @see <a href="#connect">Session.connect()</a>
   * @see <a href="OT.html#initSession">OT.initSession()</a>
   */

  /**
   * The client has disconnected from the session. This event may be dispatched asynchronously
   * in response to a successful call to the <code>disconnect()</code> method of the Session object.
   * The event may also be disptached if a session connection is lost inadvertantly, as in the case
   * of a lost network connection.
   * <p>
   * The default behavior is that all Subscriber objects are unsubscribed and removed from the
   * HTML DOM. Each Subscriber object dispatches a <code>destroyed</code> event when the element is
   * removed from the HTML DOM. If you call the <code>preventDefault()</code> method in the event
   * listener for the <code>sessionDisconnect</code> event, the default behavior is prevented, and
   * you can, optionally, clean up Subscriber objects using your own code.
   * <p> The <code>reason</code> property of the event object indicates the reason for the client
   * being disconnected.
   * @name sessionDisconnected
   * @event
   * @memberof Session
   * @see <a href="#disconnect">Session.disconnect()</a>
   * @see <a href="#forceDisconnect">Session.forceDisconnect()</a>
   * @see SessionDisconnectEvent
   */

  /**
   * The local client has lost its connection to an OpenTok session and is trying to reconnect.
   * This results from a loss in network connectivity. If the client can reconnect to the session,
   * the Session object dispatches a <code>sessionReconnected</code> event. Otherwise, if the client
   * cannot reconnect, the Session object dispatches a <code>sessionDisconnected</code> event.
   * <p>
   * In response to this event, you may want to provide a user interface notification, to let
   * the user know that the app is trying to reconnect to the session and that audio-video streams
   * are temporarily disconnected.
   *
   * @name sessionReconnecting
   * @event
   * @memberof Session
   * @see Event
   * @see <a href="#event:sessionReconnected">sessionReconnected event</a>
   * @see <a href="#event:sessionDisconnected">sessionDisconnected event</a>
   */

  /**
   * The local client has reconnected to the OpenTok session after its connection was lost
   * temporarily. When the connection is lost, the Session object dispatches a
   * <code>sessionReconnecting</code> event, prior to the <code>sessionReconnected</code>
   * event. If the client cannot reconnect to the session, the Session object dispatches a
   * <code>sessionDisconnected</code> event instead of this event.
   * <p>
   * Any existing publishers and subscribers are automatically reconnected when client reconnects
   * and the Session object dispatches this event.
   * <p>
   * Any signals sent by other clients while your client was disconnected are received upon
   * reconnecting. By default, signals initiated by the local client while disconnected
   * (by calling the <code>Session.signal()</code> method) are sent when the client reconnects
   * to the OpenTok session. You can prevent this by setting the <code>retryAfterReconnect</code>
   * property to <code>false</code> in the <code>signal</code> object you pass into the
   * <a href="#signal">Session.signal()</a> method.
   *
   * @name sessionReconnected
   * @event
   * @memberof Session
   * @see Event
   * @see <a href="#event:sessionReconnecting">sessionReconnecting event</a>
   * @see <a href="#event:sessionDisconnected">sessionDisconnected event</a>
   */

  /**
   * A new stream, published by another client, has been created on this session. For streams
   * published by your own client, the Publisher object dispatches a <code>streamCreated</code>
   * event. For a code example and more details, see {@link StreamEvent}.
   * @name streamCreated
   * @event
   * @memberof Session
   * @see StreamEvent
   * @see <a href="Session.html#publish">Session.publish()</a>
   */

  /**
   * A stream from another client has stopped publishing to the session.
   * <p>
   * The default behavior is that all Subscriber objects that are subscribed to the stream are
   * unsubscribed and removed from the HTML DOM. Each Subscriber object dispatches a
   * <code>destroyed</code> event when the element is removed from the HTML DOM. If you call the
   * <code>preventDefault()</code> method in the event listener for the
   * <code>streamDestroyed</code> event, the default behavior is prevented and you can clean up
   * Subscriber objects using your own code. See
   * <a href="Session.html#getSubscribersForStream">Session.getSubscribersForStream()</a>.
   * <p>
   * For streams published by your own client, the Publisher object dispatches a
   * <code>streamDestroyed</code> event.
   * <p>
   * For a code example and more details, see {@link StreamEvent}.
   * @name streamDestroyed
   * @event
   * @memberof Session
   * @see StreamEvent
   */

  /**
   * Defines an event dispatched when property of a stream has changed. This can happen in
   * in the following conditions:
   * <p>
   * <ul>
   *   <li> A stream has started or stopped publishing audio or video (see
   *     <a href="Publisher.html#publishAudio">Publisher.publishAudio()</a> and
   *     <a href="Publisher.html#publishVideo">Publisher.publishVideo()</a>). Note
   *     that a subscriber's video can be disabled or enabled for reasons other than
   *     the publisher disabling or enabling it. A Subscriber object dispatches
   *     <code>videoDisabled</code> and <code>videoEnabled</code> events in all
   *     conditions that cause the subscriber's stream to be disabled or enabled.
   *   </li>
   *   <li> The <code>videoDimensions</code> property of the Stream object has
   *     changed (see <a href="Stream.html#properties">Stream.videoDimensions</a>).
   *   </li>
   *   <li> The <code>videoType</code> property of the Stream object has changed.
   *     This can happen in a stream published by a mobile device. (See
   *     <a href="Stream.html#properties">Stream.videoType</a>.)
   *   </li>
   * </ul>
   *
   * @name streamPropertyChanged
   * @event
   * @memberof Session
   * @see StreamPropertyChangedEvent
   * @see <a href="Publisher.html#publishAudio">Publisher.publishAudio()</a>
   * @see <a href="Publisher.html#publishVideo">Publisher.publishVideo()</a>
   * @see <a href="Stream.html#hasAudio">Stream.hasAudio</a>
   * @see <a href="Stream.html#hasVideo">Stream.hasVideo</a>
   * @see <a href="Stream.html#videoDimensions">Stream.videoDimensions</a>
   * @see <a href="Subscriber.html#event:videoDisabled">Subscriber videoDisabled event</a>
   * @see <a href="Subscriber.html#event:videoEnabled">Subscriber videoEnabled event</a>
   */

  /**
   * A signal was received from the session. The <a href="SignalEvent.html">SignalEvent</a>
   * class defines this event object. It includes the following properties:
   * <ul>
   *   <li><code>data</code> &mdash; (String) The data string sent with the signal (if there
   *       is one).</li>
   *   <li><code>from</code> &mdash; (<a href="Connection.html">Connection</a>) The Connection
   *       corresponding to the client that sent the signal.</li>
   *   <li><code>type</code> &mdash; (String) The type assigned to the signal (if there is
   *       one).</li>
   * </ul>
   * <p>
   * You can register to receive all signals sent in the session, by adding an event handler
   * for the <code>signal</code> event. For example, the following code adds an event handler
   * to process all signals sent in the session:
   * <pre>
   * session.on("signal", function(event) {
   *   console.log("Signal sent from connection: " + event.from.id);
   *   console.log("Signal data: " + event.data);
   * });
   * </pre>
   * <p>You can register for signals of a specfied type by adding an event handler for the
   * <code>signal:type</code> event (replacing <code>type</code> with the actual type string
   * to filter on).
   *
   * @name signal
   * @event
   * @memberof Session
   * @see <a href="Session.html#signal">Session.signal()</a>
   * @see SignalEvent
   * @see <a href="#event:signal:type">signal:type</a> event
   */

  /**
   * A signal of the specified type was received from the session. The
   * <a href="SignalEvent.html">SignalEvent</a> class defines this event object.
   * It includes the following properties:
   * <ul>
   *   <li><code>data</code> &mdash; (String) The data string sent with the signal.</li>
   *   <li><code>from</code> &mdash; (<a href="Connection.html">Connection</a>) The Connection
   *   corresponding to the client that sent the signal.</li>
   *   <li><code>type</code> &mdash; (String) The type assigned to the signal (if there is one).
   *   </li>
   * </ul>
   * <p>
   * You can register for signals of a specfied type by adding an event handler for the
   * <code>signal:type</code> event (replacing <code>type</code> with the actual type string
   * to filter on). For example, the following code adds an event handler for signals of
   * type "foo":
   * <pre>
   * session.on("signal:foo", function(event) {
   *   console.log("foo signal sent from connection " + event.from.id);
   *   console.log("Signal data: " + event.data);
   * });
   * </pre>
   * <p>
   * You can register to receive <i>all</i> signals sent in the session, by adding an event
   * handler for the <code>signal</code> event.
   *
   * @name signal:type
   * @event
   * @memberof Session
   * @see <a href="Session.html#signal">Session.signal()</a>
   * @see SignalEvent
   * @see <a href="#event:signal">signal</a> event
   */
};

SessionHandle.Session.RaptorSocket = RaptorSocket;
