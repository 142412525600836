'use strict';

var assert = require('assert');

// Matches 1, 0.3, 1.1.1, 4.1.5.7, etc.
var versionRegex = /^[0-9]+(\.[0-9]+)*$/;

module.exports = function versionGreaterThan(version1, version2) {
  assert(versionRegex.test(version1));
  assert(versionRegex.test(version2));

  var v1 = version1.split('.').map(Number);
  var v2 = version2.split('.').map(Number);

  // There is a way to nicely compare arrays of different lengths, but in our case, if there is a
  // mismatch, it indicates something went wrong. So we throw instead.
  assert(v1.length === v2.length);

  var len = v1.length;

  for (var i = 0; i < len; ++i) {
    // Continue until we find a pair that doesn't match.
    if (v1[i] === v2[i]) {
      continue;
    }

    // The first mismatched pair determines the overall result.
    return v1[i] > v2[i];
  }

  // If all the pieces are equal, then the versions should be equal.
  assert(version1 === version2);

  // And since they're equal, version1 is not greater than version2.
  return false;
};
