'use strict';

var OTHelpers = require('../common-js-helpers/OTHelpers.js');
var logging = require('./logging.js');
var VideoOrientation = require('../helpers/video_orientation.js');

// id: String                           | mandatory | immutable
// type: String {video/audio/data/...}  | mandatory | immutable
// active: Boolean                      | mandatory | mutable
// orientation: Integer?                | optional  | mutable
// frameRate: Float                     | optional  | mutable
// height: Integer                      | optional  | mutable
// width: Integer                       | optional  | mutable
// preferredFrameRate: Float            | optional  | mutable
// preferredHeight: Integer             | optional  | mutable
// preferredWidth: Integer              | optional  | mutable
//
module.exports = function StreamChannel(options) {
  this.id = options.id;
  this.type = options.type;
  this.active = OTHelpers.castToBoolean(options.active);
  this.orientation = options.orientation || VideoOrientation.ROTATED_NORMAL;
  if (options.frameRate) { this.frameRate = parseFloat(options.frameRate); }
  if (options.preferredFrameRate) { this.preferredFrameRate = parseFloat(options.preferredFrameRate); }
  if (options.preferredWidth) { this.preferredWidth = parseInt(options.preferredWidth, 10); }
  if (options.preferredHeight) { this.preferredHeight = parseInt(options.preferredHeight, 10); }
  this.width = parseInt(options.width, 10);
  this.height = parseInt(options.height, 10);

  // The defaults are used for incoming streams from pre 2015Q1 release clients.
  this.source = options.source || 'camera';
  this.fitMode = options.fitMode || 'cover';

  OTHelpers.eventing(this, true);

  // Returns true if a property was updated.
  this.update = function(attributes) {
    var videoDimensions = {};
    var oldVideoDimensions = {};

    for (var key in attributes) {
      if (!attributes.hasOwnProperty(key)) {
        continue;
      }

      // we shouldn't really read this before we know the key is valid
      var oldValue = this[key];

      switch (key) {
        case 'active':
          this.active = OTHelpers.castToBoolean(attributes[key]);
          break;

        case 'disableWarning':
          this.disableWarning = OTHelpers.castToBoolean(attributes[key]);
          break;

        case 'frameRate':
          this.frameRate = parseFloat(attributes[key], 10);
          break;

        case 'width':
        case 'height':
          this[key] = parseInt(attributes[key], 10);

          videoDimensions[key] = this[key];
          oldVideoDimensions[key] = oldValue;
          break;

        case 'orientation':
          this[key] = attributes[key];

          videoDimensions[key] = this[key];
          oldVideoDimensions[key] = oldValue;
          break;

        case 'fitMode':
          this[key] = attributes[key];
          break;

        case 'source':
          this[key] = attributes[key];
          break;

        default:
          logging.warn('Tried to update unknown key ' + key + ' on ' + this.type +
            ' channel ' + this.id);
          return false;
      }

      this.trigger('update', this, key, oldValue, this[key]);
    }

    if (Object.keys(videoDimensions).length) {
      // To make things easier for the public API, we broadcast videoDimensions changes,
      // which is an aggregate of width, height, and orientation changes.
      this.trigger('update', this, 'videoDimensions', oldVideoDimensions, videoDimensions);
    }

    return true;
  };
};
