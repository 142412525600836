'use strict';

/*
 * Lazy instantiates an audio context and always return the same instance on following calls
 *
 * @returns {AudioContext}
 */

var context;

var audioContext = function() {
  context = context || new global.AudioContext();
  return context;
};

module.exports = audioContext;
