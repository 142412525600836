'use strict';

var APIKEY = require('../../api_key.js');
var Connection = require('../../connection.js');
var Message = require('./message.js');
var OTHelpers = require('../../../common-js-helpers/OTHelpers.js');
var sessionTag = require('../../session/tag.js');

var MAX_SIGNAL_DATA_LENGTH = 8192;
var MAX_SIGNAL_TYPE_LENGTH = 128;

//
// Error Codes:
// 413 - Type too long
// 400 - Type is invalid
// 413 - Data too long
// 400 - Data is invalid (can't be parsed as JSON)
// 429 - Rate limit exceeded
// 500 - Websocket connection is down
// 404 - To connection does not exist
// 400 - To is invalid
//
module.exports = function Signal(sessionId, fromConnectionId, options) {
  var isInvalidType = function(type) {
    // Our format matches the unreserved characters from the URI RFC:
    // http://www.ietf.org/rfc/rfc3986
    return !/^[a-zA-Z0-9\-\._~]+$/.exec(type);
  };

  var validateTo = function(toAddress) {
    if (!toAddress) {
      return {
        code: 400,
        reason: 'The signal to field was invalid. Either set it to a OT.Connection, ' +
                            'OT.Session, or omit it entirely'
      };
    }

    if (!(toAddress instanceof Connection || toAddress._tag === sessionTag)) {
      return {
        code: 400,
        reason: 'The To field was invalid'
      };
    }

    return null;
  };

  var validateType = function(type) {
    var error = null;

    if (type === null || type === void 0) {
      error = {
        code: 400,
        reason: 'The signal type was null or undefined. Either set it to a String value or ' +
          'omit it'
      };
    } else if (type.length > MAX_SIGNAL_TYPE_LENGTH) {
      error = {
        code: 413,
        reason: 'The signal type was too long, the maximum length of it is ' +
          MAX_SIGNAL_TYPE_LENGTH + ' characters'
      };
    } else if (isInvalidType(type)) {
      error = {
        code: 400,
        reason: 'The signal type was invalid, it can only contain letters, ' +
          'numbers, \'-\', \'_\', and \'~\'.'
      };
    }

    return error;
  };

  var validateData = function(data) {
    var error = null;
    if (data === null || data === void 0) {
      error = {
        code: 400,
        reason: 'The signal data was null or undefined. Either set it to a String value or ' +
          'omit it'
      };
    } else {
      try {
        if (JSON.stringify(data).length > MAX_SIGNAL_DATA_LENGTH) {
          error = {
            code: 413,
            reason: 'The data field was too long, the maximum size of it is ' +
              MAX_SIGNAL_DATA_LENGTH + ' characters'
          };
        }
      } catch (e) {
        error = { code: 400, reason: 'The data field was not valid JSON' };
      }
    }

    return error;
  };

  var validateRetryAfterReconnect = function(retryAfterReconnect) {
    var error = null;
    if (!(retryAfterReconnect === true || retryAfterReconnect === false)) {
      error = {
        code: 400,
        reason: 'The signal retryAfterReconnect was not true or false. Either set it to a Boolean ' +
          'value or omit it'
      };
    }

    return error;
  };

  this.toRaptorMessage = function() {
    var to = this.to;

    if (to && typeof to !== 'string') {
      to = to.id;
    }

    return Message.signals.create(APIKEY.value, sessionId, to, this.type, this.data);
  };

  this.toHash = function() {
    return options;
  };

  this.error = null;

  this.retryAfterReconnect = true;

  if (options) {
    if (options.hasOwnProperty('data')) {
      this.data = OTHelpers.clone(options.data);
      this.error = validateData(this.data);
    }

    if (options.hasOwnProperty('to')) {
      this.to = options.to;

      if (!this.error) {
        this.error = validateTo(this.to);
      }
    }

    if (options.hasOwnProperty('type')) {
      if (!this.error) {
        this.error = validateType(options.type);
      }
      this.type = options.type;
    }

    if (options.hasOwnProperty('retryAfterReconnect')) {
      if (!this.error) {
        this.error = validateRetryAfterReconnect(options.retryAfterReconnect);
      }
      this.retryAfterReconnect = options.retryAfterReconnect;
    }
  }

  this.valid = this.error === null;
};
