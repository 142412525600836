'use strict';

var OTPlugin = require('../../otplugin/otplugin.js');
var OTHelpers = require('../../common-js-helpers/OTHelpers.js');

var NativeVideoElementWrapper = require('./native_video_element_wrapper');
var PluginVideoElementWrapper = require('./plugin_video_element_wrapper');

var defaultAudioVolume = 50;

//
//
//   var _videoElement = new VideoElement({
//     fallbackText: 'blah'
//   }, errorHandler);
//
//   _videoElement.bindToStream(webRtcStream, completion);      // => VideoElement
//   _videoElement.appendTo(DOMElement)                         // => VideoElement
//
//   _videoElement.domElement                       // => DomNode
//
//   _videoElement.imgData                          // => PNG Data string
//
//   _videoElement.orientation = VideoOrientation.ROTATED_LEFT;
//
//   _videoElement.unbindStream();
//   _videoElement.destroy()                        // => Completely cleans up and
//                                                        removes the video element
//
//
module.exports = function VideoElementFacade(
  /* optional */ options/*,
  optional errorHandler*/
) {

  var _stream, _preInitialisedVolume;
  var _streamBound = false;

  var _options = OTHelpers.defaults(options && !OTHelpers.isFunction(options) ? options : {}, {
    fallbackText: 'Sorry, Web RTC is not available in your browser'
  });

  var errorHandler = OTHelpers.isFunction(arguments[arguments.length - 1]) ?
                                  arguments[arguments.length - 1] : void 0;

  var orientationHandler = function(orientation) {
    this.trigger('orientationChanged', orientation);
  }.bind(this);

  var _videoElementWrapper = (OTPlugin.isInstalled() ?
    new PluginVideoElementWrapper(_options, errorHandler, orientationHandler, defaultAudioVolume) :
    new NativeVideoElementWrapper(_options, errorHandler, orientationHandler, defaultAudioVolume)
  );

  OTHelpers.eventing(this);

  _videoElementWrapper.on('videoDimensionsChanged', function(oldValue, newValue) {
    this.trigger('videoDimensionsChanged', oldValue, newValue);
  }.bind(this));

  _videoElementWrapper.on('mediaStopped', function() {
    this.trigger('mediaStopped');
  }.bind(this));

  _videoElementWrapper.on('videoElementCreated', function(element) {
    this.trigger('videoElementCreated', element);
  }.bind(this));

  // Public Properties
  OTHelpers.defineProperties(this, {

    domElement: {
      get: function() {
        return _videoElementWrapper.domElement();
      }
    },

    videoWidth: {
      get: function() {
        return _videoElementWrapper['video' + (this.isRotated() ? 'Height' : 'Width')]();
      }
    },

    videoHeight: {
      get: function() {
        return _videoElementWrapper['video' + (this.isRotated() ? 'Width' : 'Height')]();
      }
    },

    aspectRatio: {
      get: function() {
        return (this.videoWidth() + 0.0) / this.videoHeight();
      }
    },

    isRotated: {
      get: function() {
        return _videoElementWrapper.isRotated();
      }
    },

    orientation: {
      get: function() {
        return _videoElementWrapper.orientation();
      },
      set: function(orientation) {
        _videoElementWrapper.orientation(orientation);
      }
    },

    audioChannelType: {
      get: function() {
        return _videoElementWrapper.audioChannelType();
      },
      set: function(type) {
        _videoElementWrapper.audioChannelType(type);
      }
    }
  });

  // Public Methods

  this.imgData = function() {
    return _videoElementWrapper.imgData();
  };

  this.appendTo = function(parentDomElement) {
    _videoElementWrapper.appendTo(parentDomElement);
    return this;
  };

  this.bindToStream = function(webRtcStream, completion) {
    _streamBound = false;
    _stream = webRtcStream;

    _videoElementWrapper.bindToStream(webRtcStream, function(err) {
      if (err) {
        completion(err);
        return;
      }

      _streamBound = true;

      if (typeof _preInitialisedVolume !== 'undefined') {
        this.setAudioVolume(_preInitialisedVolume);
        _preInitialisedVolume = undefined;
      }

      _videoElementWrapper.on('aspectRatioAvailable',
        this.trigger.bind(this, 'aspectRatioAvailable'));

      completion(null);
    }.bind(this));

    return this;
  };

  this.unbindStream = function() {
    if (!_stream) { return this; }

    _stream.onended = null;
    _stream = null;
    _videoElementWrapper.unbindStream();
    return this;
  };

  this.setAudioVolume = function(value) {
    if (_streamBound) {
      _videoElementWrapper.setAudioVolume(OTHelpers.roundFloat(value / 100, 2));
    } else {
      _preInitialisedVolume = parseFloat(value);
    }

    return this;
  };

  this.getAudioVolume = function() {
    if (_streamBound) {
      return parseInt(_videoElementWrapper.getAudioVolume() * 100, 10);
    }

    if (typeof _preInitialisedVolume !== 'undefined') {
      return _preInitialisedVolume;
    }

    return 50;
  };

  this.getAudioInputLevel = function() {
    return _videoElementWrapper.getAudioInputLevel();
  };

  this.whenTimeIncrements = function(callback, context) {
    _videoElementWrapper.whenTimeIncrements(callback, context);
    return this;
  };

  this.destroy = function() {
    // unbind all events so they don't fire after the object is dead
    this.off();

    _videoElementWrapper.destroy();
    return void 0;
  };
};
