'use strict';

var analytics      = require('./analytics.js');
var ExceptionCodes = require('./exception_codes.js');
var OTError        = require('./ot_error.js');
var OTHelpers      = require('../common-js-helpers/OTHelpers.js');
var sessionObjects = require('./session/objects.js');
var uuid           = require('uuid');

/**
* Report that your app experienced an issue. You can use the issue ID with
* <a href="http://tokbox.com/developer/tools/Inspector">Inspector</a> or when discussing
* an issue with the TokBox support team.
*
* @param completionHandler {Function} A function that is called when the call to this method
* succeeds or fails. This function has two parameters. The first parameter is an
* <a href="Error.html">Error</a> object that is set when the call to the <code>reportIssue()</code>
* method fails (for example, if the client is not connected to the network) or <code>null</code>
* when the call to the <code>reportIssue()</code> method succeeds. The second parameter is set to
* the report ID (a unique string) when the call succeeds.
*
* @method OT.reportIssue
* @memberof OT
*/
module.exports = function reportIssue(completionHandler) {
  var reportIssueId = uuid();
  var sessionCount = sessionObjects.sessions.length();
  var completedLogEventCount = 0;
  var errorReported = false;

  function logEventCompletionHandler(error) {
    if (error) {
      if (completionHandler && !errorReported) {
        var reportIssueError = new OTError(ExceptionCodes.REPORT_ISSUE_ERROR,
          'Error calling OT.reportIssue(). Check the client\'s network connection.');
        completionHandler(reportIssueError);
        errorReported = true;
      }
    } else {
      completedLogEventCount++;
      if (completedLogEventCount >= sessionCount && completionHandler && !errorReported) {
        completionHandler(null, reportIssueId);
      }
    }
  }

  var eventOptions = {
    action: 'ReportIssue',
    payload: {
      reportIssueId: reportIssueId
    }
  };

  if (sessionCount === 0) {
    analytics.logEvent(eventOptions, null, logEventCompletionHandler);
  } else {
    sessionObjects.sessions.forEach(function(session) {
      var individualSessionEventOptions = OTHelpers.extend({
        sessionId: session.sessionId,
        partnerId: session.sessionInfo ? session.sessionInfo.partnerId : null,
        p2p: session.sessionInfo.p2pEnabled,
        messagingServer: session.sessionInfo.messagingServer
      }, eventOptions);
      analytics.logEvent(individualSessionEventOptions, null, logEventCompletionHandler);
    });
  }
};
