'use strict';

var OTHelpers = require('../../common-js-helpers/OTHelpers.js');

var VideoOrientationTransforms = {
  0: 'rotate(0deg)',
  270: 'rotate(90deg)',
  90: 'rotate(-90deg)',
  180: 'rotate(180deg)'
};

// A mixin to create the orientation API implementation on +self+
// +getDomElementCallback+ is a function that the mixin will call when it wants to
// get the native Dom element for +self+.
//
// +initialOrientation+ sets the initial orientation (shockingly), it's currently unused
// so the initial value is actually undefined.
//
module.exports = function canBeOrientatedMixin(
  self,
  getDomElementCallback,
  orientationChangedHandler,
  initialOrientation
) {
  var _orientation = initialOrientation;

  OTHelpers.defineProperties(self, {
    isRotated: {
      get: function() {
        return this.orientation() &&
                  (this.orientation().videoOrientation === 270 ||
                   this.orientation().videoOrientation === 90);
      }
    },

    orientation: {
      get: function() { return _orientation; },
      set: function(orientation) {
        _orientation = orientation;

        var transform = VideoOrientationTransforms[orientation.videoOrientation] ||
                        VideoOrientationTransforms.ROTATED_NORMAL;

        switch (OTHelpers.env.name) {
          case 'Chrome':
          case 'Safari':
            getDomElementCallback().style.webkitTransform = transform;
            break;

          case 'IE':
            getDomElementCallback().style.msTransform = transform;
            break;

          default:
            // The standard version, just Firefox, Opera, and IE > 9
            getDomElementCallback().style.transform = transform;
        }

        orientationChangedHandler(_orientation);

      }
    },

    // see https://wiki.mozilla.org/WebAPI/AudioChannels
    // The audioChannelType is currently only available in Firefox. This property returns
    // "unknown" in other browser. The related HTML tag attribute is "mozaudiochannel"
    audioChannelType: {
      get: function() {
        if ('mozAudioChannelType' in this.domElement) {
          return this.domElement.mozAudioChannelType;
        }

        return 'unknown';
      },
      set: function(type) {
        if ('mozAudioChannelType' in this.domElement) {
          this.domElement.mozAudioChannelType = type;
        }
      }
    }
  });
};
