'use strict';

module.exports = function parseIceServers(message) {
  var iceServers;

  try {
    iceServers = JSON.parse(message.data).content.iceServers;
  } catch (e) {
    return [];
  }

  return iceServers.map(function(iceServer) {
    return {
      url: iceServer.url, // soon to be deprecated
      urls: iceServer.url,
      username: iceServer.username,
      credential: iceServer.credential
    };
  });
};
