'use strict';

module.exports = function(mod) {
  var attachments = {};

  Object.keys(mod).forEach(function(key) {
    attachments[key] = mod[key];
  });

  mod._attachToOTHelpers = attachments;
};
