'use strict';

var OTPlugin = require('../otplugin/otplugin.js');

var NativeRTCPeerConnection = (
  global.webkitRTCPeerConnection ||
  global.RTCPeerConnection ||
  global.mozRTCPeerConnection
);

module.exports = function createPeerConnection(config, constraints, webRTCStream, completion) {
  var pc;

  if (OTPlugin.isInstalled()) {
    OTPlugin.initPeerConnection(config, constraints, webRTCStream, completion);
    return;
  }

  try {
    pc = new NativeRTCPeerConnection(config, constraints);
  } catch (e) {
    // @todo why do we pass the message back up, rather than the error?
    completion(e.message);
    return;
  }

  completion(null, pc);
};
