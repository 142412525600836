'use strict';

// Unfortunately it looks like the text-encoding module always returns its implementations of
// TextEncoder and TextDecoder, so we wrap it here to expose the global (window) ones, if available.

var TextEncoding = require('text-encoding');

module.exports = {
  TextEncoder: global.TextEncoder || TextEncoding.TextEncoder,
  TextDecoder: global.TextDecoder || TextEncoding.TextDecoder
};
