'use strict';

var uuid = require('uuid');
var encoding = require('./encoding.js');
var RumorMessageTypes = require('./rumor_message_types.js');

//
//
// @references
// * https://tbwiki.tokbox.com/index.php/Rumor_Message_Packet
// * https://tbwiki.tokbox.com/index.php/Rumor_Protocol
//
var RumorMessage = function(type, toAddress, headers, data) {
  this.type = type;
  this.toAddress = toAddress;
  this.headers = headers;
  this.data = data;

  this.fromAddress = this.headers['X-TB-FROM-ADDRESS'];
  this.transactionId = this.headers['TRANSACTION-ID'];
  this.status = this.headers.STATUS;

  // Only status messages will have a status header. So a missing status header
  // does not indicate an eror.
  this.isError = this.status && this.status[0] !== '2';
};

module.exports = RumorMessage;

RumorMessage.prototype.serialize = function() {
  var strArray, dataView, i, j;
  var offset = 8;
  var cBuf = 7;
  var address = [];
  var headerKey = [];
  var headerVal = [];

  // The number of addresses
  cBuf++;

  // Write out the address.
  for (i = 0; i < this.toAddress.length; i++) {
    /*jshint newcap:false */
    address.push(new encoding.TextEncoder('utf-8').encode(this.toAddress[i]));
    cBuf += 2;
    cBuf += address[i].length;
  }

  // The number of parameters
  cBuf++;

  // Write out the params
  i = 0;

  for (var key in this.headers) {     //eslint-disable-line one-var
    if (!this.headers.hasOwnProperty(key)) {
      continue;
    }
    headerKey.push(new encoding.TextEncoder('utf-8').encode(key));
    headerVal.push(new encoding.TextEncoder('utf-8').encode(this.headers[key]));
    cBuf += 4;
    cBuf += headerKey[i].length;
    cBuf += headerVal[i].length;

    i++;
  }

  dataView = new encoding.TextEncoder('utf-8').encode(this.data);
  cBuf += dataView.length;

  // Let's allocate a binary blob of this size
  var buffer = new ArrayBuffer(cBuf);
  var uint8View = new Uint8Array(buffer, 0, cBuf);

  // We don't include the header in the lenght.
  cBuf -= 4;

  // Write out size (in network order)
  uint8View[0] = (cBuf & 0xFF000000) >>> 24;
  uint8View[1] = (cBuf & 0x00FF0000) >>> 16;
  uint8View[2] = (cBuf & 0x0000FF00) >>>  8;
  uint8View[3] = (cBuf & 0x000000FF) >>>  0;

  // Write out reserved bytes
  uint8View[4] = 0;
  uint8View[5] = 0;

  // Write out message type
  uint8View[6] = this.type;
  uint8View[7] = this.toAddress.length;

  // Now just copy over the encoded values..
  for (i = 0; i < address.length; i++) {
    strArray = address[i];
    uint8View[offset++] = strArray.length >> 8 & 0xFF;
    uint8View[offset++] = strArray.length >> 0 & 0xFF;
    for (j = 0; j < strArray.length; j++) {
      uint8View[offset++] = strArray[j];
    }
  }

  uint8View[offset++] = headerKey.length;

  // Write out the params
  for (i = 0; i < headerKey.length; i++) {
    strArray = headerKey[i];
    uint8View[offset++] = strArray.length >> 8 & 0xFF;
    uint8View[offset++] = strArray.length >> 0 & 0xFF;
    for (j = 0; j < strArray.length; j++) {
      uint8View[offset++] = strArray[j];
    }

    strArray = headerVal[i];
    uint8View[offset++] = strArray.length >> 8 & 0xFF;
    uint8View[offset++] = strArray.length >> 0 & 0xFF;
    for (j = 0; j < strArray.length; j++) {
      uint8View[offset++] = strArray[j];
    }
  }

  // And finally the data
  for (i = 0; i < dataView.length; i++) {
    uint8View[offset++] = dataView[i];
  }

  return buffer;
};

function toArrayBuffer(buffer) {
  var ab = new ArrayBuffer(buffer.length);
  var view = new Uint8Array(ab);
  for (var i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return ab;
}

RumorMessage.deserialize = function(buffer) {
  if (global.Buffer && global.Buffer.isBuffer(buffer)) {
    buffer = toArrayBuffer(buffer);
  }

  var type, strView, headerlen, headers, keyStr, valStr, length, i;
  var cBuf = 0;
  var offset = 8;
  var uint8View = new Uint8Array(buffer);

  // Write out size (in network order)
  cBuf += uint8View[0] << 24;
  cBuf += uint8View[1] << 16;
  cBuf += uint8View[2] <<  8;
  cBuf += uint8View[3] <<  0;

  type = uint8View[6];
  var address = [];

  for (i = 0; i < uint8View[7]; i++) {
    length = uint8View[offset++] << 8;
    length += uint8View[offset++];
    strView = new Uint8Array(buffer, offset, length);
    /*jshint newcap:false */
    address[i] = new encoding.TextDecoder('utf-8').decode(strView);
    offset += length;
  }

  headerlen = uint8View[offset++];
  headers = {};

  for (i = 0; i < headerlen; i++) {
    length = uint8View[offset++] << 8;
    length += uint8View[offset++];
    strView = new Uint8Array(buffer, offset, length);
    keyStr = new encoding.TextDecoder('utf-8').decode(strView);
    offset += length;

    length = uint8View[offset++] << 8;
    length += uint8View[offset++];
    strView = new Uint8Array(buffer, offset, length);
    valStr = new encoding.TextDecoder('utf-8').decode(strView);
    headers[keyStr] = valStr;
    offset += length;
  }

  var dataView = new Uint8Array(buffer, offset);
  var data = new encoding.TextDecoder('utf-8').decode(dataView);

  return new RumorMessage(type, address, headers, data);
};

RumorMessage.Connect = function(uniqueId, notifyDisconnectAddress) {
  var headers = {
    uniqueId: uniqueId,
    'TRANSACTION-ID': uuid(),
    notifyDisconnectAddress: notifyDisconnectAddress
  };

  return new RumorMessage(RumorMessageTypes.CONNECT, [], headers, '');
};

RumorMessage.Disconnect = function(reconnect) {
  return new RumorMessage(
    RumorMessageTypes.DISCONNECT,
    [],
    { reconnect: reconnect },
    ''
  );
};

RumorMessage.Subscribe = function(topics) {
  return new RumorMessage(RumorMessageTypes.SUBSCRIBE, topics, {}, '');
};

RumorMessage.Unsubscribe = function(topics) {
  return new RumorMessage(RumorMessageTypes.UNSUBSCRIBE, topics, {}, '');
};

RumorMessage.Publish = function(topics, message, headers) {
  return new RumorMessage(RumorMessageTypes.MESSAGE, topics, headers || {}, message || '');
};

RumorMessage.Status = function(topics, headers) {
  return new RumorMessage(RumorMessageTypes.STATUS, topics, headers || {}, '');
};

// This message is used to implement keepalives on the persistent
// socket connection between the client and server. Every time the
// client sends a PING to the server, the server will respond with
// a PONG.
RumorMessage.Ping = function() {
  return new RumorMessage(RumorMessageTypes.PING, [], {}, '');
};
