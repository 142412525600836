'use strict';

var AutoUpdater = require('./auto_updater.js');
var logging = require('./logging.js');
var OTHelpers = require('../common-js-helpers/OTHelpers.js');
var PluginProxies = require('./plugin_proxies.js');

var isReady = false;

var readyCallbacks = [];

    // This stores the error from the load attempt. We use
    // this if registerReadyListener gets called after a load
    // attempt fails.
var loadError;

var destroy = function destroy() {
  PluginProxies.removeAll();
};

var registerReadyListener = function registerReadyListener(callback) {
  if (!OTHelpers.isFunction(callback)) {
    logging.warn('registerReadyListener was called with something that was not a function.');
    return;
  }

  if (isReady) {
    callback.call(void 0, loadError);
  } else {
    readyCallbacks.push(callback);
  }
};

var notifyReadyListeners = function notifyReadyListeners() {
  isReady = true;

  var callback;

  while ((callback = readyCallbacks.pop()) && OTHelpers.isFunction(callback)) {
    callback.call(void 0, loadError);
  }
};

var onDomReady = function onDomReady() {
  AutoUpdater.get(function(err, updater) {
    if (err) {
      loadError = 'Error while loading the AutoUpdater: ' + err;
      notifyReadyListeners();
      return;
    }

    // If the plugin is out of date then we kick off the
    // auto update process and then bail out.
    if (updater.isOutOfDate()) {
      updater.autoUpdate();
      return;
    }

    // Inject the controller object into the page, wait for it to load or timeout...
    PluginProxies.createMediaCapturer(function(err) {
      loadError = err;

      if (!loadError && (!PluginProxies.mediaCapturer ||
            !PluginProxies.mediaCapturer.isValid())) {
        loadError = 'The TB Plugin failed to load properly';
      }

      notifyReadyListeners();

      OTHelpers.onDOMUnload(destroy);
    });
  });
};

OTHelpers.onDOMLoad(onDomReady);

module.exports = {
  listen: registerReadyListener,
  isReady: function() { return isReady; }
};
