'use strict';

var OTHelpers = require('../common-js-helpers/OTHelpers.js');

// A Factory method for generating simple state machine classes.
//
// @usage
//    var StateMachine = generateSimpleStateMachine('start', ['start', 'middle', 'end', {
//      start: ['middle'],
//      middle: ['end'],
//      end: ['start']
//    }]);
//
//    var states = new StateMachine();
//    state.current;            // <-- start
//    state.set('middle');
//
module.exports = function generateSimpleStateMachine(initialState, states, transitions) {
  var validStates = states.slice();
  var validTransitions = OTHelpers.clone(transitions);

  var isValidState = function(state) {
    return validStates.indexOf(state) !== -1;
  };

  var isValidTransition = function(fromState, toState) {
    return validTransitions[fromState] &&
      validTransitions[fromState].indexOf(toState) !== -1;
  };

  return function(stateChangeFailed) {
    var currentState = initialState;
    var previousState = null;

    this.current = currentState;

    function signalChangeFailed(message, newState) {
      stateChangeFailed({
        message: message,
        newState: newState,
        currentState: currentState,
        previousState: previousState
      });
    }

    // Validates +newState+. If it's invalid it triggers stateChangeFailed and returns false.
    function handleInvalidStateChanges(newState) {
      if (!isValidState(newState)) {
        signalChangeFailed('\'' + newState + '\' is not a valid state', newState);

        return false;
      }

      if (!isValidTransition(currentState, newState)) {
        signalChangeFailed('\'' + currentState + '\' cannot transition to \'' +
          newState + '\'', newState);

        return false;
      }

      return true;
    }

    this.set = function(newState) {
      if (!handleInvalidStateChanges(newState)) { return; }
      previousState = currentState;
      this.current = currentState = newState;
    };
  };
};
