'use strict';

var RTCStatsReport = function RTCStatsReport(reports) {
  for (var id in reports) {
    if (reports.hasOwnProperty(id)) {
      this[id] = reports[id];
    }
  }
};

RTCStatsReport.prototype.forEach = function(callback, context) {
  for (var id in this) {
    if (this.hasOwnProperty(id)) {
      callback.call(context, this[id]);
    }
  }
};

module.exports = RTCStatsReport;
