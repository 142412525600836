'use strict';

var assign = require('lodash.assign');
var once = require('lodash.once');
var uuid = require('uuid');

var analytics = require('../../analytics.js');
var ExceptionCodes = require('../../exception_codes.js');
var Dispatcher = require('./dispatcher.js');
var hasIceRestartsCapability = require('../../../helpers/hasIceRestartsCapability.js');
var hasRenegotiationCapability = require('../../../helpers/hasRenegotiationCapability.js');
var logging = require('../../logging.js');
var Message = require('./message.js');
var OTError = require('../../ot_error.js');
var OTHelpers = require('../../../common-js-helpers/OTHelpers.js');
var Signal = require('./signal.js');
var RumorSocket = require('../rumor/rumor_socket.js');

function SignalError(code, message) {
  this.code = code;
  this.message = message;

  // Undocumented. Left in for backwards compatibility:
  this.reason = message;
}

// The Dispatcher bit is purely to make testing simpler, it defaults to a new Dispatcher so in
// normal operation you would omit it.
var RaptorSocket = function RaptorSocket(
  connectionId,
  widgetId,
  messagingSocketUrl,
  symphonyUrl,
  dispatcher
) {
  var _apiKey, _sessionId, _token, _rumor, _completion, _p2p, _messagingServer;
  var _states = ['disconnected', 'connecting', 'connected', 'error', 'disconnecting'];

  var _dispatcher = dispatcher || new Dispatcher();

  //// Private API
  var setState = OTHelpers.statable(this, _states, 'disconnected');

  var logAnalyticsEvent = function(opt) {
    if (!opt.action || !opt.variation) {
      logging.warn('Expected action and variation');
    }

    analytics.logEvent(assign(
      {
        sessionId: _sessionId,
        partnerId: _apiKey,
        p2p: _p2p,
        messagingServer: _messagingServer,
        connectionId: connectionId
      },
      opt
    ));
  };

  var onConnectComplete = function onConnectComplete(error) {
    if (error) {
      setState('error');
    } else {
      setState('connected');
    }

    _completion.apply(null, arguments);
  };

  var onClose = function onClose(err) {
    var reason = 'clientDisconnected';
    if (!this.is('disconnecting') && _rumor.is('error')) {
      reason = 'networkDisconnected';
    }
    if (err && err.code === 4001) {
      reason = 'networkTimedout';
    }

    setState('disconnected');

    _dispatcher.onClose(reason);
  }.bind(this);

  var onError = function onError(err) {
    logging.error('OT.Raptor.Socket error:', err);
  };
  // @todo what does having an error mean? Are they always fatal? Are we disconnected now?

  var onReconnecting = function onReconnecting() {
    _dispatcher.onReconnecting();
  };

  var onReconnected = function onReconnected() {
    logAnalyticsEvent({
      action: 'Reconnect',
      variation: 'Success',
      retries: _rumor.reconnectRetriesCount(),
      messageQueueSize: _rumor.messageQueueSize(),
      socketId: _rumor.socketID()
    });

    _dispatcher.onReconnected();
  };

  var onReconnectAttempt = function onReconnectAttempt() {
    logAnalyticsEvent({
      action: 'Reconnect',
      variation: 'Attempt',
      retries: _rumor.reconnectRetriesCount(),
      messageQueueSize: _rumor.messageQueueSize(),
      socketId: _rumor.socketID()
    });
  };

  var convertRumorConnectError = function convertRumorConnectError(error) {
    var errorCode, errorMessage;
    var knownErrorCodes = [400, 403, 409];

    if (error.code === ExceptionCodes.CONNECT_FAILED) {
      errorCode = error.code;
      errorMessage = OTError.getTitleByCode(error.code);
    } else if (error.code && knownErrorCodes.indexOf(error.code) > -1) {
      errorCode = ExceptionCodes.CONNECT_FAILED;
      errorMessage = 'Received error response to connection create message.';
    } else {
      errorCode = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
      errorMessage = 'Unexpected server response. Try this operation again later.';
    }

    return {
      errorCode: errorCode,
      errorMessage: errorMessage
    };
  };

  var onReconnectFailure = function onReconnectFailure(error) {
    var converted = convertRumorConnectError(error);

    logAnalyticsEvent({
      action: 'Reconnect',
      variation: 'Failure',
      failureReason: 'ConnectToSession',
      failureCode: converted.errorCode,
      failureMessage: converted.errorMessage,
      messageQueueSize: _rumor.messageQueueSize(),
      socketId: _rumor.socketID()
    });
  };

  //// Public API

  this.connect = function(token, sessionInfo, completion) {
    if (!this.is('disconnected', 'error')) {
      logging.warn('Cannot connect the Raptor Socket as it is currently connected. You should ' +
        'disconnect first.');
      return;
    }

    setState('connecting');
    _apiKey = sessionInfo.partnerId;
    _sessionId = sessionInfo.sessionId;
    _p2p = sessionInfo.p2pEnabled;
    _messagingServer = sessionInfo.messagingServer;
    _token = token;
    _completion = completion;

    var rumorChannel = '/v2/partner/' + _apiKey + '/session/' + _sessionId;

    _rumor = new RaptorSocket.RumorSocket({
      messagingURL: messagingSocketUrl,
      notifyDisconnectAddress: symphonyUrl,
      connectionId: connectionId,
      enableReconnection: sessionInfo.reconnection
    });

    _rumor.onClose(onClose);
    _rumor.onError(onError);
    _rumor.onReconnecting(onReconnecting);
    _rumor.onReconnectAttempt(onReconnectAttempt);
    _rumor.onReconnectFailure(onReconnectFailure);
    _rumor.onReconnected(onReconnected);
    _rumor.onMessage(_dispatcher.dispatch.bind(_dispatcher));

    _rumor.connect(function(error) {
      if (error) {
        onConnectComplete({
          reason: 'WebSocketConnection',
          code: error.code,
          message: error.message
        });
        return;
      }

      logging.debug('OT.Raptor.Socket connected. Subscribing to ' +
        rumorChannel + ' on ' + messagingSocketUrl);

      _rumor.subscribe([rumorChannel]);

      var capabilities = [];
      var supportRenegotiation = (
        RaptorSocket.hasIceRestartsCapability() ||
        RaptorSocket.hasRenegotiationCapability()
      ) && sessionInfo.renegotiation;

      if (supportRenegotiation) {
        capabilities.push('renegotiation');
      }

      //connect to session
      var connectMessage = Message.connections.create(
        _apiKey,
        _sessionId,
        _rumor.id(),
        capabilities
      );
      this.publish(connectMessage, { 'X-TB-TOKEN-AUTH': _token }, true, function(error, reply) {
        if (error) {
          var converted = convertRumorConnectError(error);

          onConnectComplete({
            reason: 'ConnectToSession',
            code: converted.errorCode,
            message: converted.errorMessage,
            socketId: _rumor.socketID()
          });

          return;
        }

        var onSessionState = function onSessionState(error, sessionState) {
          if (error) {
            var errorCode, errorMessage;
            var knownErrorCodes = [400, 403, 409];

            if (knownErrorCodes.indexOf(error.code) > -1) {
              errorCode = ExceptionCodes.CONNECT_FAILED;
              errorMessage = 'Received error response to session read';
            } else {
              errorCode = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
              errorMessage = 'Unexpected server response. Try this operation again later.';
            }

            logAnalyticsEvent({
              action: 'Connect',
              variation: 'Failure',
              failureReason: 'GetSessionState',
              failureCode: errorCode,
              failureMessage: errorMessage,
              socketId: _rumor.socketID()
            });

            onConnectComplete({
              reason: 'GetSessionState',
              code: errorCode,
              message: errorMessage,
              socketId: _rumor.socketID()
            });
          } else {
            onConnectComplete(undefined, sessionState);
          }
        };

        if (reply.data) {
          // OPENTOK-27994: Unfortunately, we need to send a fake session#read into the dispatcher
          // in order to get the right side effects to happen to the Session. Ideally, we could
          // transform the data here and send it into the completion handler for Session to decide
          // for itself what to do with it. The problem is that SessionDispatcher contains both
          // transformation code that belongs here and Session manipulation that belongs in Session,
          // and refactoring that structure isn't appropriate right now.
          var transactionId = uuid();
          _dispatcher.registerCallback(transactionId, onSessionState);
          _dispatcher.emit('session#read', JSON.parse(reply.data), transactionId);
        } else {
          // Older implementations do not send session#read data in the connect reply, so we have
          // to get it the old way (OPENTOK-27775).
          this.publish(Message.sessions.get(_apiKey, _sessionId), {}, true, onSessionState);
        }
      }.bind(this));
    }.bind(this));
  };

  this.disconnect = function(drainSocketBuffer) {
    if (this.is('disconnected')) {
      return;
    }

    setState('disconnecting');
    _rumor.disconnect(drainSocketBuffer);
  };

  // Publishes +message+ to the Symphony app server.
  //
  // The completion handler is optional, as is the headers
  // dict, but if you provide the completion handler it must
  // be the last argument.
  //
  this.publish = function(message, headers, retryAfterReconnect, completion) {
    completion = completion || function() {};
    var completionOnce = once(completion);

    logging.debug('OT.Raptor.Socket Publish (ID:' + transactionId + ')');
    logging.debug(message);

    if (
      _rumor.isNot('connected', 'reconnecting') ||
      (_rumor.is('reconnecting') && !retryAfterReconnect)
    ) {
      completionOnce(new OTError(500, 'Not connected.'));
      logging.error('OT.Raptor.Socket: cannot publish until the socket is connected.');

      return undefined;
    }

    var transactionId = uuid();

    _dispatcher.registerCallback(transactionId, completionOnce);

    _rumor.publish(
      [symphonyUrl],
      message,
      OTHelpers.extend({}, headers, {
        'Content-Type': 'application/x-raptor+v2',
        'TRANSACTION-ID': transactionId,
        'X-TB-FROM-ADDRESS': _rumor.id()
      }),
      retryAfterReconnect,
      function(err) {
        // We want to propagate errors from rumor here. In particular, errors
        // related to not receiving a reply due to disconnection. However, when
        // a reply is received, the dispatcher may transform the reply, and may
        // generate an error that would not be recognized here. This isn't the
        // only awkward outcome related to the dispatcher design, and there are
        // plans to address this technical debt: OPENTOK-27994.
        if (err) {
          completionOnce.apply(undefined, arguments);
        }
      }
    );

    return transactionId;
  };

  // Register a new stream against _sessionId
  this.streamCreate = function(name, streamId, audioFallbackEnabled, channels, minBitrate,
    maxBitrate, completion) {
    var message = Message.streams.create(_apiKey,
                                         _sessionId,
                                         streamId,
                                         name,
                                         audioFallbackEnabled,
                                         channels,
                                         minBitrate,
                                         maxBitrate);

    this.publish(message, {}, true, function(error, message) {
      completion(error, streamId, message);
    });
  };

  this.streamDestroy = function(streamId) {
    this.publish(Message.streams.destroy(_apiKey, _sessionId, streamId), {}, true);
  };

  this.streamChannelUpdate = function(streamId, channelId, attributes) {
    this.publish(Message.streamChannels.update(_apiKey, _sessionId,
      streamId, channelId, attributes), {}, true);
  };

  this.subscriberCreate = function(streamId, subscriberId, channelsToSubscribeTo, completion) {
    this.publish(Message.subscribers.create(_apiKey, _sessionId,
      streamId, subscriberId, _rumor.id(), channelsToSubscribeTo), {}, true, completion);
  };

  this.subscriberDestroy = function(streamId, subscriberId) {
    this.publish(Message.subscribers.destroy(_apiKey, _sessionId,
      streamId, subscriberId), {}, true);
  };

  this.subscriberUpdate = function(streamId, subscriberId, attributes) {
    this.publish(Message.subscribers.update(_apiKey, _sessionId,
      streamId, subscriberId, attributes), {}, true);
  };

  this.subscriberChannelUpdate = function(streamId, subscriberId, channelId, attributes) {
    this.publish(Message.subscriberChannels.update(_apiKey, _sessionId,
      streamId, subscriberId, channelId, attributes), {}, true);
  };

  this.forceDisconnect = function(connectionIdToDisconnect, completion) {
    this.publish(Message.connections.destroy(_apiKey, _sessionId,
      connectionIdToDisconnect), {}, true, completion);
  };

  this.forceUnpublish = function(streamIdToUnpublish, completion) {
    this.publish(Message.streams.destroy(_apiKey, _sessionId,
      streamIdToUnpublish), {}, true, completion);
  };

  this.jsepCandidate = function(streamId, candidate) {
    this.publish(
      Message.streams.candidate(_apiKey, _sessionId, streamId, candidate), {}, true
    );
  };

  this.jsepCandidateP2p = function(streamId, subscriberId, candidate) {
    this.publish(
      Message.subscribers.candidate(_apiKey, _sessionId, streamId,
        subscriberId, candidate), {}, true
    );
  };

  this.jsepOffer = function(uri, offerSdp) {
    this.publish(Message.offer(uri, offerSdp), {}, true);
  };

  this.jsepAnswer = function(streamId, answerSdp) {
    this.publish(Message.streams.answer(_apiKey, _sessionId, streamId, answerSdp), {}, true);
  };

  this.jsepAnswerP2p = function(streamId, subscriberId, answerSdp) {
    this.publish(Message.subscribers.answer(_apiKey, _sessionId, streamId,
      subscriberId, answerSdp), {}, true);
  };

  this.signal = function(options, completion, logEventFn) {
    var signal = new Signal(_sessionId, _rumor.id(), options || {});

    if (!signal.valid) {
      if (completion && OTHelpers.isFunction(completion)) {
        completion(new SignalError(signal.error.code, signal.error.reason), signal.toHash());
      }

      return;
    }

    this.publish(signal.toRaptorMessage(), {}, signal.retryAfterReconnect, function(err) {
      var error, errorCode, errorMessage;
      var expectedErrorCodes = [400, 403, 404, 413, 500];

      if (err) {
        if (err.code && expectedErrorCodes.indexOf(err.code) > -1) {
          errorCode = err.code;
          errorMessage = err.message;
        } else {
          errorCode = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
          errorMessage = 'Unexpected server response. Try this operation again later.';
        }
        error = new SignalError(errorCode, errorMessage);
      } else {
        var typeStr = signal.data ? typeof (signal.data) : null;
        logEventFn('signal', 'send', { type: typeStr });
      }

      if (completion && OTHelpers.isFunction(completion)) { completion(error, signal.toHash()); }
    });
  };

  this.id = function() {
    return _rumor && _rumor.id();
  };
};

RaptorSocket.hasIceRestartsCapability = hasIceRestartsCapability;
RaptorSocket.hasRenegotiationCapability = hasRenegotiationCapability;
RaptorSocket.RumorSocket = RumorSocket;

module.exports = RaptorSocket;
